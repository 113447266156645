import PersonAddIcon from '@mui/icons-material/PersonAdd';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tab,
  Tabs,
} from '@mui/material';
import { sv as locale } from '@norban/locale';
import React, { useState } from 'react';

import CreateUserForm from '../scenes/UserList/components/CreateUserForm';

import UserServerSideAutocomplete from './UserServerSideAutocomplete';

type Props = {
  cancel: string;
  confirm: string;
  content: React.ReactNode;
  dense?: boolean;
  open: boolean;
  selectedUserId: string | null;
  title: string;
  onCancel: () => void;
  onConfirm: (userId: string) => void;
  onSelectedUserIdChanged: (userId: string | null) => void;
  limitToUserRoles?: ('admin' | 'user' | 'banned')[];
};

const UserCreateOrSearchForDialog = ({
  cancel,
  confirm,
  content,
  dense = true,
  open,
  selectedUserId,
  title,
  onSelectedUserIdChanged,
  onCancel,
  onConfirm,
  limitToUserRoles = undefined,
}: Props) => {
  const L = locale.backoffice.userCreateOrSearchFor;
  const [selectedTab, setSelectedTab] = useState<'create' | 'search'>('search');

  return (
    <Dialog open={open} onClose={onCancel} maxWidth="xs" fullWidth>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Box mb={3}>
          <Tabs
            value={selectedTab}
            onChange={(_, tab) => {
              onSelectedUserIdChanged(null);
              setSelectedTab(tab);
            }}
            sx={{ minHeight: 48 }}
          >
            <Tab
              icon={<PersonSearchIcon />}
              iconPosition="start"
              label={L.searchFor}
              value="search"
            />
            <Tab
              icon={<PersonAddIcon />}
              iconPosition="start"
              label={L.createNew}
              value="create"
            />
          </Tabs>
        </Box>
        {selectedTab === 'create' && (
          <CreateUserForm
            asDialog={false}
            open
            onClose={userId => onSelectedUserIdChanged(userId ?? null)}
          />
        )}
        {selectedTab === 'search' && (
          <UserServerSideAutocomplete
            autoFocus
            dense={dense}
            fullWidth
            selectedUserId={selectedUserId}
            limitToUserRoles={limitToUserRoles}
            onSelectedUserIdChanged={onSelectedUserIdChanged}
            sortAlphabetically
          />
        )}
        {content}
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="primary">
          {cancel}
        </Button>
        <Button
          onClick={() => {
            onConfirm(selectedUserId!);
          }}
          disabled={!selectedUserId}
          color="primary"
        >
          {confirm}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UserCreateOrSearchForDialog;
