import * as Sentry from '@sentry/react';
import { createBrowserHistory } from 'history';
import React from 'react';
import * as ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';

import App from './App';
import { NORBAN_ENVIRONMENT } from './config';
import configureStore from './configureStore';
import { SessionProvider } from './hooks/useSession';
import './index.css';

const { store } = configureStore();

const history = createBrowserHistory();

Sentry.init({
  // norban-backoffice
  dsn: 'https://6f14b0a0ab0dbb5eeed33bacb8e7a889@o482580.ingest.sentry.io/4506614267510784',
  environment: NORBAN_ENVIRONMENT,
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV5Instrumentation({ history }),
    }),
    new Sentry.Replay({
      maskAllText: false,
      blockAllMedia: false,
      // Allowlist specific URLs to be captured.
      // Since this is an internal tool, we allowlist all URLs.
      networkDetailAllowUrls: [/.*/],
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0,
  // Session Replay
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

ReactDOM.createRoot(document.getElementById('root')!).render(
  <Provider store={store}>
    <SessionProvider>
      <App />
    </SessionProvider>
  </Provider>,
);
