import { useEffect, useMemo, useRef, useState } from 'react';

const MAX_NUMBER_OF_CHECKED_ITEMS = 1000;

interface Entry {
  id: string;
}

const useCheckedList = <T extends Entry>(allEntries: T[]) => {
  const [checkedList, setCheckedList] = useState<T[]>([]);
  const entries = useMemo(
    () =>
      allEntries.length > MAX_NUMBER_OF_CHECKED_ITEMS
        ? allEntries.slice(0, MAX_NUMBER_OF_CHECKED_ITEMS)
        : allEntries,
    [allEntries],
  );

  const prevAllList = useRef<T[]>([]);

  // Clear checked list when external list of entries change
  useEffect(() => {
    if (
      prevAllList.current.length !== allEntries.length ||
      prevAllList.current.some(
        entry => !allEntries.find(entry2 => entry2.id === entry.id),
      )
    ) {
      setCheckedList([]);
    }
    prevAllList.current = [...allEntries];
  }, [allEntries]);

  const toggle = (entry: T) => {
    const index = checkedList.findIndex(c => c.id === entry.id);
    if (index === -1) {
      setCheckedList([...checkedList, entry]);
    } else {
      setCheckedList(checkedList.filter(c => c.id !== entry.id));
    }
  };

  const toggleAll = () => {
    if (checkedList.length === entries.length) {
      setCheckedList([]);
    } else {
      setCheckedList(entries);
    }
  };

  const clearAll = () => {
    setCheckedList([]);
  };

  const isChecked = (entry: T) => {
    return checkedList.some(c => c.id === entry.id);
  };

  const isAllChecked = () => {
    return entries.length > 0 && checkedList.length === entries.length;
  };

  return {
    checkedList,
    toggle,
    toggleAll,
    clearAll,
    isChecked,
    isAllChecked,
  };
};

export default useCheckedList;
