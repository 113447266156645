import {
  Card,
  CardContent,
  CardHeader,
  FormControlLabel,
  Switch,
} from '@mui/material';
import { sv as locale } from '@norban/locale';
import React from 'react';

import TextField from '../../../../components/form/TextField';
import { BofHomeProfileHomeFragment } from '../../../../generated/backend/graphql';
import { DispatchType } from '../../hooks/useHomeProfileReducer';

type Props = {
  home: BofHomeProfileHomeFragment;
  dispatch: DispatchType;
};

const MarketingCard = ({ home, dispatch }: Props) => {
  const L = locale.backoffice.homeMarketingCard;
  return (
    <Card>
      <CardHeader title={L.title} />
      <CardContent>
        <FormControlLabel
          label={home.marketingEnabled ? L.enabled : L.disabled}
          control={
            <Switch
              checked={home.marketingEnabled || false}
              onChange={e =>
                dispatch({
                  key: 'marketingEnabled',
                  value: e.target.checked,
                })
              }
              name="marketingEnabled"
              color="primary"
            />
          }
        />

        <TextField
          label={L.marketingTitle}
          value={home.marketingTitle}
          name="marketingTitle"
          dispatch={dispatch}
        />

        <TextField
          label={L.marketingDescription}
          value={home.marketingDescription}
          name="marketingDescription"
          multiline
          rows="4"
          dispatch={dispatch}
        />
      </CardContent>
    </Card>
  );
};

export default MarketingCard;
