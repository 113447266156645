import { Checkbox } from '@mui/material';
import React from 'react';

import { BofHomeProfileHomeFragment } from '../../generated/backend/graphql';
import { DispatchType } from '../../scenes/Home/hooks/useHomeProfileReducer';

type Props = {
  checked: boolean;
  dispatch: DispatchType;
  name: keyof BofHomeProfileHomeFragment;
};

const DispatchCheckbox = ({ checked, dispatch, name }: Props) => (
  <Checkbox
    checked={!!checked}
    onChange={evt => dispatch({ key: name, value: evt.target.checked })}
  />
);

export default DispatchCheckbox;
