import { gql, useLazyQuery, useMutation, useQuery } from '@apollo/client';
import DeleteIcon from '@mui/icons-material/Delete';
import OpenInBrowserIcon from '@mui/icons-material/Launch';
import RestoreIcon from '@mui/icons-material/RestoreFromTrash';
import {
  Alert,
  IconButton,
  Paper,
  Snackbar,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { sv as locale } from '@norban/locale';
import React, {
  useRef,
  useState,
  useCallback,
  useMemo,
  useEffect,
} from 'react';

import { DropZone } from '../../../components/DropZone';

const Q_ALL_FILES = gql`
  query BofUserFiles($userId: ID!) {
    user(id: $userId) {
      files {
        id
        deleted
        filename
        mimetype
        createdAt
        updatedAt
      }
    }
  }
`;

const Q_SIGNED_URL = gql`
  query BofUploadedFileUrl($userId: ID!, $id: ID!) {
    user(id: $userId) {
      files(filter: { ids: [$id] }) {
        filename
        signedUrl {
          url
        }
      }
    }
  }
`;

const M_UPLOAD_FILE = gql`
  mutation BofUploadFile($file: Upload!, $userId: ID!) {
    uploadUserFile(input: { file: $file, userId: $userId }) {
      id
      deleted
      filename
      mimetype
      createdAt
      updatedAt
    }
  }
`;

const M_SET_DELETED_FILE = gql`
  mutation BofDeleteUserFile($fileId: ID!, $deleted: Boolean!) {
    updateUserFile(input: { id: $fileId, deleted: $deleted }) {
      id
      deleted
      filename
      mimetype
      createdAt
      updatedAt
    }
  }
`;

const M_RENAME_FILE = gql`
  mutation BofRenameUserFile($fileId: ID!, $filename: String!) {
    updateUserFile(input: { id: $fileId, filename: $filename }) {
      id
      deleted
      filename
      mimetype
      createdAt
      updatedAt
    }
  }
`;

const UserUploadedFiles = ({ id }) => {
  const L = locale.backoffice;
  const fileInputRef = useRef();

  const [success, setSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [getFileStorageUrl, { loading: refetchLoading }] =
    useLazyQuery(Q_SIGNED_URL);

  const [uploadUserFile, { loading: uploadLoading }] =
    useMutation(M_UPLOAD_FILE);
  const [deleteRestoreUserFile, { loading: deleteRestoreLoading }] =
    useMutation(M_SET_DELETED_FILE);
  const [renameUserFile, { loading: renameLoading }] =
    useMutation(M_RENAME_FILE);

  const { loading: dataLoading, data } = useQuery(Q_ALL_FILES, {
    variables: {
      userId: id,
    },
  });

  // This is a bit of a hack...
  // If we call refetch directly, the data object doesn't change unless data
  // changed which doesn't trigger the DataGrid to reload for failed writes etc
  const [rows, setRows] = useState([]);
  useEffect(() => setRows(data?.user?.files.slice() || []), [data]);

  const openFileInNewWindow = useCallback(
    fileId => {
      const windowRef = window.open();
      windowRef.opener = null;

      (async () => {
        const url = await getFileStorageUrl({
          variables: { userId: id, id: fileId },
        });
        windowRef.location = url.data.user.files[0].signedUrl.url;
      })();
    },
    [getFileStorageUrl, id],
  );

  const uploadFile = useCallback(
    async file => {
      try {
        const { data } = await uploadUserFile({
          variables: { userId: id, file },
        });
        setRows([...rows, data.uploadUserFile]);
        setSuccess(true);
      } catch (error) {
        setSuccess(false);
        setErrorMessage(error.message);
        // reset the picker input
        fileInputRef.current.value = '';
      }
    },
    [id, rows, uploadUserFile],
  );

  const deleteRestoreFile = useCallback(
    async (fileId, deleted) => {
      try {
        const { data } = await deleteRestoreUserFile({
          variables: { fileId, deleted },
        });
        setRows([
          ...rows.filter(row => row.id !== fileId),
          data.updateUserFile,
        ]);
        setSuccess(true);
      } catch (error) {
        setErrorMessage(error.message);
      }
    },
    [deleteRestoreUserFile, rows],
  );

  const renameFile = useCallback(
    async (fileId, filename) => {
      try {
        const data = await renameUserFile({
          variables: {
            fileId,
            filename,
          },
        });
        setRows([...rows.filer(row => row.id !== fileId), data.updateUserFile]);
        setSuccess(true);
      } catch (error) {
        setErrorMessage(error.message);
      }
    },
    [renameUserFile, rows],
  );

  const loading = useMemo(
    () =>
      deleteRestoreLoading ||
      uploadLoading ||
      dataLoading ||
      renameLoading ||
      refetchLoading,
    [
      deleteRestoreLoading,
      uploadLoading,
      dataLoading,
      renameLoading,
      refetchLoading,
    ],
  );

  const columns = useMemo(
    () => [
      {
        field: 'id',
        headerName: 'Link',
        sortable: false,
        width: 100,
        renderCell: ({ value, row }) => (
          <Tooltip title="Öppna fil">
            <IconButton
              aria-label="open"
              onClick={() => openFileInNewWindow(value)}
              disabled={loading || row.deleted}
            >
              <OpenInBrowserIcon />
            </IconButton>
          </Tooltip>
        ),
      },
      {
        field: 'filename',
        headerName: L.userFilesView.filename,
        type: 'string',
        flex: 4,
        editable: true,
        valueSetter: ({ row, value }) => {
          renameFile(row.id, value);
          return { ...row, filename: value };
        },
        renderCell: ({ row, value }) =>
          row.deleted ? (
            <Typography
              variant="body1"
              color="
              textSecondary"
              style={{ textDecoration: 'line-through' }}
            >
              {value}
            </Typography>
          ) : (
            <Typography variant="body1">{value}</Typography>
          ),
      },

      {
        field: 'mimetype',
        headerName: L.userFilesView.filetype,
        type: 'string',
        flex: 1,
      },
      {
        field: 'createdAt',
        headerName: L.userFilesView.uploadDate,
        type: 'date',
        valueGetter: ({ value }) => new Date(value),
        renderCell: ({ value }) =>
          new Intl.DateTimeFormat('sv-SE', {
            day: 'numeric',
            month: 'short',
            year: 'numeric',
          }).format(value),
        flex: 1.5,
      },
      {
        field: 'updatedAt',
        headerName: L.userFilesView.updateDate,
        type: 'date',
        valueGetter: ({ value }) => new Date(value),
        renderCell: ({ value }) =>
          new Intl.DateTimeFormat('sv-SE', {
            day: 'numeric',
            month: 'short',
            year: 'numeric',
          }).format(value),
        flex: 1.5,
      },
      {
        field: 'deleted',
        headerName: 'Släng',
        width: 100,
        align: 'right',
        renderCell: ({ row, value: deleted }) =>
          deleted ? (
            <Tooltip title="Återställ fil">
              <IconButton
                aria-label="restore"
                onClick={() => deleteRestoreFile(row.id, false)}
                disabled={loading}
              >
                <RestoreIcon />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip title="Ta bort fil">
              <IconButton
                aria-label="delete"
                onClick={() => deleteRestoreFile(row.id, true)}
                disabled={loading}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          ),
      },
    ],
    [
      L.userFilesView.filename,
      L.userFilesView.filetype,
      L.userFilesView.updateDate,
      L.userFilesView.uploadDate,
      deleteRestoreFile,
      loading,
      openFileInNewWindow,
      renameFile,
    ],
  );

  return (
    <div>
      <Snackbar
        open={errorMessage}
        autoHideDuration={6000}
        onClose={() => setErrorMessage()}
      >
        <Alert severity="error">
          {errorMessage || 'Unknown error occured'}
        </Alert>
      </Snackbar>

      <Snackbar
        open={success}
        autoHideDuration={6000}
        onClose={() => setSuccess(false)}
      >
        <Alert severity="success">{L.userFilesView.success}</Alert>
      </Snackbar>

      <Stack spacing={2}>
        <DropZone onFileSelected={uploadFile} loading={uploadLoading} />

        <Paper>
          <DataGrid
            initialState={{
              sorting: {
                sortModel: [
                  { field: 'deleted', sort: 'asc' },
                  { field: 'createdAt', sort: 'desc' },
                ],
              },
            }}
            autoHeight
            columns={columns}
            rows={rows}
          />
        </Paper>
      </Stack>
    </div>
  );
};

export default UserUploadedFiles;
