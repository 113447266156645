import { Stack } from '@mui/material';
import React from 'react';

import UserActivityLogCard from './UserActivityLogCard';
import UserCrmActionCard from './UserCrmActionCard';

type Props = {
  homeId?: string;
  userId: string;
};

export default function UserCrmAndLog({ homeId = undefined, userId }: Props) {
  return (
    <Stack spacing={3}>
      <UserCrmActionCard homeId={homeId} userId={userId} />
      <UserActivityLogCard userId={userId} />
    </Stack>
  );
}
