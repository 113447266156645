import { PropsOf } from '@emotion/react';
import { TextField as MuiTextField } from '@mui/material';
import React from 'react';

export type PassthroughProps<N extends string, T> = {
  dispatch: (action: { key: N; value: T | null }) => void;
  name: N;
  value: T | undefined | null;
} & PropsOf<typeof MuiTextField>;

type Props<N extends string, T> = PassthroughProps<N, T> & {
  onEncode: (value: T | undefined | null) => string;
  onDecode: (value: string) => T | null;
};

const Field = <N extends string, T>({
  dispatch,
  name,
  value,
  margin = 'normal',
  onEncode,
  onDecode,
  ...props
}: Props<N, T>) => {
  return (
    <MuiTextField
      fullWidth
      value={onEncode(value)}
      onChange={evt => {
        dispatch({
          key: name,
          value: onDecode(evt.target.value),
        });
      }}
      variant="outlined"
      margin={margin}
      {...props}
    />
  );
};

export default Field;
