import { useMutation, useQuery, gql } from '@apollo/client';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  IconButton,
  Paper,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { sv as locale } from '@norban/locale';
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { storeFilterUserLabels } from '../../actions/userActions';
import QueryError from '../../components/QueryError';
import QueryLoading from '../../components/QueryLoading';
import { tableRowHoverColor } from '../../theme';

import UserLabelDialog from './components/UserLabelDialog';

const Q_ALL_USER_LABELS = gql`
  query BofGetAllUserLabels {
    userLabels {
      id
      label
      teamsHook
    }
  }
`;

const M_CREATE_USER_LABEL = gql`
  mutation BofCreateUserLabel($input: NewUserLabelInput!) {
    createUserLabel(input: $input) {
      label
      teamsHook
    }
  }
`;

const M_UPDATE_USER_LABEL = gql`
  mutation BofUpdateUserLabel($id: ID!, $input: UpdatedUserLabelInput!) {
    updateUserLabel(id: $id, input: $input)
  }
`;

const M_DELETE_USER_LABEL = gql`
  mutation BofDeleteUserLabel($id: ID!) {
    deleteUserLabel(id: $id)
  }
`;

const useStyles = makeStyles(theme => ({
  fab: {
    position: 'fixed',
    bottom: theme.spacing(4),
    right: theme.spacing(4),
  },
  row: {
    cursor: 'pointer',
    [theme.breakpoints.up('sm')]: {
      '&:hover': {
        backgroundColor: tableRowHoverColor,
      },
    },
  },
}));

function ellipsize(str) {
  if (str.length < 45) return str;
  return `${str.substring(0, 32)}...${str.substring(str.length - 10)}`;
}

const UserLabel = () => {
  const L = locale.backoffice.userLabelView;
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [label, setLabel] = useState('');
  const [teamsHook, setTeamsHook] = useState('');
  const [snackbar, setSnackbar] = useState({ open: false, message: '' });

  const [dialog, setDialog] = useState({ open: false, entry: null });

  const dispatch = useDispatch();

  const { loading, error, data, refetch } = useQuery(Q_ALL_USER_LABELS);

  const filterUserLabels = useSelector(
    state =>
      state.userFilter.filterUserLabels ||
      data?.userLabels?.map(() => true) ||
      [],
  );

  const [createLabel] = useMutation(M_CREATE_USER_LABEL, {
    onCompleted: () => refetch(),
    onError: () => {
      setSnackbar({ open: true, message: L.failedCreate });
    },
  });
  const [updateLabel] = useMutation(M_UPDATE_USER_LABEL, {
    onCompleted: () => refetch(),
    onError: () => {
      setSnackbar({ open: true, message: L.failedCreate });
    },
  });
  const [deleteLabel] = useMutation(M_DELETE_USER_LABEL, {
    onCompleted: () => refetch(),
    onError: () => {
      setSnackbar({ open: true, message: L.failedDelete });
    },
  });

  if (loading) {
    return <QueryLoading />;
  }

  if (error) {
    return <QueryError error={error} data={data} />;
  }

  const handleSubmit = () => {
    createLabel({
      variables: {
        input: {
          label,
          teamsHook,
        },
      },
    });

    storeFilterUserLabels(dispatch, [...filterUserLabels, false]);
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClick = entry => setDialog({ entry, open: true });

  return (
    <>
      <Paper>
        <Table className="table" aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Id</TableCell>
              <TableCell>User label</TableCell>
              <TableCell>Teams</TableCell>
              <TableCell>{L.delete}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.userLabels?.map((label, index) => (
              <TableRow
                key={label.id}
                className={classes.row}
                onClick={() => handleClick(label)}
              >
                <TableCell>{label.id}</TableCell>
                <TableCell>{label.label}</TableCell>
                <TableCell>{ellipsize(label.teamsHook)}</TableCell>
                <TableCell>
                  <IconButton
                    aria-label="delete"
                    onClick={evt => {
                      evt.stopPropagation();
                      deleteLabel({ variables: { id: label.id } });

                      const newUserLabelFilter = [
                        ...filterUserLabels.slice(0, index),
                        ...filterUserLabels.slice(index + 1),
                      ];
                      storeFilterUserLabels(dispatch, newUserLabelFilter);
                    }}
                    size="large"
                  >
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
      <Fab
        color="primary"
        aria-label="add"
        className={classes.fab}
        onClick={() => setOpen(true)}
      >
        <AddIcon />
      </Fab>
      {/* Add Dialog */}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">{L.addTitle}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="User label"
            fullWidth
            value={label}
            onChange={evt => setLabel(evt.target.value)}
          />
          <TextField
            margin="dense"
            label="Teams"
            fullWidth
            value={teamsHook}
            onChange={evt => setTeamsHook(evt.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {L.cancel}
          </Button>
          <Button onClick={handleSubmit} color="primary">
            {L.add}
          </Button>
        </DialogActions>
      </Dialog>
      {/* Edit Dialog */}
      <UserLabelDialog
        open={dialog.open}
        entry={dialog.entry}
        onSubmit={async entry => {
          // Submit!
          await updateLabel({
            variables: {
              id: entry.id,
              input: {
                label: entry.label,
                teamsHook: entry.teamsHook,
              },
            },
          });
          // refetch();
        }}
        onClose={() => {
          setDialog({ open: false, entry: null });
        }}
      />
      <Snackbar
        open={snackbar.open}
        onClose={() => setSnackbar({ open: false, message: '' })}
        message={snackbar.message}
        autoHideDuration={5000}
      />
    </>
  );
};

export default UserLabel;
