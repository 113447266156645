/* eslint-disable import/no-unused-modules */
// Theme is inspired by: https://react-material-kit.devias.io/
import { createTheme } from '@mui/material/styles';

const color = (code: string) => ({ main: code });

export const beige = '#f2f2f2';
export const black = '#000000';
export const blue = '#275390';
export const blueLight = '#3e83e3';
export const blueLight2 = '#0269ce';
export const brightSand = '#e9e0d7';
export const brown = '#c48669';
export const coolGray = '#aab4be';
export const lightGrey = '#e6e6e6';
export const coral = '#d87762';
export const darkBlue = '#172d44';
export const darkGreen = '#14594c';
export const darkSand = '#81755e';
export const green = '#4f8462';
export const grey = '#a1a1a1';
export const nightBlue = '#22242c';
export const norbrown = '#b0a08c';
export const offBlack = '#282828';
export const offWhite = '#fbfbfb';
export const orange = '#db8053';
export const peach = '#edd6ca';
export const pink = '#e3928f';
export const purple = '#543744';
export const sand = '#c7bbaf';
export const steelGray = '#73818e';
export const white = '#ffffff';
export const yellow = '#e7b558';
export const isabelline = '#f3f3ef';

export const tableRowHoverColor = '#eee';

export const appBarHeight = 64;

// All the following keys are optional, as default values are provided.
export const theme = createTheme({
  palette: {
    background: { default: isabelline },
    primary: color(blueLight2),
    secondary: color(darkBlue),
    error: color(coral),
    // Used by `getContrastText()` to maximize the contrast between the background and
    // the text.
    contrastThreshold: 3,
    // Used to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
    info: color(green),
  },

  typography: {
    fontFamily: ['Circular', 'helvetica', 'sans-serif'].join(','),
    overline: {
      fontSize: 11,
      fontWeight: 500,
      letterSpacing: '0.33px',
      color: '#546e7a',
    },
  },
});
