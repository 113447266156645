import { Box, CircularProgress, Grid } from '@mui/material';
import React from 'react';

const SpinnerRow = () => (
  <Grid container>
    <Grid item xs={12}>
      <Box
        m={2}
        mt={5}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <CircularProgress />
      </Box>
    </Grid>
  </Grid>
);

export default SpinnerRow;
