import { useMutation, gql } from '@apollo/client';
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Snackbar,
} from '@mui/material';
import { sv as locale } from '@norban/locale';
import React, { useEffect, useState } from 'react';

const M_UPDATE_USER = gql`
  mutation BofUpdateUser($userId: ID!, $input: UpdatedUserInput!) {
    updateUser(userId: $userId, input: $input) {
      userLabelIds
    }
  }
`;

const EditUserLabelsDialog = ({
  open,
  onClose,
  userId,
  userLabelIds,
  userAssociatedLabels,
}) => {
  const L = locale.backoffice.editUserLabels;

  const createSelectedMap = (userLabels, userAssociatedLabels) =>
    userLabels?.map(
      label => !!userAssociatedLabels?.find(ual => ual === label.id),
    );
  const [selectedMap, setSelectedMap] = useState(
    createSelectedMap(userLabelIds, userAssociatedLabels),
  );

  const [snackbar, setSnackbar] = useState({ open: false, message: '' });

  useEffect(() => {
    setSelectedMap(createSelectedMap(userLabelIds, userAssociatedLabels));
  }, [userLabelIds, userAssociatedLabels, open]);

  const [updateUser] = useMutation(M_UPDATE_USER, {
    onCompleted: () => {
      onClose(true);
    },
    onError: () => {
      setSnackbar({ open: true, message: L.failed });
    },
  });

  return (
    <>
      <Dialog open={open} onClose={() => onClose(false)}>
        <form>
          <DialogTitle>{L.title}</DialogTitle>
          <DialogContent>
            <div>
              {userLabelIds?.map((l, i) => {
                return (
                  <Box mb={1} mr={1} key={l.label}>
                    <Chip
                      key={l.label}
                      color={selectedMap?.[i] ? 'primary' : 'default'}
                      label={l.label}
                      clickable
                      onClick={() => {
                        const newSelectedMap = Object.assign([], selectedMap, {
                          [i]: !selectedMap[i],
                        });
                        setSelectedMap(newSelectedMap);
                      }}
                    />
                  </Box>
                );
              })}
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                onClose(false);
              }}
              color="primary"
            >
              {L.cancel}
            </Button>
            <Button
              onClick={() => {
                const updateIds = selectedMap
                  .map((item, i) => item && userLabelIds?.[i].id)
                  .filter(Boolean);
                updateUser({
                  variables: {
                    userId,
                    input: { userLabelIds: updateIds },
                  },
                });
              }}
              color="primary"
            >
              {L.submit}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <Snackbar
        open={snackbar.open}
        onClose={() => {
          setSnackbar({ open: false, message: '' });
          onClose(false);
        }}
        message={snackbar.message}
        autoHideDuration={5000}
      />
    </>
  );
};

export default EditUserLabelsDialog;
