import { z } from 'zod';

export const JwtPayload = z.object({
  exp: z.number(),
  iat: z.number(),
  iss: z.string(),
});

export const ApiPayloadZod = z.object({
  user: z.number(),
  role: z.string(),
});
export type ApiPayload = z.infer<typeof ApiPayloadZod>;

export const LoginSessionPayloadZod = z.object({
  user: z.number(),
  id: z.number().optional(),
  pin: z.string().optional(),
});
export type LoginSessionPayload = z.infer<typeof LoginSessionPayloadZod>;

export const LoginSessionPayloadFullZod = z.intersection(
  LoginSessionPayloadZod,
  JwtPayload,
);

export const LimitedScopePayloadZod = z.object({
  user: z.number(),
  scopes: z.array(z.string()),
});
export type LimitedScopePayload = z.infer<typeof LimitedScopePayloadZod>;

export const BankidSignaturePayloadZod = z.object({
  // Personal identity number
  pin: z.string(),
  name: z.string(),
  // The signed object (if any)
  s: z.object({
    m: z.optional(z.string()),
    hm: z.optional(z.string()),
    ext: z.optional(z.string()),
  }),
});
export type BankidSignaturePayload = z.infer<typeof BankidSignaturePayloadZod>;

export const BankidSessionIdPayloadZod = z.object({
  id: z.string(),
  // The signed object (if any)
  s: z.object({
    m: z.optional(z.string()),
    hm: z.optional(z.string()),
  }),
});
export type BankidSessionIdPayload = z.infer<typeof BankidSessionIdPayloadZod>;
