import {
  RESET_FILTERS,
  STORE_EXPANDED,
  STORE_FILTER_ASSIGNED_AGENT_ID,
  STORE_FILTER_CRM_ACTIONS_ENABLED,
  STORE_FILTER_CRM_ACTIONS_INVERTED,
  STORE_FILTER_CRM_ACTION_DATE_END,
  STORE_FILTER_CRM_ACTION_DATE_START,
  STORE_FILTER_CRM_ASSIGNED_USER_ID,
  STORE_FILTER_FOLLOWERS_DATE_END,
  STORE_FILTER_FOLLOWERS_DATE_START,
  STORE_FILTER_FOLLOWERS_ENABLED,
  STORE_FILTER_USER_HAS_EMAIL_ENABLED,
  STORE_FILTER_HOMES_ENABLED,
  STORE_FILTER_HOMES_STATES,
  STORE_FILTER_HOME_CREATION_DATE_ENABLED,
  STORE_FILTER_HOME_CREATION_DATE_END,
  STORE_FILTER_HOME_CREATION_DATE_START,
  STORE_FILTER_HOME_TYPES,
  STORE_FILTER_HOME_TYPES_ENABLED,
  STORE_FILTER_LIVING_AREA_RANGE,
  STORE_FILTER_LIVING_AREA_RANGE_ENABLED,
  STORE_FILTER_MESSAGES_ENABLED,
  STORE_FILTER_MESSAGES_INVERTED,
  STORE_FILTER_MULTI_AREA,
  STORE_FILTER_MULTI_AREA_ENABLED,
  STORE_FILTER_ROLES,
  STORE_FILTER_ROLES_ENABLED,
  STORE_FILTER_ROOMS_RANGE,
  STORE_FILTER_ROOMS_RANGE_ENABLED,
  STORE_FILTER_USER_CREATED_DATE_ENABLED,
  STORE_FILTER_USER_CREATED_DATE_END,
  STORE_FILTER_USER_CREATED_DATE_START,
  STORE_FILTER_USER_HAS_EMAIL,
  STORE_FILTER_USER_HAS_HOME,
  STORE_FILTER_USER_HAS_HOME_ENABLED,
  STORE_FILTER_USER_LABELS,
  STORE_FILTER_USER_LABELS_ENABLED,
  STORE_FILTER_USER_LABELS_INVERTED,
  STORE_HAS_ASSIGNED_AGENT_ENABLED,
  STORE_HAS_ASSIGNED_AGENT_INVERTED,
  STORE_ORDER,
  STORE_ORDER_BY,
  STORE_ORDER_USERS_ENABLED,
  STORE_INCLUDE_ASSOCIATED_AREAS,
} from '../actions/userActions';
import { HomeState, HomeType, Role } from '../generated/backend/graphql';

const DEFAULT_ROOMS_RANGE = [0, 10];
const DEFAULT_LIVING_AREA_RANGE = [0, 200];

const defaultState = {
  // name -> boolean (default false)
  filterExpanded: {
    user: true,
  },

  filterAreas: [],
  filterAreasEnabled: false,

  filterCrmActionsDateEnd: undefined,
  filterCrmActionsDateStart: undefined,
  filterCrmActionsEnabled: false,
  filterCrmActionsInverted: false,
  // filterCrmAssignedUserId:
  // - 'DEFAULT': Filter set to current admin user
  // - User ID: Set filter to specific admin user
  // - undefined: No filter applied
  // - null: Filter unassigned
  filterCrmAssignedUserId: 'DEFAULT',

  filterFollowersEnabled: false,
  filterFollowersEndDate: null,
  filterFollowersStartDate: null,

  filterUserHasHomeEnabled: false,
  filterUserHasHome: true,

  filterUserHasEmailEnabled: false,
  filterUserHasEmail: true,

  // Default all checked
  filterHomeStates: Object.keys(HomeState).map(() => true),
  filterHomeStatesEnabled: false,

  filterLivingAreaRangeEnabled: false,
  filterLivingAreaRange: DEFAULT_LIVING_AREA_RANGE,

  filterUserCreatedDateEnabled: false,
  filterUserCreatedDateEnd: null,
  filterUserCreatedDateStart: null,

  filterMessagesEnabled: false,
  filterMessagesInverted: false,

  // Default all checked
  filterRolesEnabled: false,
  filterRoles: Object.values(Role).map(value => value === Role.User),

  filterRoomsRangeEnabled: false,
  filterRoomsRange: DEFAULT_ROOMS_RANGE,

  filterUserLabels: null,
  filterUserLabelsEnabled: false,
  filterUserLabelsInverted: false,

  filterHomeCreationDateEnabled: false,
  filterHomeCreationDateEnd: undefined,
  filterHomeCreationDateStart: undefined,

  filterHomeTypes: Object.values(HomeType).map(() => true),
  filterHomeTypesEnabled: false,

  order: 'asc',
  orderBy: 'Current task',
  orderUsersEnabled: false,
  hasAssignedAgentEnabled: false,
  hasAssignedAgentInverted: false,
  filterAssignedAgent: null,
  includeAssociatedAreas: true,
};

export default (state = defaultState, action = {}) => {
  const { type, value } = action;
  switch (type) {
    case STORE_ORDER:
      return {
        ...state,
        order: value,
      };
    case STORE_ORDER_BY:
      return {
        ...state,
        orderBy: value,
      };
    case STORE_FILTER_HOMES_ENABLED:
      return {
        ...state,
        filterHomeStatesEnabled: value,
      };
    case STORE_FILTER_USER_HAS_HOME:
      return {
        ...state,
        filterUserHasHome: value,
      };
    case STORE_FILTER_USER_HAS_HOME_ENABLED:
      return {
        ...state,
        filterUserHasHomeEnabled: value,
      };
    case STORE_FILTER_ROLES_ENABLED:
      return {
        ...state,
        filterRolesEnabled: value,
      };
    case STORE_FILTER_ROLES:
      return {
        ...state,
        filterRoles: value,
      };
    case STORE_FILTER_HOMES_STATES:
      return {
        ...state,
        filterHomeStates: value,
      };
    case STORE_FILTER_USER_LABELS_ENABLED:
      return {
        ...state,
        filterUserLabelsEnabled: value,
      };
    case STORE_FILTER_USER_LABELS_INVERTED:
      return {
        ...state,
        filterUserLabelsInverted: value,
      };
    case STORE_FILTER_USER_LABELS:
      return {
        ...state,
        filterUserLabels: value,
      };
    case STORE_FILTER_CRM_ACTIONS_ENABLED:
      return {
        ...state,
        filterCrmActionsEnabled: value,
      };
    case STORE_FILTER_CRM_ACTIONS_INVERTED:
      return {
        ...state,
        filterCrmActionsInverted: value,
      };
    case STORE_FILTER_CRM_ASSIGNED_USER_ID:
      return {
        ...state,
        filterCrmAssignedUserId: value,
      };
    case STORE_FILTER_MESSAGES_ENABLED:
      return {
        ...state,
        filterMessagesEnabled: value,
      };
    case STORE_FILTER_MESSAGES_INVERTED:
      return {
        ...state,
        filterMessagesInverted: value,
      };
    case STORE_FILTER_CRM_ACTION_DATE_START:
      return {
        ...state,
        filterCrmActionsDateStart: value,
      };
    case STORE_FILTER_CRM_ACTION_DATE_END:
      return {
        ...state,
        filterCrmActionsDateEnd: value,
      };
    case STORE_EXPANDED:
      return {
        ...state,
        filterExpanded: {
          ...state.filterExpanded,
          [action.name]: action.expanded,
        },
      };
    case STORE_FILTER_USER_CREATED_DATE_ENABLED:
      return {
        ...state,
        filterUserCreatedDateEnabled: value,
      };
    case STORE_FILTER_USER_CREATED_DATE_START:
      return {
        ...state,
        filterUserCreatedDateStart: value,
      };
    case STORE_FILTER_USER_CREATED_DATE_END:
      return {
        ...state,
        filterUserCreatedDateEnd: value,
      };
    case STORE_FILTER_FOLLOWERS_ENABLED:
      return {
        ...state,
        filterFollowersEnabled: value,
      };
    case STORE_FILTER_FOLLOWERS_DATE_START:
      return {
        ...state,
        filterFollowersStartDate: value,
      };
    case STORE_FILTER_FOLLOWERS_DATE_END:
      return {
        ...state,
        filterFollowersEndDate: value,
      };
    case STORE_ORDER_USERS_ENABLED:
      return {
        ...state,
        orderUsersEnabled: value,
      };
    case STORE_HAS_ASSIGNED_AGENT_ENABLED:
      return {
        ...state,
        hasAssignedAgentEnabled: value,
      };
    case STORE_HAS_ASSIGNED_AGENT_INVERTED:
      return {
        ...state,
        hasAssignedAgentInverted: value,
      };
    case STORE_FILTER_ASSIGNED_AGENT_ID:
      return {
        ...state,
        filterAssignedAgentId: value,
      };
    case STORE_FILTER_ROOMS_RANGE_ENABLED:
      return {
        ...state,
        filterRoomsRangeEnabled: value,
      };
    case STORE_FILTER_ROOMS_RANGE:
      return {
        ...state,
        filterRoomsRange: value,
      };
    case STORE_FILTER_LIVING_AREA_RANGE_ENABLED:
      return {
        ...state,
        filterLivingAreaRangeEnabled: value,
      };
    case STORE_FILTER_LIVING_AREA_RANGE:
      return {
        ...state,
        filterLivingAreaRange: value,
      };
    case STORE_FILTER_MULTI_AREA:
      return {
        ...state,
        filterAreas: value,
      };
    case STORE_FILTER_MULTI_AREA_ENABLED:
      return {
        ...state,
        filterAreasEnabled: value,
      };
    case STORE_FILTER_HOME_CREATION_DATE_ENABLED:
      return {
        ...state,
        filterHomeCreationDateEnabled: value,
      };
    case STORE_FILTER_HOME_CREATION_DATE_START:
      return {
        ...state,
        filterHomeCreationDateStart: value,
      };
    case STORE_FILTER_HOME_CREATION_DATE_END:
      return {
        ...state,
        filterHomeCreationDateEnd: value,
      };
    case STORE_FILTER_HOME_TYPES:
      return {
        ...state,
        filterHomeTypes: value,
      };
    case STORE_FILTER_HOME_TYPES_ENABLED:
      return {
        ...state,
        filterHomeTypesEnabled: value,
      };
    case STORE_FILTER_USER_HAS_EMAIL_ENABLED:
      return {
        ...state,
        filterUserHasEmailEnabled: value,
      };
    case STORE_FILTER_USER_HAS_EMAIL:
      return {
        ...state,
        filterUserHasEmail: value,
      };
    case STORE_INCLUDE_ASSOCIATED_AREAS:
      return {
        ...state,
        includeAssociatedAreas: value,
      };
    case RESET_FILTERS:
      return defaultState;
    default:
      return state;
  }
};
