import { useQuery } from '@apollo/client';
import AndroidIcon from '@mui/icons-material/Android';
import AppleIcon from '@mui/icons-material/Apple';
import ComputerIcon from '@mui/icons-material/Computer';
import StorageIcon from '@mui/icons-material/Storage';
import {
  Button,
  CircularProgress,
  Paper,
  Stack,
  TextField,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/system';
import React, { useState } from 'react';

import {
  BofUserEventsDocument,
  BofUserEventsQuery,
} from '../../../generated/backend/graphql';
import { steelGray } from '../../../theme';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  stack: { marginBottom: '20px' },
  row: {
    alignItems: 'center',
    display: 'flex',
    '&:nth-child(odd)': {
      backgroundColor: '#f2f2f2',
    },
  },
  platform: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: '80px',
    justifyContent: 'center',
    width: '80px',
  },
  platformVersion: {
    fontSize: '12px',
  },
  divider: {
    backgroundColor: steelGray,
    height: '1px',
    width: '20px',
  },
  info: {
    display: 'flex',
    flexDirection: 'column',
    padding: '8px 32px',
  },
  date: { fontSize: '14px', fontWeight: 500, margin: 0 },
  event: {
    fontSize: '18px',
    fontWeight: 'bold',
    lineHeight: '24px',
    margin: 0,
  },
  where: {
    margin: 0,
    fontSize: '12px',
  },
}));

type Props = {
  id: string;
};

function UserAnalytics({ id }: Props) {
  const classes = useStyles();
  const [filter, setFilter] = useState('');
  const { data, loading, error, refetch } = useQuery(BofUserEventsDocument, {
    variables: { userId: id },
  });

  const hasData = !!data?.userAnalyticsEvents?.length;

  function renderPlatform(e: BofUserEventsQuery['userAnalyticsEvents'][0]) {
    if (e.deviceData) {
      if (e.deviceData.platformOs === 'ios') {
        return (
          <>
            <AppleIcon />
            <div
              className={classes.platformVersion}
            >{`${e.deviceData?.platformVersion}`}</div>
          </>
        );
      }

      return (
        <>
          <AndroidIcon />
          <div
            className={classes.platformVersion}
          >{`${e.deviceData?.platformVersion}`}</div>
        </>
      );
    }
    if (e.eventType === 'WebEvent') {
      return (
        <>
          <ComputerIcon />
          <div className={classes.platformVersion}>Web</div>
        </>
      );
    }

    return (
      <>
        <StorageIcon />
        <div className={classes.platformVersion}>Server</div>
      </>
    );
  }

  return (
    <Paper className={classes.root}>
      <Stack direction="row" spacing={2} className={classes.stack}>
        <Button size="small" variant="contained" onClick={() => refetch()}>
          Refetch Events
        </Button>
        <TextField
          size="small"
          value={filter}
          onChange={evt => setFilter(evt.target.value)}
        />
      </Stack>
      {loading && !error && <CircularProgress />}
      {!loading && error && <h1>Error 🥲</h1>}
      {!loading && !error && !hasData && <h1>No Datah!</h1>}
      {!loading && !error && hasData && (
        <div>
          {data.userAnalyticsEvents
            .filter(entry =>
              entry.event.toLowerCase().includes(filter.toLowerCase()),
            )
            .map(e => {
              return (
                <div key={e.id} className={classes.row}>
                  <div className={classes.platform}>{renderPlatform(e)}</div>

                  <div className={classes.divider} />
                  <div className={classes.info}>
                    <p className={classes.date}>
                      {e.date
                        ? new Intl.DateTimeFormat('sv-SE', {
                            day: 'numeric',
                            month: 'short',
                            year: 'numeric',
                            hour: 'numeric',
                            minute: 'numeric',
                          }).format(new Date(e.date))
                        : ''}
                    </p>
                    <p className={classes.event}>{e.event}</p>
                    <p className={classes.where}>{e.screen || e.page}</p>
                  </div>
                </div>
              );
            })}
        </div>
      )}
    </Paper>
  );
}

export default UserAnalytics;
