import { useMutation, useQuery } from '@apollo/client';
import { Grid, Stack } from '@mui/material';
import { sv as locale } from '@norban/locale';
import React, { useMemo } from 'react';

import QueryError from '../../../../components/QueryError';
import QueryLoading from '../../../../components/QueryLoading';
import SlideButton from '../../../../components/SlideButton';
import {
  BiddingState,
  BofHomeProfileDocument,
  BofHomeProfileHomeFragment,
  BofHomeProfileUpdateUserDocument,
  HomeInput,
  HomeState,
} from '../../../../generated/backend/graphql';
import usePopupAlert from '../../../../hooks/usePopupAlert';
import useHomeProfileReducer from '../../hooks/useHomeProfileReducer';

import BaseInformationCard from './BaseInformationCard';
import LocationCard from './LocationCard';
import MonthlyFeeCard from './MonthlyFeeCard';

type Props = {
  homeId: string;
};

const L = locale.backoffice;

export default function HomeFacts({ homeId }: Props) {
  const { PopupAlert, showPopupAlert } = usePopupAlert();

  const { modifiedHome, updateModifiedHome, isModified } =
    useHomeProfileReducer();

  const { loading, error, data } = useQuery(BofHomeProfileDocument, {
    variables: { homeId },
    skip: !homeId,
  });

  const [mutateHome] = useMutation(BofHomeProfileUpdateUserDocument, {
    onCompleted: () => updateModifiedHome('reset'),
    onError: error => {
      if (error?.message?.includes('Vitec validation')) {
        showPopupAlert(error.message, 'error');
      } else {
        showPopupAlert(L.homeProfile.failedUpdate, 'error');
      }
    },
  });

  const home: BofHomeProfileHomeFragment | null = useMemo(() => {
    if (!data?.home) {
      return null;
    }

    return {
      ...data?.home,
      ...modifiedHome,
    };
  }, [data?.home, modifiedHome]);

  const rootAreas = data?.rootAreas;

  const submit = (evt: { preventDefault: () => void }) => {
    evt.preventDefault();

    if (
      home?.state === HomeState.Sold &&
      home?.biddingState !== BiddingState.Closed
    ) {
      showPopupAlert(L.homeProfile.failedUpdateDueToBiddingState, 'error');
      return;
    }

    const {
      __typename,
      plot,
      building,
      description,
      address,
      undisclosedAddress,
      coordinate,
      ...filteredModifiedHome
    } = modifiedHome;

    const specialFilteredHomeFields = {
      // Flattened
      plotArea: plot?.area,
      buildingYear: building?.year,
      city: address?.city,
      streetAddress: address?.streetAddress,
      streetName: address?.streetName,
      zipCode: address?.zipCode,

      // Nested, but special handling
      description: description
        ? {
            short: description.short,
            other: description.other,
          }
        : undefined,
      coordinate: coordinate
        ? {
            latitude: coordinate.latitude,
            longitude: coordinate.longitude,
          }
        : undefined,
      undisclosedAddress: undisclosedAddress
        ? {
            name: undisclosedAddress.name,
            latitude: undisclosedAddress.latitude,
            longitude: undisclosedAddress.longitude,
          }
        : undefined,
    } satisfies HomeInput;

    // N.B. These must match the fields synced to Vitec in backend/HomeModel/patchWithInput
    // This is to ensure backwards compatibility with the sync behavior expected
    const vitecSyncFields = {
      buildingYear: home?.building?.year,
      monthlyFee: home?.monthlyFee,
      monthlyFeeDescription: home?.monthlyFeeDescription,
      streetAddress: home?.address?.streetAddress,
      zipCode: home?.address?.zipCode,
      city: home?.address?.city,
      livingArea: home?.livingArea,
      numberOfRooms: home?.numberOfRooms,
      elevator: home?.elevator,
      floor: home?.floor,
      askingPrice: home?.askingPrice,
      closingPrice: home?.closingPrice,
      description: {
        short: home?.description?.short,
        other: home?.description?.other,
      },
      biddingState: home?.biddingState,
    } satisfies HomeInput;

    const input = {
      // Most fields are the same as the original home
      ...filteredModifiedHome,

      // Some, however, are flattened or nested
      ...specialFilteredHomeFields,

      // Some should always be included for vitec sync to work properly
      ...vitecSyncFields,
    } satisfies HomeInput;

    mutateHome({
      variables: {
        homeId,
        input,
      },
    });
  };

  if (loading) {
    return <QueryLoading />;
  }

  if (error) {
    return <QueryError error={error} data={data} />;
  }

  if (!home || !rootAreas) {
    return <div>{L.loading}</div>;
  }

  return (
    <>
      <form onSubmit={submit}>
        <Grid container spacing={3}>
          <Grid item sm={12} md={6}>
            <Stack spacing={3}>
              <BaseInformationCard home={home} dispatch={updateModifiedHome} />
            </Stack>
          </Grid>
          <Grid item sm={12} md={6}>
            <Stack spacing={3}>
              <LocationCard
                home={home}
                areas={rootAreas}
                dispatch={updateModifiedHome}
              />
              <MonthlyFeeCard home={home} dispatch={updateModifiedHome} />
            </Stack>
          </Grid>
        </Grid>
        <SlideButton modified={isModified} />
      </form>
      <PopupAlert />
    </>
  );
}
