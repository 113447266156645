import { Card, CardContent, CardHeader } from '@mui/material';
import { sv as locale } from '@norban/locale';
import React from 'react';

import NumberField from '../../../../components/form/NumberField';
import TextField from '../../../../components/form/TextField';
import { BofHomeProfileHomeFragment } from '../../../../generated/backend/graphql';
import { DispatchType } from '../../hooks/useHomeProfileReducer';

type Props = {
  home: BofHomeProfileHomeFragment;
  dispatch: DispatchType;
};

const MonthlyFeeCard = ({ home, dispatch }: Props) => {
  const L = locale.backoffice.homeMonthlyFeeCard;
  const { monthlyFee, monthlyFeeMax, monthlyFeeDescription } = home || {};

  return (
    <Card>
      <CardHeader title={L.title} />
      <CardContent>
        <NumberField
          label={L.monthlyFee}
          value={monthlyFee}
          name="monthlyFee"
          dispatch={dispatch}
          type="number"
        />
        <NumberField
          label={L.monthlyFeeMax}
          value={monthlyFeeMax}
          name="monthlyFeeMax"
          dispatch={dispatch}
          type="number"
        />
        <TextField
          label={L.monthlyFeeDescription}
          value={monthlyFeeDescription}
          name="monthlyFeeDescription"
          multiline
          rows="3"
          dispatch={dispatch}
        />
      </CardContent>
    </Card>
  );
};

export default MonthlyFeeCard;
