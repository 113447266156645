import { useEffect, useRef, useState } from 'react';

const useStateWithDelayedCallback = <T>(
  initialValue: T | undefined,
  callback: (value: T | undefined) => void,
  delay: number,
): [T | undefined, (arg: T) => void] => {
  const [value, setValue] = useState(initialValue);
  const prevValue = useRef(initialValue);
  const cachedCallback = useRef(callback);

  const setWrappedValue = (newValue: T) => {
    prevValue.current = value;
    setValue(newValue);
  };

  useEffect(() => {
    cachedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    if (prevValue.current !== value) {
      const timeout = setTimeout(() => {
        cachedCallback.current(value);
      }, delay);

      return () => clearTimeout(timeout);
    }

    return undefined;
  }, [delay, value]);

  return [value, setWrappedValue];
};

export default useStateWithDelayedCallback;
