// Apollo Client unfortunately has no support for custom scalars.
// The following two functions can be used to translate the
// DateTime scalar type. Details:
// https://github.com/apollographql/apollo-client/issues/585

// Convert Date() to DateTime scalar
export const dateToDateTimeScalar = date => {
  return date && date.toISOString();
};

// Convert DateTime scalar to Date()
export const dateTimeScalarToDate = dateTimeScalar => {
  return dateTimeScalar && new Date(dateTimeScalar);
};
