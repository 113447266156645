import { gql, useMutation, useQuery } from '@apollo/client';
import { Snackbar, Tab, Tabs } from '@mui/material';
import React, { useMemo, useReducer, useState } from 'react';
import {
  Link,
  Route,
  Switch,
  useLocation,
  useParams,
  useRouteMatch,
} from 'react-router-dom';

import PageHeader from '../../components/PageHeader';
import QueryError from '../../components/QueryError';
import QueryLoading from '../../components/QueryLoading';
import SlideButton from '../../components/SlideButton';
import { BofAreaViewDocument } from '../../generated/backend/graphql';

import AgentsCard from './AgentsCard';
import AreaEditCard from './AreaEditCard';
import AssociatedAreasCard from './AssociatedAreasCard';
import HomesCard from './HomesCard';

const M_UPDATE_AREA = gql`
  mutation BofUpdateArea($id: ID!, $input: AreaInput!) {
    updateArea(id: $id, input: $input) {
      id
    }
  }
`;

const AreaView = () => {
  const [modified, setModified] = useState(false);
  const [snackbarOpen, showSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const location = useLocation();
  const { id } = useParams();

  const [state, dispatch] = useReducer((state, action) => {
    if (action.initialState) {
      const area = action.initialState;
      return {
        id: area.id,
        name: area.name,
        public: area.public,
        parentId: area.parentId,
        polygon: JSON.stringify(
          area.polygon?.map(coord => ({
            latitude: coord.latitude,
            longitude: coord.longitude,
          })) ?? [],
        ),
      };
    }

    if (action.key) {
      setModified(true);
      return {
        ...state,
        [action.key]: action.value,
        public: action.value === 'true',
      };
    }

    throw new Error(`invalid action: ${JSON.stringify(action)}`);
  }, {});

  const { data, loading, error } = useQuery(BofAreaViewDocument, {
    variables: { id },
    onCompleted: data => {
      const { area } = data ?? {};
      if (!modified && area) {
        dispatch({ initialState: area });
      }
    },
  });

  const agents = useMemo(
    () => data?.area?.assignedAdmins ?? [],
    [data?.area?.assignedAdmins],
  );

  const [mutateArea] = useMutation(M_UPDATE_AREA, {
    onCompleted: () => setModified(false),
    onError: err => {
      console.error(err);
      setSnackbarMessage('Failed to update area');
      showSnackbar(true);
    },
  });

  const submit = evt => {
    const { id, ...payload } = state;
    evt.preventDefault();
    mutateArea({
      variables: {
        id,
        input: {
          ...payload,
          polygon: state.polygon ? JSON.parse(state.polygon) : null,
        },
      },
    });
  };

  const { path, url } = useRouteMatch();

  if (loading) {
    return <QueryLoading />;
  }

  if (error) {
    return <QueryError error={error} data={data} />;
  }

  const { areas, area } = data;

  return (
    <div>
      {area && (
        <>
          <PageHeader title={area?.name} />
          <Tabs value={location.pathname.split('/')[3] || 'base'}>
            <Tab
              value="base"
              label="Huvudinformation"
              component={Link}
              to={url}
            />
            <Tab
              value="homes"
              label="Bostäder"
              component={Link}
              to={`${url}/homes`}
            />
            <Tab
              value="agents"
              label="Mäklare"
              component={Link}
              to={`${url}/agents`}
            />
            <Tab
              value="associated-areas"
              label="Syskonområden"
              component={Link}
              to={`${url}/associated-areas`}
            />
          </Tabs>

          <form onSubmit={submit}>
            <Switch>
              <Route path={`${path}/homes`}>
                <HomesCard homes={area.homes || []} />
              </Route>
              <Route path={`${path}/agents`}>
                <AgentsCard areaId={area.id} agents={agents} />
              </Route>
              <Route path={`${path}/associated-areas`}>
                <AssociatedAreasCard areaId={area.id} areas={areas} />
              </Route>
              <Route>
                <AreaEditCard
                  area={state}
                  areas={areas}
                  agents={agents}
                  homes={area.homes || []}
                  dispatch={dispatch}
                  hasActions
                />
              </Route>
            </Switch>

            <SlideButton modified={modified} />

            <Snackbar
              open={snackbarOpen}
              onClose={() => showSnackbar(false)}
              message={snackbarMessage}
              autoHideDuration={5000}
            />
          </form>
        </>
      )}
      {!area && <h3>Misslyckades hämta area från servern!</h3>}
    </div>
  );
};

export default AreaView;
