// Note that this form is read-only by design. The user/home profile
// is used to change tags.
//
// FIXME:
// The code includes some steps to make it editable, notably the
// handleChange function, which I've decided to keep until the
// user/home profile is implemented.

import { gql } from '@apollo/client';
import { Mutation } from '@apollo/client/react/components';
import { Checkbox, FormControlLabel, Grid } from '@mui/material';
import { sv as locale } from '@norban/locale';
import React, { useEffect, useState } from 'react';

import SubmitButton from '../../../../../components/SubmitButton';
import { TagType } from '../../../../../generated/backend/graphql';

const DescribeHomeForm = props => {
  const { user, home, submitting, updateUser } = props;
  const userId = user.id;
  const [tags, setTags] = useState(new Set(home.tags));

  useEffect(() => {
    if (submitting) {
      updateUser();
    }
  }, [submitting, updateUser]);

  const M_UPDATE_HOME = gql`
    mutation BofDescribeHomeFormUpdateUser(
      $userId: ID!
      $input: UpdatedUserInput!
    ) {
      updateUser(userId: $userId, input: $input) {
        home {
          tags
        }
      }
    }
  `;

  const sortedTags = Object.values(TagType).sort((a, b) => {
    const aa = locale.MH.TAG_YOUR_HOME[a];
    const bb = locale.MH.TAG_YOUR_HOME[b];
    return aa.localeCompare(bb);
  });

  return (
    <Mutation mutation={M_UPDATE_HOME}>
      {(updateHome, { loading }) => (
        <>
          <Grid container spacing={0}>
            {sortedTags.map(tagType => (
              <Grid key={tagType} item>
                <FormControlLabel
                  control={
                    <Checkbox
                      value={tagType}
                      checked={tags.has(tagType)}
                      onChange={() => {
                        if (tags.has(tagType)) {
                          setTags(
                            new Set([...tags].filter(tag => tag !== tagType)),
                          );
                        } else {
                          setTags(new Set([...tags, tagType]));
                        }
                      }}
                    />
                  }
                  label={locale.MH.TAG_YOUR_HOME[tagType]}
                />
              </Grid>
            ))}
          </Grid>
          <Grid item xs={12}>
            <SubmitButton
              loading={loading}
              onClick={() =>
                updateHome({
                  variables: {
                    userId,
                    input: {
                      home: { tags: [...tags] },
                    },
                  },
                })
              }
            />
          </Grid>
        </>
      )}
    </Mutation>
  );
};

export default DescribeHomeForm;
