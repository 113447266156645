import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import moment from 'moment';
import React, { useEffect, useState } from 'react';

import {
  dateToDateTimeScalar,
  dateTimeScalarToDate,
} from '../../../../../components/client';

const StagingForm = props => {
  const { home, updateUser, submitting, readOnly } = props;
  const ps = home.photoShoot;

  const dt = dateTimeScalarToDate(ps.stagingDate);
  const initialDate = dt && moment(dt).format('YYYY-MM-DD');
  const initialTime = dt && moment(dt).format('HH:mm');
  const [stagingDate, setStagingDate] = useState(initialDate || '');
  const [stagingTime, setStagingTime] = useState(initialTime || '');
  const [stylist, setStylist] = useState(ps.stylist || '');

  useEffect(() => {
    if (submitting) {
      const stagingDateTime = moment(
        `${stagingDate} ${stagingTime}`,
        'YYYY-MM-DD HH:mm',
      );

      updateUser({
        home: {
          photoShoot: {
            stagingDate: dateToDateTimeScalar(stagingDateTime),
            stylist,
          },
        },
      });
    }
  }, [stagingDate, stagingTime, stylist, submitting, updateUser]);

  return (
    <form>
      <Typography variant="h5">Staging</Typography>
      <Grid container spacing={4} style={{ maxWidth: 520 }}>
        <Grid item xs={6.8}>
          <TextField
            disabled={readOnly}
            fullWidth
            InputLabelProps={{ shrink: true }}
            label="Date"
            margin="normal"
            onChange={evt => setStagingDate(evt.target.value)}
            type="date"
            value={stagingDate}
          />
        </Grid>
        <Grid item xs={5.2}>
          <TextField
            disabled={readOnly}
            fullWidth
            InputLabelProps={{ shrink: true }}
            label="Time"
            margin="normal"
            onChange={evt => setStagingTime(evt.target.value)}
            type="time"
            value={stagingTime}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            disabled={readOnly}
            fullWidth
            label="Stylist"
            margin="normal"
            onChange={evt => setStylist(evt.target.value)}
            value={stylist}
          />
        </Grid>
      </Grid>
    </form>
  );
};

export default StagingForm;
