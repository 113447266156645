import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { sv as locale } from '@norban/locale';
import React, { useState } from 'react';

import AreaSelect from '../../../../../components/form/AreaSelect';

const NewConstructionSettingsAddDialog = ({
  open,
  rootAreas,
  onClose,
  onSubmit,
}) => {
  const L = locale.backoffice;

  const [name, setName] = useState('');
  const [state, setState] = useState('');
  const [linked, setLinked] = useState(true);
  const [linkedHomeId, setLinkedId] = useState(null);
  const [tenure, setTenure] = useState('HOUSING_COOPERATIVE');
  const [type, setType] = useState('APARTMENT');
  const [homeState, setHomeState] = useState('PREMARKET');
  const [streetAddress, setStreetAddress] = useState('');
  const [city, setCity] = useState('');
  const [areaId, setAreaId] = useState(null);
  const [askingPrice, setAskingPrice] = useState(null);
  const [livingArea, setLivingArea] = useState(null);
  const [numberOfRooms, setNumberOfRooms] = useState(null);
  const [floor, setFloor] = useState(null);
  const [monthlyFee, setMonthlyFee] = useState(null);
  const [plotArea, setPlotArea] = useState(null);
  const [
    newConstructionEstCompletionDate,
    setNewConstructionEstCompletionDate,
  ] = useState(null);
  const [vitecEstateId, setVitecEstateId] = useState(null);
  const [adHideArea, setAdHideArea] = useState(false);

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">
        {L.newConstructionSettings.addTitle}
      </DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label={L.newConstructionSettings.name}
          fullWidth
          value={name}
          onChange={evt => setName(evt.target.value)}
        />
        <TextField
          margin="dense"
          label={L.newConstructionSettings.state}
          fullWidth
          value={state}
          onChange={evt => setState(evt.target.value)}
        />
        <FormControl component="fieldset" margin="normal">
          <RadioGroup
            aria-label={L.newConstructionSettings.linkHome}
            name="withHome"
            value={linked ? 'linked' : 'create'}
            onChange={evt => setLinked(evt.target.value === 'linked')}
            row
          >
            <FormControlLabel
              value="linked"
              control={<Radio color="primary" />}
              label={L.newConstructionSettings.linkHome}
              labelPlacement="end"
            />
            <FormControlLabel
              value="create"
              control={<Radio color="primary" />}
              label={L.newConstructionSettings.createHome}
              labelPlacement="end"
            />
          </RadioGroup>
        </FormControl>
        {linked && (
          <TextField
            margin="dense"
            label={L.newConstructionSettings.linkedHomeId}
            fullWidth
            type="number"
            value={linkedHomeId || ''}
            onChange={evt => setLinkedId(evt.target.value)}
          />
        )}
        {!linked && (
          <>
            <Box mb={2}>
              <Select
                value={tenure || 'default'}
                onChange={evt => setTenure(evt.target.value)}
              >
                {Object.keys(L.enumerations.Tenure).map(tenure => (
                  <MenuItem key={tenure} value={tenure}>
                    {L.enumerations.Tenure[tenure]}
                  </MenuItem>
                ))}
              </Select>
            </Box>
            <Box mb={2}>
              <Select
                value={type || 'default'}
                onChange={evt => setType(evt.target.value)}
              >
                {Object.keys(L.enumerations.HomeType).map(type => (
                  <MenuItem key={type} value={type}>
                    {L.enumerations.HomeType[type]}
                  </MenuItem>
                ))}
              </Select>
            </Box>
            <Box>
              <Select
                value={homeState || 'default'}
                onChange={evt => setHomeState(evt.target.value)}
              >
                {Object.keys(L.enumerations.HomeState).map(state => (
                  <MenuItem key={state} value={state}>
                    {L.enumerations.HomeState[state]}
                  </MenuItem>
                ))}
              </Select>
            </Box>
            <TextField
              margin="dense"
              label={L.newConstructionSettings.streetAddress}
              fullWidth
              value={streetAddress}
              onChange={evt => setStreetAddress(evt.target.value)}
            />
            <TextField
              margin="dense"
              label={L.newConstructionSettings.city}
              fullWidth
              value={city}
              onChange={evt => setCity(evt.target.value)}
            />
            <AreaSelect
              label={L.newConstructionSettings.area}
              value={areaId}
              areas={rootAreas}
              name="areaId"
              dispatch={({ value }) => setAreaId(value)}
              variant="standard"
            />
            <TextField
              margin="dense"
              label={L.newConstructionSettings.askingPrice}
              fullWidth
              type="number"
              value={askingPrice != null ? askingPrice : ''}
              onChange={evt =>
                setAskingPrice(parseInt(evt.target.value, 10) || null)
              }
            />
            <TextField
              margin="dense"
              label={L.newConstructionSettings.livingArea}
              fullWidth
              type="number"
              value={livingArea != null ? livingArea : ''}
              onChange={evt => {
                setLivingArea(parseFloat(evt.target.value) || null);
              }}
            />
            <TextField
              margin="dense"
              label={L.newConstructionSettings.numberOfRooms}
              fullWidth
              type="number"
              value={numberOfRooms != null ? numberOfRooms : ''}
              onChange={evt =>
                setNumberOfRooms(parseFloat(evt.target.value) || null)
              }
            />
            <TextField
              margin="dense"
              label={L.newConstructionSettings.floor}
              fullWidth
              type="number"
              value={floor != null ? floor : ''}
              onChange={evt => setFloor(parseFloat(evt.target.value) || null)}
            />
            <TextField
              margin="dense"
              label={L.newConstructionSettings.monthlyFee}
              fullWidth
              type="number"
              value={monthlyFee != null ? monthlyFee : ''}
              onChange={evt =>
                setMonthlyFee(parseFloat(evt.target.value) || null)
              }
            />
            <TextField
              margin="dense"
              label={L.newConstructionSettings.plotArea}
              fullWidth
              type="number"
              value={plotArea != null ? plotArea : ''}
              onChange={evt =>
                setPlotArea(parseFloat(evt.target.value) || null)
              }
            />
            <DateTimePicker
              clearable="true"
              ampm={false}
              format="yyyy-MM-dd HH:mm"
              margin="normal"
              label={L.newConstructionSettings.estCompletionDate}
              value={newConstructionEstCompletionDate}
              onChange={value => setNewConstructionEstCompletionDate(value)}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              renderInput={props => <TextField {...props} />}
            />
            <TextField
              margin="dense"
              label={L.newConstructionSettings.vitecEstateId}
              fullWidth
              value={vitecEstateId}
              onChange={evt => setVitecEstateId(evt.target.value)}
            />
            <FormControlLabel
              control={
                <Checkbox
                  value={adHideArea}
                  checked={adHideArea}
                  onChange={evt => setAdHideArea(evt.target.checked)}
                />
              }
              label={L.newConstructionSettings.adHideArea}
            />
          </>
        )}
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} color="primary">
          {L.newConstructionSettings.cancel}
        </Button>
        <Button
          onClick={async () => {
            onSubmit({
              name,
              state,
              linkedHomeId,
              tenure,
              type,
              homeState,
              streetAddress,
              city,
              areaId,
              askingPrice,
              livingArea,
              numberOfRooms,
              floor,
              monthlyFee,
              plotArea,
              newConstructionEstCompletionDate,
              vitecEstateId,
              adHideArea,
            });
            onClose();
          }}
          color="primary"
        >
          {L.newConstructionSettings.create}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default NewConstructionSettingsAddDialog;
