import { useMutation, useQuery } from '@apollo/client';
import EmailIcon from '@mui/icons-material/Email';
import LaunchIcon from '@mui/icons-material/Launch';
import SmsIcon from '@mui/icons-material/Sms';
import { Alert, Box, Button, Paper, Stack, Tab, Tabs } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { sv as locale } from '@norban/locale';
import { LeadTemplate } from '@norban/utils/dist';
import React, { useState } from 'react';

import EmailDialog from '../../../components/EmailDialog';
import SMSDialog from '../../../components/SMSDialog';
import {
  BofLeadsTemplatesDocument,
  BofSendSmsDocument,
  BulkEmailType,
  BulkEmailUnsubscribeType,
} from '../../../generated/backend/graphql';
import usePopupAlert from '../../../hooks/usePopupAlert';

type Props = {
  userId: string;
  homeId?: string;
  email?: string;
  phone?: string;
  assignedAgentId?: string;
};

type SelectedTab =
  | 'onboarding'
  | 'interest'
  | 'interest2'
  | 'sms'
  | 'interest3'
  | 'hidden';

type SelectedTabMap = {
  [key in SelectedTab]: { key: BulkEmailType; description: string };
};

const useStyles = makeStyles(() => ({
  message: {
    whiteSpace: 'pre-wrap',
  },
}));

const SelectedTabEmailMap: SelectedTabMap = {
  onboarding: {
    key: BulkEmailType.LeadTemplateOnboarding,
    description: 'Bearbetningsmall onboarding',
  },
  interest: {
    key: BulkEmailType.LeadTemplateInterest,
    description: 'Bearbetningsmall intresse',
  },
  interest2: {
    key: BulkEmailType.LeadTemplateInterest2,
    description: 'Bearbetningsmall intresse 2',
  },
  sms: {
    key: BulkEmailType.LeadTemplateSms,
    description: 'Bearbetningsmall SMS',
  },
  interest3: {
    key: BulkEmailType.LeadTemplateInterest3,
    description: 'Bearbetningsmall intresse 3',
  },
  hidden: {
    key: BulkEmailType.LeadTemplateHidden,
    description: 'Bearbetningsmall dold',
  },
};

const LeadTemplates = ({
  userId,
  homeId = undefined,
  email = undefined,
  phone = undefined,
  assignedAgentId = undefined,
}: Props) => {
  const L = locale.backoffice.leadTemplates;

  const classes = useStyles();

  const { PopupAlert, showPopupAlert } = usePopupAlert();

  const [currentTab, setCurrentTag] = useState<SelectedTab>('interest');
  const [dialogOpen, setDialogOpen] = useState(false);
  const [SMSDialogOpen, setSMSDialogOpen] = useState(false);

  const { data, loading, error } = useQuery(BofLeadsTemplatesDocument, {
    variables: { id: currentTab, userId },
    skip: !email,
  });
  const { leadTemplate } = data || {};
  const numHomeId = homeId ? parseInt(homeId, 10) : undefined;

  const [sendSMS] = useMutation(BofSendSmsDocument);

  if (!homeId) {
    return (
      <Paper>
        <Alert severity="warning">
          Bearbetningsmallar kan bara användas för användare med registerad
          bostad
        </Alert>
      </Paper>
    );
  }

  const handleSendSMS = async (message: string) => {
    if (!assignedAgentId) {
      showPopupAlert(L.noAssignedAgent, 'error');
      return;
    }

    if (!userId) {
      showPopupAlert(L.noUser, 'error');
      return;
    }

    if (!message) {
      showPopupAlert(L.noMessage, 'error');
      return;
    }

    try {
      await sendSMS({
        variables: {
          input: {
            message,
            senderId: assignedAgentId,
            recipientId: userId,
          },
        },
      });
      showPopupAlert(L.smsSent, 'success');
    } catch (error) {
      showPopupAlert(L.failedSendingSMS, 'error');
    }
  };

  return (
    <>
      <Paper>
        <Tabs
          variant="scrollable"
          value={currentTab}
          onChange={(_event, newValue) => setCurrentTag(newValue)}
        >
          <Tab key="onboarding" value="onboarding" label={L.onboarding} />
          <Tab key="interest" value="interest" label={L.interest} />
          <Tab key="interest2" value="interest2" label={L.interest2} />
          <Tab key="sms" value="sms" label={L.sms} />
          <Tab key="interest3" value="interest3" label={L.interest3} />
          <Tab key="hidden" value="hidden" label={L.hidden} />
        </Tabs>
        <Box p={2}>
          <Stack>
            <Box mb={2}>
              {/* horizontal stack */}
              <Stack direction="row" spacing={2}>
                {currentTab !== 'sms' && (
                  <Button
                    color="secondary"
                    size="small"
                    variant="contained"
                    startIcon={<EmailIcon />}
                    onClick={() => setDialogOpen(true)}
                  >
                    {L.sendEmail}
                  </Button>
                )}
                {currentTab === 'sms' && (
                  <Stack direction="row" spacing={2}>
                    <Button
                      color="secondary"
                      size="small"
                      variant="contained"
                      disabled={!phone}
                      startIcon={<SmsIcon />}
                      onClick={() => setSMSDialogOpen(true)}
                    >
                      {L.sendSMS}
                    </Button>
                    <Button
                      color="secondary"
                      size="small"
                      variant="contained"
                      disabled={!phone}
                      startIcon={<LaunchIcon />}
                      href={`sms:${phone}?body=${encodeURIComponent(leadTemplate?.message ?? '')}`}
                    >
                      {L.openInApp}
                    </Button>
                  </Stack>
                )}
              </Stack>
            </Box>
            {(error || !email) && (
              <Alert severity="error">
                {error ? error.message : 'Användaren saknar email'}
              </Alert>
            )}
            {data?.leadTemplate.warnings.length != null &&
              data.leadTemplate.warnings.length > 0 && (
                <Alert severity="warning">
                  <strong>
                    {`Viss information nedan saknas eller kan vara felaktig: 
                  ${data.leadTemplate.warnings
                    .map(warning => L[warning as keyof LeadTemplate])
                    .join(', ')}`}
                  </strong>
                </Alert>
              )}
            {data?.leadTemplate.warnings.length != null &&
              data.leadTemplate.warnings.length === 0 && (
                <Alert severity="success">
                  <strong>Meddelandet är fullständigt!</strong>
                </Alert>
              )}
            <pre className={classes.message}>
              {loading && 'Laddar...'}
              {!loading && !error && <span>{leadTemplate?.message}</span>}
            </pre>
          </Stack>
        </Box>
      </Paper>
      <EmailDialog
        defaultType={SelectedTabEmailMap[currentTab].key}
        recipients={[{ id: userId, email: email ?? '' }]}
        userFilter={{}}
        userFilterCount={-1}
        open={dialogOpen}
        onSent={() => {}}
        onClose={() => setDialogOpen(false)}
        typeDatas={[
          {
            type: SelectedTabEmailMap[currentTab].key,
            unsubscribeType: BulkEmailUnsubscribeType.None,
            description: SelectedTabEmailMap[currentTab].description,
            subject: leadTemplate?.subject ?? '',
            message: leadTemplate?.message ?? '',
            signatureType: 'withLogo',
          },
        ]}
        skipFirstTwoSteps
        homeId={numHomeId}
      />
      <SMSDialog
        message={leadTemplate?.message ?? ''}
        phone={phone ?? ''}
        open={SMSDialogOpen}
        onClose={() => setSMSDialogOpen(false)}
        onSend={handleSendSMS}
      />
      <PopupAlert />
    </>
  );
};

export default LeadTemplates;
