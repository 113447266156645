import L from 'leaflet';
import React, { useEffect, useReducer, useState, useCallback } from 'react';
import * as ReactDOM from 'react-dom/client';

import SlideButton from '../../components/SlideButton';

import 'leaflet/dist/leaflet.css';
import '@geoman-io/leaflet-geoman-free';
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css';
import LayerBoundPopup from './LayerBoundPopup';
import pip from './wise-leaflet-pip';

pip(L);

const MAPBOX_TOKEN =
  'pk.eyJ1Ijoibm9yYmFuIiwiYSI6ImNrNDQxaHpmajA2MzUzZW4wbjltODhsOHcifQ.61_LDTpDfyGDBfc5p-D77A';
const MAPBOX_STYLE = 'mapbox/streets-v11';

// Create new empty area
const createNewArea = () => ({ name: 'Unknown', parent: null, public: false });

const layerPolygonToAreaPolygon = layer => {
  const layerCoords = layer.getLatLngs();
  if (layerCoords.length !== 1) {
    // We should only ever get layers with 1 polygon within the
    // constraints of the editor
    return [];
  }

  return layerCoords[0].map(latLng => ({
    latitude: latLng.lat,
    longitude: latLng.lng,
  }));
};

// Add popup and edit callbacks to layer
let currentlyOpenPopup = null;
const bindHandlersToLayer = (layer, area, onModified, onPolygonUpdated) => {
  // eslint-disable-next-line no-param-reassign
  layer.area = area;

  const updateColor = () => {
    // eslint-disable-next-line no-param-reassign
    layer.setStyle({ fillColor: area.public ? 'green' : 'red' });
  };
  updateColor();

  // Set up a popup, create a new react root
  // and bind that to the layer popup
  const popupReactRoot = document.createElement('div');
  layer.bindPopup(popupReactRoot);
  ReactDOM.createRoot(popupReactRoot).render(
    <LayerBoundPopup
      layer={layer}
      area={area}
      onModified={() => {
        onModified();
        updateColor();
      }}
    />,
  );

  // Show popup on click
  layer.on('click', () => {
    if (currentlyOpenPopup === layer) {
      return;
    }

    if (currentlyOpenPopup) {
      currentlyOpenPopup.closePopup();
    }

    currentlyOpenPopup = layer;
    layer.openPopup();
  });

  layer.on('pm:edit', () => {
    onPolygonUpdated(layerPolygonToAreaPolygon(layer));
    onModified();
  });
};

// Create leaflet layer from area
const areaToLayer = (
  area,
  onModified,
  onPolygonUpdated,
  onClaimAllSubAreas,
) => {
  const layer = L.polygon(
    area.polygon.map(latlng => [latlng.latitude, latlng.longitude]),
  );

  bindHandlersToLayer(
    layer,
    area,
    onModified,
    onPolygonUpdated,
    onClaimAllSubAreas,
  );

  return layer;
};

const listReducer = () => (state, action) => {
  switch (action.type) {
    case 'add': {
      if (state.indexOf(action.item) !== -1) {
        return state;
      }

      return [...state, action.item];
    }
    case 'remove': {
      const index = state.indexOf(action.item);
      if (index === -1) {
        return state;
      }

      return [...state.slice(0, index), ...state.slice(index + 1)];
    }
    case 'set':
      return action.items;
    case 'reset':
      return [];
    default:
      throw new Error('Unexpected action');
  }
};

const Map = ({ areas: originalAreas, updateArea, deleteArea }) => {
  // Currently active leaflet map
  const [map, setMap] = useState(null);

  // List of modified areas
  const [modifiedAreas, modifiedAreasDispatch] = useReducer(listReducer(), []);

  // List of deleted areas
  const [deletedAreas, deletedAreasDispatch] = useReducer(listReducer(), []);

  // Working copy of all areas
  const [areas, areasDispatch] = useReducer(listReducer(), []);

  // Called when geoman creates a new layer using the geoman edit tools
  const onCreateAreaFromNewLayer = useCallback(layer => {
    const newArea = createNewArea();

    // Copy the layer polygon to the new empty area
    newArea.polygon = layerPolygonToAreaPolygon(layer);

    newArea.layer = layer;
    bindHandlersToLayer(
      layer,
      newArea,
      () => {
        modifiedAreasDispatch({ type: 'add', item: newArea });
      },
      polygon => {
        newArea.polygon = polygon;
      },
    );

    // Add to the working set of areas
    modifiedAreasDispatch({ type: 'add', item: newArea });
    areasDispatch({ type: 'add', item: newArea });
  }, []);

  // Called when geoman deleted a layer using the geoman delete tool
  const onRemoveAreaRelatedToLayer = useCallback(layer => {
    // Remove the area from the working copy
    areasDispatch({ type: 'remove', item: layer.area });

    // Add area to the deleted list
    deletedAreasDispatch({ type: 'add', item: layer.area });
  }, []);

  useEffect(() => {
    const areasWithLayers = areas
      .filter(area => area.polygon && area.polygon.length > 0)
      .map(area => {
        if (area.layer) {
          return area;
        }

        const modifiedArea = {
          ...area,
        };

        modifiedArea.layer = areaToLayer(
          modifiedArea,
          () => {
            modifiedAreasDispatch({ type: 'add', item: modifiedArea });
          },
          polygon => {
            modifiedArea.polygon = polygon;
          },
        );

        return modifiedArea;
      });

    areasWithLayers.forEach(({ layer }) => layer.addTo(map));
    return () => {
      areasWithLayers.forEach(({ layer }) => map.removeLayer(layer));
    };
  }, [areas, map]);

  // Trigger a reset of areas when originalAreas change
  useEffect(() => {
    areasDispatch({
      type: 'set',
      items: originalAreas,
    });
  }, [originalAreas]);

  // Effect responsible for setting up leaflet
  useEffect(() => {
    let newMap = map;
    if (!newMap) {
      // create map
      // Create a map in the div #map
      newMap = L.map('map');
      L.tileLayer(
        'https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}',
        {
          maxZoom: 18,
          id: MAPBOX_STYLE,
          tileSize: 512,
          zoomOffset: -1,
          accessToken: MAPBOX_TOKEN,
        },
      ).addTo(newMap);

      // Configure geoman controls
      newMap.pm.addControls({
        position: 'topleft',
        drawMarker: false,
        drawCircle: false,
        drawPolyline: false,
        drawRectangle: false,
        editMode: false,
        dragMode: false,
        cutPolygon: false,
        removeMode: false,
        drawCircleMarker: false,
        drawPolygon: true,
      });

      newMap.setView([57.78145, 14.15618], 6);

      setMap(newMap);
    }

    const onCreate = ({ layer }) => onCreateAreaFromNewLayer(layer);
    const onRemove = ({ layer }) => onRemoveAreaRelatedToLayer(layer);

    newMap.on('pm:create', onCreate);
    newMap.on('pm:remove', onRemove);
    return () => {
      newMap.off('pm:create', onCreate);
      newMap.off('pm:remove', onRemove);
    };
  }, [map, onCreateAreaFromNewLayer, onRemoveAreaRelatedToLayer]);

  return (
    <form
      onSubmit={async e => {
        e.preventDefault();
        e.stopPropagation();

        // Save all modified areas
        await Promise.all(
          modifiedAreas.map(async area => {
            const result = await updateArea(area);

            if (!result.error) {
              const mutableArea = area;
              mutableArea.id = result.data.updateArea.id;
            }

            return result;
          }),
        );

        // Delete all removed areas
        await Promise.all(deletedAreas.map(async area => deleteArea(area.id)));

        modifiedAreasDispatch({ type: 'reset' });
        deletedAreasDispatch({ type: 'reset' });
      }}
    >
      <div id="map" style={{ height: '100vh' }} />
      <SlideButton
        modified={modifiedAreas.length > 0 || deletedAreas.length > 0}
      />
    </form>
  );
};

export default Map;
