import LinkIcon from '@mui/icons-material/Link';
import { Button } from '@mui/material';
import React from 'react';

type Props = {
  to: string;
  content: string;
};

const LinkButton = ({ to, content }: Props) => (
  <Button
    startIcon={<LinkIcon />}
    size="small"
    href={to}
    color="primary"
    target="_blank"
  >
    {content}
  </Button>
);

export default LinkButton;
