import React from 'react';

import CreateUserForm from './CreateUserForm';

interface Props {
  open: boolean;
  onClose: (userId?: string) => void;
  withHomeDefault?: boolean;
}

const CreateUserDialog = ({
  open,
  onClose,
  withHomeDefault = false,
}: Props) => {
  return (
    <CreateUserForm
      asDialog
      open={open}
      onClose={onClose}
      withHomeDefault={withHomeDefault}
    />
  );
};

export default CreateUserDialog;
