import { useQuery } from '@apollo/client';
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@mui/material';
import { sv as locale } from '@norban/locale';
import React from 'react';

import { BofStatsMonitorsDocument } from '../../../generated/backend/graphql';

const StatsMonitors = () => {
  const L = locale.backoffice.monitors;

  const { data } = useQuery(BofStatsMonitorsDocument);
  const userHomeMonitors = data?.userHomeMonitors ?? [];
  const uniqueUsersLength = new Set(userHomeMonitors.map(m => m.user.id)).size;

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>{L.total}</TableCell>
          <TableCell>{L.unique}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell>{data ? userHomeMonitors.length : '-'}</TableCell>
          <TableCell>{data ? uniqueUsersLength : '-'}</TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

export default StatsMonitors;
