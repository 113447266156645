import React from 'react';

import Field, { PassthroughProps } from './Field';

const NumberField = <N extends string>(
  props: PassthroughProps<N, number | null>,
) => (
  <Field
    {...props}
    onEncode={value => value?.toString() ?? ''}
    onDecode={value => (value ? Number(value) : null)}
  />
);

export default NumberField;
