import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import AssignedUserIcon from '@mui/icons-material/AssignmentInd';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import ChatIcon from '@mui/icons-material/Chat';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import EmailIcon from '@mui/icons-material/Email';
import HomeIcon from '@mui/icons-material/Home';
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import LabelIcon from '@mui/icons-material/Label';
import LocalActivityOutlinedIcon from '@mui/icons-material/LocalActivityOutlined';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import StraightenIcon from '@mui/icons-material/Straighten';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TableCell,
  TableRow,
} from '@mui/material';
import Table from '@mui/material/Table';
import { makeStyles } from '@mui/styles';
import { sv as locale } from '@norban/locale';
import { endOfDay, startOfDay } from 'date-fns';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import {
  resetFilter,
  storeCrmActionsDateEnd,
  storeCrmActionsDateStart,
  storeExpanded,
  storeFilterAreaEnabled,
  storeFilterAssignedAgentId,
  storeFilterCrmActionsEnabled,
  storeFilterCrmActionsInverted,
  storeFilterCrmAssignedUserId,
  storeFilterFollowersDateEnd,
  storeFilterFollowersDateStart,
  storeFilterFollowersEnabled,
  storeFilterHomeCreationDateEnabled,
  storeFilterHomeCreationDateEnd,
  storeFilterHomeCreationDateStart,
  storeFilterHomeTypes,
  storeFilterHomeTypesEnabled,
  storeFilterHomesEnabled,
  storeFilterHomesStates,
  storeFilterLivingAreaRange,
  storeFilterLivingAreaRangeEnabled,
  storeFilterMessagesEnabled,
  storeFilterMessagesInverted,
  storeFilterMultiArea,
  storeFilterRoles,
  storeFilterRolesEnabled,
  storeFilterRoomsRange,
  storeFilterRoomsRangeEnabled,
  storeFilterUserCreatedDateEnabled,
  storeFilterUserCreatedDateEnd,
  storeFilterUserCreatedDateStart,
  storeFilterUserHasHome,
  storeFilterUserHasHomeEnabled,
  storeFilterUserLabels,
  storeFilterUserLabelsEnabled,
  storeFilterUserLabelsInverted,
  storeHasAssignedAgentEnabled,
  storeHasAssignedAgentInverted,
  storeOrderUsersEnabled,
  storeFilterUserHasEmailEnabled,
  storeFilterUserHasEmail,
  storeFilterIncludeAssociatedAreas,
} from '../../../actions/userActions';
import DateSelector from '../../../components/DateSelector';
import FilterSection from '../../../components/FilterSection';
import MultiAreaSelect from '../../../components/MultiAreaSelect';
import RangeMinMaxInputs from '../../../components/RangeMinMaxInputs';
import { HomeState, HomeType, Role } from '../../../generated/backend/graphql';
import useSession from '../../../hooks/useSession';
import useStateWithDelayedCallback from '../../../hooks/useStateWithDelayedCallback';

import BoolArrayCheckboxes from './BoolArrayCheckboxes';
import CRMRanges from './CRMRanges';
import { FilterAdminSelect } from './FilterAdminSelect';
import FilterCheckbox from './FilterCheckbox';
import MultiLabelSelect from './MultiLabelSelect';

const useStyles = makeStyles(() => ({
  areaContainer: {
    width: '100%',
  },
}));

const UserTableFilter = ({
  onFilterUsers,
  rootAreas,
  userFilter,
  userLabels,
}) => {
  const dispatch = useDispatch();

  const {
    filterAreas,
    filterAreasEnabled,
    filterUserHasHomeEnabled,
    filterCrmActionsDateEnd,
    filterCrmActionsDateStart,
    filterCrmActionsEnabled,
    filterCrmActionsInverted,
    filterCrmAssignedUserId: filterCrmAssignedUserIdOrDefault,
    filterHomeTypes,
    filterExpanded,
    filterRolesEnabled,
    filterFollowersEnabled,
    filterFollowersEndDate,
    filterFollowersStartDate,
    filterHomeStates,
    filterHomeStatesEnabled,
    filterHomeCreationDateEnabled,
    filterHomeCreationDateEnd,
    filterHomeCreationDateStart,
    filterUserHasHome,
    filterLivingAreaRange,
    filterLivingAreaRangeEnabled,
    filterMessagesEnabled,
    filterMessagesInverted,
    filterRoles,
    filterRoomsRange,
    filterRoomsRangeEnabled,
    filterHomeTypesEnabled,
    filterUserCreatedDateEnabled,
    filterUserCreatedDateEnd,
    filterUserCreatedDateStart,
    filterUserLabels,
    filterUserLabelsEnabled,
    filterUserLabelsInverted,
    orderUsersEnabled,
    hasAssignedAgentEnabled,
    hasAssignedAgentInverted,
    filterAssignedAgentId,
    filterUserHasEmailEnabled,
    filterUserHasEmail,
    includeAssociatedAreas,
  } = userFilter;

  const { userId } = useSession();

  const filterCrmAssignedUserId =
    filterCrmAssignedUserIdOrDefault === 'DEFAULT'
      ? userId?.toString()
      : filterCrmAssignedUserIdOrDefault;

  const [roomsRange, setRoomsRange] = useStateWithDelayedCallback(
    filterRoomsRange,
    useCallback(
      value => {
        storeFilterRoomsRange(dispatch, value);
      },
      [dispatch],
    ),
    300,
  );
  const [livingAreaRange, setLivingAreaRange] = useStateWithDelayedCallback(
    filterLivingAreaRange,
    useCallback(
      value => {
        storeFilterLivingAreaRange(dispatch, value);
      },
      [dispatch],
    ),
    300,
  );

  const L = locale.backoffice;
  const classes = useStyles();

  return (
    <Table size="small">
      <FilterSection
        title={L.userList.titleUser}
        expanded={filterExpanded.user}
        onExpandedChanged={value => storeExpanded(dispatch, 'user', value)}
        filters={[
          {
            label: L.userList.userRole,
            icon: <AdminPanelSettingsIcon />,
            enabled: filterRolesEnabled,
            onEnabledChanged: value => {
              onFilterUsers();
              storeFilterRolesEnabled(dispatch, value);
            },
            children: (
              <BoolArrayCheckboxes
                disabled={!filterRolesEnabled}
                value={filterRoles}
                onChange={value => {
                  onFilterUsers();
                  storeFilterRoles(dispatch, value);
                }}
                formatLabel={i => Object.keys(Role)[i]}
              />
            ),
          },
          {
            label: L.userList.hasHome,
            icon: <HomeIcon />,
            enabled: filterUserHasHomeEnabled,
            onEnabledChanged: value => {
              onFilterUsers();
              storeFilterUserHasHomeEnabled(dispatch, value);
            },
            children: (
              <Grid item xs={12} my={1}>
                <FormControl fullWidth>
                  <InputLabel>{L.userList.hasHome}</InputLabel>
                  <Select
                    fullWidth
                    size="small"
                    disabled={!filterUserHasHomeEnabled}
                    value={filterUserHasHome}
                    label={L.userList.hasHome}
                    onChange={event => {
                      const { value } = event.target;
                      onFilterUsers();
                      storeFilterUserHasHome(dispatch, value);
                    }}
                  >
                    <MenuItem value>Ja</MenuItem>
                    <MenuItem value={false}>Nej</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            ),
          },
          {
            label: L.userList.hasEmail,
            icon: <EmailIcon />,
            enabled: filterUserHasEmailEnabled,
            onEnabledChanged: value => {
              onFilterUsers();
              storeFilterUserHasEmailEnabled(dispatch, value);
            },
            children: (
              <Grid item xs={12} my={1}>
                <FormControl fullWidth>
                  <InputLabel>{L.userList.hasEmail}</InputLabel>
                  <Select
                    fullWidth
                    size="small"
                    disabled={!filterUserHasEmailEnabled}
                    value={filterUserHasEmail}
                    label={L.userList.hasEmail}
                    onChange={event => {
                      const { value } = event.target;
                      onFilterUsers();
                      storeFilterUserHasEmail(dispatch, value);
                    }}
                  >
                    <MenuItem value>Ja</MenuItem>
                    <MenuItem value={false}>Nej</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            ),
          },
        ]}
      />
      <FilterSection
        title={L.userList.titleHome}
        expanded={filterExpanded.homes}
        onExpandedChanged={value => storeExpanded(dispatch, 'homes', value)}
        filters={[
          {
            label: L.userList.marketStatus,
            icon: <HomeIcon />,
            enabled: filterHomeStatesEnabled,
            onEnabledChanged: value => {
              onFilterUsers();
              storeFilterHomesEnabled(dispatch, value);
            },
            children: (
              <BoolArrayCheckboxes
                disabled={!filterHomeStatesEnabled}
                value={filterHomeStates}
                onChange={value => {
                  onFilterUsers();
                  storeFilterHomesStates(dispatch, value);
                }}
                formatLabel={i => {
                  return L.enumerations.HomeState[Object.values(HomeState)[i]];
                }}
              />
            ),
          },
          {
            label: L.userList.homeType,
            icon: <HomeWorkIcon />,
            enabled: filterHomeTypesEnabled,
            onEnabledChanged: value => {
              onFilterUsers();
              storeFilterHomeTypesEnabled(dispatch, value);
            },
            children: (
              <BoolArrayCheckboxes
                disabled={!filterHomeTypesEnabled}
                value={filterHomeTypes}
                onChange={value => {
                  onFilterUsers();
                  storeFilterHomeTypes(dispatch, value);
                }}
                formatLabel={i =>
                  L.enumerations.HomeType[Object.values(HomeType)[i]]
                }
              />
            ),
          },
          {
            label: L.userList.homeArea,
            icon: <LocationCityIcon />,
            enabled: filterAreasEnabled,
            onEnabledChanged: value => {
              onFilterUsers();
              storeFilterAreaEnabled(dispatch, value);
            },
            children: (
              <Stack fullWidth className={classes.areaContainer}>
                <Box mt={1}>
                  <MultiAreaSelect
                    enabled={filterAreasEnabled}
                    label={L.userList.areas}
                    areas={rootAreas ?? []}
                    value={filterAreas}
                    onValueChanged={value =>
                      storeFilterMultiArea(dispatch, value)
                    }
                  />
                </Box>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={includeAssociatedAreas}
                      disabled={!filterAreasEnabled}
                      onChange={() =>
                        storeFilterIncludeAssociatedAreas(
                          dispatch,
                          !includeAssociatedAreas,
                        )
                      }
                    />
                  }
                  label="Inkludera syskonområde"
                />
              </Stack>
            ),
          },
          {
            label: L.userList.homeRooms,
            icon: <MeetingRoomIcon />,
            enabled: filterRoomsRangeEnabled,
            onEnabledChanged: value => {
              onFilterUsers();
              storeFilterRoomsRangeEnabled(dispatch, value);
            },
            children: (
              <RangeMinMaxInputs
                disabled={!filterRoomsRangeEnabled}
                valueMin={roomsRange?.[0]}
                valueMax={roomsRange?.[1]}
                onChange={range => setRoomsRange(range)}
                unit="rum"
              />
            ),
          },
          {
            label: L.userList.homeLivingArea,
            icon: <StraightenIcon />,
            enabled: filterLivingAreaRangeEnabled,
            onEnabledChanged: value => {
              onFilterUsers();
              storeFilterLivingAreaRangeEnabled(dispatch, value);
            },
            children: (
              <RangeMinMaxInputs
                disabled={!filterLivingAreaRangeEnabled}
                valueMin={livingAreaRange?.[0]}
                valueMax={livingAreaRange?.[1]}
                onChange={range => setLivingAreaRange(range)}
                unit="m²"
              />
            ),
          },
          {
            label: L.userList.homeCreation,
            icon: <CalendarTodayIcon />,
            enabled: filterHomeCreationDateEnabled,
            onEnabledChanged: value => {
              onFilterUsers();
              storeFilterHomeCreationDateEnabled(dispatch, value);
            },
            children: (
              <DateSelector
                disabled={!filterHomeCreationDateEnabled}
                startDate={filterHomeCreationDateStart}
                endDate={filterHomeCreationDateEnd}
                quickSelect
                onChangeStartDate={date =>
                  storeFilterHomeCreationDateStart(dispatch, startOfDay(date))
                }
                onChangeEndDate={date =>
                  storeFilterHomeCreationDateEnd(dispatch, endOfDay(date))
                }
              />
            ),
          },
          {
            label: L.userList.assignedAgent,
            icon: <SupportAgentIcon />,
            enabled: hasAssignedAgentEnabled,
            onEnabledChanged: value => {
              onFilterUsers();
              storeHasAssignedAgentEnabled(dispatch, value);
            },
            children: (
              <Grid item xs={12} alignItems="center" display="flex">
                <FilterCheckbox
                  disabled={!hasAssignedAgentEnabled}
                  checked={hasAssignedAgentInverted}
                  onCheckedChanged={value => {
                    onFilterUsers();
                    storeHasAssignedAgentInverted(dispatch, value);
                  }}
                />
                <FilterAdminSelect
                  label={L.userList.assignedAgent}
                  disabled={
                    !hasAssignedAgentEnabled || hasAssignedAgentInverted
                  }
                  userId={filterAssignedAgentId ?? null}
                  onUserIdChanged={value => {
                    storeFilterAssignedAgentId(dispatch, value);
                  }}
                />
              </Grid>
            ),
          },
        ]}
      />
      <FilterSection
        title={L.userList.titleOther}
        expanded={filterExpanded.misc}
        onExpandedChanged={value => storeExpanded(dispatch, 'misc', value)}
        filters={[
          {
            label: L.userList.labels,
            icon: <LabelIcon />,
            enabled: filterUserLabelsEnabled,
            onEnabledChanged: value => {
              onFilterUsers();
              storeFilterUserLabelsEnabled(dispatch, value);
            },
            children: (
              <Grid item xs={12} display="flex" flexWrap="nowrap">
                <FilterCheckbox
                  disabled={!filterUserLabelsEnabled}
                  checked={filterUserLabelsInverted}
                  onCheckedChanged={value => {
                    onFilterUsers();
                    storeFilterUserLabelsInverted(dispatch, value);
                  }}
                />
                <MultiLabelSelect
                  enabled={filterUserLabelsEnabled && !filterUserLabelsInverted}
                  label={L.userList.labels}
                  labels={userLabels ?? []}
                  value={filterUserLabels ?? []}
                  onValueChanged={value => {
                    storeFilterUserLabels(dispatch, value);
                  }}
                />
              </Grid>
            ),
          },
          {
            label: L.userList.crm,
            icon: <AssignmentTurnedInIcon />,
            enabled: filterCrmActionsEnabled,
            onEnabledChanged: value => {
              onFilterUsers();
              storeFilterCrmActionsEnabled(dispatch, value);
            },
            children: (
              <>
                <Grid item xs={12} display="flex" flexWrap="nowrap">
                  <FilterCheckbox
                    disabled={!filterCrmActionsEnabled}
                    checked={filterCrmActionsInverted}
                    onCheckedChanged={value => {
                      onFilterUsers();
                      storeFilterCrmActionsInverted(dispatch, value);
                    }}
                  />
                  <FilterCheckbox
                    icon={<AssignedUserIcon fontSize="small" />}
                    disabled={
                      !filterCrmActionsEnabled || filterCrmActionsInverted
                    }
                    checked={filterCrmAssignedUserId !== undefined}
                    onCheckedChanged={value => {
                      onFilterUsers();
                      storeFilterCrmAssignedUserId(
                        dispatch,
                        value ? null : undefined,
                      );
                    }}
                  />
                  <FilterAdminSelect
                    disabled={
                      !filterCrmActionsEnabled ||
                      filterCrmActionsInverted ||
                      filterCrmAssignedUserId === undefined
                    }
                    userId={filterCrmAssignedUserId ?? null}
                    onUserIdChanged={value => {
                      storeFilterCrmAssignedUserId(dispatch, value);
                    }}
                  />
                </Grid>
                <Grid item xs={12} display="flex" flexWrap="nowrap">
                  <CRMRanges
                    disabled={
                      !filterCrmActionsEnabled || filterCrmActionsInverted
                    }
                    startDate={filterCrmActionsDateStart}
                    endDate={filterCrmActionsDateEnd}
                    onChangeDates={(start, end) => {
                      storeCrmActionsDateStart(dispatch, startOfDay(start));
                      storeCrmActionsDateEnd(dispatch, endOfDay(end));
                    }}
                  />
                  <DateSelector
                    disabled={
                      !filterCrmActionsEnabled || filterCrmActionsInverted
                    }
                    startDate={filterCrmActionsDateStart}
                    endDate={filterCrmActionsDateEnd}
                    onChangeStartDate={date =>
                      storeCrmActionsDateStart(dispatch, startOfDay(date))
                    }
                    onChangeEndDate={date =>
                      storeCrmActionsDateEnd(dispatch, endOfDay(date))
                    }
                  />
                </Grid>
              </>
            ),
          },
          {
            label: L.userList.messages,
            icon: <ChatIcon />,
            enabled: filterMessagesEnabled,
            onEnabledChanged: value => {
              onFilterUsers();
              storeFilterMessagesEnabled(dispatch, value);
            },
            children: (
              <FilterCheckbox
                disabled={!filterMessagesEnabled}
                checked={filterMessagesInverted}
                onCheckedChanged={value => {
                  onFilterUsers();
                  storeFilterMessagesInverted(dispatch, value);
                }}
              />
            ),
          },
          {
            label: L.userList.userCreatedDate,
            icon: <CalendarTodayIcon />,
            enabled: filterUserCreatedDateEnabled,
            onEnabledChanged: value => {
              onFilterUsers();
              storeFilterUserCreatedDateEnabled(dispatch, value);
            },
            children: (
              <DateSelector
                quickSelect
                disabled={!filterUserCreatedDateEnabled}
                startDate={filterUserCreatedDateStart}
                endDate={filterUserCreatedDateEnd}
                onChangeStartDate={date =>
                  storeFilterUserCreatedDateStart(dispatch, date)
                }
                onChangeEndDate={date => {
                  storeFilterUserCreatedDateEnd(dispatch, date);
                }}
              />
            ),
          },
          {
            label: L.userList.followers,
            icon: <DirectionsRunIcon />,
            enabled: filterFollowersEnabled,
            onEnabledChanged: value => {
              onFilterUsers();
              storeFilterFollowersEnabled(dispatch, value);
            },
            children: (
              <DateSelector
                quickSelect
                disabled={!filterFollowersEnabled}
                startDate={filterFollowersStartDate}
                startLabel=""
                endDate={filterFollowersEndDate}
                endLabel=""
                onChangeStartDate={date => {
                  storeFilterFollowersDateStart(dispatch, date);
                }}
                onChangeEndDate={date => {
                  storeFilterFollowersDateEnd(dispatch, date);
                }}
                isTime
              />
            ),
          },
          {
            label: L.userList.orderByActivity,
            icon: <LocalActivityOutlinedIcon />,
            enabled: orderUsersEnabled,
            onEnabledChanged: value => {
              onFilterUsers();
              storeOrderUsersEnabled(dispatch, value);
            },
            children: (
              <Grid
                item
                xs={12}
                alignItems="center"
                display="flex"
                marginTop="8px"
              >
                {L.userList.orderByActivity}
              </Grid>
            ),
          },
        ]}
      />
      {/* A table row, with a reset filter button */}
      <TableRow>
        <TableCell>
          <Button
            onClick={() => {
              resetFilter(dispatch);
              onFilterUsers();
            }}
          >
            {L.userList.resetFilter}
          </Button>
        </TableCell>
      </TableRow>
    </Table>
  );
};

export default UserTableFilter;
