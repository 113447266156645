import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import moment from 'moment';
import React, { useEffect, useState } from 'react';

import {
  dateToDateTimeScalar,
  dateTimeScalarToDate,
} from '../../../../../components/client';

const ValuationMeetingForm = props => {
  const { home, updateUser, submitting, readOnly } = props;
  const vm = home.valuationMeeting;
  const dt = dateTimeScalarToDate(vm.date);
  const [date, setDate] = useState(
    (dt && moment(dt).format('YYYY-MM-DD')) || '',
  );
  const [time, setTime] = useState((dt && moment(dt).format('HH:mm')) || '');
  const [location, setLocation] = useState(vm.location || '');

  useEffect(() => {
    if (submitting) {
      const dateTime = moment(`${date} ${time}`, 'YYYY-MM-DD HH:mm');
      updateUser({
        home: {
          valuationMeeting: {
            location,
            date: dateToDateTimeScalar(dateTime),
          },
        },
      });
    }
  }, [date, location, submitting, time, updateUser]);

  return (
    <form>
      <Grid container spacing={4} style={{ maxWidth: 520 }}>
        <Grid item xs={6.8}>
          <TextField
            disabled={readOnly}
            fullWidth
            InputLabelProps={{ shrink: true }}
            label="Date"
            margin="normal"
            onChange={evt => setDate(evt.target.value)}
            type="date"
            value={date}
          />
        </Grid>
        <Grid item xs={5.2}>
          <TextField
            disabled={readOnly}
            fullWidth
            InputLabelProps={{ shrink: true }}
            label="Time"
            margin="normal"
            onChange={evt => setTime(evt.target.value)}
            type="time"
            value={time}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            disabled={readOnly}
            fullWidth
            label="Location"
            margin="normal"
            onChange={evt => setLocation(evt.target.value)}
            value={location}
            variant="outlined"
          />
        </Grid>
      </Grid>
    </form>
  );
};

export default ValuationMeetingForm;
