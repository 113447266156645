import * as Chartist from 'chartist';
import 'chartist/dist/index.css';
import React, { useEffect, useRef } from 'react';

interface Props {
  data: Chartist.LineChartData;
  className?: string;
  options?: object;
}

const LineChart = ({ data, className = undefined, options = {} }: Props) => {
  const chartRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const chart = new Chartist.LineChart(chartRef.current, data, options);

    return () => {
      try {
        chart.detach();
      } catch (err) {
        throw new Error(`Internal chartist error${err}`);
      }
    };
  }, [data, options]);

  return <div className={`ct-chart ${className || ''}`} ref={chartRef} />;
};

export default LineChart;
