import { useMutation, useQuery } from '@apollo/client';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Paper,
  Typography,
} from '@mui/material';
import { Stack } from '@mui/system';
import { sv as locale } from '@norban/locale';
import React, { useCallback, useMemo, useState } from 'react';

import {
  BofOwnerUploadedImagesAndContractsDocument,
  BofUserUploadedImageNotifyDocument,
  BofUserUploadedImageUpdateDocument,
  OwnerUploadedImageState,
} from '../../../../generated/backend/graphql';
import usePopupAlert from '../../../../hooks/usePopupAlert';
import UserUploadedImage from '../../../User/components/UserUploadedImage';

type Props = {
  homeId: string;
  showUserInfo?: boolean;
};

const OwnerUploadedImages = ({ homeId, showUserInfo = false }: Props) => {
  const L = locale.backoffice;
  const [modifiedImages, setModifiedImages] = useState<
    Map<
      string,
      {
        state: OwnerUploadedImageState;
        reason: string;
      }
    >
  >(new Map());
  const [showAllOptions, setShowAllOptions] = useState(false);
  const [shouldNotifyUser, setShouldNotifyUser] = useState(true);

  const { data } = useQuery(BofOwnerUploadedImagesAndContractsDocument, {
    variables: {
      homeId,
    },
  });
  const [updateImage] = useMutation(BofUserUploadedImageUpdateDocument);
  const [notifyUser] = useMutation(BofUserUploadedImageNotifyDocument, {
    variables: {
      homeId,
    },
  });

  const allImages = useMemo(
    () => data?.ownerUploadedImages ?? [],
    [data?.ownerUploadedImages],
  );

  const { PopupAlert, showPopupAlert } = usePopupAlert();

  const handleUpdateImages = useCallback(async () => {
    try {
      const promises = [...modifiedImages].map(([id, { state, reason }]) =>
        updateImage({
          variables: { id, state, rejectionReason: reason },
        }),
      );
      await Promise.all(promises);
      // When all images are updated, notify user
      if (shouldNotifyUser) {
        await notifyUser();
      }
      setModifiedImages(new Map());
    } catch (e) {
      console.error(e);
      showPopupAlert(`Misslyckades att uppdatera bilderna`, 'error');
    }
  }, [
    modifiedImages,
    shouldNotifyUser,
    notifyUser,
    updateImage,
    showPopupAlert,
  ]);

  return (
    <>
      <Grid container spacing={2}>
        {showUserInfo && (
          <Grid item xs={12}>
            <Paper>
              <Box p={2}>
                <Typography variant="h6">Uppdragsavtal</Typography>
                {data?.agreementContracts &&
                data?.agreementContracts.length > 0 ? (
                  data?.agreementContracts.map(agreementContract => (
                    <Stack
                      direction="row"
                      key={agreementContract.id}
                      spacing={2}
                    >
                      <Typography variant="body1">
                        {agreementContract.id}
                      </Typography>
                      <Typography variant="body1">
                        {agreementContract.signState === 'SIGNED'
                          ? L.agreementContract.SIGNED
                          : L.agreementContract[
                              agreementContract.state as
                                | 'DRAFT'
                                | 'ACTIVATED'
                                | 'FINALIZED'
                                | 'CANCELLED'
                            ]}
                      </Typography>
                    </Stack>
                  ))
                ) : (
                  <Typography variant="body1">-</Typography>
                )}
              </Box>
            </Paper>
          </Grid>
        )}
        {allImages.map(image => {
          const modifiedImage = modifiedImages.get(image.id);
          return (
            <Grid item xs={12} key={image.id}>
              <UserUploadedImage
                id={image.id}
                imageId={image.imageId}
                state={modifiedImage?.state ?? image.state}
                reason={modifiedImage?.reason ?? image.rejectionReason}
                onChangeImageState={(id, state, reason) => {
                  const updatedMap = new Map(modifiedImages);
                  updatedMap.set(id, { state, reason });
                  setModifiedImages(updatedMap);
                }}
                onDeleteImage={id => {
                  if (modifiedImages.has(id)) {
                    const updatedMap = new Map(modifiedImages);
                    updatedMap.delete(id);
                    setModifiedImages(updatedMap);
                  }
                }}
                showAllOptions={showAllOptions}
              />
            </Grid>
          );
        })}
        {allImages.length === 0 && (
          <Grid item xs={12}>
            <Typography variant="body1">
              {L.ownerUploadedImages.none}
            </Typography>
          </Grid>
        )}
        <Grid item container xs={12}>
          <Stack direction="column" alignItems="flex-start">
            <FormControlLabel
              label={L.ownerUploadedImages.sendNotification}
              control={
                <Checkbox
                  checked={shouldNotifyUser}
                  onChange={e => setShouldNotifyUser(e.target.checked)}
                />
              }
            />
            <FormControlLabel
              label={L.ownerUploadedImages.showAllOptions}
              control={
                <Checkbox
                  checked={showAllOptions}
                  onChange={() => setShowAllOptions(!showAllOptions)}
                />
              }
            />
            <Button
              disabled={modifiedImages.size === 0}
              onClick={() => handleUpdateImages()}
              variant="contained"
            >
              {L.save}
            </Button>
          </Stack>
        </Grid>
      </Grid>
      <PopupAlert />
    </>
  );
};

export default OwnerUploadedImages;
