import SaveIcon from '@mui/icons-material/Save';
import { Fab, Slide } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { sv as locale } from '@norban/locale';
import React from 'react';

import { theme } from '../theme';

const useStyles = makeStyles<typeof theme>(theme => ({
  fab: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(4),
  },
}));

const SlideButton = ({
  modified,
  onClick = undefined,
}: {
  modified: boolean;
  onClick?: () => void;
}) => {
  const L = locale.backoffice;
  const classes = useStyles();

  return (
    <Slide direction="up" in={modified} mountOnEnter unmountOnExit>
      <Fab
        variant="extended"
        color="primary"
        aria-label="save"
        type="submit"
        className={classes.fab}
        onClick={onClick}
      >
        <SaveIcon />
        &nbsp;{L.saveChanges}
      </Fab>
    </Slide>
  );
};

export default SlideButton;
