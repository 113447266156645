import { gql, useQuery } from '@apollo/client';
import Divider from '@mui/material/Divider';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import makeStyles from '@mui/styles/makeStyles';
import { sv as locale } from '@norban/locale';
import { timeline } from '@norban/utils';
import React, { useMemo } from 'react';
import {
  Link,
  Route,
  useLocation,
  useParams,
  useRouteMatch,
} from 'react-router-dom';

import { TaskState } from '../../../../generated/backend/graphql';

import PhaseView from './PhaseView';

const Q_USER_TIMELINE = gql`
  query BofUserTimeline($id: ID) {
    user(id: $id) {
      home {
        timeline {
          tasks {
            type
            state
            phase
          }
        }
      }
    }
  }
`;

const useStyles = makeStyles({
  phaseTab: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: '10px',
  },

  phaseTabSub: {
    fontSize: '8px',
  },
});

const phaseOrder = timeline.default
  .map(task => task.phase)
  .filter((v, i, a) => a.indexOf(v) === i);

const phaseTree = timeline.default.reduce((tree, task) => {
  if (tree[task.phase]) {
    tree[task.phase].push(task);
  } else {
    // eslint-disable-next-line no-param-reassign
    tree[task.phase] = [task];
  }
  return tree;
}, {});

const RoutedPhaseView = ({
  activePhase,
  userId,
  subscription,
  onSubscriptionHandled,
  timeline,
  refetchTimeline,
}) => {
  const { phaseNo } = useParams();
  const phase = phaseTree[phaseOrder[phaseNo || activePhase]];

  return (
    <PhaseView
      phase={phase}
      userId={userId}
      subscription={subscription}
      onSubscriptionHandled={onSubscriptionHandled}
      timeline={timeline}
      refetchTimeline={refetchTimeline}
    />
  );
};

const Timeline = ({ userId, subscription, onSubscriptionHandled }) => {
  const classes = useStyles();
  const { pathname } = useLocation();

  const { data, refetch: refetchTimeline } = useQuery(Q_USER_TIMELINE, {
    variables: { id: userId },
  });

  const timeline = data?.user?.home?.timeline;

  const activePhase = useMemo(() => {
    if (timeline) {
      const index = phaseOrder.findIndex((v, i) => {
        // Skip the first phase, it's always completed
        if (i === 0) {
          return false;
        }

        const phase = phaseOrder[i];

        if (
          timeline.tasks
            .filter(task => task.phase === phase)
            .some(task => task.state !== TaskState.Concluded)
        ) {
          return true;
        }

        return false;
      });

      return index >= 0 ? index : 0;
    }

    return 0;
  }, [timeline]);

  const { path, url } = useRouteMatch();

  return (
    <div className="tabs">
      <div>
        <Tabs
          value={pathname.split('/')[4] || `${activePhase}`}
          variant="scrollable"
        >
          {phaseOrder.map((phase, index) => {
            const label1 = `Phase ${index + 1}`;
            const label2 = locale.backoffice.phaseLabels[index];
            const tabPath = `${url}/${index}`;

            return (
              <Tab
                key={phase}
                style={{ minWidth: '16px' }}
                label={
                  <div className={classes.phaseTab}>
                    <div>{label1}</div>
                    <div className={classes.phaseTabSub}>{label2}</div>
                  </div>
                }
                value={tabPath.split('/')[4] || `${activePhase}`}
                component={Link}
                to={tabPath}
              />
            );
          })}
        </Tabs>
        <Divider />
      </div>
      <br />

      <Route path={`${path}/:phaseNo?`}>
        <RoutedPhaseView
          activePhase={activePhase}
          userId={userId}
          subscription={subscription}
          onSubscriptionHandled={onSubscriptionHandled}
          timeline={timeline}
          refetchTimeline={refetchTimeline}
        />
      </Route>
    </div>
  );
};

export default Timeline;
