import debug from 'debug';

type Loggers = {
  fatal: debug.Debugger;
  info: debug.Debugger;
  trace: debug.Debugger;
};

let appName = '';
const packageNames: string[] = [];
const packageLoggers = new Map<string, Loggers>();

const updateLoggers = () => {
  packageNames.forEach(name => {
    const packageLogName = `${appName}:${name}`;
    const newLoggers = {
      fatal: debug(`${packageLogName}:fatal`),
      info: debug(`${packageLogName}:info`),
      trace: debug(`${packageLogName}:trace`),
    };

    // Update the existing debug object with the new debug functions
    const existingLoggers = packageLoggers.get(name);
    if (existingLoggers) {
      Object.assign(existingLoggers, newLoggers);
    } else {
      packageLoggers.set(name, newLoggers);
    }
  });
};

export const setAppName = (newAppName: string) => {
  appName = newAppName;
  updateLoggers();

  debug.enable(`${appName}:*`);
  debug(`${appName}:debug`)(`Enabled debug for ${appName}`);
};

export const createDebug = (name: string) => {
  packageNames.push(name);
  updateLoggers();

  return packageLoggers.get(name)!;
};

export const registerUncaughtExceptionHandlers = () => {
  const selfDebug = createDebug('debug');
  selfDebug.info(
    'Registering uncaughtException and unhandledRejection handlers',
  );

  process.on('uncaughtException', err => {
    selfDebug.fatal('There was an uncaught error', err);
    process.exit(1); //mandatory (as per the Node.js docs)
  });

  process.on('unhandledRejection', (reason, promise) => {
    selfDebug.info('Unhandled Rejection at:', promise, 'reason:', reason);
    // Application specific logging, throwing an error, or other logic here
  });
};
