import { useMutation, useQuery } from '@apollo/client';
import HomeIcon from '@mui/icons-material/Home';
import PersonIcon from '@mui/icons-material/Person';
import {
  CardContent,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import {
  DataGrid,
  GridColDef,
  GridRowParams,
  gridDateComparator,
  gridNumberComparator,
} from '@mui/x-data-grid';
import { sv as locale } from '@norban/locale';
import React, { useCallback, useMemo, useState } from 'react';

import PageHeader from '../../components/PageHeader';
import RootCard from '../../components/RootCard';
import {
  BofDeleteHomeMessageDocument,
  BofHomeMessageDialogMessageFragment,
  BofHomeMessageFragment,
  BofHomeMessagesDocument,
  BofUpdateHomeMessageDocument,
  HomeMessageState,
} from '../../generated/backend/graphql';

import HomeMessageDialog from './components/HomeMessageDialog';

const L = locale.backoffice.homeMessages;

const HomeMessages = () => {
  const [showOnlyPending, setShowOnlyPending] = useState(true);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedMessage, setSelectedMessage] = useState<
    BofHomeMessageFragment | undefined
  >(undefined);

  const { data, loading } = useQuery(BofHomeMessagesDocument, {
    variables: {
      filter: {
        states: showOnlyPending
          ? [HomeMessageState.Pending]
          : [
              HomeMessageState.Approved,
              HomeMessageState.Rejected,
              HomeMessageState.Delivered,
            ],
      },
    },
  });

  const [updateHomeMessage] = useMutation(BofUpdateHomeMessageDocument, {
    update: cache => {
      cache.evict({ fieldName: 'homeMessages' });
      cache.gc();
    },
  });

  const [deleteHomeMessage] = useMutation(BofDeleteHomeMessageDocument, {
    update: cache => {
      cache.evict({ fieldName: 'homeMessages' });
      cache.gc();
    },
  });

  const rows = useMemo(() => {
    if (loading) {
      return [{ id: L.loading }];
    }

    if (!data?.homeMessages) {
      return [];
    }

    return data.homeMessages
      .filter(message => {
        if (showOnlyPending) {
          return message.state === HomeMessageState.Pending;
        }

        return true;
      })
      .map(message => ({
        ...message,
        fromUserName: message.fromUser.user.name ?? '-',
        homeAddress: message.toHome.address.streetAddress ?? '-',
        agent: message.toHome.assignedAgent?.name ?? '-',
      }));
  }, [data, loading, showOnlyPending]);

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: 'fromUserId',
        headerName: L.fromUserId,
        sortable: true,
        width: 80,
        renderHeader: () => <PersonIcon />,
        renderCell: ({ value }) => (
          <a
            onClick={e => e.stopPropagation()}
            href={`/users/${value}`}
            target="_blank"
            rel="noreferrer"
          >
            {value}
          </a>
        ),
        sortComparator: gridNumberComparator,
      },
      {
        field: 'fromUserName',
        headerName: 'Skickad av',
        sortable: true,
        width: 150,
        valueGetter: ({ value }) => value,
      },
      {
        field: 'homeAddress',
        headerName: 'Skickad till',
        sortable: true,
        width: 150,
        valueGetter: ({ value }) => value,
      },
      {
        field: 'toHomeId',
        headerName: L.toHomeId,
        sortable: true,
        width: 80,
        renderHeader: () => <HomeIcon />,
        renderCell: ({ value }) => (
          <a
            onClick={e => e.stopPropagation()}
            href={`/homes/${value}`}
            target="_blank"
            rel="noreferrer"
          >
            {value}
          </a>
        ),
        sortComparator: gridNumberComparator,
      },
      {
        field: 'message',
        flex: 1,
        headerName: L.message,
        sortable: true,
        valueGetter: ({ value }) => value,
      },
      {
        field: 'agent',
        headerName: 'Mäklare',
        sortable: true,
        width: 150,
        valueGetter: ({ value }) => value,
      },
      {
        field: 'createdAt',
        headerName: L.created,
        sortable: true,
        width: 100,
        valueGetter: ({ value }) => value,
        renderCell: ({ value }) =>
          value
            ? new Intl.DateTimeFormat('sv-SE', {
                day: 'numeric',
                month: 'short',
                hour: 'numeric',
                minute: 'numeric',
              }).format(new Date(value))
            : '',
        sortComparator: gridDateComparator,
      },
      {
        field: 'state',
        headerName: L.state,
        sortable: true,
        valueGetter: ({ value }) =>
          (L as { [key: string]: string })[value] ?? value,
      },
    ],
    [],
  );

  const handleOpenRow = useCallback(
    (row: GridRowParams<BofHomeMessageFragment>) => {
      const message = row.row as BofHomeMessageFragment;
      setSelectedMessage(message);
      setDialogOpen(true);
    },
    [],
  );

  const handleSubmit = useCallback(
    async (messageChanges: Partial<BofHomeMessageDialogMessageFragment>) => {
      setDialogOpen(false);

      if (!Object.keys(messageChanges).length) {
        return;
      }

      if (!selectedMessage) {
        return;
      }

      await updateHomeMessage({
        variables: {
          input: {
            id: selectedMessage.id,
            message: messageChanges.message,
            state: messageChanges.state,
          },
        },
      });
    },
    [selectedMessage, updateHomeMessage],
  );

  const handleDelete = useCallback(async () => {
    setDialogOpen(false);

    if (!selectedMessage) {
      return;
    }

    await deleteHomeMessage({
      refetchQueries: [BofHomeMessagesDocument],
      variables: {
        id: selectedMessage.id,
      },
    });
  }, [deleteHomeMessage, selectedMessage]);

  return (
    <>
      <HomeMessageDialog
        open={dialogOpen}
        onClose={() => {
          setDialogOpen(false);
        }}
        onSave={handleSubmit}
        onDelete={handleDelete}
        message={selectedMessage}
      />
      <PageHeader title={L.title} />
      <RootCard>
        <CardContent>
          <RadioGroup
            aria-label="Filterinställning"
            value={showOnlyPending}
            onChange={evt => setShowOnlyPending(evt.target.value === 'true')}
            row
          >
            <FormControlLabel
              value
              control={<Radio />}
              label={L.showOnlyPending}
            />
            <FormControlLabel
              value={false}
              control={<Radio />}
              label={L.handled}
            />
          </RadioGroup>
        </CardContent>
        <DataGrid
          autoHeight
          columns={columns}
          rows={rows}
          onRowClick={handleOpenRow}
          disableRowSelectionOnClick
        />
      </RootCard>
    </>
  );
};

export default HomeMessages;
