import React, { useEffect, useState } from 'react';

import LayerPopup from './LayerPopup';

const LayerBoundPopup = ({ layer, area, onModified }) => {
  const [editMode, setEditMode] = useState(layer.pm.enabled());
  const [name, setName] = useState(area.name);
  const [isPublic, setIsPublic] = useState(area.public);

  useEffect(() => {
    if (editMode) {
      layer.pm.enable({
        allowSelfIntersection: false,
      });
    } else {
      layer.pm.disable();
    }

    if (area.name !== name) {
      const mutableArea = area;
      mutableArea.name = name;
      onModified();
    }

    if (area.public !== isPublic) {
      const mutableArea = area;
      mutableArea.public = isPublic;
      onModified();
    }
  }, [layer, onModified, editMode, name, area, isPublic]);

  return (
    <LayerPopup
      name={name}
      onNameChanged={setName}
      editMode={editMode}
      onEditModeChanged={setEditMode}
      isPublic={isPublic}
      onIsPublicChanged={setIsPublic}
    />
  );
};

export default LayerBoundPopup;
