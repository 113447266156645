import { ApolloError, useMutation, useQuery } from '@apollo/client';
import { EditNoteOutlined } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import CachedIcon from '@mui/icons-material/Cached';
import EmailIcon from '@mui/icons-material/Email';
import HomeIcon from '@mui/icons-material/Home';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import {
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  FormControlLabel,
  IconButton,
  Link,
  Paper,
  Snackbar,
  Stack,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { sv as locale } from '@norban/locale';
import cx from 'classnames';
import moment from 'moment';
import React, { useCallback, useMemo, useReducer, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import AddFollowerDialog from '../../components/AddFollowerDialog';
import EmailDialog from '../../components/EmailDialog';
import FollowerSyncErrors from '../../components/FollowerSyncErrors';
import MoveFollowersDialog from '../../components/MoveFollowersDialog';
import QueryError from '../../components/QueryError';
import QueryLoading from '../../components/QueryLoading';
import {
  BofHomeFollowersDocument,
  BofHomeFollowersFollowHomeDocument,
  BofHomeFollowersFollowerFragment,
  BofHomeFollowersSyncFollowersDocument,
  BofHomeFollowersUpdateFollowersDocument,
  BulkEmailType,
  BulkEmailUnsubscribeType,
  FollowerUpdatePreference,
  SyncFollowerError,
} from '../../generated/backend/graphql';
import useCheckedList from '../../hooks/useCheckedList';
import usePopupAlert from '../../hooks/usePopupAlert';
import { beige, blueLight2, offBlack, tableRowHoverColor } from '../../theme';

import EditCategoriesDialog from './components/EditCategoriesDialog';
import FollowerDialog from './components/FollowerDialog';

const useStyles = makeStyles({
  userLink: {
    color: blueLight2,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  primaryTextColor: {
    color: offBlack,
  },
  row: {
    cursor: 'pointer',
    textDecoration: 'none',
    '&:hover': {
      backgroundColor: tableRowHoverColor,
    },
  },
  rowInactive: {
    opacity: 0.5,
  },
  horizontalScrollPaper: {
    overflowX: 'scroll',
  },
  countLabel: {
    alignSelf: 'flex-end',
    backgroundColor: beige,
    borderRadius: '10px',
    margin: '5px',
    padding: '5px 10px',
  },
});

const L = locale.backoffice.allFollowers;

enum FollowerType {
  ViewingParticipant = 'VIEWING_PARTICIPANT',
  Greeter = 'GREETER',
}

export default function HomeFollowers({ homeId }: { homeId: string }) {
  const [snackbarOpen, showSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [syncErrors, setSyncErrors] = useState<SyncFollowerError[]>();
  const [selectedFollowerId, setSelectedFollowerId] = useState<string>();
  const [searchString, setSearchString] = useState('');
  const [viewingParticipantFilter, setViewingParticipantFilter] =
    useState(false);
  const [greeterFilter, setGreeterFilter] = useState(false);
  const [noTypeFilter, setNoTypeFilter] = useState(false);

  type CategoryFilterType = FollowerUpdatePreference | null;
  const [categoryFilter, categoryFilterReducer] = useReducer(
    (
      state: Set<CategoryFilterType>,
      {
        include,
        payload,
      }: {
        include: boolean;
        payload: CategoryFilterType;
      },
    ) =>
      include
        ? new Set([...state, payload])
        : new Set([...state].filter(category => category !== payload)),
    new Set<CategoryFilterType>(),
  );

  const { loading, error, data, refetch } = useQuery(BofHomeFollowersDocument, {
    variables: { homeId },
  });

  const [followHome] = useMutation(BofHomeFollowersFollowHomeDocument, {
    refetchQueries: [BofHomeFollowersDocument],
    onError: () => {
      setSnackbarMessage('Failed to follow home');
      showSnackbar(true);
    },
  });

  const [updateFollower] = useMutation(BofHomeFollowersUpdateFollowersDocument);

  const [syncFollowers, { loading: syncFollowersLoading }] = useMutation(
    BofHomeFollowersSyncFollowersDocument,
  );

  const { followers } = data?.home || {};

  const selectedFollower = useMemo(
    () => followers?.find(follower => follower.id === selectedFollowerId),
    [followers, selectedFollowerId],
  );

  const fmt = locale.backoffice.generic.dateAndTimeFormat;
  const styles = useStyles('');
  const [dialogOpen, setDialogOpen] = useState<
    | 'email'
    | 'add_follower'
    | 'follower'
    | 'moveFollowers'
    | 'confirmDelete'
    | 'edit_categories'
  >();
  const [showInactive, setShowInactive] = useState(false);
  const [showUninstered, setShowUninterested] = useState(false);
  const { PopupAlert, showPopupAlert } = usePopupAlert();

  const filteredFollowers = useMemo(() => {
    const filtered = (followers ?? [])
      .filter(follower => {
        if (searchString === '') {
          return true;
        }

        if (
          (follower.user?.name &&
            follower.user.name
              .toLocaleLowerCase()
              .includes(searchString.toLocaleLowerCase())) ||
          (follower.user?.phone &&
            follower.user.phone
              .toLocaleLowerCase()
              .includes(searchString.toLocaleLowerCase())) ||
          (follower.user?.email &&
            follower.user.email
              .toLocaleLowerCase()
              .includes(searchString.toLocaleLowerCase())) ||
          (follower.user?.id &&
            follower.user.id
              .toLocaleLowerCase()
              .includes(searchString.toLocaleLowerCase()))
        ) {
          return true;
        }

        return false;
      })
      .map(follower => {
        const followerTypes = follower.isViewingParticipant
          ? [FollowerType.ViewingParticipant]
          : [];

        if (
          data?.homeMessages.find(
            homeMessage => homeMessage.fromUserId === follower.userId,
          )
        ) {
          followerTypes.push(FollowerType.Greeter);
        }

        return {
          ...follower,
          followerTypes,
        };
      })
      .filter(({ active, followerTypes, updatePreference }) => {
        let typeFilterInclude = false;

        if (!noTypeFilter && !viewingParticipantFilter && !greeterFilter) {
          typeFilterInclude = true;
        } else {
          if (noTypeFilter && followerTypes.length === 0) {
            typeFilterInclude = true;
          }
          if (
            viewingParticipantFilter &&
            followerTypes.includes(FollowerType.ViewingParticipant)
          ) {
            typeFilterInclude = true;
          }
          if (greeterFilter && followerTypes.includes(FollowerType.Greeter)) {
            typeFilterInclude = true;
          }
        }

        const categoryFilterInclude =
          categoryFilter.size === 0 ||
          categoryFilter.has(updatePreference ?? null);

        const activeFilterInclude = showInactive || active;
        const uninterestedFilterInclude =
          categoryFilter.has(FollowerUpdatePreference.DeclinesUpdates) ||
          showUninstered ||
          updatePreference !== FollowerUpdatePreference.DeclinesUpdates;

        return (
          typeFilterInclude &&
          categoryFilterInclude &&
          activeFilterInclude &&
          uninterestedFilterInclude
        );
      });
    return filtered;
  }, [
    followers,
    searchString,
    data?.homeMessages,
    noTypeFilter,
    viewingParticipantFilter,
    greeterFilter,
    categoryFilter,
    showInactive,
    showUninstered,
  ]);

  const { checkedList, toggle, toggleAll, clearAll, isChecked, isAllChecked } =
    useCheckedList<BofHomeFollowersFollowerFragment>(filteredFollowers);

  const emailUsers = [
    ...new Map(checkedList.map(({ user }) => [user?.id, user])).values(),
  ].reduce(
    (acc, user) => {
      const id = user?.id;
      const email = user?.email;

      if (!id || !email) {
        return acc;
      }

      return [...acc, { id, email }];
    },
    [] as { id: string; email: string }[],
  );

  const handleFollowerClick = useCallback(
    (
      e: React.MouseEvent<HTMLTableRowElement, MouseEvent>,
      follower: BofHomeFollowersFollowerFragment,
    ) => {
      e.preventDefault();
      setSelectedFollowerId(follower.id);
      setDialogOpen('follower');
    },
    [],
  );

  const handleSync = useCallback(async () => {
    try {
      setSyncErrors(undefined);
      const res = await syncFollowers({
        variables: { followerIds: checkedList.map(({ id }) => id) },
      });

      if (
        res.data?.syncFollowers.errors &&
        res.data?.syncFollowers.errors.length > 0
      ) {
        showPopupAlert(locale.backoffice.errors.sync, 'error');
        setSyncErrors(res.data?.syncFollowers.errors);
      } else {
        showPopupAlert(locale.backoffice.allFollowers.syncSuccess, 'success');
      }
    } catch (error) {
      const ae = error as ApolloError;
      showPopupAlert(ae.message, 'error');
    }
  }, [checkedList, showPopupAlert, syncFollowers]);

  const handleEditCategories = useCallback(() => {
    setDialogOpen('edit_categories');
  }, []);

  const handleUpdateCategories = useCallback(
    (category: FollowerUpdatePreference | null) => {
      setDialogOpen(undefined);
      updateFollower({
        variables: {
          ids: checkedList.map(({ id }) => id),
          input: {
            updatePreference: category,
          },
        },
      });
    },
    [checkedList, updateFollower],
  );

  if (homeId == null) {
    return <div>Användaren har inget hem</div>;
  }

  if (loading) {
    return <QueryLoading />;
  }

  if (error) {
    return <QueryError error={error} data={data} />;
  }

  if (!followers) {
    return null;
  }

  return (
    <Paper>
      <Stack
        spacing={2}
        alignItems="flex-start"
        padding={2}
        direction="row"
        useFlexGap
        flexWrap="wrap"
      >
        <Button
          color="secondary"
          size="small"
          variant="contained"
          onClick={() => setDialogOpen('add_follower')}
          startIcon={<AddIcon />}
        >
          {L.addFollower}
        </Button>
        <Button
          color="secondary"
          disabled={checkedList.length === 0}
          size="small"
          variant="contained"
          onClick={() => setDialogOpen('email')}
          startIcon={<EmailIcon />}
        >
          Skicka mejl
        </Button>
        <Button
          color="secondary"
          disabled={checkedList.length === 0}
          size="small"
          startIcon={<SwapHorizIcon />}
          variant="contained"
          onClick={() => setDialogOpen('moveFollowers')}
        >
          {L.moveFollowers}
        </Button>
        <Button
          color="secondary"
          disabled={checkedList.length === 0 || syncFollowersLoading}
          size="small"
          startIcon={<CachedIcon />}
          variant="contained"
          onClick={handleSync}
        >
          {L.sync}
        </Button>
        <Button
          color="secondary"
          disabled={checkedList.length === 0 || syncFollowersLoading}
          size="small"
          variant="contained"
          onClick={handleEditCategories}
          startIcon={<EditNoteOutlined />}
        >
          {L.editCategories}
        </Button>
      </Stack>
      <Stack
        spacing={2}
        alignItems="flex-start"
        pl={2}
        pr={2}
        direction="row"
        useFlexGap
        flexWrap="wrap"
      >
        <FormControlLabel
          label="Visa inaktiva"
          control={
            <Switch
              value={showInactive}
              checked={showInactive}
              color="primary"
              onChange={() => setShowInactive(!showInactive)}
            />
          }
        />
        <Divider orientation="vertical" variant="middle" flexItem />
        <FormControlLabel
          control={
            <Checkbox
              checked={noTypeFilter}
              onChange={() => setNoTypeFilter(!noTypeFilter)}
            />
          }
          label="Följare"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={viewingParticipantFilter}
              onChange={() =>
                setViewingParticipantFilter(!viewingParticipantFilter)
              }
            />
          }
          label="Visningsdeltagare"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={greeterFilter}
              onChange={() => setGreeterFilter(!greeterFilter)}
            />
          }
          label="Skickat hälsning"
        />
        <Divider orientation="vertical" variant="middle" flexItem />
        <FormControlLabel
          label="Visa ej intresserade"
          control={
            <Switch
              disabled={categoryFilter.has(
                FollowerUpdatePreference.DeclinesUpdates,
              )}
              value={
                showUninstered ||
                categoryFilter.has(FollowerUpdatePreference.DeclinesUpdates)
              }
              checked={
                showUninstered ||
                categoryFilter.has(FollowerUpdatePreference.DeclinesUpdates)
              }
              color="primary"
              onChange={(_, checked) => setShowUninterested(checked)}
            />
          }
        />
        {[
          {
            label: locale.backoffice.followerCategory.NONE,
            value: null,
          },
          {
            label: locale.backoffice.followerCategory.WANTS_UPDATES,
            value: FollowerUpdatePreference.WantsUpdates,
          },
        ].map(({ label, value }) => (
          <FormControlLabel
            key={label}
            control={
              <Checkbox
                checked={categoryFilter.has(value)}
                onChange={(_, checked) =>
                  categoryFilterReducer({
                    include: checked,
                    payload: value,
                  })
                }
              />
            }
            label={label}
          />
        ))}
        <FormControlLabel
          key={locale.backoffice.followerCategory.CONTACTED}
          control={
            <Checkbox
              checked={categoryFilter.has(FollowerUpdatePreference.Contacted)}
              onChange={(_, checked) =>
                categoryFilterReducer({
                  include: checked,
                  payload: FollowerUpdatePreference.Contacted,
                })
              }
            />
          }
          label={locale.backoffice.followerCategory.CONTACTED}
        />
      </Stack>
      <Stack
        spacing={2}
        alignItems="center"
        justifyContent="space-between"
        pl={2}
        pr={2}
        direction="row"
        useFlexGap
        flexWrap="wrap"
      >
        <TextField
          fullWidth
          label={locale.backoffice.search}
          size="small"
          sx={{ maxWidth: '300px' }}
          value={searchString}
          onChange={e => setSearchString(e.target.value)}
        />
      </Stack>
      <Paper className={styles.horizontalScrollPaper}>
        <Stack>
          <Typography className={styles.countLabel} variant="subtitle2">
            {L.count}: {filteredFollowers.length}
          </Typography>
          <Table size="small" aria-label="home follower table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <Checkbox
                    checked={isAllChecked()}
                    disabled={filteredFollowers.length === 0}
                    onChange={e => {
                      if (e.target.checked) {
                        toggleAll();
                      } else {
                        clearAll();
                      }
                    }}
                  />
                </TableCell>
                <TableCell>{L.name}</TableCell>
                <TableCell>{L.phone}</TableCell>
                <TableCell>{L.type}</TableCell>
                <TableCell>{L.category}</TableCell>
                <TableCell>{L.userId}</TableCell>
                <TableCell>{L.hasHome}</TableCell>
                <TableCell>{L.created}</TableCell>
                <TableCell>{L.deactivated}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading && <CircularProgress />}
              {filteredFollowers.map(follower => {
                const {
                  active,
                  activeToggledAt,
                  createdAt,
                  user,
                  id,
                  userId,
                  updatePreference,
                  followerTypes,
                } = follower ?? {};

                const { name, phone } = user ?? {
                  name: null,
                  phone: null,
                };

                return (
                  <TableRow
                    key={id}
                    className={`${styles.row}${
                      active ? '' : ` ${styles.rowInactive}`
                    }`}
                    onClick={e => handleFollowerClick(e, follower)}
                  >
                    <TableCell
                      onClick={e => {
                        e.stopPropagation();
                        toggle(follower);
                      }}
                    >
                      <Checkbox checked={isChecked(follower)} />
                    </TableCell>
                    <TableCell>{name || '-'}</TableCell>
                    <TableCell>
                      <Link
                        className={cx(!phone && styles.primaryTextColor)}
                        href={(phone && `tel:${phone}`) ?? undefined}
                        underline="hover"
                      >
                        {phone || '-'}
                      </Link>
                    </TableCell>
                    <TableCell>
                      {followerTypes.length > 0
                        ? followerTypes
                            .map(
                              type =>
                                locale.backoffice.enumerations.FollowerType[
                                  type
                                ],
                            )
                            .join(', ')
                        : '-'}
                    </TableCell>
                    <TableCell>
                      {updatePreference
                        ? locale.backoffice.followerCategory[updatePreference]
                        : '-'}
                    </TableCell>
                    <TableCell>
                      <RouterLink
                        className={styles.userLink}
                        rel="noopener noreferrer"
                        target="_blank"
                        to={`/users/${userId}/profile`}
                        onClick={e => e.stopPropagation()}
                      >
                        {userId || '-'}
                      </RouterLink>
                    </TableCell>
                    <TableCell>
                      {follower.user?.home?.id && (
                        <IconButton
                          aria-label="home"
                          component={RouterLink}
                          to={`/homes/${follower.user?.home?.id}`}
                          size="large"
                        >
                          <HomeIcon />
                        </IconButton>
                      )}
                    </TableCell>
                    <TableCell>{moment(createdAt).format(fmt)}</TableCell>
                    <TableCell>
                      {activeToggledAt && !active
                        ? moment(activeToggledAt).format(fmt)
                        : '-'}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Stack>
      </Paper>
      {syncErrors && <FollowerSyncErrors errors={syncErrors} />}
      <PopupAlert />
      <Snackbar
        open={snackbarOpen}
        onClose={() => showSnackbar(false)}
        message={snackbarMessage}
        autoHideDuration={5000}
      />
      <EmailDialog
        defaultType={BulkEmailType.FollowerViewingScheduled}
        recipients={emailUsers}
        homeId={Number(homeId)}
        userFilter={undefined}
        userFilterCount={-1}
        open={dialogOpen === 'email'}
        onSent={() => {}}
        onClose={() => setDialogOpen(undefined)}
        typeDatas={[
          {
            type: BulkEmailType.FollowerPhotosPublished,
            unsubscribeType: BulkEmailUnsubscribeType.Follower,
            description: 'Bilder publicerade',
            message: locale.backoffice.emailDialog.photosPublishedMessage,
            signatureType: 'withoutLogo',
          },
          {
            type: BulkEmailType.FollowerGenericUpdate,
            unsubscribeType: BulkEmailUnsubscribeType.Follower,
            description: 'Allmän följaruppdatering',
            message: locale.backoffice.emailDialog.genericUpdate,
            signatureType: 'withoutLogo',
          },
          {
            type: BulkEmailType.FollowerViewingScheduled,
            unsubscribeType: BulkEmailUnsubscribeType.Follower,
            description: 'Visning planerad',
            message: locale.backoffice.emailDialog.viewingPlannedMessage,
            signatureType: 'withoutLogo',
          },
          {
            type: BulkEmailType.FollowerNowOnPremarket,
            unsubscribeType: BulkEmailUnsubscribeType.Follower,
            description: 'Bostaden är nu på Pre-Market™',
            message: locale.backoffice.emailDialog.nowOnPremarketMessage,
            signatureType: 'withoutLogo',
          },
          {
            type: BulkEmailType.FollowerCustomWithHome,
            unsubscribeType: BulkEmailUnsubscribeType.Follower,
            description: 'Skriv själv med bostadskort',
            signatureType: 'withoutLogo',
          },
          {
            type: BulkEmailType.FollowerSoldUpdate,
            unsubscribeType: BulkEmailUnsubscribeType.Follower,
            description: 'Såld!',
            message: locale.backoffice.emailDialog.followerSoldUpdateMessage,
            signatureType: 'withoutLogo',
          },
        ]}
      />
      <AddFollowerDialog
        open={dialogOpen === 'add_follower'}
        onConfirm={async (userId, isViewingParticipant) => {
          setDialogOpen(undefined);
          if (!userId) {
            return;
          }

          followHome({
            variables: {
              userId,
              homeId,
              // If the user is a viewing participant, we need to pass an empty
              // object to the viewing field in order mark the follower as a
              // viewing participant without logging an actual viewing time slot.
              viewing: isViewingParticipant
                ? {
                    id: '',
                    timeSlots: [],
                  }
                : undefined,
            },
          });
        }}
        onCancel={() => setDialogOpen(undefined)}
      />
      <MoveFollowersDialog
        homeId={homeId}
        partialFollowers={checkedList.map(({ id, userId }) => ({
          id: id ?? undefined,
          userId: userId ?? undefined,
        }))}
        open={dialogOpen === 'moveFollowers'}
        onMoveFollowers={() => refetch()}
        onClose={() => setDialogOpen(undefined)}
      />
      <FollowerDialog
        follower={selectedFollower}
        open={dialogOpen === 'follower'}
        onClose={() => {
          setDialogOpen(undefined);
          setSelectedFollowerId(undefined);
        }}
      />
      <EditCategoriesDialog
        open={dialogOpen === 'edit_categories'}
        onClose={() => setDialogOpen(undefined)}
        onOk={handleUpdateCategories}
      />
    </Paper>
  );
}
