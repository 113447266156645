import { useQuery } from '@apollo/client';
import {
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { sv as locale } from '@norban/locale';
import {
  eachDayOfInterval,
  endOfDay,
  format,
  startOfDay,
  subMonths,
} from 'date-fns';
import React, { useMemo, useState } from 'react';

import {
  BofStatsFollowerVsHomeDocument,
  HomeState,
} from '../../../generated/backend/graphql';

import LineChart from './LineChart';
import StatsDateSelector from './StatsDateSelector';

const useStyles = makeStyles({
  chart: {
    paddingTop: 24,
    '& .ct-series-a': {
      '& .ct-line': {
        stroke: '#D87762',
      },
      '& .ct-point': {
        stroke: '#D87762',
      },
    },
    '& .ct-series-b': {
      '& .ct-line': {
        stroke: '#3E83E3',
      },
      '& .ct-point': {
        stroke: '#3E83E3',
      },
    },
  },
});

const StatsFollowerVsHome = () => {
  const timeAtMount = useMemo(() => new Date(), []);
  const [group, setGroup] = useState('PUBLIC');
  const [startDate, setStartDate] = useState<Date>(
    startOfDay(subMonths(timeAtMount, 3)),
  );
  const L = locale.backoffice.statsFollowerVsHome;
  const [endDate, setEndDate] = useState<Date>(endOfDay(timeAtMount));

  const classes = useStyles();
  const statesMap: { [key: string]: HomeState[] | null } = {
    PUBLIC: [HomeState.Premarket, HomeState.OpenMarket, HomeState.Sold],
    SOLD: [HomeState.Sold],
    ALL: null,
  };

  const validDateFormat = (dateString: string) =>
    /^\d{4}-\d{2}-\d{2}$/.test(dateString);

  // Check using date-fns if startDate is valid
  const startDateValid = validDateFormat(
    startDate.toLocaleDateString('sv-SE').slice(0, 10),
  );
  // Check if endDate is valid
  const endDateValid = validDateFormat(
    endDate.toLocaleDateString('sv-SE').slice(0, 10),
  );
  // Check if endDate is after startDate
  const dateRangeValid =
    endDateValid && startDateValid && endDate.getTime() >= startDate.getTime();

  const dateLabels = dateRangeValid
    ? eachDayOfInterval({
        start: startDate,
        end: endDate,
      }).map((date: Date) => format(date, 'yyyy-MM-dd'))
    : [];

  const { data } = useQuery(BofStatsFollowerVsHomeDocument, {
    variables: {
      from: startDate.toString(),
      to: endDate.toString(),
      states: statesMap[group],
    },
  });

  const countPerDay = (dateArray: { created: string }[]) =>
    dateArray.reduce(
      (acc: { [key: string]: number }, user: { created: string }) => {
        const date = new Date(user.created);
        const dateKey = format(date, 'yyyy-MM-dd');
        if (acc[dateKey]) {
          acc[dateKey] += 1;
        } else {
          acc[dateKey] = 1;
        }
        return acc;
      },
      {},
    );

  const range = (dateMap: { [dateKey: string]: number }) =>
    dateLabels.map((date: string) => {
      const mappedValue = dateMap[date];
      if (mappedValue) {
        return mappedValue;
      }
      return 0;
    });

  const lengthFilteredHomes = data?.filteredHomes.length ?? 0;
  const lengthHomes = data?.homes.length ?? 0;

  const mapFilteredHomesPerDay = countPerDay(data?.filteredHomes ?? []);
  const mapHomesPerDay = countPerDay(data?.homes ?? []);

  const seriesFilteredHomes = range(mapFilteredHomesPerDay);
  const seriesHomes = range(mapHomesPerDay);

  const graphData = {
    labels: dateLabels,
    series: [seriesFilteredHomes, seriesHomes],
  };

  const max = Math.max(...seriesFilteredHomes, ...seriesHomes);

  const options = {
    high: max,
    low: 0,
    axisX: {
      labelInterpolationFnc(value: number, index: number) {
        return index % 10 === 0 ? value : null;
      },
    },
    height: '50vh',
    width: '100%',
  };

  return (
    <>
      <StatsDateSelector
        startDate={startDate}
        endDate={endDate}
        accumulated={false}
        onChangeCombinedDates={(start: Date, end: Date) => {
          setStartDate(start);
          setEndDate(end);
        }}
        onChangeStartDate={(date: Date) => {
          setStartDate(date);
        }}
        onChangeEndDate={(date: Date) => {
          setEndDate(date);
        }}
        onChangeAccumulated={() => {}}
      />
      <Box mt={2} ml={2}>
        <RadioGroup
          name="radio-buttons-group"
          row
          value={group}
          onChange={e => setGroup(e.target.value)}
        >
          <FormControlLabel
            control={<Radio />}
            label={L.public}
            value="PUBLIC"
          />
          <FormControlLabel control={<Radio />} label={L.sold} value="SOLD" />
          <FormControlLabel control={<Radio />} label={L.all} value="ALL" />
        </RadioGroup>
      </Box>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{L.homesWhereOwnerFirstWasFollower}</TableCell>
            <TableCell>{L.homes}</TableCell>
            <TableCell>{L.percent}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>{lengthFilteredHomes}</TableCell>
            <TableCell>{lengthHomes}</TableCell>
            <TableCell>
              {lengthHomes
                ? `${((lengthFilteredHomes / lengthHomes) * 100).toFixed(2)}%`
                : 0}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <LineChart className={classes.chart} data={graphData} options={options} />
    </>
  );
};

export default StatsFollowerVsHome;
