import { useMutation, useQuery } from '@apollo/client';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Stack,
} from '@mui/material';
import { GridDeleteIcon } from '@mui/x-data-grid';
import { sv as locale } from '@norban/locale';
import { addDays } from 'date-fns';
import React from 'react';

import {
  BofFollowerCandidateCardMarkForKickstartDocument,
  BofFollowerCandidateCardMarkedForKickstartDocument,
} from '../../../../generated/backend/graphql';

type Props = {
  homeId: string;
  onError: () => void;
};

const FollowerCandidateCard = ({ homeId, onError }: Props) => {
  const L = locale.backoffice.followerCandidate;

  const { data } = useQuery(
    BofFollowerCandidateCardMarkedForKickstartDocument,
    {
      variables: { homeId },
    },
  );
  const { markedForKickstart } = data?.home || {};
  const [markForKickstart] = useMutation(
    BofFollowerCandidateCardMarkForKickstartDocument,
    {
      onError,
      variables: {
        homeId,
        input: {
          markedForKickstart: markedForKickstart
            ? null
            : addDays(new Date(), 1).toISOString(),
        },
      },
    },
  );

  // check using date-fns if markedForKickstart is tomorrow
  const isTomorrow = markedForKickstart
    ? addDays(new Date(), 1).toDateString() ===
      new Date(markedForKickstart).toDateString()
    : false;

  return (
    <Card>
      <CardHeader title={L.title} />
      <CardContent>
        <Stack spacing={2} alignItems="flex-start">
          <Box>{L.content}</Box>

          <Button
            variant="contained"
            color="primary"
            onClick={async () => {
              await markForKickstart();
            }}
            disabled={!!markedForKickstart}
          >
            {L.setAsCandidate}
          </Button>
          {markedForKickstart && (
            <>
              <Box>{L.match}</Box>
              <Box alignItems="center">
                {isTomorrow && (
                  <CheckCircleIcon
                    style={{
                      color: 'green',
                      marginRight: '8px',
                    }}
                  />
                )}
                {new Intl.DateTimeFormat('sv-SE', {
                  year: 'numeric',
                  month: 'short',
                  day: 'numeric',
                }).format(new Date(markedForKickstart))}
                <Box ml={1} display="inline">
                  <IconButton
                    edge="end"
                    color="inherit"
                    onClick={async () => markForKickstart()}
                  >
                    <GridDeleteIcon />
                  </IconButton>
                </Box>
              </Box>
            </>
          )}
        </Stack>
      </CardContent>
    </Card>
  );
};

export default FollowerCandidateCard;
