import { PropsOf } from '@emotion/react';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select as MuiSelect,
} from '@mui/material';
import { sv as locale } from '@norban/locale';
import React, { useMemo } from 'react';

type Props<N extends string, T extends string> = {
  label: string;
  name: N;
  value: T | undefined | null;
  options: Record<string, T>;
  dispatch: (action: { key: N; value: T }) => void;
  includeEmpty: boolean;
  margin?: PropsOf<typeof FormControl>['margin'];
  sortOptions?: boolean;
};

const Select = <N extends string, T extends string>({
  label,
  name,
  value,
  options,
  dispatch,
  includeEmpty,
  margin = 'normal',
  sortOptions = true,
}: Props<N, T>) => {
  const optionArray = useMemo(
    () => [
      ...Object.entries(options)
        .map(([key, value]) => ({
          key,
          value,
        }))
        .sort((a, b) => (sortOptions ? a.value.localeCompare(b.value) : 1)),
      ...(includeEmpty
        ? [{ key: '_', value: locale.backoffice.components.Select.empty }]
        : []),
    ],
    [options, includeEmpty, sortOptions],
  );

  return (
    <FormControl fullWidth variant="outlined" margin={margin}>
      <InputLabel>{label}</InputLabel>
      <MuiSelect
        fullWidth
        label={label}
        value={value || (includeEmpty ? '_' : Object.keys(options)[0])}
        onChange={evt => dispatch({ key: name, value: evt.target.value as T })}
      >
        {optionArray.map(({ key, value }) => (
          <MenuItem key={key} value={key}>
            {value}
          </MenuItem>
        ))}
      </MuiSelect>
    </FormControl>
  );
};

export default Select;
