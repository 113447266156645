import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
} from '@mui/material';
import { sv as locale } from '@norban/locale';
import React from 'react';

import TextField from '../../../../components/form/TextField';
import { BofHomeProfileHomeFragment } from '../../../../generated/backend/graphql';
import { vitecEstateLink } from '../../../../utils';
import { DispatchType } from '../../hooks/useHomeProfileReducer';

type Props = {
  home: BofHomeProfileHomeFragment;
  dispatch: DispatchType;
};

const VitecCard = ({ home, dispatch }: Props) => {
  const L = locale.backoffice.homeProfile.vitecCard;
  const { vitecEstateId } = home || {};
  const vitecLink = vitecEstateId && vitecEstateLink(vitecEstateId);
  const idError =
    !!vitecEstateId && !/^OBJ[0-9]{5}_[0-9]{10}$/.exec(vitecEstateId);

  return (
    <Card>
      <CardHeader title={L.title} />
      <CardContent>
        <TextField
          label={L.estateId}
          value={vitecEstateId}
          name="vitecEstateId"
          dispatch={dispatch}
          error={idError}
          helperText={idError && L.idError}
        />
      </CardContent>
      <CardActions>
        <Button
          size="small"
          color="primary"
          href={vitecLink ?? ''}
          target="_blank"
          rel="noopener noreferrer"
          disabled={!vitecLink}
        >
          Open Vitec Express
        </Button>
      </CardActions>
    </Card>
  );
};

export default VitecCard;
