import { gql } from '@apollo/client';
import { Query } from '@apollo/client/react/components';
import {
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@mui/material';
import { sv as locale } from '@norban/locale';
import moment from 'moment';
import React from 'react';

import QueryError from '../components/QueryError';
import QueryLoading from '../components/QueryLoading';

const Q_ALL_TEMPORARY_HOUSING_ENTRIES = gql`
  query BofGetAllTemporaryHousing {
    temporaryHousingEntries {
      id
      city
      rooms
      startDate
      endDate
      name
      email
      phoneNumber
    }
  }
`;

const TemporaryHousing = () => {
  const L = locale.backoffice.temporaryHousing;
  return (
    <Query query={Q_ALL_TEMPORARY_HOUSING_ENTRIES}>
      {({ loading, error, data }) => {
        if (loading) {
          return <QueryLoading />;
        }

        if (error) {
          return <QueryError error={error} data={data} />;
        }

        return (
          <Paper style={{ overflowX: 'auto' }}>
            <Table className="table" aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>{L.city}</TableCell>
                  <TableCell>{L.rooms}</TableCell>
                  <TableCell>{L.startDate}</TableCell>
                  <TableCell>{L.endDate}</TableCell>
                  <TableCell>{L.name}</TableCell>
                  <TableCell>{L.email}</TableCell>
                  <TableCell>{L.phoneNumber}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.temporaryHousingEntries.map(entry => (
                  <TableRow key={entry.id}>
                    <TableCell>{entry.city}</TableCell>
                    <TableCell>{entry.rooms}</TableCell>
                    <TableCell>
                      {moment(entry.startDate).format('D MMM YYYY')}
                    </TableCell>
                    <TableCell>
                      {moment(entry.endDate).format('D MMM YYYY')}
                    </TableCell>
                    <TableCell>{entry.name}</TableCell>
                    <TableCell>{entry.email}</TableCell>
                    <TableCell>{entry.phoneNumber}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Paper>
        );
      }}
    </Query>
  );
};

export default TemporaryHousing;
