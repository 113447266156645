import { Autocomplete, TextField } from '@mui/material';
import React, { useMemo } from 'react';

const MultiSelect = ({
  enabled = true,
  options,
  label,
  value,
  onValueChanged,
  placeholder = undefined,
  className = undefined,
}: {
  enabled?: boolean;
  options: { key: string; value: string }[];
  label: string;
  placeholder?: string;
  value: string[];
  onValueChanged: (value: string[]) => void;
} & Pick<React.ComponentProps<typeof Autocomplete>, 'className'>) => {
  const selectedOptions = useMemo(
    () => options.filter(opt => value?.includes(opt.key)),
    [options, value],
  );

  return (
    <Autocomplete
      className={className}
      fullWidth
      size="small"
      disabled={!enabled}
      id="combo-box-demo"
      options={options}
      multiple
      value={selectedOptions}
      getOptionLabel={option => option.value}
      onChange={(_, options) => {
        onValueChanged(options.map(({ key }) => key));
      }}
      renderInput={params => (
        <TextField
          {...params}
          fullWidth
          label={label}
          placeholder={placeholder}
          InputLabelProps={{ shrink: placeholder ? true : undefined }}
          variant="outlined"
        />
      )}
    />
  );
};

export default MultiSelect;
