import { gql, useMutation, useQuery } from '@apollo/client';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import LaunchIcon from '@mui/icons-material/Launch';
import {
  Button,
  Fab,
  IconButton,
  Paper,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { sv as locale } from '@norban/locale';
import React, { useMemo, useState } from 'react';

import QueryError from '../../../../components/QueryError';
import QueryLoading from '../../../../components/QueryLoading';
import { tableRowHoverColor } from '../../../../theme';
import { vitecEstateLink } from '../../../../utils';

import NewConstructionSettingsAddDialog from './components/NewConstructionSettingsAddDialog';
import NewConstructionSettingsDeleteDialog from './components/NewConstructionSettingsDeleteDialog';
import NewConstructionSettingsEditDialog from './components/NewConstructionSettingsEditDialog';

const useStyles = makeStyles(theme => ({
  fab: {
    position: 'fixed',
    bottom: theme.spacing(4),
    right: theme.spacing(4),
  },
  row: {
    cursor: 'pointer',
    [theme.breakpoints.up('sm')]: {
      '&:hover': {
        backgroundColor: tableRowHoverColor,
      },
    },
  },
}));

const Q_NEW_CONSTRUCTION_HOMES_AND_ROOT_AREAS = gql`
  query BofNewConstructionHomesAndRootAreas($homeId: ID!) {
    newConstructionHomes(homeId: $homeId) {
      id
      homeId
      linkedHomeId
      name
      state
      home {
        tenure
        type
        state
        address {
          streetAddress
          city
        }
        areaId
        askingPrice
        livingArea
        numberOfRooms
        floor
        monthlyFee
        plot {
          area
        }
        newConstructionEstCompletionDate
        vitecEstateId
        adHideArea
      }
    }
    rootAreas(filter: { includePrivate: true }) {
      id
      name
      children {
        id
        name
        children {
          id
          name
        }
      }
    }
  }
`;

const M_CREATE_NEW_CONSTRUCTION_HOME = gql`
  mutation BofcreateNewConstructionSettings(
    $newHome: NewConstructionHomeInput!
  ) {
    createNewConstructionHome(newHome: $newHome) {
      id
    }
  }
`;

const M_UPDATE_NEW_CONSTRUCTION_HOME = gql`
  mutation BofUpdateNewConstructionSettings(
    $id: ID!
    $updatedHome: NewConstructionHomeInput!
  ) {
    updateNewConstructionHome(id: $id, updatedHome: $updatedHome) {
      id
    }
  }
`;

const M_DELETE_NEW_CONSTRUCTION_HOME = gql`
  mutation BofDeleteNewConstructionSettings(
    $id: ID!
    $deleteLinkedHome: Boolean
  ) {
    deleteNewConstructionHome(id: $id, deleteLinkedHome: $deleteLinkedHome)
  }
`;

const NewConstructionSettings = ({ homeId, onClose }) => {
  const L = locale.backoffice.newConstructionSettings;
  const classes = useStyles();
  const [addDialogOpen, setAddDialogOpen] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [editDialogEntry, setEditDialogEntry] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [snackbar, setSnackbar] = useState({ open: false, message: '' });

  const { data, loading, error, refetch } = useQuery(
    Q_NEW_CONSTRUCTION_HOMES_AND_ROOT_AREAS,
    {
      variables: { homeId },
    },
  );

  // Filter out GraphQL __typename
  const ncHomes = useMemo(
    () =>
      data?.newConstructionHomes?.map(home => {
        const { __typename, ...filteredHome } = home;
        return filteredHome;
      }),
    [data],
  );
  const rootAreas = useMemo(() => data?.rootAreas, [data]);

  const [createHome] = useMutation(M_CREATE_NEW_CONSTRUCTION_HOME, {
    onCompleted: () => refetch(),
    onError: () =>
      setSnackbar({
        open: true,
        message: 'Failed to create new construction home',
      }),
  });

  const [updateHome] = useMutation(M_UPDATE_NEW_CONSTRUCTION_HOME, {
    onCompleted: () => refetch(),
    onError: () =>
      setSnackbar({
        open: true,
        message: 'Failed to update new construction home',
      }),
  });

  const [deleteHome] = useMutation(M_DELETE_NEW_CONSTRUCTION_HOME, {
    onCompleted: () => refetch(),
    onError: () =>
      setSnackbar({
        open: true,
        message: 'Failed to delete new construction home',
      }),
  });

  const handleClick = ncHome => {
    setEditDialogEntry(ncHome);
    setEditDialogOpen(true);
  };

  if (loading) {
    return <QueryLoading />;
  }

  if (error) {
    return <QueryError error={error} data={data} />;
  }

  return (
    <>
      <Button onClick={onClose}>Tillbaka</Button>
      <Paper style={{ overflowX: 'auto' }}>
        <Table className="table" aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Id</TableCell>
              <TableCell>{L.name}</TableCell>
              <TableCell>{L.state}</TableCell>
              <TableCell>{L.linkedHomeId}</TableCell>
              <TableCell>{L.link}</TableCell>
              <TableCell>{L.askingPrice}</TableCell>
              <TableCell>{L.livingArea}</TableCell>
              <TableCell>{L.numberOfRooms}</TableCell>
              <TableCell>{L.floor}</TableCell>
              <TableCell>{L.monthlyFee}</TableCell>
              <TableCell>{L.plotArea}</TableCell>
              <TableCell>{L.newConstructionEstCompletionDate}</TableCell>
              <TableCell>{L.vitecEstateId}</TableCell>
              <TableCell>{L.delete}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {ncHomes?.map(ncHome => (
              <TableRow
                key={ncHome.id}
                className={classes.row}
                onClick={() => handleClick(ncHome)}
              >
                <TableCell>{ncHome.id}</TableCell>
                <TableCell>{ncHome.name}</TableCell>
                <TableCell>{ncHome.state}</TableCell>
                <TableCell>{ncHome.linkedHomeId}</TableCell>
                <TableCell>
                  {ncHome?.home?.vitecEstateId && (
                    <a
                      href={vitecEstateLink(ncHome?.home?.vitecEstateId)}
                      onClick={evt => evt.stopPropagation()}
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label="open vitec estate"
                    >
                      <LaunchIcon />
                    </a>
                  )}
                </TableCell>
                <TableCell>{ncHome?.home?.askingPrice}</TableCell>
                <TableCell>
                  {`${ncHome?.home?.livingArea || ''}`.replace('.', ',')}
                </TableCell>
                <TableCell>
                  {`${ncHome?.home?.numberOfRooms || ''}`.replace('.', ',')}
                </TableCell>
                <TableCell>
                  {`${ncHome?.home?.floor || ''}`.replace('.', ',')}
                </TableCell>
                <TableCell>{ncHome?.home?.monthlyFee}</TableCell>
                <TableCell>{ncHome?.home?.plot?.area}</TableCell>
                <TableCell>
                  {ncHome?.home?.newConstructionEstCompletionDate}
                </TableCell>
                <TableCell>{ncHome?.home?.vitecEstateId}</TableCell>
                <TableCell>
                  <IconButton
                    aria-label="delete"
                    onClick={evt => {
                      evt.stopPropagation();
                      setDeleteDialogOpen(true);
                      setDeleteId(ncHome.id);
                    }}
                    size="large"
                  >
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
      <Fab
        color="primary"
        aria-label="add"
        className={classes.fab}
        onClick={() => setAddDialogOpen(true)}
      >
        <AddIcon />
      </Fab>
      <NewConstructionSettingsAddDialog
        open={addDialogOpen}
        rootAreas={rootAreas}
        onClose={() => setAddDialogOpen(false)}
        onSubmit={data => {
          if (data) {
            createHome({
              variables: {
                newHome: {
                  homeId,
                  name: data.name,
                  state: data.state,
                  linkedHomeId: data.linkedHomeId,
                  // Home properties
                  tenure: data.tenure,
                  type: data.type,
                  homeState: data.homeState,
                  streetAddress: data.streetAddress,
                  city: data.city,
                  areaId: data.areaId,
                  askingPrice: data.askingPrice,
                  livingArea: data.livingArea,
                  numberOfRooms: data.numberOfRooms,
                  floor: data.floor,
                  monthlyFee: data.monthlyFee,
                  plotArea: data.plotArea,
                  newConstructionEstCompletionDate:
                    data.newConstructionEstCompletionDate,
                  vitecEstateId: data.vitecEstateId,
                  adHideArea: data.adHideArea,
                },
              },
            });
          }

          setAddDialogOpen(false);
        }}
      />
      <NewConstructionSettingsEditDialog
        open={editDialogOpen}
        rootAreas={rootAreas}
        entry={editDialogEntry}
        onClose={() => {
          setEditDialogOpen(false);
          setEditDialogEntry(null);
        }}
        onSubmit={data => {
          updateHome({
            variables: {
              id: editDialogEntry.id,
              updatedHome: data,
            },
          });
        }}
      />
      <NewConstructionSettingsDeleteDialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        onDelete={deleteLinkedHome => {
          deleteHome({ variables: { id: deleteId, deleteLinkedHome } });
          setDeleteId(null);
        }}
      />
      <Snackbar
        open={snackbar.open}
        onClose={() => setSnackbar({ open: false, message: '' })}
        message={snackbar.message}
        autoHideDuration={5000}
      />
    </>
  );
};

export default NewConstructionSettings;
