import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import React, { useEffect, useState } from 'react';

const BiddingForm = props => {
  const { home, updateUser, submitting, readOnly } = props;

  const [biddingUrl, setBiddingUrl] = useState(home.biddingUrl || '');

  useEffect(() => {
    if (submitting) {
      const data = { biddingUrl };
      updateUser({ home: data });
    }
  }, [biddingUrl, submitting, updateUser]);

  return (
    <form>
      <Grid container spacing={4} style={{ maxWidth: 520 }}>
        <Grid item xs={12}>
          <TextField
            disabled={readOnly}
            fullWidth
            label="Länk till annons när budgivning är publik"
            margin="normal"
            onChange={evt => setBiddingUrl(evt.target.value)}
            value={biddingUrl}
          />
        </Grid>
      </Grid>
    </form>
  );
};

export default BiddingForm;
