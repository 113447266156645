export const RESET_FILTERS = 'RESET_FILTERS';
export const STORE_EXPANDED = 'STORE_EXPANDED';
export const STORE_FILTER_CRM_ACTION_DATE_END =
  'STORE_FILTER_CRM_ACTION_DATE_END';
export const STORE_FILTER_CRM_ACTION_DATE_START =
  'STORE_FILTER_CRM_ACTION_DATE_START';
export const STORE_FILTER_CRM_ACTIONS_ENABLED =
  'STORE_FILTER_CRM_ACTIONS_ENABLED';
export const STORE_FILTER_CRM_ACTIONS_INVERTED =
  'STORE_FILTER_CRM_ACTIONS_INVERTED';
export const STORE_FILTER_CRM_ASSIGNED_USER_ID =
  'STORE_FILTER_CRM_ASSIGNED_USER_ID';
export const STORE_FILTER_FOLLOWERS_DATE_END =
  'STORE_FILTER_FOLLOWERS_DATE_END';
export const STORE_FILTER_FOLLOWERS_DATE_START =
  'STORE_FILTER_FOLLOWERS_DATE_START';
export const STORE_FILTER_FOLLOWERS_ENABLED = 'STORE_FILTER_FOLLOWERS_ENABLED';
export const STORE_FILTER_HOMES_ENABLED = 'STORE_FILTER_HOMES_ENABLED';
export const STORE_FILTER_USER_HAS_HOME_ENABLED =
  'STORE_FILTER_USER_HAS_HOME_ENABLED';
export const STORE_FILTER_USER_HAS_HOME = 'STORE_FILTER_USER_HAS_HOME';
export const STORE_FILTER_HOMES_STATES = 'STORE_FILTER_HOMES_STATES';
export const STORE_FILTER_HOME_CREATION_DATE_ENABLED =
  'STORE_FILTER_HOME_CREATION_DATE_ENABLED';
export const STORE_FILTER_HOME_CREATION_DATE_END =
  'STORE_FILTER_HOME_CREATION_DATE_END';
export const STORE_FILTER_HOME_CREATION_DATE_START =
  'STORE_FILTER_HOME_CREATION_DATE_START';
export const STORE_FILTER_HOME_TYPES = 'STORE_FILTER_HOME_TYPES';
export const STORE_FILTER_HOME_TYPES_ENABLED =
  'STORE_FILTER_HOME_TYPES_ENABLED';
export const STORE_FILTER_LIVING_AREA_RANGE = 'STORE_FILTER_LIVING_AREA_RANGE';
export const STORE_FILTER_LIVING_AREA_RANGE_ENABLED =
  'STORE_FILTER_LIVING_AREA_RANGE_ENABLED';
export const STORE_FILTER_MESSAGES_ENABLED = 'STORE_FILTER_MESSAGES_ENABLED';
export const STORE_FILTER_MESSAGES_INVERTED = 'STORE_FILTER_MESSAGES_INVERTED';
export const STORE_FILTER_MULTI_AREA = 'STORE_AREA_FILTER';
export const STORE_FILTER_MULTI_AREA_ENABLED =
  'STORE_FILTER_MULTI_AREA_ENABLED';
export const STORE_FILTER_ROLES_ENABLED = 'STORE_FILTER_ROLES_ENABLED';
export const STORE_FILTER_ROLES = 'STORE_FILTER_ROLES';
export const STORE_FILTER_ROOMS_RANGE = 'STORE_FILTER_ROOMS_RANGE';
export const STORE_FILTER_ROOMS_RANGE_ENABLED =
  'STORE_FILTER_ROOMS_RANGE_ENABLED';
export const STORE_FILTER_SEARCH_QUERY = 'STORE_FILTER_SEARCH_QUERY';
export const STORE_FILTER_USER_CREATED_DATE_ENABLED =
  'STORE_FILTER_USER_CREATED_DATE_ENABLED';
export const STORE_FILTER_USER_CREATED_DATE_END =
  'STORE_FILTER_USER_CREATED_DATE_END';
export const STORE_FILTER_USER_CREATED_DATE_START =
  'STORE_FILTER_USER_CREATED_DATE_START';
export const STORE_FILTER_USER_LABELS = 'STORE_FILTER_USER_LABELS';
export const STORE_FILTER_USER_LABELS_ENABLED =
  'STORE_FILTER_USER_LABELS_ENABLED';
export const STORE_FILTER_USER_LABELS_INVERTED =
  'STORE_FILTER_USER_LABELS_INVERTED';
export const STORE_ORDER = 'STORE_ORDER';
export const STORE_ORDER_BY = 'STORE_ORDER_BY';
export const STORE_ORDER_USERS_ENABLED = 'STORE_ORDER_USERS_ENABLED';
export const STORE_HAS_ASSIGNED_AGENT_ENABLED =
  'STORE_HAS_ASSIGNED_AGENT_ENABLED';
export const STORE_HAS_ASSIGNED_AGENT_INVERTED =
  'STORE_HAS_ASSIGNED_AGENT_INVERTED';
export const STORE_FILTER_ASSIGNED_AGENT_ID = 'STORE_FILTER_ASSIGNED_AGENT_ID';
export const STORE_FILTER_USER_HAS_EMAIL_ENABLED =
  'STORE_FILTER_USER_HAS_EMAIL_ENABLED';
export const STORE_FILTER_USER_HAS_EMAIL = 'STORE_FILTER_USER_HAS_EMAIL';
export const STORE_INCLUDE_ASSOCIATED_AREAS = 'STORE_INCLUDE_ASSOCIATED_AREAS';

export const resetFilter = dispatch => {
  dispatch({ type: RESET_FILTERS });
};
export const storeCrmActionsDateEnd = (dispatch, value) =>
  dispatch({ type: STORE_FILTER_CRM_ACTION_DATE_END, value });
export const storeCrmActionsDateStart = (dispatch, value) =>
  dispatch({ type: STORE_FILTER_CRM_ACTION_DATE_START, value });
export const storeExpanded = (dispatch, name, expanded) =>
  dispatch({ type: STORE_EXPANDED, name, expanded });
export const storeFilterAreaEnabled = (dispatch, value) =>
  dispatch({ type: STORE_FILTER_MULTI_AREA_ENABLED, value });
export const storeFilterCrmActionsEnabled = (dispatch, value) =>
  dispatch({ type: STORE_FILTER_CRM_ACTIONS_ENABLED, value });
export const storeFilterCrmActionsInverted = (dispatch, value) =>
  dispatch({ type: STORE_FILTER_CRM_ACTIONS_INVERTED, value });
export const storeFilterCrmAssignedUserId = (dispatch, value) =>
  dispatch({ type: STORE_FILTER_CRM_ASSIGNED_USER_ID, value });
export const storeFilterFollowersDateEnd = (dispatch, value) =>
  dispatch({ type: STORE_FILTER_FOLLOWERS_DATE_END, value });
export const storeFilterFollowersDateStart = (dispatch, value) =>
  dispatch({ type: STORE_FILTER_FOLLOWERS_DATE_START, value });
export const storeFilterFollowersEnabled = (dispatch, value) =>
  dispatch({ type: STORE_FILTER_FOLLOWERS_ENABLED, value });
export const storeFilterHomesEnabled = (dispatch, value) =>
  dispatch({ type: STORE_FILTER_HOMES_ENABLED, value });
export const storeFilterUserHasHome = (dispatch, value) =>
  dispatch({ type: STORE_FILTER_USER_HAS_HOME, value });
export const storeFilterUserHasHomeEnabled = (dispatch, value) =>
  dispatch({ type: STORE_FILTER_USER_HAS_HOME_ENABLED, value });
export const storeFilterHomesStates = (dispatch, value) =>
  dispatch({ type: STORE_FILTER_HOMES_STATES, value });
export const storeFilterHomeTypes = (dispatch, value) =>
  dispatch({ type: STORE_FILTER_HOME_TYPES, value });
export const storeFilterHomeCreationDateEnabled = (dispatch, value) =>
  dispatch({ type: STORE_FILTER_HOME_CREATION_DATE_ENABLED, value });
export const storeFilterHomeCreationDateEnd = (dispatch, value) =>
  dispatch({ type: STORE_FILTER_HOME_CREATION_DATE_END, value });
export const storeFilterHomeCreationDateStart = (dispatch, value) =>
  dispatch({ type: STORE_FILTER_HOME_CREATION_DATE_START, value });
export const storeFilterHomeTypesEnabled = (dispatch, value) =>
  dispatch({ type: STORE_FILTER_HOME_TYPES_ENABLED, value });
export const storeFilterLivingAreaRange = (dispatch, value) =>
  dispatch({ type: STORE_FILTER_LIVING_AREA_RANGE, value });
export const storeFilterLivingAreaRangeEnabled = (dispatch, value) =>
  dispatch({ type: STORE_FILTER_LIVING_AREA_RANGE_ENABLED, value });
export const storeFilterMessagesEnabled = (dispatch, value) =>
  dispatch({ type: STORE_FILTER_MESSAGES_ENABLED, value });
export const storeFilterMessagesInverted = (dispatch, value) =>
  dispatch({ type: STORE_FILTER_MESSAGES_INVERTED, value });
export const storeFilterMultiArea = (dispatch, value) =>
  dispatch({ type: STORE_FILTER_MULTI_AREA, value });
export const storeFilterRolesEnabled = (dispatch, value) =>
  dispatch({ type: STORE_FILTER_ROLES_ENABLED, value });
export const storeFilterRoles = (dispatch, value) =>
  dispatch({ type: STORE_FILTER_ROLES, value });
export const storeFilterRoomsRange = (dispatch, value) =>
  dispatch({ type: STORE_FILTER_ROOMS_RANGE, value });
export const storeFilterRoomsRangeEnabled = (dispatch, value) =>
  dispatch({ type: STORE_FILTER_ROOMS_RANGE_ENABLED, value });
export const storeFilterSearchQuery = (dispatch, value) =>
  dispatch({ type: STORE_FILTER_SEARCH_QUERY, value });
export const storeFilterUserCreatedDateEnabled = (dispatch, value) =>
  dispatch({ type: STORE_FILTER_USER_CREATED_DATE_ENABLED, value });
export const storeFilterUserCreatedDateEnd = (dispatch, value) =>
  dispatch({ type: STORE_FILTER_USER_CREATED_DATE_END, value });
export const storeFilterUserCreatedDateStart = (dispatch, value) =>
  dispatch({ type: STORE_FILTER_USER_CREATED_DATE_START, value });
export const storeFilterUserLabels = (dispatch, value) =>
  dispatch({ type: STORE_FILTER_USER_LABELS, value });
export const storeFilterUserLabelsEnabled = (dispatch, value) =>
  dispatch({ type: STORE_FILTER_USER_LABELS_ENABLED, value });
export const storeFilterUserLabelsInverted = (dispatch, value) =>
  dispatch({ type: STORE_FILTER_USER_LABELS_INVERTED, value });
export const storeOrder = (dispatch, value) =>
  dispatch({ type: STORE_ORDER, value });
export const storeOrderBy = (dispatch, value) =>
  dispatch({ type: STORE_ORDER_BY, value });
export const storeOrderUsersEnabled = (dispatch, value) =>
  dispatch({ type: STORE_ORDER_USERS_ENABLED, value });
export const storeHasAssignedAgentEnabled = (dispatch, value) =>
  dispatch({ type: STORE_HAS_ASSIGNED_AGENT_ENABLED, value });
export const storeHasAssignedAgentInverted = (dispatch, value) =>
  dispatch({ type: STORE_HAS_ASSIGNED_AGENT_INVERTED, value });
export const storeFilterAssignedAgentId = (dispatch, value) =>
  dispatch({ type: STORE_FILTER_ASSIGNED_AGENT_ID, value });
export const storeFilterUserHasEmailEnabled = (dispatch, value) =>
  dispatch({ type: STORE_FILTER_USER_HAS_EMAIL_ENABLED, value });
export const storeFilterUserHasEmail = (dispatch, value) =>
  dispatch({ type: STORE_FILTER_USER_HAS_EMAIL, value });
export const storeFilterIncludeAssociatedAreas = (dispatch, value) =>
  dispatch({ type: STORE_INCLUDE_ASSOCIATED_AREAS, value });
