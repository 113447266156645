import { Card, CardContent, CardHeader } from '@mui/material';
import { sv as locale } from '@norban/locale';
import React from 'react';

import DateField from '../../../../components/form/DateField';
import { BofHomeProfileHomeFragment } from '../../../../generated/backend/graphql';
import { DispatchType } from '../../hooks/useHomeProfileReducer';

type Props = {
  home: BofHomeProfileHomeFragment;
  dispatch: DispatchType;
};

const MilestoneCard = ({ home, dispatch }: Props) => {
  const L = locale.backoffice.homeProfile.milestoneCard;
  const { agreementDate, contractDate, accessDate, withdrawalDate } =
    home || {};

  return (
    <Card>
      <CardHeader title={L.title} />
      <CardContent>
        <DateField
          label={L.agreementDate}
          value={agreementDate}
          name="agreementDate"
          dispatch={dispatch}
          InputLabelProps={{ shrink: true }}
        />
        <DateField
          label={L.contractDate}
          value={contractDate}
          name="contractDate"
          dispatch={dispatch}
          InputLabelProps={{ shrink: true }}
        />
        <DateField
          label={L.accessDate}
          value={accessDate}
          name="accessDate"
          dispatch={dispatch}
          InputLabelProps={{ shrink: true }}
        />
        <DateField
          label={L.withdrawalDate}
          value={withdrawalDate}
          name="withdrawalDate"
          dispatch={dispatch}
          InputLabelProps={{ shrink: true }}
        />
      </CardContent>
    </Card>
  );
};

export default MilestoneCard;
