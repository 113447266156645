import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import { sv as locale } from '@norban/locale';
import React, { useState } from 'react';

const NewConstructionSettingsDeleteDialog = ({ open, onClose, onDelete }) => {
  const L = locale.backoffice.newConstructionSettings;

  const [removeLinked, setRemoveLinked] = useState(true);

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">{L.deleteTitle}</DialogTitle>
      <DialogContent>
        <FormControl component="fieldset" margin="normal">
          <RadioGroup
            aria-label="Ta bort bostad"
            name="remove"
            value={removeLinked ? 'remove' : 'keep'}
            onChange={evt => setRemoveLinked(evt.target.value === 'remove')}
            row
          >
            <FormControlLabel
              value="remove"
              control={<Radio color="primary" />}
              label="Ta även bort länkad bostad"
              labelPlacement="end"
            />
            <FormControlLabel
              value="keep"
              control={<Radio color="primary" />}
              label="Spara länkad bostad"
              labelPlacement="end"
            />
          </RadioGroup>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          {L.cancel}
        </Button>
        <Button
          onClick={async () => {
            onDelete(removeLinked);
            onClose();
          }}
          color="primary"
        >
          {L.delete}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default NewConstructionSettingsDeleteDialog;
