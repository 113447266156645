import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { sv as locale } from '@norban/locale';
import moment from 'moment';
import React, { useEffect } from 'react';

const useStyles = makeStyles({
  linkCard: {
    backgroundColor: '#1e88e5',
    color: '#fff',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },

  linkCardText: {
    color: '#fff',
  },

  linkCardButton: {
    color: '#fff',
    backgroundColor: '#4b9fea',
  },
});

const ViewingForm = ({ home, readOnly, submitting, updateUser }) => {
  const classes = useStyles();
  const estateId = home.vitecEstateId;
  const link = `https://express.maklare.vitec.net/object/${estateId}/visningar`;

  useEffect(() => {
    if (submitting) {
      updateUser();
    }
  }, [submitting, updateUser]);

  const vitecLink = (
    <Card style={{ backgroundColor: '#ff0' }}>
      <CardContent className={classes.linkCard}>
        <div style={{ flex: 1 }}>
          <Typography
            className={classes.linkCardText}
            gutterBottom
            variant="h2"
            component="h5"
          >
            Viewings
          </Typography>
          <Typography className={classes.linkCardText} component="p">
            {locale.backoffice.tasks.VIEWINGS.enterViewingsInVitec}
          </Typography>
        </div>

        <Button className={classes.linkCardButton} size="small" href={link}>
          {locale.backoffice.tasks.VIEWINGS.goToVitec}
        </Button>
      </CardContent>
    </Card>
  );
  return (
    <>
      {vitecLink}

      {home.viewings.map((viewing, index) => {
        const startDate = moment(viewing.startsAt).format('YYYY-MM-DD');
        const startTime = moment(viewing.startsAt).format('HH:mm');
        const endTime = moment(viewing.endsAt).format('HH:mm');
        return (
          <div key={viewing.startsAt}>
            {index > 0 && <Divider variant="middle" />}
            <h3>
              {locale.backoffice.generic.viewing} {index + 1}
            </h3>
            <Grid container spacing={4} style={{ maxWidth: 520 }}>
              <Grid item xs={12}>
                <TextField
                  disabled={readOnly}
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  label={locale.backoffice.generic.date}
                  margin="normal"
                  value={startDate}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  disabled={readOnly}
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  label={locale.backoffice.generic.time}
                  margin="normal"
                  value={`${startTime} — ${endTime}`}
                />
              </Grid>
            </Grid>
          </div>
        );
      })}
    </>
  );
};

export default ViewingForm;
