import { useQuery } from '@apollo/client';
import FlagIcon from '@mui/icons-material/Flag';
import {
  Badge,
  ButtonBase,
  Card,
  CardContent,
  CardHeader,
  FormControlLabel,
  Grid,
  Paper,
  Radio,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/system';
import {
  DataGrid,
  GridColDef,
  GridRowParams,
  GridSortCellParams,
  gridClasses,
  gridDateComparator,
} from '@mui/x-data-grid';
import { sv as locale } from '@norban/locale';
import { addDays, endOfDay } from 'date-fns';
import React, { useMemo, useState } from 'react';

import UserAutocomplete from '../../components/UserAutocomplete';
import {
  BofAgendDashHomeFragment,
  BofAgendDashRowFragment,
  BofAgentsDashDocument,
  BofAgentsDashQuery,
  BofAgentsDashStatsDocument,
  CrmActionType,
  DateRangeInput,
  HomeState,
  SalesProcessStatus,
} from '../../generated/backend/graphql';
import useSession from '../../hooks/useSession';
import { appBarHeight, green, theme } from '../../theme';
import { homeStateShort } from '../../utils';

const useStyles = makeStyles(() => ({
  badge: {
    marginLeft: '15px !important',
  },
  chatBoxButton: {
    width: '100%',
  },
  chatBox: {
    width: '100%',
  },
  crmBox: {
    height: 190,
    minHeight: 190,
    overflow: 'auto',
  },
  tabs: {
    position: 'sticky',
    top: appBarHeight,
    backgroundColor: 'white',
    zIndex: 1,
  },
}));

type CRMFilterType = 'delayedToday' | 'tomorrow' | 'forward';

const AgentsDash = () => {
  const L = locale.backoffice;
  const classes = useStyles();
  const { userId: myUserId } = useSession();

  const [manuallySelectedUserId, setUserId] = useState<string | null>(null);
  const userId = manuallySelectedUserId ?? myUserId?.toString() ?? null;

  const [userTab, setUserTab] = useState<'processing' | 'assignments'>(
    'assignments',
  );

  const [crmFilter, setCrmFilter] = useState<CRMFilterType>('delayedToday');
  const [userFilter, setUserFilter] = useState<
    | 'opportunities'
    | 'established'
    | 'converted'
    | 'pm'
    | 'om'
    | 'paused'
    | 'sold'
  >('pm');

  const now = new Date();
  const crmFilter2Options: { [key in CRMFilterType]: DateRangeInput } = {
    delayedToday: { to: endOfDay(now).toISOString() },
    tomorrow: {
      from: endOfDay(now).toISOString(),
      to: endOfDay(addDays(now, 1)).toISOString(),
    },
    forward: { from: endOfDay(addDays(now, 1)).toISOString() },
  };

  const { data: statsData } = useQuery(BofAgentsDashStatsDocument, {
    variables: {
      adminId: userId,
    },
  });

  const numEvents = statsData?.crmActions.length ?? 0;
  const numChats = statsData?.chatOwners.length ?? 0;

  const { data, loading } = useQuery(BofAgentsDashDocument, {
    variables: {
      crmActionsFilter: {
        assignedUserId: userId,
        dateRange: crmFilter2Options[crmFilter],
        completed: false,
        types: [CrmActionType.Manual],
      },
      homeFilter: {
        assignedAgentId: userId,
        homeStates: [
          HomeState.PremarketLight,
          HomeState.Premarket,
          HomeState.OpenMarket,
          HomeState.Sold,
          HomeState.Hidden,
        ],
      },
    },
  });

  const allHomes = data?.homes ?? [];

  const opportunities = allHomes.filter(
    ({ state, salesProcessStatus }) =>
      [HomeState.PremarketLight, HomeState.Hidden].includes(state) &&
      salesProcessStatus === SalesProcessStatus.Opportunity,
  );

  const established = allHomes.filter(
    ({ state, salesProcessStatus }) =>
      [HomeState.PremarketLight, HomeState.Hidden].includes(state) &&
      salesProcessStatus === SalesProcessStatus.EstablishedContact,
  );

  const converted = allHomes.filter(
    ({ state, salesProcessStatus }) =>
      [HomeState.PremarketLight, HomeState.Hidden].includes(state) &&
      salesProcessStatus === SalesProcessStatus.Converted,
  );

  const pm = allHomes.filter(({ state }) => state === HomeState.Premarket);

  const om = allHomes.filter(({ state }) => state === HomeState.OpenMarket);

  const paused = allHomes.filter(
    ({ state, salesProcessStatus }) =>
      salesProcessStatus &&
      [HomeState.PremarketLight, HomeState.Hidden].includes(state) &&
      [SalesProcessStatus.Paused, SalesProcessStatus.Assignment].includes(
        salesProcessStatus,
      ),
  );

  const sold = allHomes.filter(
    ({ state, salesProcessStatus }) =>
      [HomeState.PremarketLight, HomeState.Hidden, HomeState.Sold].includes(
        state,
      ) &&
      salesProcessStatus &&
      [
        SalesProcessStatus.Sold,
        SalesProcessStatus.SoldPremarket,
        SalesProcessStatus.SoldOpenMarket,
      ].includes(salesProcessStatus),
  );

  const assignmentsPMCount = pm.filter(({ activity }) => !!activity).length;

  const assignmentsOMCount = om.filter(({ activity }) => !!activity).length;

  const assignmentsPausedCount = paused.filter(
    ({ activity }) => !!activity,
  ).length;

  const assignmentsSoldCount = sold.filter(({ activity }) => !!activity).length;

  const assignmentCount =
    assignmentsPMCount +
    assignmentsOMCount +
    assignmentsPausedCount +
    assignmentsSoldCount;

  const homes = useMemo(() => {
    switch (userFilter) {
      case 'opportunities':
        return opportunities;
      case 'established':
        return established;
      case 'converted':
        return converted;
      case 'pm':
        return pm;
      case 'om':
        return om;
      case 'paused':
        return paused;
      case 'sold':
        return sold;
      default:
        return [];
    }
  }, [converted, established, om, opportunities, paused, pm, sold, userFilter]);

  const crmManualActions = data?.crmActions ?? [];

  const rowUser = (row: BofAgendDashRowFragment) =>
    row?.user ?? row?.home?.user ?? null;

  const rowHome = (row: BofAgendDashRowFragment) =>
    row?.home ?? row?.user?.home ?? null;

  const crmColumns: GridColDef[] = [
    {
      disableColumnMenu: true,
      field: 'action',
      flex: 2,
      headerName: L.agentsDash.action,
      sortable: false,
      valueGetter: ({ value }) => value,
    },
    {
      disableColumnMenu: true,
      field: 'user',
      flex: 1,
      headerName: L.agentsDash.seller,
      sortable: false,
      valueGetter: ({ row }) => {
        const user = rowUser(row);
        return user?.name ?? user?.id;
      },
    },
    {
      disableColumnMenu: true,
      field: 'address',
      flex: 1,
      headerName: L.agentsDash.address,
      sortable: false,
      valueGetter: ({ row }) => {
        const home = rowHome(row);
        return home?.address?.streetAddress;
      },
    },
    {
      disableColumnMenu: true,
      field: 'targetDate',
      flex: 1,
      headerName: L.agentsDash.targetDate,
      sortable: false,
      valueGetter: ({ value }) => value,
      renderCell: ({ value }) =>
        value
          ? new Intl.DateTimeFormat('sv-SE', {
              month: 'short',
              day: 'numeric',
            }).format(new Date(value))
          : '',
      sortComparator: gridDateComparator,
    },
  ];

  const sellerColumns: GridColDef[] = [
    {
      field: 'activity',
      headerName: L.agentsDash.activity,
      minWidth: 60,
      sortable: true,
      renderCell: ({ value }) =>
        value ? <FlagIcon htmlColor={green} /> : null,
      sortingOrder: ['desc', 'asc'],
    },
    {
      field: 'name',
      flex: 1,
      headerName: L.agentsDash.seller,
      minWidth: 120,
      sortable: true,
      valueGetter: ({ row }) => row?.user?.name ?? row?.userId,
    },
    {
      field: 'streetAddress',
      flex: 1,
      headerName: L.streetAddress,
      minWidth: 120,
      sortable: true,
      valueGetter: ({ row }) => row?.address?.streetAddress,
    },
    {
      field: 'askingPrice',
      flex: 1,
      headerName: L.askingPrice,
      minWidth: 120,
      sortable: true,
      valueGetter: ({ row }) => row?.askingPrice,
      valueFormatter: ({ value }) => Intl.NumberFormat('sv-SE').format(value),
    },
    {
      field: 'notes',
      flex: 1,
      headerName: L.agentsDash.notes,
      minWidth: 120,
      sortable: true,
      valueGetter: ({ row }) => row?.notes,
    },
    {
      field: 'priority',
      flex: 1,
      headerName: L.agentsDash.relation,
      minWidth: 120,
      sortable: true,
      valueGetter: ({ row }: { row: BofAgendDashHomeFragment }) =>
        row?.user?.userLabels.map(ul => ul.label).join(', '),
    },
    // Note: Fields 'events' and 'status to be added here once we decided the requirements
    {
      field: 'listing',
      flex: 1,
      headerName: L.agentsDash.listing,
      minWidth: 120,
      sortable: true,
      valueGetter: ({ row }) => (row?.state ? homeStateShort(row.state) : ''),
    },
    {
      field: 'lastContacted',
      flex: 1,
      headerName: L.agentsDash.lastContactedAt,
      minWidth: 120,
      sortable: true,
      type: 'date',
      valueGetter: ({ row }) =>
        row?.user?.lastContactedAt
          ? new Date(row?.user?.lastContactedAt)
          : null,
      valueFormatter: ({ value }) =>
        value
          ? new Intl.DateTimeFormat('sv-SE', {
              year: 'numeric',
              month: 'short',
              day: 'numeric',
            }).format(value)
          : '',
      sortComparator: (
        a: Date | null,
        b: Date | null,
        paramA: GridSortCellParams<Date | null>,
        paramB: GridSortCellParams<Date | null>,
      ) => {
        const sortDirection = paramA.api
          .getSortModel()
          .find(
            (s: unknown) =>
              typeof s === 'object' &&
              s &&
              'field' in s &&
              s.field === 'lastContacted',
          )?.sort;

        // Always sort nulls last
        switch (sortDirection) {
          case 'asc':
            if (!a) {
              return 1;
            }
            if (!b) {
              return -1;
            }
            break;
          case 'desc':
            if (!a) {
              return -1;
            }
            if (!b) {
              return 1;
            }
            break;
          default:
        }

        // Sort by date if both are defined
        return gridDateComparator(a, b, paramA, paramB);
      },
    },
    {
      field: 'crmActions',
      flex: 1,
      headerName: L.agentsDash.nextCRM,
      minWidth: 120,
      sortable: true,
      valueGetter: ({ row }: { row: BofAgentsDashQuery['homes'][0] }) => {
        const crmActions = row?.user?.crmActions ?? [];
        const crmActionsAssigned = crmActions.filter(
          action => action.assignedUserId === userId,
        );
        const nextCrmAction = crmActionsAssigned.reduce<
          (typeof crmActionsAssigned)[0] | undefined
        >(
          (acc, action) =>
            !acc || acc.targetDate > action.targetDate ? action : acc,
          undefined,
        );
        const nextCrmActionTargetDate = nextCrmAction?.targetDate
          ? new Intl.DateTimeFormat('sv-SE', {
              year: 'numeric',
              month: 'short',
              day: 'numeric',
            }).format(new Date(nextCrmAction.targetDate))
          : undefined;
        return nextCrmActionTargetDate ?? '-';
      },
    },
  ];

  const handleChatClick = () => {
    window.open(`/chats/${userId}`, '_blank', 'noreferrer');
  };

  const handleEventClick = () => {
    window.open(
      `/events?filterMode=showUnandledAndAssigned&assignedUserId=${userId}`,
      '_blank',
      'noreferrer',
    );
  };

  const handleCrmRowClick = ({
    row,
  }: GridRowParams<BofAgendDashRowFragment>) => {
    if (row.user?.id) {
      window.open(`/users/${row.user.id}/crm`, '_blank', 'noreferrer');
    }

    if (row.home?.id) {
      window.open(`/homes/${row.home.id}/crm`, '_blank', 'noreferrer');
    }
  };

  const handleSellerRowClick = ({
    row,
  }: GridRowParams<BofAgendDashHomeFragment>) => {
    if (row?.id) {
      window.open(`/homes/${row?.id}`, '_blank', 'noreferrer');
    }
  };

  const handleUserTabChange = (newTab: 'processing' | 'assignments') => {
    setUserFilter(newTab === 'processing' ? 'opportunities' : 'pm');
    setUserTab(newTab);
  };

  const rows = loading ? [{ id: L.loading }] : (crmManualActions ?? []);

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <Box flex="1">
          <UserAutocomplete
            defaultLimit={300}
            label={L.agentsDash.who}
            limitToUserRoles={['admin']}
            onSelectedUserIdChanged={setUserId}
            selectedUserId={userId}
            sortAlphabetically
            textFieldProps={{
              fullWidth: true,
            }}
          />
        </Box>
      </Grid>
      <Grid item xs={6} md={3}>
        <ButtonBase onClick={handleChatClick} className={classes.chatBoxButton}>
          <Card variant="outlined" className={classes.chatBox}>
            <CardContent>
              <Box
                display="flex"
                alignItems="center"
                flexDirection="column"
                justifyContent="center"
              >
                <Box mr={1}>
                  <Typography>{L.agentsDash.chats}</Typography>
                </Box>
                <Typography variant="h3">{numChats}</Typography>
              </Box>
            </CardContent>
          </Card>
        </ButtonBase>
      </Grid>
      <Grid item xs={6} md={3}>
        <ButtonBase
          onClick={handleEventClick}
          className={classes.chatBoxButton}
        >
          <Card variant="outlined" className={classes.chatBox}>
            <CardContent>
              <Box
                display="flex"
                alignItems="center"
                flexDirection="column"
                justifyContent="center"
              >
                <Box mr={1}>
                  <Typography>{L.agentsDash.events}</Typography>
                </Box>
                <Typography variant="h3">{numEvents}</Typography>
              </Box>
            </CardContent>
          </Card>
        </ButtonBase>
      </Grid>
      <Grid item xs={12}>
        <Card>
          <CardHeader title={L.crmActionView.todo} />
          <CardContent>
            <FormControlLabel
              control={
                <Radio
                  checked={crmFilter === 'delayedToday'}
                  onChange={() => setCrmFilter('delayedToday')}
                />
              }
              label={L.agentsDash.delayedToday}
            />
            <FormControlLabel
              control={
                <Radio
                  checked={crmFilter === 'tomorrow'}
                  onChange={() => setCrmFilter('tomorrow')}
                />
              }
              label={L.agentsDash.tomorrow}
            />
            <FormControlLabel
              control={
                <Radio
                  checked={crmFilter === 'forward'}
                  onChange={() => setCrmFilter('forward')}
                />
              }
              label={L.agentsDash.forward}
            />
          </CardContent>
          <Box className={classes.crmBox}>
            <DataGrid
              autoHeight
              columnHeaderHeight={40}
              columns={crmColumns}
              hideFooter
              rowHeight={40}
              rows={rows}
              rowSelection={false}
              onRowClick={handleCrmRowClick}
              sortModel={[{ field: 'targetDate', sort: 'asc' }]}
              sx={{
                minHeight: '100%',
              }}
            />
          </Box>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Paper>
          <Tabs
            variant="fullWidth"
            value={userTab}
            onChange={(_, tab) => handleUserTabChange(tab)}
          >
            <Tab label={L.agentsDash.inProcess} value="processing" />
            <Tab
              icon={
                <Badge
                  badgeContent={assignmentCount}
                  className={classes.badge}
                  color="info"
                />
              }
              iconPosition="end"
              label={L.agentsDash.assignments}
              value="assignments"
            />
          </Tabs>
          {userTab === 'processing' ? (
            <Tabs
              variant="fullWidth"
              value={userFilter}
              onChange={(_, tab) => setUserFilter(tab)}
              className={classes.tabs}
            >
              <Tab
                label={
                  isMobile
                    ? L.agentsDash.opportunitiesShort
                    : L.agentsDash.opportunities
                }
                value="opportunities"
              />
              <Tab
                label={
                  isMobile
                    ? L.agentsDash.establishedContactShort
                    : L.agentsDash.establishedContact
                }
                value="established"
              />
              <Tab
                label={
                  isMobile ? L.agentsDash.meetingsShort : L.agentsDash.meetings
                }
                value="converted"
              />
            </Tabs>
          ) : (
            <Tabs
              variant="fullWidth"
              value={userFilter}
              onChange={(_, tab) => setUserFilter(tab)}
              className={classes.tabs}
            >
              <Tab
                icon={
                  <Badge
                    badgeContent={assignmentsPMCount}
                    className={classes.badge}
                    color="info"
                  />
                }
                iconPosition="end"
                label={isMobile ? L.agentsDash.PMShort : L.agentsDash.PM}
                value="pm"
              />
              <Tab
                icon={
                  <Badge
                    badgeContent={assignmentsOMCount}
                    className={classes.badge}
                    color="info"
                  />
                }
                iconPosition="end"
                label={isMobile ? L.agentsDash.OMShort : L.agentsDash.OM}
                value="om"
              />
              <Tab
                icon={
                  <Badge
                    badgeContent={assignmentsPausedCount}
                    className={classes.badge}
                    color="info"
                  />
                }
                iconPosition="end"
                label={L.agentsDash.paused}
                value="paused"
              />
              <Tab
                icon={
                  <Badge
                    badgeContent={assignmentsSoldCount}
                    className={classes.badge}
                    color="info"
                  />
                }
                iconPosition="end"
                label={L.agentsDash.sold}
                value="sold"
              />
            </Tabs>
          )}
          <DataGrid
            autoHeight
            sx={{
              [`& .${gridClasses.cell}`]: {
                py: 1,
              },
            }}
            columns={sellerColumns}
            rows={homes}
            onRowClick={handleSellerRowClick}
            getRowHeight={() => 'auto'}
          />
        </Paper>
      </Grid>
    </Grid>
  );
};

export default AgentsDash;
