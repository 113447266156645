import { RadioGroup, Radio, FormControlLabel } from '@mui/material';
import React, { useEffect, useState } from 'react';

const BookPhotoShootPendingForm = props => {
  const { home, readOnly, updateUser, submitting } = props;
  const [includeStaging, setIncludeStaging] = useState(
    home.photoShoot.includeStaging,
  );

  useEffect(() => {
    if (submitting) {
      updateUser({
        home: {
          photoShoot: {
            includeStaging,
          },
        },
      });
    }
  }, [includeStaging, submitting, updateUser]);

  return (
    <form>
      <RadioGroup
        onChange={evt => setIncludeStaging(evt.target.value === 'true')}
        value={String(!!includeStaging)}
      >
        <FormControlLabel
          disabled={readOnly}
          value="true"
          control={<Radio />}
          label="Yes"
        />

        <FormControlLabel
          disabled={readOnly}
          value="false"
          control={<Radio />}
          label="No"
        />
      </RadioGroup>
    </form>
  );
};

export default BookPhotoShootPendingForm;
