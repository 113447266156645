import { Typography, Paper } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';

const useStyles = makeStyles({
  root: {
    backgroundColor: '#f2f4fe',
    color: '#2f4ada',
    padding: '16px',
    margin: '16px 0',
  },
});

const InfoBar = ({ content }) => {
  const classes = useStyles();
  return (
    <Paper className={classes.root} elevation={0}>
      <Typography component="p">{content}</Typography>
    </Paper>
  );
};

export default InfoBar;
