import { Grid } from '@mui/material';
import { sv as locale } from '@norban/locale';
import React from 'react';

import usePopupAlert from '../../../../hooks/usePopupAlert';

import FollowerCandidateCard from './FollowerCandidateCard';
import MonitorCandidateCard from './MonitorCandidateCard';

type Props = {
  homeId: string;
};

const L = locale.backoffice;

export default function HomeMatching({ homeId }: Props) {
  const { PopupAlert, showPopupAlert } = usePopupAlert();

  return (
    <>
      <Grid container spacing={2} width="100%">
        <Grid item sm={12} md={6}>
          <MonitorCandidateCard
            homeId={homeId}
            onError={() =>
              showPopupAlert(L.monitorCandidate.errorMessage, 'error')
            }
          />
        </Grid>
        <Grid item sm={12} md={6}>
          <FollowerCandidateCard
            homeId={homeId}
            onError={() =>
              showPopupAlert(L.followerCandidate.errorMessage, 'error')
            }
          />
        </Grid>
      </Grid>
      <PopupAlert />
    </>
  );
}
