import { useState, useEffect, useRef } from 'react';

function useDebouncedState<T>(
  initialValue: T,
  delay: number,
): [T, T, (value: T) => void] {
  const [state, setState] = useState<T>(initialValue); // State that updates immediately
  const [debouncedState, setDebouncedState] = useState<T>(initialValue); // Debounced state
  const previousState = useRef<T>(initialValue);

  useEffect(() => {
    // Safety check to prevent the state from updating immediately when mounting the component
    if (state !== previousState.current) {
      const handler = setTimeout(() => {
        setDebouncedState(state);
      }, delay);

      previousState.current = state;

      return () => {
        clearTimeout(handler);
      };
    }

    return undefined;
  }, [state, delay]);

  return [state, debouncedState, setState];
}

export default useDebouncedState;
