import { isPast, isToday, parseISO } from 'date-fns';
import { useMemo } from 'react';

import { CrmAction, User } from '../../../generated/backend/graphql';

// Sorting
const strNumCompare = (a: string, b: string) => Number(a) - Number(b);
const sortId = (user1: User, user2: User) => strNumCompare(user1.id, user2.id);
const sortHome = (user1: User, user2: User) => {
  const home1 = user1.home;
  const home2 = user2.home;

  return home1 && home2 ? strNumCompare(home1.id, home2.id) : 0;
};
const sortUserName = (user1: User, user2: User) => {
  const name1 = user1.name ?? '';
  const name2 = user2.name ?? '';

  return name1.localeCompare(name2);
};
const sortAddress = (user1: User, user2: User) => {
  const streetAddress1 =
    user1.home && user1.home.address && user1.home.address.streetAddress;
  const streetAddress2 =
    user2.home && user2.home.address && user2.home.address.streetAddress;
  if (streetAddress1 && streetAddress2) {
    return streetAddress1.localeCompare(streetAddress2);
  }

  return 0;
};
const sortLink = (user1: User, user2: User) => {
  const home1 = user1.home;
  const home2 = user2.home;

  return (home2?.vitecEstateId ? 1 : 0) - (home1?.vitecEstateId ? 1 : 0);
};
const sortLabel = (user1: User, user2: User) => {
  const userLabel1 = user1.userLabelIds?.[0];
  const userLabel2 = user2.userLabelIds?.[0];

  if (!userLabel1 && !userLabel2) {
    return 0;
  }
  if (userLabel1 && !userLabel2) {
    return -1;
  }
  if (!userLabel1 && userLabel2) {
    return 1;
  }
  return userLabel1?.localeCompare(userLabel2 ?? '') ?? 0;
};
const sortAction = (user1: User, user2: User) => {
  const { crmActions: crmActions1 } = user1;
  const { crmActions: crmActions2 } = user2;

  const gradeActions = (actions: CrmAction[]) => {
    if (!actions || actions.length === 0) {
      return 0;
    }

    if (
      actions?.some(
        action =>
          action.targetDate &&
          (isPast(parseISO(action.targetDate)) ||
            isToday(parseISO(action.targetDate))),
      )
    ) {
      return 2;
    }

    return 1;
  };

  return gradeActions(crmActions2) - gradeActions(crmActions1);
};

const sortActivityScore = (user1: User, user2: User) => {
  const { activityScore: activityScore1 } = user1;
  const { activityScore: activityScore2 } = user2;

  return activityScore2 - activityScore1;
};

const sortAgent = (user1: User, user2: User) => {
  const name1 = user1.home?.assignedAgent?.name ?? '-';
  const name2 = user2.home?.assignedAgent?.name ?? '-';

  return name1.localeCompare(name2);
};

const sortUsers = (
  user1: User,
  user2: User,
  order: string,
  orderBy: string,
) => {
  let comp = 0;

  if (orderBy === 'id') {
    comp = sortId(user1, user2);
  } else if (orderBy === 'homeId') {
    comp = sortHome(user1, user2);
  } else if (orderBy === 'user') {
    comp = sortUserName(user1, user2);
  } else if (orderBy === 'address') {
    comp = sortAddress(user1, user2);
  } else if (orderBy === 'link') {
    comp = sortLink(user1, user2);
  } else if (orderBy === 'label') {
    comp = sortLabel(user1, user2);
  } else if (orderBy === 'action') {
    comp = sortAction(user1, user2);
  } else if (orderBy === 'activityScore') {
    comp = sortActivityScore(user1, user2);
  } else if (orderBy === 'agent') {
    comp = sortAgent(user1, user2);
  }

  return order === 'asc' ? comp : -comp;
};

export default function useFilterUsers(
  users: User[],
  order: string,
  orderBy: string,
) {
  return useMemo(
    () =>
      users?.sort((user1, user2) => sortUsers(user1, user2, order, orderBy)),
    [users, order, orderBy],
  );
}
