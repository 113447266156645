import { Card } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/system';
import React from 'react';

type Props = {
  children: React.ReactNode;
};

const useStyles = makeStyles((theme: Theme) => ({
  rootCard: {
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(-2),
      marginRight: theme.spacing(-2),
    },
  },
}));

const RootCard = ({ children }: Props) => {
  const classes = useStyles();

  return <Card className={classes.rootCard}>{children}</Card>;
};

export default RootCard;
