import { MenuItem, Select } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { sv as locale } from '@norban/locale';
import { isSameDay, subDays } from 'date-fns';
import React, { useCallback, useMemo } from 'react';

import { startOfNorban } from '../../../utils';

const useStyles = makeStyles(theme => ({
  select: {
    fontSize: 15,
    height: theme.spacing(5),
    margin: theme.spacing(1, 1, 1, 0),
  },
}));

const CRMRanges = ({ disabled, startDate, endDate, onChangeDates }) => {
  const L = locale.backoffice.crmRanges;
  const classes = useStyles();

  const onChangeValue = useCallback(
    newValue => {
      const now = new Date();

      if (newValue === 'prior') {
        onChangeDates(startOfNorban, subDays(now, 1));
      } else if (newValue === 'today') {
        onChangeDates(now, now);
      }
    },
    [onChangeDates],
  );

  const value = useMemo(() => {
    const now = new Date();

    if (startDate && endDate) {
      if (
        isSameDay(startDate, startOfNorban) &&
        isSameDay(endDate, subDays(now, 1))
      ) {
        return 'prior';
      }
      if (isSameDay(startDate, now) && isSameDay(endDate, now)) {
        return 'today';
      }
    }

    return 'other';
  }, [endDate, startDate]);

  return (
    <Select
      autoWidth
      value={value}
      className={classes.select}
      size="small"
      onChange={event => onChangeValue(event.target.value)}
      disabled={disabled}
      displayEmpty
    >
      <MenuItem value="other" disabled>
        {L.other}
      </MenuItem>
      <MenuItem value="prior">{L.prior}</MenuItem>
      <MenuItem value="today">{L.today}</MenuItem>
    </Select>
  );
};

export default CRMRanges;
