import { gql, useMutation, useQuery } from '@apollo/client';
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material';
import { sv as locale } from '@norban/locale';
import React, { useEffect, useMemo, useState } from 'react';

import QueryError from '../../../components/QueryError';
import { ChatChangeOwnership } from '../../../generated/backend/graphql';

const M_CHAT_ADMINISTER = gql`
  mutation BofChatAdminister(
    $type: ChatChangeOwnership!
    $userId: ID!
    $ownerId: ID
  ) {
    chatAdminister(type: $type, userId: $userId, ownerId: $ownerId)
  }
`;

const Q_ADMINS = gql`
  query BofAdmins {
    users(filter: { roles: "ADMIN" }) {
      id
      name
    }
    user {
      id
    }
  }
`;

const ChatAdministerDialog = ({
  open,
  user,
  owner,
  type,
  onClose,
  onError,
  onAdminister,
}) => {
  const L = locale.backoffice.chats;

  const [administer, setAdminister] = useState('take');
  const [newOwner, setNewOwner] = useState(owner);
  const [newUser, setNewUser] = useState('');
  const { data, error, loading } = useQuery(Q_ADMINS);

  const [chatAdminister] = useMutation(M_CHAT_ADMINISTER, {
    onError: error => {
      if (
        /User is already administered/.exec(error?.graphQLErrors?.[0]?.message)
      ) {
        onError(L.alreadyAdminstered);
      } else if (
        /Admin user doesn't exist/.exec(error?.graphQLErrors?.[0]?.message)
      ) {
        onError(L.noAdminUser);
      } else if (
        /Admin user is not an admin/.exec(error?.graphQLErrors?.[0]?.message)
      ) {
        onError(L.userIsNotAdmin);
      } else if (
        /User doesn't exist/.exec(error?.graphQLErrors?.[0]?.message)
      ) {
        onError(L.noUser);
      } else {
        onError(L.failedAdminister);
      }
    },
  });

  const ownerOptions = useMemo(() => {
    return data?.users
      ? data.users.map(({ id, name }) => ({
          id,
          label: `${name} (${id}) ${id === data.user.id ? ' - Inloggad' : ''}`,
        }))
      : [];
  }, [data]);

  useEffect(() => {
    if (user !== newUser) {
      setNewUser(user);
    }
    if (owner !== newOwner) {
      setNewOwner(owner);
    }
    if (type && type !== administer) {
      setAdminister(type);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, owner, type]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{ '& .MuiDialog-paper': { overflow: 'visible' } }}
    >
      <DialogTitle>{L.administer}</DialogTitle>
      <DialogContent>
        <RadioGroup
          aria-label="Ta ägandeskap"
          name="Ägandeskap"
          value={administer}
          onChange={evt => {
            setAdminister(evt.target.value);
          }}
          row
        >
          <FormControlLabel
            value="take"
            control={<Radio color="primary" />}
            label="Ta ägandeskap"
            labelPlacement="end"
          />
          <FormControlLabel
            value="release"
            control={<Radio color="primary" />}
            label="Släpp ägandeskap"
            labelPlacement="end"
          />
        </RadioGroup>
        <Box mt={1}>
          <TextField
            type="number"
            margin="dense"
            label={L.user}
            fullWidth
            value={newUser}
            onChange={evt => setNewUser(evt.target.value)}
          />
        </Box>
        <Box mt={1}>
          <Autocomplete
            disablePortal
            loading={loading}
            options={ownerOptions}
            renderInput={params => <TextField {...params} label={L.owner} />}
            value={ownerOptions.find(
              ({ id }) => id === (newOwner || data?.user.id),
            )}
            onChange={(event, option) => {
              if (option) {
                setNewOwner(option.id);
              }
            }}
          />
        </Box>
        {error && <QueryError error={error} />}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button
          disabled={!newUser}
          onClick={async () => {
            await chatAdminister({
              variables: {
                type:
                  administer === 'take'
                    ? ChatChangeOwnership.Take
                    : ChatChangeOwnership.Release,
                userId: newUser,
                ...(newOwner && { ownerId: newOwner }),
              },
            });
            onAdminister();
            onClose();
          }}
          color="primary"
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ChatAdministerDialog;
