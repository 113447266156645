import { useMutation, gql } from '@apollo/client';
import {
  Dialog,
  DialogTitle,
  TextField,
  DialogContent,
  DialogActions,
  Button,
  Snackbar,
} from '@mui/material';
import React, { useState } from 'react';

const M_BAN_USER = gql`
  mutation BofBanUser($userId: ID!) {
    banUser(userId: $userId)
  }
`;

const BanUserDialog = ({ open, onClose }) => {
  const [userId, setUserId] = useState('');
  const [error, setError] = useState(false);
  const [snackbarOpen, showSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const onCloseAndClear = () => {
    setUserId('');
    onClose();
  };

  const [banUser] = useMutation(M_BAN_USER, {
    onCompleted: () => {
      setSnackbarMessage('User banned');
      showSnackbar(true);
    },
    onError: err => {
      console.error(err);
      setSnackbarMessage('Failed to ban user');
      showSnackbar(true);
    },
  });

  const submit = evt => {
    evt.preventDefault();

    const numUserId = parseInt(userId, 10);
    if (!userId || isNaN(numUserId)) {
      setError(true);
      return false;
    }

    banUser({
      variables: {
        userId: numUserId,
      },
    });

    onCloseAndClear();
    return false;
  };

  return (
    <>
      <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
        <form onSubmit={submit}>
          <DialogTitle>Ban user</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              label="User ID"
              value={userId}
              onChange={evt =>
                setUserId(evt.target.value) && error && setError(false)
              }
              fullWidth
              error={error}
              helperText={error ? 'Incorrect user ID' : ' '}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={onCloseAndClear} color="primary">
              Cancel
            </Button>

            <Button onClick={submit} color="primary">
              Ban
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <Snackbar
        open={snackbarOpen}
        onClose={() => showSnackbar(false)}
        message={snackbarMessage}
        autoHideDuration={5000}
      />
    </>
  );
};

export default BanUserDialog;
