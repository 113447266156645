import svGlobal from './sv/sv.json';
import svNotifications from './sv/sv.notifications.json';
import svBackoffice from './sv/sv.backoffice.json';
import svMail from './sv/sv.mail.json';
import svWeb from './sv/sv.web.json';

export const sv = {
  ...svGlobal,
  notifications: svNotifications,
  mail: svMail,
  backoffice: svBackoffice,
  web: svWeb,
};

export default {
  sv,
};
