import { Stack, Tabs, Tab } from '@mui/material';
import React from 'react';
import {
  Link,
  Redirect,
  Route,
  Switch,
  useLocation,
  useRouteMatch,
} from 'react-router-dom';

import AgreementContracts from './AgreementContracts';
import HomeSalesProcessMain from './HomeSalesProcessMain';

type Props = {
  homeId: string;
  userId?: string;
};

export default function HomeSalesProcess({
  homeId,
  userId = undefined,
}: Props) {
  const { path, url } = useRouteMatch();
  const { pathname } = useLocation();

  return (
    <Stack spacing={2}>
      <Tabs value={pathname.split('/')[4]}>
        <Tab
          component={Link}
          label="Affären"
          to={`${url}/process`}
          value="process"
        />
        <Tab
          component={Link}
          label="UA"
          to={`${url}/agreement-contracts`}
          value="agreement-contracts"
        />
      </Tabs>
      <Switch>
        <Route path={`${path}/process`} exact>
          <HomeSalesProcessMain homeId={homeId} />
        </Route>
        <Route path={`${path}/agreement-contracts`}>
          <AgreementContracts homeId={homeId} userId={userId} />
        </Route>
        <Route>
          <Redirect to={`${url}/process`} />
        </Route>
      </Switch>
    </Stack>
  );
}
