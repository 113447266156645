import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  FormControlLabel,
  FormHelperText,
} from '@mui/material';
import { sv as locale } from '@norban/locale';
import React from 'react';

import NumberField from '../../../../components/form/NumberField';
import Select from '../../../../components/form/Select';
import TextField from '../../../../components/form/TextField';
import { BofHomeProfileHomeFragment } from '../../../../generated/backend/graphql';
import { DispatchType } from '../../hooks/useHomeProfileReducer';

type Props = {
  home: BofHomeProfileHomeFragment;
  dispatch: DispatchType;
};

const BaseInformationCard = ({ home, dispatch }: Props) => {
  const L = locale.backoffice;

  return (
    <Card>
      <CardHeader title={L.homeProfile.title} />
      <CardContent>
        <Select
          label={L.homeProfile.homeType}
          value={home.type}
          name="type"
          dispatch={dispatch}
          options={L.enumerations.HomeType}
          includeEmpty
        />
        <Select
          label={L.homeProfile.tenure}
          value={home.tenure}
          name="tenure"
          dispatch={dispatch}
          options={L.enumerations.Tenure}
          includeEmpty
          sortOptions={false}
        />
        <NumberField
          label={L.homeProfile.floor}
          value={home.floor}
          name="floor"
          dispatch={dispatch}
          type="number"
        />
        <NumberField
          label={L.homeProfile.floorMax}
          value={home.floorMax}
          name="floorMax"
          dispatch={dispatch}
          type="number"
        />
        <NumberField
          label={L.homeProfile.livingArea}
          value={home.livingArea}
          name="livingArea"
          dispatch={dispatch}
          type="number"
        />
        <NumberField
          label={L.homeProfile.livingAreaMax}
          value={home.livingAreaMax}
          name="livingAreaMax"
          dispatch={dispatch}
          type="number"
        />
        <NumberField
          label={L.homeProfile.otherArea}
          value={home.otherArea}
          name="otherArea"
          dispatch={dispatch}
          type="number"
        />
        <NumberField
          label={L.homeProfile.plotArea}
          value={home.plot.area}
          name="plotArea"
          dispatch={({ value }) =>
            dispatch({
              key: 'plot',
              value: {
                ...home.plot,
                area: value,
              },
            })
          }
          type="number"
        />
        <NumberField
          label={L.homeProfile.numberOfRooms}
          value={home.numberOfRooms}
          name="numberOfRooms"
          dispatch={dispatch}
          type="number"
        />
        <NumberField
          label={L.homeProfile.numberOfRoomsMax}
          value={home.numberOfRoomsMax}
          name="numberOfRoomsMax"
          dispatch={dispatch}
          type="number"
        />
        <Box mb={0.5}>
          <FormHelperText>Additional spaces</FormHelperText>
        </Box>
        <FormControlLabel
          label={L.enumerations.AdditionalSpace.BALCONY}
          control={
            <Checkbox
              value={!!home.balcony}
              checked={!!home.balcony}
              color="primary"
              onChange={evt =>
                dispatch({ key: 'balcony', value: evt.target.checked })
              }
            />
          }
        />
        <FormControlLabel
          label={L.enumerations.AdditionalSpace.PATIO}
          control={
            <Checkbox
              value={!!home.patio}
              checked={!!home.patio}
              color="primary"
              onChange={evt =>
                dispatch({ key: 'patio', value: evt.target.checked })
              }
            />
          }
        />
        <FormControlLabel
          label={L.homeProfile.elevator}
          control={
            <Checkbox
              value={!!home.elevator}
              checked={!!home.elevator}
              color="primary"
              onChange={evt =>
                dispatch({ key: 'elevator', value: evt.target.checked })
              }
            />
          }
        />
        <FormControlLabel
          label={L.enumerations.AdditionalSpace.TERRACE}
          control={
            <Checkbox
              value={!!home.terrace}
              checked={!!home.terrace}
              color="primary"
              onChange={evt =>
                dispatch({ key: 'terrace', value: evt.target.checked })
              }
            />
          }
        />
        <TextField
          label={L.homeProfile.otherDescription}
          value={home.description?.other}
          name="other"
          multiline
          rows="8"
          dispatch={({ value }) =>
            dispatch({
              key: 'description',
              value: {
                ...home.description,
                other: value,
              },
            })
          }
        />
        <TextField
          label={L.homeProfile.shortDescription}
          value={home.description?.short}
          name="short"
          multiline
          rows="3"
          dispatch={({ value }) =>
            dispatch({
              key: 'description',
              value: {
                ...home.description,
                short: value,
              },
            })
          }
        />
      </CardContent>
    </Card>
  );
};

export default BaseInformationCard;
