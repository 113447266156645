import { Checkbox, FormControlLabel, Typography } from '@mui/material';
import React from 'react';

function BoolArrayCheckboxes({
  value,
  onChange,
  formatLabel,
  disabled,
}: {
  value: boolean[];
  onChange: (value: boolean[]) => void;
  formatLabel: (index: number) => string;
  disabled: boolean;
}) {
  return value.map((checked, i) => (
    <FormControlLabel
      key={i}
      label={<Typography style={{ fontSize: 15 }}>{formatLabel(i)}</Typography>}
      control={
        <Checkbox
          disabled={disabled}
          checked={checked}
          color="primary"
          onChange={(_, checked) => {
            onChange([...value.slice(0, i), checked, ...value.slice(i + 1)]);
          }}
        />
      }
    />
  ));
}

export default BoolArrayCheckboxes;
