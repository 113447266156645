import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import moment from 'moment';
import React, { useEffect, useState } from 'react';

import {
  dateToDateTimeScalar,
  dateTimeScalarToDate,
} from '../../../../../components/client';

const PhotoShootForm = props => {
  const { home, updateUser, submitting, readOnly } = props;
  const ps = home.photoShoot;

  const dt = dateTimeScalarToDate(ps.photoShootDate);
  const initialDate = dt && moment(dt).format('YYYY-MM-DD');
  const initialTime = dt && moment(dt).format('HH:mm');
  const [photoShootDate, setPhotoShootDate] = useState(initialDate || '');
  const [photoShootTime, setPhotoShootTime] = useState(initialTime || '');
  const [photographer, setPhotographer] = useState(ps.photographer || '');

  useEffect(() => {
    if (submitting) {
      const photoShootDateTime = moment(
        `${photoShootDate} ${photoShootTime}`,
        'YYYY-MM-DD HH:mm',
      );

      updateUser({
        home: {
          photoShoot: {
            photoShootDate: dateToDateTimeScalar(photoShootDateTime),
            photographer,
          },
        },
      });
    }
  }, [photoShootDate, photoShootTime, photographer, submitting, updateUser]);

  return (
    <form>
      <Typography variant="h5">Photo Shoot</Typography>
      <Grid container spacing={4} style={{ maxWidth: 520 }}>
        <Grid item xs={6.8}>
          <TextField
            disabled={readOnly}
            fullWidth
            InputLabelProps={{ shrink: true }}
            label="Date"
            margin="normal"
            onChange={evt => setPhotoShootDate(evt.target.value)}
            type="date"
            value={photoShootDate}
          />
        </Grid>
        <Grid item xs={5.2}>
          <TextField
            disabled={readOnly}
            fullWidth
            InputLabelProps={{ shrink: true }}
            label="Time"
            margin="normal"
            onChange={evt => setPhotoShootTime(evt.target.value)}
            type="time"
            value={photoShootTime}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            disabled={readOnly}
            fullWidth
            label="Photographer"
            margin="normal"
            onChange={evt => setPhotographer(evt.target.value)}
            value={photographer}
          />
        </Grid>
      </Grid>
    </form>
  );
};

export default PhotoShootForm;
