import { Link } from '@mui/material';
import Grid from '@mui/material/Grid';
import React, { useEffect } from 'react';

const ReviewPremarketAdForm = props => {
  const { home, updateUser, submitting } = props;

  useEffect(() => {
    if (submitting) {
      updateUser();
    }
  }, [submitting, updateUser]);

  return (
    <form>
      <Grid container spacing={4} style={{ maxWidth: 520 }}>
        <Grid item xs={4}>
          <Link
            href={home.adPreviewUrl}
            target="_blank"
            rel="noopener noreferrer"
            underline="hover"
          >
            {home.adPreviewUrl}
          </Link>
        </Grid>
      </Grid>
    </form>
  );
};

export default ReviewPremarketAdForm;
