import { Box, Input, Typography } from '@mui/material';
import React from 'react';

import { RangeInput } from '../../../generated/backend/graphql';

type Props = {
  value?: RangeInput;
  onChange: (value?: RangeInput) => void;
};

export default function RangeInputFields({
  value = undefined,
  onChange,
}: Props) {
  return (
    <Box flex="1" flexDirection="row" display="flex" alignItems="baseline">
      <Box pr={2}>
        <Input
          type="number"
          placeholder="Min"
          value={value?.low}
          onChange={e => {
            onChange({
              low: parseFloat(e.target.value),
              high: value?.high,
            });
          }}
        />
      </Box>
      <Typography gutterBottom> - </Typography>
      <Box pl={2}>
        <Input
          type="number"
          placeholder="Max"
          value={value?.high}
          onChange={e => {
            onChange({
              low: value?.low,
              high: parseFloat(e.target.value),
            });
          }}
        />
      </Box>
    </Box>
  );
}
