import { useQuery } from '@apollo/client';
import { Stack, TextField, Typography } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import makeStyles from '@mui/styles/makeStyles';
import { sv as locale } from '@norban/locale';
import React, { useState } from 'react';

import {
  BofUserServerSideAutoCompleteSelectedUserDocument,
  BofUserServerSideAutoCompleteUserFragment,
  BofUserServerSideAutoCompleteUsersDocument,
} from '../generated/backend/graphql';
import useDebouncedState from '../hooks/useDebouncedState';
import { steelGray } from '../theme';

const L = locale.backoffice.components.UserAutocomplete;

const useStyles = makeStyles(() => ({
  detailText: {
    color: steelGray,
  },
  row: {
    alignItems: 'flex-start !important',
  },
}));

type Props = {
  autoFocus?: boolean;
  dense?: boolean;
  disabled?: boolean;
  selectedUserId: string | null;
  label?: string;
  limitToUserRoles?: ('admin' | 'user' | 'banned')[];
  sortAlphabetically?: boolean;
  textFieldProps?: Partial<React.ComponentProps<typeof TextField>>;
  onSelectedUserIdChanged: (userId: string | null) => void;
  defaultLimit?: number;
} & Pick<
  React.ComponentProps<typeof Autocomplete<string | null>>,
  'className' | 'fullWidth' | 'size'
>;

const UserServerSideAutocomplete = ({
  autoFocus = false,
  dense = true,
  className,
  defaultLimit = 30,
  disabled = false,
  fullWidth = undefined,
  label = L.label,
  limitToUserRoles = undefined,
  selectedUserId,
  size = undefined,
  sortAlphabetically = false,
  textFieldProps = {},
  onSelectedUserIdChanged,
}: Props) => {
  const classes = useStyles();
  const [focused, setFocused] = useState(false);

  const [, debouncedSearchQuery, setDebouncedSearchQuery] = useDebouncedState(
    '',
    300,
  );

  const { data, loading } = useQuery(
    BofUserServerSideAutoCompleteUsersDocument,
    {
      variables: {
        filter: {
          roles: limitToUserRoles,
          searchQuery: debouncedSearchQuery,
          limit: defaultLimit,
          order: sortAlphabetically ? { field: 'name' } : undefined,
        },
      },
      skip: !debouncedSearchQuery,
    },
  );
  // We need to load the selected user separately to cover the case where the selected user
  // is not in the list of queried users.
  const { data: dataSelectedUser, loading: loadingSelectedUser } = useQuery(
    BofUserServerSideAutoCompleteSelectedUserDocument,
    {
      variables: {
        userId: selectedUserId ?? '',
      },
      skip: !selectedUserId,
    },
  );

  const filterOptions = (x: BofUserServerSideAutoCompleteUserFragment[]) => x;

  const users = (() => {
    if (focused) {
      return data?.queriedUsers ?? [];
    }

    return dataSelectedUser?.selectedUser
      ? [dataSelectedUser.selectedUser]
      : [];
  })();

  const value = (() => {
    if (focused) {
      return null;
    }

    return dataSelectedUser?.selectedUser ?? null;
  })();

  return (
    <Autocomplete
      loading={loading || loadingSelectedUser}
      loadingText={L.loading}
      className={className}
      disabled={disabled}
      filterOptions={filterOptions}
      fullWidth={fullWidth}
      getOptionLabel={option => `${option?.name} [${option.id}]`}
      multiple={false}
      options={users}
      size={size}
      value={value ?? null}
      onChange={(_, newValue) => {
        onSelectedUserIdChanged(newValue?.id ?? null);
      }}
      onInputChange={(_, newInputValue) => {
        setDebouncedSearchQuery(newInputValue);
      }}
      renderInput={params => (
        <TextField
          autoFocus={autoFocus}
          label={label}
          {...textFieldProps}
          {...params}
          onBlur={() => setFocused(false)}
          onFocus={() => setFocused(true)}
          sx={{ backgroundColor: 'white' }}
        />
      )}
      renderOption={
        dense
          ? undefined
          : ({ className, ...props }, option) => {
              return (
                <Stack
                  component="li"
                  padding={1}
                  className={`${className} ${classes.row}`}
                  {...props}
                >
                  <Typography variant="body1">{`${option.name} [${option.id}]`}</Typography>
                  <Typography
                    className={classes.detailText}
                    variant="caption"
                  >{`Tel: ${option.phone ?? '-'}`}</Typography>
                  <Typography
                    className={classes.detailText}
                    variant="caption"
                  >{`Mejl: ${option.email ?? '-'}`}</Typography>
                </Stack>
              );
            }
      }
    />
  );
};

export default UserServerSideAutocomplete;
