import { useQuery } from '@apollo/client';
import HomeIcon from '@mui/icons-material/Home';
import {
  Button,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import {
  DataGrid,
  GridColDef,
  GridSortModel,
  gridNumberComparator,
} from '@mui/x-data-grid';
import { sv as locale } from '@norban/locale';
import React, { useMemo, useState } from 'react';

import PageHeader from '../../components/PageHeader';
import RootCard from '../../components/RootCard';
import {
  BofUserImagesDocument,
  OwnerUploadedImageState,
} from '../../generated/backend/graphql';
import { homeStateShort } from '../../utils';
import OwnerUploadedImages from '../Home/components/HomeListing/OwnerUploadedImages';

const L = locale.backoffice;

const columns: GridColDef[] = [
  {
    field: 'name',
    headerName: L.name,
    sortable: false,
    width: 160,
    valueGetter: ({ row }) => row.name,
  },
  {
    field: 'address',
    headerName: L.streetAddress,
    sortable: false,
    width: 160,
    valueGetter: ({ row }) => row.streetAddress,
  },
  {
    field: 'id',
    headerName: L.homeId,
    sortable: true,
    width: 80,
    renderHeader: () => <HomeIcon />,
    renderCell: ({ value }) => (
      <a
        href={`/homes/${value}`}
        onClick={evt => evt.stopPropagation()}
        target="_blank"
        rel="noreferrer"
      >
        {value}
      </a>
    ),
    sortComparator: gridNumberComparator,
  },
  {
    field: 'state',
    headerName: L.state,
    sortable: false,
    width: 80,
    valueGetter: ({ row }) => (row?.state ? homeStateShort(row.state) : ''),
  },
  {
    field: 'count',
    headerName: L.ownerUploadedImages.numberOfImages,
    type: 'string',
    width: 80,
  },
  {
    field: 'createdAt',
    headerName: L.ownerUploadedImages.createdAt,
    type: 'date',
    width: 150,
    renderCell: ({ value }) =>
      new Intl.DateTimeFormat('sv-SE', {
        day: 'numeric',
        month: 'short',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      }).format(value),
  },
  {
    field: 'updatedAt',
    headerName: L.ownerUploadedImages.updatedAt,
    type: 'date',
    width: 150,
    renderCell: ({ value }) =>
      new Intl.DateTimeFormat('sv-SE', {
        day: 'numeric',
        month: 'short',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      }).format(value),
  },
  {
    field: 'agent',
    headerName: 'Mäklare',
    type: 'string',
    width: 160,
    valueGetter: ({ row }) => row.agent,
  },
];

const UserImages = () => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [filter, setFilter] = useState<'awaiting' | 'handled'>('awaiting');
  const [currentHomeId, setCurrentHomeId] = useState<string | undefined | null>(
    null,
  );
  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: 'createdAt',
      sort: 'desc',
    },
  ]);

  const { data } = useQuery(BofUserImagesDocument, {
    variables: {
      states:
        filter === 'awaiting'
          ? [OwnerUploadedImageState.Initial, OwnerUploadedImageState.Pending]
          : [
              OwnerUploadedImageState.Approved,
              OwnerUploadedImageState.Rejected,
            ],
    },
  });

  type RowType = {
    count: number;
    id: string;
    createdAt: Date;
    updatedAt: Date;
    streetAddress: string;
    state: string;
    name: string;
    agent: string;
  };

  const rows = useMemo<RowType[]>(() => {
    if (!data?.ownerUploadedImages) {
      return [];
    }

    const map = data.ownerUploadedImages.reduce<Map<string, RowType>>(
      (acc, curr) => {
        const { homeId } = curr;
        const element = acc.get(homeId);

        if (element) {
          element.count += 1;

          const newUpdatedAt = new Date(curr.updatedAt);
          if (newUpdatedAt > element.updatedAt) {
            element.updatedAt = newUpdatedAt;
          }

          const newCreatedAt = new Date(curr.createdAt);
          if (newCreatedAt < element.createdAt) {
            element.createdAt = newCreatedAt;
          }
        } else {
          acc.set(homeId, {
            count: 1,
            id: homeId,
            createdAt: new Date(curr.createdAt),
            updatedAt: new Date(curr.updatedAt),
            streetAddress: curr.home?.address?.streetAddress ?? '',
            state: curr.home?.state ?? '',
            name: curr.home?.user?.name ?? '',
            agent: curr.home?.assignedAgent?.name ?? '',
          });
        }

        return acc;
      },
      new Map(),
    );

    return Array.from(map.values());
  }, [data]);

  const handleRowClick = (homeId: string) => {
    setCurrentHomeId(homeId);
    setDialogOpen(true);
  };

  const handleClose = () => {
    setDialogOpen(false);
  };

  return (
    <>
      <Dialog open={dialogOpen} onClose={handleClose} fullWidth maxWidth="md">
        <DialogTitle>{L.ownerUploadedImages.title}</DialogTitle>
        <DialogContent>
          {currentHomeId && (
            <OwnerUploadedImages homeId={currentHomeId} showUserInfo />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {L.close}
          </Button>
        </DialogActions>
      </Dialog>
      <PageHeader title={L.ownerUploadedImages.title} />
      <RootCard>
        <CardContent>
          <RadioGroup
            aria-label="Filterinställning"
            value={filter}
            onChange={evt =>
              setFilter(
                evt.target.value === 'awaiting' ? 'awaiting' : 'handled',
              )
            }
            row
          >
            <FormControlLabel
              value="awaiting"
              control={<Radio />}
              label={L.ownerUploadedImages.awaitingReview}
            />
            <FormControlLabel
              value="handled"
              control={<Radio />}
              label={L.ownerUploadedImages.approvedOrRejected}
            />
          </RadioGroup>
        </CardContent>
        <DataGrid
          autoHeight
          columns={columns}
          disableRowSelectionOnClick
          rows={rows}
          onRowClick={({ row }) => handleRowClick(row.id)}
          sortModel={sortModel}
          onSortModelChange={model => setSortModel(model)}
        />
      </RootCard>
    </>
  );
};

export default UserImages;
