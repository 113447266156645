import { useMutation, useQuery } from '@apollo/client';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { sv as locale } from '@norban/locale';
import React, { useState } from 'react';

import {
  BofMoveFollowerLookupHomeDocument,
  BofMoveFollowerUpdateFollowersDocument,
} from '../generated/backend/graphql';

import ConfirmationDialog from './ConfirmationDialog';
import HomeLookup from './HomeLookup';

type Props = {
  homeId: string | undefined;
  partialFollowers: { id: string | undefined; userId: string | undefined }[];
  open: boolean;
  onClose: () => void;
  onMoveFollowers: (ids: string[]) => void;
};

const MoveFollowersDialog = ({
  homeId,
  partialFollowers,
  open,
  onClose,
  onMoveFollowers,
}: Props) => {
  const L = locale.backoffice;
  const [updatedHomeId, setHomeId] = useState<number | undefined>(undefined);
  const [confirmOpen, setConfirmOpen] = useState(false);

  const { data: lookupData, loading } = useQuery(
    BofMoveFollowerLookupHomeDocument,
    {
      variables: {
        homeId: updatedHomeId ? `${updatedHomeId}` : '',
      },
      skip: !updatedHomeId,
    },
  );

  const ids = partialFollowers.map(pf => pf.id).filter(Boolean) as string[];
  const lookupUserIds =
    lookupData?.followers?.map(follower => follower.userId) ?? [];
  const moveIds = partialFollowers
    .filter(pf => !lookupUserIds.includes(pf.userId))
    .map(pf => pf.id)
    .filter(Boolean) as string[];

  const [updateFollowers] = useMutation(
    BofMoveFollowerUpdateFollowersDocument,
    {
      variables: {
        ids: moveIds,
        input: {
          homeId: updatedHomeId ? `${updatedHomeId}` : undefined,
        },
      },
    },
  );

  const handleSubmit = async () => {
    try {
      await updateFollowers();
    } catch (error) {
      console.error('Error updating followers:', error);
    }
  };

  const renderWhichToMove = () => {
    if (!lookupData?.followers) {
      return <div>{L.moveFollowers.lookupToMove}</div>;
    }
    if (moveIds.length === 0) {
      return <div>{L.moveFollowers.noFollowersToMove}</div>;
    }

    return (
      <div>
        {L.moveFollowers.numberToMove} {moveIds.length} st
      </div>
    );
  };

  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
        <DialogTitle>{L.moveFollowers.title}</DialogTitle>
        <DialogContent>
          Kandidater: {ids.length} st
          <HomeLookup
            homeId={updatedHomeId}
            label={L.moveFollowers.toHome}
            loading={loading}
            streetAddress={lookupData?.home?.address.streetAddress ?? ''}
            onChangeHomeId={updatedHomeId => setHomeId(updatedHomeId)}
          />
          {renderWhichToMove()}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => onClose()} color="primary">
            {L.cancel}
          </Button>

          <Button
            disabled={homeId === updatedHomeId}
            onClick={() => setConfirmOpen(true)}
            color="primary"
          >
            {L.moveFollowers.move}
          </Button>
        </DialogActions>
      </Dialog>
      <ConfirmationDialog
        open={confirmOpen}
        title={L.confirm}
        content={L.confirm}
        onConfirm={async () => {
          await handleSubmit();
          setConfirmOpen(false);
          onMoveFollowers(moveIds);
          onClose();
        }}
        onCancel={() => setConfirmOpen(false)}
      />
    </>
  );
};

export default MoveFollowersDialog;
