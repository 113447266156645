/* eslint-disable no-underscore-dangle */
declare global {
  interface Window {
    _env_: {
      WEB_URI: string;
      BACKEND_URI: string;
      CONTENT_URI: string;
      NORBAN_ENVIRONMENT: string;
    };
  }
}

let env = {
  WEB_URI: '',
  BACKEND_URI: '',
  CONTENT_URI: '',
  NORBAN_ENVIRONMENT: '',
};

if (typeof window !== 'undefined') {
  env = {
    WEB_URI: window._env_.WEB_URI,
    BACKEND_URI: window._env_.BACKEND_URI,
    CONTENT_URI: window._env_.CONTENT_URI,
    NORBAN_ENVIRONMENT: window._env_.NORBAN_ENVIRONMENT,
  };
}

export const { WEB_URI, BACKEND_URI, NORBAN_ENVIRONMENT, CONTENT_URI } = env;
