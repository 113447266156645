import {
  Card,
  CardContent,
  Table,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import React from 'react';

const HomesCard = ({ homes }) => {
  return (
    <Card>
      <CardContent>
        <Table>
          <TableHead>
            <TableCell>id</TableCell>
            <TableCell>Adress</TableCell>
            <TableCell>City</TableCell>
          </TableHead>
          {homes.map(home => (
            <TableRow key={home.id}>
              <TableCell>{home.id}</TableCell>
              <TableCell>{home.address.streetAddress}</TableCell>
              <TableCell>{home.address.city}</TableCell>
            </TableRow>
          ))}
        </Table>
      </CardContent>
    </Card>
  );
};

export default HomesCard;
