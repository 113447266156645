import { sv as locale } from '@norban/locale';
import { useEffect, useRef } from 'react';

import { WEB_URI } from './config';

const L = locale.backoffice;

export const startOfNorban = new Date('2019-07-01');

// This hook was copied from this blog post by Dan Abramov:
// https://overreacted.io/making-setinterval-declarative-with-react-hooks/
export function useInterval(callback: () => void, delay: number) {
  const savedCallback = useRef<() => void>();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current?.();
    }

    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }

    return () => {};
  }, [delay]);
}

export const vitecEstateLink = (estateId: string) =>
  `https://express.maklare.vitec.net/objekt/${estateId}`;

export const norbanWebLink = (homeId: string) =>
  `${WEB_URI}/vara-bostader/${homeId}`;

export function insertIf(condition: boolean, object: object) {
  return condition ? object : {};
}

export function homeStateShort(state: string) {
  return L.enumerations.HomeStateShort[
    state as
      | 'PREMARKET_LIGHT'
      | 'PREMARKET'
      | 'OPEN_MARKET'
      | 'SOLD'
      | 'HIDDEN'
      | 'WITHDRAWN'
      | 'NONE'
  ];
}
