import { RadioGroup, Radio, FormControlLabel } from '@mui/material';
import { sv as locale } from '@norban/locale';
import React, { useEffect } from 'react';

const BookPhotoShootForm = props => {
  const { home, readOnly, submitting, updateUser } = props;

  // FIXME: Pick translation from @norban/locale.
  const choices = {
    WANT_STYLING: locale.MH.BOOK_PHOTO_SHOOT.option1,
    NO_STYLING: locale.MH.BOOK_PHOTO_SHOOT.option2,
    MORE_INFO: locale.MH.BOOK_PHOTO_SHOOT.option3,
  };

  useEffect(() => {
    if (submitting) {
      updateUser();
    }
  }, [submitting, updateUser]);

  return (
    <RadioGroup value={home.photoShoot.request}>
      {Object.keys(choices).map(key => (
        <FormControlLabel
          disabled={readOnly}
          key={key}
          value={key}
          control={<Radio />}
          label={choices[key]}
        />
      ))}
    </RadioGroup>
  );
};

export default BookPhotoShootForm;
