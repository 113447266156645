import { ApolloError, useMutation, useQuery } from '@apollo/client';
import ChatIcon from '@mui/icons-material/Chat';
import { Button, Card, CardActions, CardContent, Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Box } from '@mui/system';
import { sv as locale } from '@norban/locale';
import { format, parseISO } from 'date-fns';
import { emojify } from 'node-emoji';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import QueryError from '../../../components/QueryError';
import QueryLoading from '../../../components/QueryLoading';
import UserAutocomplete from '../../../components/UserAutocomplete';
import {
  BofChatDisplayNameDocument,
  BofChatViewChatAdministerDocument,
  BofChatViewDocument,
  BofSendChatMessageDocument,
  BofUserChatMessageFragment,
  ChatChangeOwnership,
} from '../../../generated/backend/graphql';
import usePopupAlert from '../../../hooks/usePopupAlert';

import ChatBubble from './ChatBubble';
import ChatHandledButton from './ChatHandledButton';
import ChatTextField from './ChatTextField';
import SendChatReminder from './SendChatReminder';

const L = locale.backoffice;

const useChatViewStyles = makeStyles({
  chatMessagesContainer: {
    maxHeight: '400px',
    overflow: 'auto',
    padding: '8px',
  },

  bubble: {
    backgroundColor: '#2196f3',
    borderRadius: '16px',
    color: '#fff',
    display: 'flex',
    flexShrink: 0,
    fontSize: '0.8125rem',
    padding: '4px 12px',
    marginBottom: '8px',
  },

  incomingBubble: {
    backgroundColor: '#e0e0e0',
    color: '#000',
  },

  bubbleContainer: {
    display: 'flex',
    margin: '2px',
  },

  message: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '75%',
  },
  date: {
    color: '#888',
  },
  initials: {
    marginRight: 4,
  },

  incoming: {
    justifyContent: 'flex-start',
  },

  outgoing: {
    justifyContent: 'flex-end',
  },

  newMessageTextField: {
    width: '100%',
    padding: '8px',
  },
});

const useChatMessagesStyles = makeStyles({
  delimiter: {
    border: 0,
    borderTop: '1px solid #ddd',
    boxShadow: 'none',
    margin: '40px 0 10px',
  },
  day: {
    fontWeight: 'bold',
    textAlign: 'center',
  },
});

const ChatMessages = ({
  messages,
  userId,
  lastRead = undefined,
}: {
  messages: BofUserChatMessageFragment[];
  userId: string;
  lastRead?: string;
}) => {
  const classes = useChatMessagesStyles();
  const { data } = useQuery(BofChatDisplayNameDocument);

  const endOfChat = useRef<HTMLDivElement | null>(null);

  const scrollToBottom = useCallback(() => {
    if (endOfChat.current) {
      endOfChat.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  const jumpToBottom = useCallback(() => {
    if (endOfChat.current) {
      endOfChat.current.scrollIntoView({ behavior: 'auto' });
    }
  }, []);

  useEffect(() => {
    if (messages.length > 0) {
      scrollToBottom();
    }
  }, [scrollToBottom, messages]);

  useEffect(() => {
    jumpToBottom();
  }, [jumpToBottom]);

  const messagesByDate = messages?.reduce<
    Record<string, BofUserChatMessageFragment[]>
  >((acc, cur) => {
    const date = format(parseISO(cur.timestamp), 'd MMM, yyyy');

    return {
      ...acc,
      [date]: [...(acc[date] ?? []), cur],
    };
  }, {});

  return (
    <>
      {Object.entries(messagesByDate).map(([date, msgs], i) => (
        <React.Fragment key={i}>
          {i > 0 && <hr className={classes.delimiter} />}
          <div className={classes.day}>{date}</div>
          {msgs?.map(msg => (
            <ChatBubble
              key={msg.id}
              message={emojify(msg.message)}
              sender={msg.sender}
              incoming={msg.sender.id !== userId}
              timestamp={msg.timestamp}
              lastRead={lastRead}
              displayNames={data?.users}
            />
          ))}
        </React.Fragment>
      ))}
      <div key="bottomAnchor" ref={endOfChat} />
    </>
  );
};

type Props = {
  userId: string;
  lastKnownMessageId?: string;
};

const ChatView = ({ userId, lastKnownMessageId = undefined }: Props) => {
  const [changedOwnerId, setChangedOwnerId] = useState<string>('');
  const classes = useChatViewStyles();
  const { PopupAlert, showPopupAlert } = usePopupAlert();

  const [showChatReminderDialog, setShowChatReminderDialog] = useState(false);

  const { loading, error, data, refetch } = useQuery(BofChatViewDocument, {
    variables: { userId },
    onCompleted: responseData => {
      setChangedOwnerId(responseData?.chatOwners[0]?.ownerId ?? '');
    },
  });

  const chatOwner = data?.chatOwners[0];
  const ownerId = chatOwner?.ownerId ?? undefined;
  const handled = !!chatOwner?.handled;

  useEffect(() => {
    if (lastKnownMessageId) {
      refetch();
    }
  }, [lastKnownMessageId, refetch]);

  const [sendMessageMutation] = useMutation(BofSendChatMessageDocument, {
    refetchQueries: [BofChatViewDocument],
  });

  const [chatAdminister] = useMutation(BofChatViewChatAdministerDocument);

  const administer = useCallback(
    async (type: ChatChangeOwnership, ownerId: string | undefined) => {
      await chatAdminister({
        refetchQueries: [BofChatViewDocument],
        variables: {
          ownerId,
          type,
          userId,
        },
      });
    },
    [chatAdminister, userId],
  );

  const handleAdministerOwnership = useCallback(async () => {
    try {
      if (changedOwnerId !== ownerId) {
        await administer(ChatChangeOwnership.Release, ownerId);
        if (changedOwnerId) {
          await administer(ChatChangeOwnership.Take, changedOwnerId);
        }

        showPopupAlert(L.userChatView.ownerChange, 'success');
      }
    } catch (e) {
      const ae = e as ApolloError;
      showPopupAlert(ae.message, 'error');
    }
  }, [administer, changedOwnerId, ownerId, showPopupAlert]);

  const sendMessage = useCallback(
    (message: string) => {
      sendMessageMutation({ variables: { to: userId, message } });
    },
    [sendMessageMutation, userId],
  );

  if (loading) {
    return <QueryLoading />;
  }

  if (error || !data) {
    return <QueryError error={error} data={data} />;
  }

  return (
    <Card>
      <CardContent>
        <Grid container gap={2} alignItems="center">
          <Grid item xs={12} md="auto">
            <ChatHandledButton
              disabled={loading}
              handled={handled}
              ownerId={ownerId}
              userId={userId}
              onToggle={handled => {
                if (!handled) {
                  setChangedOwnerId('');
                }
                refetch();
              }}
            />
          </Grid>
          <Grid item xs md={4}>
            <Box>
              <UserAutocomplete
                disabled={loading || handled}
                defaultLimit={300}
                fullWidth
                label="Ägare"
                limitToUserRoles={['admin']}
                onSelectedUserIdChanged={id => setChangedOwnerId(id ?? '')}
                size="small"
                selectedUserId={changedOwnerId ?? null}
              />
            </Box>
          </Grid>
          <Grid item xs="auto" md="auto">
            <Button
              disabled={loading || ownerId === changedOwnerId || handled}
              size="medium"
              variant="contained"
              onClick={handleAdministerOwnership}
            >
              {L.save}
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button
              color="secondary"
              size="small"
              startIcon={<ChatIcon />}
              variant="contained"
              onClick={() => setShowChatReminderDialog(true)}
            >
              {L.userChatView.emailReminder}
            </Button>
          </Grid>
        </Grid>
      </CardContent>
      <Grid item xs={12}>
        <Card>
          <CardContent className={classes.chatMessagesContainer}>
            <ChatMessages
              messages={data?.chatMessages}
              userId={userId}
              lastRead={data.lastRead ?? undefined}
            />
          </CardContent>
          <CardActions style={{ background: '#eee' }}>
            <ChatTextField onSendMessage={sendMessage} />
          </CardActions>
        </Card>
      </Grid>
      <PopupAlert />
      <SendChatReminder
        open={showChatReminderDialog}
        onClose={() => setShowChatReminderDialog(false)}
        userId={userId}
      />
    </Card>
  );
};

export default ChatView;
