import React, { useMemo } from 'react';

import MultiSelect from './form/MultiSelect';

type RecursiveArea = {
  id: string;
  name: string;
  children?: RecursiveArea[];
  public: boolean;
};

function createOptions(
  area: RecursiveArea,
  prevValue = '',
): {
  key: string;
  value: string;
}[] {
  const key = area.id;
  const value = !prevValue ? area.name : `${prevValue} / ${area.name}`;
  const closedStatus = area.public ? '' : ' - stängt område';

  return [
    { key, value: `${value} [${area.id}]${closedStatus}` },
    ...(area.children?.flatMap(childArea => createOptions(childArea, value)) ??
      []),
  ];
}

const MultiAreaSelect = ({
  enabled = true,
  areas,
  label,
  value,
  onValueChanged,
  placeholder = undefined,
}: {
  enabled?: boolean;
  areas: RecursiveArea[];
  label: string;
  placeholder?: string;
  value: string[];
  onValueChanged: (value: string[]) => void;
}) => {
  const options = useMemo(
    () => [
      // null option
      { key: 'NULL', value: 'Saknar område' },
      ...areas
        .flatMap(area => createOptions(area))
        .sort((a, b) => a.value.localeCompare(b.value)),
    ],
    [areas],
  );
  return (
    <MultiSelect
      enabled={enabled}
      options={options}
      label={label}
      placeholder={placeholder}
      value={value}
      onValueChanged={onValueChanged}
    />
  );
};

export default MultiAreaSelect;
