export const STORE_START_DATE = 'STORE_START_DATE';
export const STORE_END_DATE = 'STORE_END_DATE';
export const STORE_OWNERSHIP = 'STORE_OWNERSHIP';
export const STORE_INCLUDE_HANDLED = 'STORE_INCLUDE_HANDLED';
export const STORE_CAP_BY_LIMIT = 'STORE_CAP_BY_LIMIT';

const actionStoreStartDate = startDate => ({
  type: STORE_START_DATE,
  startDate,
});

const actionStoreEndDate = endDate => ({
  type: STORE_END_DATE,
  endDate,
});

const actionStoreOwnership = ownership => ({
  type: STORE_OWNERSHIP,
  ownership,
});

const actionIncludeHandled = includeHandled => ({
  type: STORE_INCLUDE_HANDLED,
  includeHandled,
});

const actionCapByLimit = capByLimit => ({
  type: STORE_CAP_BY_LIMIT,
  capByLimit,
});

export const storeStartDate = (dispatch, startDate) =>
  dispatch(actionStoreStartDate(startDate));

export const storeEndDate = (dispatch, endDate) =>
  dispatch(actionStoreEndDate(endDate));

export const storeOwnership = (dispatch, ownership) =>
  dispatch(actionStoreOwnership(ownership));

export const storeIncludeHandled = (dispatch, includeHandled) =>
  dispatch(actionIncludeHandled(includeHandled));

export const storeCapByLimit = (dispatch, capByLimit) =>
  dispatch(actionCapByLimit(capByLimit));
