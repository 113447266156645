import { useMutation, useQuery } from '@apollo/client';
import { Add as AddIcon, Delete as DeleteIcon } from '@mui/icons-material';
import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  IconButton,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import Grid from '@mui/material/Grid';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { sv as locale } from '@norban/locale';
import React, { useReducer, useState } from 'react';

import ConfirmationDialog from '../../../components/ConfirmationDialog';
import MultiAreaSelect from '../../../components/MultiAreaSelect';
import {
  BofCreateUserMonitorDocument,
  BofDeleteUserMonitorDocument,
  BofUserMonitorsDocument,
  BofUserMonitorsQuery,
  BofUserMonitorsRootAreasDocument,
  RangeInput,
  UserHomeMonitorCategory,
  UserHomeMonitorFeature,
} from '../../../generated/backend/graphql';

import RangeInputFields from './RangeInputFields';

type Row = BofUserMonitorsQuery['userHomeMonitors'][0];

type State = {
  areaIds?: string[];
  category: UserHomeMonitorCategory | 'All';
  features?: UserHomeMonitorFeature[];
  livingArea?: RangeInput;
  rooms?: RangeInput;
};

export default function UserMonitors({ id }: { id: string }) {
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [state, dispatch] = useReducer(
    (
      state: State,
      action:
        | { type: 'areaIds'; value?: string[] }
        | { type: 'category'; value: UserHomeMonitorCategory | 'All' }
        | { type: 'features'; value?: UserHomeMonitorFeature[] }
        | { type: 'livingArea'; value?: RangeInput }
        | { type: 'rooms'; value?: RangeInput }
        | { type: 'reset' },
    ): State => {
      switch (action.type) {
        case 'areaIds':
          return { ...state, areaIds: action.value };
        case 'category':
          return { ...state, category: action.value };
        case 'features':
          return { ...state, features: action.value };
        case 'livingArea':
          return { ...state, livingArea: action.value };
        case 'rooms':
          return { ...state, rooms: action.value };
        case 'reset':
          return { category: 'All' };
        default:
          return state;
      }
    },
    {
      category: 'All',
    },
  );
  const [saveError, setSaveError] = useState(false);
  const L = locale.backoffice.userMonitors;

  const { data } = useQuery<BofUserMonitorsQuery>(BofUserMonitorsDocument, {
    variables: {
      userId: id,
    },
  });

  const { data: dataRootAreas } = useQuery(BofUserMonitorsRootAreasDocument);

  const [deleteUserHomeMonitor] = useMutation(BofDeleteUserMonitorDocument, {
    refetchQueries: [
      {
        query: BofUserMonitorsDocument,
        variables: {
          userId: id,
        },
      },
    ],
  });

  const [createUserHomeMonitor] = useMutation(BofCreateUserMonitorDocument, {
    refetchQueries: [
      {
        query: BofUserMonitorsDocument,
        variables: {
          userId: id,
        },
      },
    ],
  });

  const [deleteAction, setDeleteAction] = useState<{
    action?: () => void;
    id: string;
  }>();

  const rows = data?.userHomeMonitors;

  const columns: GridColDef<Row>[] = [
    {
      field: 'id',
      headerName: L.id,
      type: 'string',
    },
    {
      field: 'createdAt',
      headerName: L.createdAt,
      type: 'date',
      valueGetter: ({ value }) => new Date(value),
      renderCell: ({ value }) =>
        new Intl.DateTimeFormat('sv-SE', {
          day: 'numeric',
          month: 'short',
          year: 'numeric',
        }).format(value),
    },
    {
      field: 'areas',
      headerName: L.areas,
      type: 'string',
      width: 200,
      renderCell: ({ value }) => (
        <Grid>
          {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            value?.map((area: any, index: number) => (
              <Box key={index}>
                {`${area?.name ?? ''} (${area?.root?.name ?? ''})`}
              </Box>
            )) ?? L.allAreas
          }
        </Grid>
      ),
    },
    {
      field: 'rooms',
      headerName: L.rooms,
      type: 'string',
      renderCell: ({ value }) =>
        value ? `${value?.low ?? ''} - ${value?.high ?? ''}` : L.allRooms,
    },
    {
      field: 'livingArea',
      headerName: L.livingArea,
      type: 'string',
      renderCell: ({ value }) =>
        value ? `${value?.low ?? ''} - ${value?.high ?? ''}` : L.allLivingArea,
    },
    {
      field: 'categories',
      headerName: L.categories,
      width: 150,
      type: 'string',
      renderCell: ({ value }) => (
        <Grid>
          {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            value?.map((value: any, index: number) => (
              <Box key={index}>{value}</Box>
            )) ?? L.allTypes
          }
        </Grid>
      ),
    },
    {
      field: 'features',
      headerName: L.features,
      width: 150,
      type: 'string',
      renderCell: ({ value }) => (
        <Grid>
          {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            value?.map((value: any, index: number) => (
              <Box key={index}>{value}</Box>
            )) ?? L.allFeatures
          }
        </Grid>
      ),
    },
    {
      field: 'delete',
      headerName: L.delete,
      type: 'string',
      sortable: false,
      filterable: false,
      width: 80,
      renderCell: ({ row }) => {
        const handleClick = () => {
          setDeleteAction({
            action: () => {
              deleteUserHomeMonitor({
                variables: {
                  id: row.id,
                },
              });
            },
            id: row.id,
          });
        };

        return (
          <IconButton aria-label={L.delete} onClick={handleClick}>
            <DeleteIcon />
          </IconButton>
        );
      },
    },
  ];

  if (!rows) {
    return null;
  }

  return (
    <>
      <Card>
        <CardContent>
          <Button
            color="secondary"
            size="small"
            startIcon={<AddIcon />}
            variant="contained"
            onClick={() => {
              dispatch({ type: 'reset' });
              setSaveError(false);
              setCreateDialogOpen(true);
            }}
          >
            Skapa bevakning
          </Button>
        </CardContent>
        <DataGrid
          autoHeight
          columns={columns}
          rows={rows}
          getRowHeight={() => 'auto'}
        />
      </Card>
      <ConfirmationDialog
        title={`Ta bort ${deleteAction?.id}?`}
        open={!!deleteAction?.action}
        onCancel={() =>
          setDeleteAction({
            id: deleteAction?.id ?? '',
            action: undefined,
          })
        }
        onConfirm={() => {
          deleteAction?.action?.();
          setDeleteAction({
            id: deleteAction?.id ?? '',
            action: undefined,
          });
        }}
        content={undefined}
      />
      <Dialog
        open={createDialogOpen}
        onClose={() => setCreateDialogOpen(false)}
      >
        <DialogTitle>Skapa bevakning</DialogTitle>
        <DialogContent>
          <Grid columns={1} container spacing={2}>
            <Grid item xs={12}>
              <Typography mb={1} variant="subtitle2">
                Bostadstyp
              </Typography>
              <RadioGroup
                row
                value={state.category}
                onChange={e =>
                  dispatch({
                    type: 'category',
                    value: e.target.value as UserHomeMonitorCategory,
                  })
                }
              >
                <FormControlLabel
                  control={<Radio />}
                  label="Alla typer"
                  value="All"
                />
                <FormControlLabel
                  control={<Radio />}
                  label="Lägenheter"
                  value={UserHomeMonitorCategory.Apartment}
                />
                <FormControlLabel
                  control={<Radio />}
                  label="Hus"
                  value={UserHomeMonitorCategory.House}
                />
              </RadioGroup>
            </Grid>
            <Grid item xs={12}>
              <Typography mb={1} variant="subtitle2">
                Areor
              </Typography>
              <MultiAreaSelect
                enabled
                label="Välj areor"
                areas={dataRootAreas?.rootAreas ?? []}
                value={state.areaIds ?? []}
                onValueChanged={value => dispatch({ type: 'areaIds', value })}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography mb={1} variant="subtitle2">
                Rum
              </Typography>
              <RangeInputFields
                value={state.rooms}
                onChange={value => {
                  dispatch({
                    type: 'rooms',
                    value,
                  });
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography mb={1} variant="subtitle2">
                Storlek
              </Typography>
              <RangeInputFields
                value={state.livingArea}
                onChange={value => {
                  dispatch({
                    type: 'livingArea',
                    value,
                  });
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography mb={1} variant="subtitle2">
                Krav
              </Typography>
              <FormGroup row>
                {[
                  { label: 'Hiss', value: UserHomeMonitorFeature.Elevator },
                  {
                    label: 'Balkong / uteplats',
                    value: UserHomeMonitorFeature.OutdoorSpace,
                  },
                ].map(({ label, value }) => {
                  const checked = state.features?.includes(value);

                  return (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={checked}
                          value={checked}
                          onChange={() =>
                            dispatch({
                              type: 'features',
                              value: state.features?.includes(value)
                                ? state.features.filter(f => f !== value)
                                : [...(state.features ?? []), value],
                            })
                          }
                        />
                      }
                      key={value}
                      label={label}
                    />
                  );
                })}
              </FormGroup>
            </Grid>
          </Grid>
          {saveError && (
            <Box mt={2}>
              <Alert severity="error">Något gick fel, försök igen.</Alert>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setCreateDialogOpen(false)} color="secondary">
            Avbryt
          </Button>
          <Button
            onClick={async () => {
              try {
                await createUserHomeMonitor({
                  variables: {
                    input: {
                      areaIds: state.areaIds,
                      categories:
                        state.category !== 'All' ? [state.category] : undefined,
                      features: state.features,
                      livingArea: state.livingArea,
                      rooms: state.rooms,
                      userId: id,
                    },
                  },
                });
                setCreateDialogOpen(false);
              } catch (e) {
                setSaveError(true);
              }
            }}
            color="primary"
          >
            Spara
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
