export function formatNumber(x: number | undefined | null) {
  if (typeof x === 'number') {
    return Intl.NumberFormat('sv-SE', {
      // 20 is the maximum number of significant digits
      // This is not a problem in reality as numbers has a maximum of ~15 significant digits
      maximumFractionDigits: 20,
      maximumSignificantDigits: 20,
    }).format(x);
  }
  // eslint-disable-next-line no-console
  console.error(
    `Expected \`x\` to be a number but got \`${
      x === undefined ? 'undefined' : 'null'
    }\``,
  );
  return x;
}
