import { useMutation, gql } from '@apollo/client';
import { Snackbar } from '@mui/material';
import React, { useState } from 'react';

import ConfirmationDialog from '../../../components/ConfirmationDialog';

const M_SEND_CHAT_REMINDER = gql`
  mutation BofSendChatReminder($userId: ID!) {
    sendChatReminder(userId: $userId)
  }
`;

function SendChatReminder({ userId, open, onClose }) {
  const [snackbarOpen, showSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const [sendChatReminder] = useMutation(M_SEND_CHAT_REMINDER, {
    onCompleted: () => {
      setSnackbarMessage('Påminnelse skickad');
      showSnackbar(true);
    },
    onError: err => {
      console.error(err);
      setSnackbarMessage('Misslyckades med att skicka påminnelse');
      showSnackbar(true);
    },
  });

  return (
    <>
      <ConfirmationDialog
        open={open}
        title="Skicka påminnelse"
        content="Skicka påminnelse om olästa meddelanden?"
        onConfirm={() => {
          sendChatReminder({ variables: { userId } });
          onClose();
        }}
        onCancel={onClose}
      />

      <Snackbar
        open={snackbarOpen}
        onClose={() => showSnackbar(false)}
        message={snackbarMessage}
        autoHideDuration={5000}
      />
    </>
  );
}

export default SendChatReminder;
