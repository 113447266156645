import { combineReducers } from 'redux';

import areaList from './areaList';
import chatFilter from './chatFilter';
import followerFilter from './followerFilter';
import userFilter from './userFilter';
import userSearch from './userSearch';

export default combineReducers({
  userFilter,
  followerFilter,
  userSearch,
  chatFilter,
  areaList,
});
