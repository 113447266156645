import { useQuery } from '@apollo/client';
import { Card, CardContent } from '@mui/material';
import { grey } from '@mui/material/colors';
import { Box } from '@mui/system';
import { sv as locale } from '@norban/locale';
import React, { useEffect, useState } from 'react';

import UserCreateOrSearchForDialog from '../../../components/UserCreateOrSearchForDialog';
import {
  BofAgreementContractUserPickerUserDocument,
  BofAgreementContractUserPickerUserFragment,
} from '../../../generated/backend/graphql';

const L = locale.backoffice;

type Props = {
  open: boolean;
  limitToUserRoles?: ('admin' | 'user' | 'banned')[];
  onConfirm: (User: BofAgreementContractUserPickerUserFragment) => void;
  onCancel: () => void;
};

const AgreementContractUserPicker = ({
  open,
  limitToUserRoles = undefined,
  onConfirm,
  onCancel,
}: Props) => {
  const [userPickerUserId, setUserPickerUserId] = useState<string | null>(null);

  // Reset user picker user if user picker is closed
  useEffect(() => {
    if (!open) {
      setUserPickerUserId(null);
    }
  }, [open]);

  const { loading, data, error } = useQuery(
    BofAgreementContractUserPickerUserDocument,
    {
      variables: { id: userPickerUserId },
      skip: !userPickerUserId,
    },
  );

  const userPickerUser = data?.user;

  return (
    <UserCreateOrSearchForDialog
      limitToUserRoles={limitToUserRoles}
      cancel={L.cancel}
      confirm={L.agreementContractSignee.import}
      content={
        <Box mb={2} mt={2}>
          <Card
            sx={{
              bgcolor: grey.A200,
            }}
          >
            <CardContent>
              {loading && '...'}
              {error && '❌'}
              {userPickerUser && (
                <>
                  <div>{userPickerUserId}</div>
                  <div>{userPickerUser.name}</div>
                  <div>{userPickerUser.email}</div>
                </>
              )}
            </CardContent>
          </Card>
        </Box>
      }
      open={open}
      selectedUserId={userPickerUserId}
      title={L.agreementContractSignee.importFromUser}
      onCancel={onCancel}
      onConfirm={() => {
        if (!userPickerUser) {
          return;
        }

        onConfirm(userPickerUser);
      }}
      onSelectedUserIdChanged={userId => {
        setUserPickerUserId(userId);
      }}
    />
  );
};

export default AgreementContractUserPicker;
