import { useMutation } from '@apollo/client';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { sv as locale } from '@norban/locale';
import React from 'react';

import { BofCreateHomeDocument } from '../../../generated/backend/graphql';

type Props = {
  open: boolean;
  onClose: () => void;
};

const CreateHomeDialog = ({ open, onClose }: Props) => {
  const L = locale.backoffice.createHomeDialog;
  const [createHome] = useMutation(BofCreateHomeDocument);
  const submit = async () => {
    const home = await createHome({
      variables: {
        input: {},
      },
    });

    if (home?.data?.createHome?.id) {
      window.open(
        `/homes/${home.data.createHome.id}`,
        '_blank',
        'noopener, noreferrer',
      );
    }
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogTitle>{L.title}</DialogTitle>
      <DialogContent>{L.body}</DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          {L.cancel}
        </Button>

        <Button onClick={submit} color="primary">
          {L.create}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateHomeDialog;
