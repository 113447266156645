import { useQuery, useMutation, gql } from '@apollo/client';
import React from 'react';

import QueryError from '../../components/QueryError';
import QueryLoading from '../../components/QueryLoading';

import Map from './Map';

const Q_ALL_AREAS = gql`
  query BofAllAreas {
    areas(filter: { includePrivate: true }) {
      id
      name
      public
      polygon {
        latitude
        longitude
      }
    }
  }
`;

const M_UPDATE_AREA = gql`
  mutation BofUpdateAreaPolygon($id: ID, $input: AreaInput!) {
    updateArea(id: $id, input: $input) {
      id
      name
      polygon {
        latitude
        longitude
      }
    }
  }
`;

const M_DELETE_AREA = gql`
  mutation BofDeleteAreaPolygon($id: ID!) {
    deleteArea(id: $id)
  }
`;

const AreaMapContainer = () => {
  const { loading, error, data } = useQuery(Q_ALL_AREAS);
  const [mutateArea] = useMutation(M_UPDATE_AREA);
  const [deleteArea] = useMutation(M_DELETE_AREA);

  if (loading) {
    return <QueryLoading />;
  }

  if (error) {
    return <QueryError error={error} data={data} />;
  }

  return (
    <Map
      areas={data.areas}
      updateArea={area =>
        mutateArea({
          variables: {
            id: area.id,
            input: {
              parentId: area.parentId,
              name: area.name,
              public: area.public,
              polygon: (area.polygon || []).map(coord => ({
                longitude: coord.longitude,
                latitude: coord.latitude,
              })),
            },
          },
        })
      }
      deleteArea={id => deleteArea({ variables: { id } })}
    />
  );
};

export default AreaMapContainer;
