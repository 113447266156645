import { TextField, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';

const DepositForm = props => {
  const { updateUser, submitting, readOnly, user } = props;
  const [bankName, setBankName] = useState(user.bankInformation.name);
  const [clearingNumber, setClearingNumber] = useState(
    user.bankInformation.clearingNumber,
  );
  const [accountNumber, setAccountNumber] = useState(
    user.bankInformation.accountNumber,
  );

  useEffect(() => {
    if (submitting) {
      updateUser({
        bankInformation: { clearingNumber, accountNumber, name: bankName },
      });
    }
  }, [accountNumber, bankName, clearingNumber, submitting, updateUser]);

  return (
    <form>
      <Grid container spacing={4} style={{ maxWidth: 520 }}>
        <Grid item xs={12}>
          <TextField
            disabled={readOnly}
            label="Bank Name"
            value={bankName || ''}
            margin="normal"
            variant="outlined"
            onChange={evt => setBankName(evt.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            disabled={readOnly}
            label="Clearing Number"
            value={clearingNumber || ''}
            margin="normal"
            variant="outlined"
            onChange={evt => setClearingNumber(evt.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            disabled={readOnly}
            label="Account Number"
            value={accountNumber || ''}
            margin="normal"
            variant="outlined"
            onChange={evt => setAccountNumber(evt.target.value)}
            fullWidth
          />
        </Grid>
      </Grid>
    </form>
  );
};

export default DepositForm;
