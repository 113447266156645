import { Button, InputBase } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { sv as locale } from '@norban/locale';
import React, { useCallback, useEffect, useState } from 'react';

const useStyles = makeStyles({
  newMessageTextField: {
    width: '100%',
    padding: '8px',
  },
});

const ChatTextField = ({
  onSendMessage,
}: {
  onSendMessage: (message: string) => void;
}) => {
  const L = locale.backoffice.chat;
  const classes = useStyles();
  const [text, setText] = useState('');

  const submit = useCallback(async () => {
    if (text.length > 0) {
      await onSendMessage(text);
      setText('');
    }
  }, [onSendMessage, text]);

  // Submit on ctrl+enter:
  useEffect(() => {
    const submitOnCtrlEnter = ({ key, ctrlKey }: KeyboardEvent) =>
      key === 'Enter' && ctrlKey && submit();

    document.addEventListener('keydown', submitOnCtrlEnter);
    return () => document.removeEventListener('keydown', submitOnCtrlEnter);
  }, [submit]);

  return (
    <>
      <InputBase
        className={classes.newMessageTextField}
        placeholder={L.writeAMessage}
        margin="dense"
        onChange={evt => setText(evt.target.value)}
        value={text}
        multiline
        inputProps={{ style: { padding: '0px' } }}
      />
      <Button color="primary" variant="contained" onClick={submit}>
        {L.send}
      </Button>
    </>
  );
};

export default ChatTextField;
