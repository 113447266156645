import StatsIcon from '@mui/icons-material/BarChart';
import BusinessIcon from '@mui/icons-material/Business';
import ChatIcon from '@mui/icons-material/ChatBubble';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import DrawIcon from '@mui/icons-material/Draw';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import HomeIcon from '@mui/icons-material/Home';
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import ImageIcon from '@mui/icons-material/Image';
import LabelIcon from '@mui/icons-material/Label';
import LaunchIcon from '@mui/icons-material/Launch';
import ListAltIcon from '@mui/icons-material/ListAlt';
import MapIcon from '@mui/icons-material/Map';
import PeopleIcon from '@mui/icons-material/People';
import ScheduleSendIcon from '@mui/icons-material/ScheduleSend';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import WavingHandIcon from '@mui/icons-material/WavingHand';
import {
  Collapse,
  Drawer,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { Breakpoint, createTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import makeStyles from '@mui/styles/makeStyles';
import { sv as locale } from '@norban/locale';
import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { isabelline } from '../theme';

const drawerWidth = 220;

const theme = createTheme();

const useStyles = makeStyles(() => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    backgroundColor: isabelline,
    width: drawerWidth,
  },
  toolbar: theme.mixins.toolbar,
}));

function useIsWidthUp(breakpoint: Breakpoint) {
  // const theme = useTheme();
  return useMediaQuery(theme.breakpoints.up(breakpoint));
}

type Props = {
  open: boolean;
  onClose: () => void;
};

const TopDrawer = ({ open, onClose }: Props) => {
  const isSmUp = useIsWidthUp('sm');
  const location = useLocation();

  const classes = useStyles();
  const L = locale.backoffice.sideBar;

  const [miscOpen, setMiscOpen] = useState(false);

  const path = location.pathname;
  const current = path.split('/')[1] || 'users';

  return (
    <Drawer
      variant={isSmUp ? 'permanent' : 'temporary'}
      open={open}
      onClose={onClose}
      className={classes.drawer}
      classes={{ paper: classes.drawerPaper }}
    >
      <div className={classes.toolbar} />
      <List>
        <ListItemButton
          selected={current === 'agents-dash'}
          component={Link}
          to="/agents-dash"
        >
          <ListItemIcon>
            <SupportAgentIcon />
          </ListItemIcon>
          <ListItemText primary={L.agentsDash} />
        </ListItemButton>

        <ListItemButton
          selected={current === 'users'}
          component={Link}
          to="/users"
        >
          <ListItemIcon>
            <PeopleIcon />
          </ListItemIcon>
          <ListItemText primary={L.users} />
        </ListItemButton>

        <ListItemButton
          selected={current === 'homes'}
          component={Link}
          to="/homes"
        >
          <ListItemIcon>
            <HomeWorkIcon />
          </ListItemIcon>
          <ListItemText primary={L.homes} />
        </ListItemButton>

        <ListItemButton
          selected={current === 'followers'}
          component={Link}
          to="/followers"
        >
          <ListItemIcon>
            <DirectionsRunIcon />
          </ListItemIcon>
          <ListItemText primary={L.followers} />
        </ListItemButton>

        <ListItemButton
          selected={current === 'chats'}
          component={Link}
          to="/chats"
        >
          <ListItemIcon>
            <ChatIcon />
          </ListItemIcon>
          <ListItemText primary={L.chats} />
        </ListItemButton>

        <ListItemButton
          selected={current === 'home-messages'}
          component={Link}
          to="/home-messages"
        >
          <ListItemIcon>
            <WavingHandIcon />
          </ListItemIcon>
          <ListItemText primary={L.homeMessages} />
        </ListItemButton>

        <ListItemButton
          selected={current === 'agreement-contracts'}
          component={Link}
          to="/agreement-contracts"
        >
          <ListItemIcon>
            <DrawIcon />
          </ListItemIcon>
          <ListItemText primary={L.agreementContracts} />
        </ListItemButton>

        <ListItemButton
          selected={current === 'user-images'}
          component={Link}
          to="/user-images"
        >
          <ListItemIcon>
            <ImageIcon />
          </ListItemIcon>
          <ListItemText primary={L.userImages} />
        </ListItemButton>

        <ListItemButton
          selected={current === 'events'}
          component={Link}
          to="/events"
        >
          <ListItemIcon>
            <ListAltIcon />
          </ListItemIcon>
          <ListItemText primary={L.events} />
        </ListItemButton>

        <ListItemButton onClick={() => setMiscOpen(!miscOpen)}>
          <ListItemText primary={L.miscellaneous} />
          {miscOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>

        <Collapse in={miscOpen} timeout="auto" unmountOnExit>
          <ListItemButton
            selected={current === 'stats'}
            component={Link}
            to="/stats/homes"
          >
            <ListItemIcon>
              <StatsIcon />
            </ListItemIcon>
            <ListItemText primary={L.stats} />
          </ListItemButton>

          <ListItemButton
            selected={current === 'areas'}
            component={Link}
            to="/areas/list"
          >
            <ListItemIcon>
              <MapIcon />
            </ListItemIcon>
            <ListItemText primary={L.areas} />
          </ListItemButton>

          <ListItemButton
            selected={current === 'user-label'}
            component={Link}
            to="/user-label"
          >
            <ListItemIcon>
              <LabelIcon />
            </ListItemIcon>
            <ListItemText primary={L.userLabel} />
          </ListItemButton>

          <ListItemButton
            selected={current === 'scheduled-mail'}
            component={Link}
            to="/scheduled-mail"
          >
            <ListItemIcon>
              <ScheduleSendIcon />
            </ListItemIcon>
            <ListItemText primary={L.scheduledEmail} />
          </ListItemButton>

          <ListItemButton
            selected={current === 'temporary-housing'}
            component={Link}
            to="/temporary-housing"
          >
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText primary={L.temporaryHousing} />
          </ListItemButton>

          <ListItemButton
            selected={current === 'open-market-redirects'}
            component={Link}
            to="/open-market-redirects"
          >
            <ListItemIcon>
              <LaunchIcon />
            </ListItemIcon>
            <ListItemText primary={L.openMarketRedirects} />
          </ListItemButton>
          <ListItemButton
            selected={current === 'offices'}
            component={Link}
            to="/offices"
          >
            <ListItemIcon>
              <BusinessIcon />
            </ListItemIcon>
            <ListItemText primary={L.offices} />
          </ListItemButton>
        </Collapse>
      </List>
    </Drawer>
  );
};

export default TopDrawer;
