import { sv as locale } from '@norban/locale';

import { HomeState, HomeType, TenureType } from '../generated/backend/graphql';

const L = locale.backoffice.enumerations;

export const translateState = (state: HomeState) => L.HomeState[state] || '-';

export const translateType = (type: HomeType) => L.HomeType[type] || '-';

export const translateTenure = (tenure: TenureType) => L.Tenure[tenure] || '-';
