import { useQuery } from '@apollo/client';
import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
} from '@mui/material';
import React, { useMemo } from 'react';
import { useHistory } from 'react-router';

import { BofAreaAgentsDocument } from '../../generated/backend/graphql';

const AreaAgents = () => {
  const history = useHistory();
  const { data } = useQuery(BofAreaAgentsDocument);
  const agents = useMemo(
    () =>
      data?.users
        .slice()
        .sort((a, b) => (a.name ?? '')?.localeCompare(b.name ?? '')) ?? [],
    [data?.users],
  );

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Namn</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {agents.map(({ id, name }) => (
          <TableRow
            hover
            key={id}
            role="button"
            sx={{ cursor: 'pointer' }}
            onClick={() => history.push(`/agents/${id}`)}
          >
            <TableCell>{name}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default AreaAgents;
