import { Box, Input, Typography } from '@mui/material';
import React from 'react';

type Props = {
  disabled: boolean;
  valueMin: number | undefined;
  valueMax: number | undefined;
  onChange: (array: [number | undefined, number | undefined]) => void;
  unit: string;
};

const RangeMinMaxInputs = ({
  disabled,
  valueMin,
  valueMax,
  onChange,
  unit,
}: Props) => (
  <Box flex="1" flexDirection="row" display="flex" alignItems="baseline">
    <Box px={2}>
      <Input
        type="number"
        disabled={disabled}
        value={valueMin}
        onChange={event => {
          const { value } = event.target;
          onChange([value ? Number(value) : undefined, valueMax]);
        }}
        onBlur={event => {
          const { value } = event.target;
          onChange([value ? Number(value) : undefined, valueMax]);
        }}
      />
    </Box>
    <Typography gutterBottom> - </Typography>
    <Box px={2}>
      <Input
        type="number"
        disabled={disabled}
        value={valueMax}
        onChange={event => {
          const { value } = event.target;
          onChange([valueMin, value ? Number(value) : undefined]);
        }}
        onBlur={event => {
          const { value } = event.target;
          onChange([valueMin, value ? Number(value) : undefined]);
        }}
      />
    </Box>
    <Typography gutterBottom>{unit}</Typography>
  </Box>
);

export default RangeMinMaxInputs;
