import { useQuery } from '@apollo/client';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormHelperText,
  Link,
} from '@mui/material';
import { sv as locale } from '@norban/locale';
import axios from 'axios';
import React from 'react';

import AreaSelect from '../../../../components/form/AreaSelect';
import NumberField from '../../../../components/form/NumberField';
import TextField from '../../../../components/form/TextField';
import {
  BofHomeProfileHomeFragment,
  BofHomeProfileRootAreasFragment,
  WebHomeLocationCardAssignedAgentsDocument,
} from '../../../../generated/backend/graphql';
import { DispatchType } from '../../hooks/useHomeProfileReducer';

const geoCodeURI = (streetAddress: string, city: string) =>
  `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURI(
    streetAddress,
  )}+${encodeURI(city)}&key=AIzaSyACsMMykK1HMemhUnUJu31Gw9PU4iY0Eis`;

async function geocode(streetAddress: string, city: string) {
  const exactResponse = await axios.get(geoCodeURI(streetAddress, city));
  const exactResult = exactResponse?.data?.results?.[0];

  // Get street name without street number
  // eslint-disable-next-line camelcase
  const streetName = exactResult?.address_components?.find(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (addrComp: any) => addrComp?.types.includes('route'),
    // eslint-disable-next-line camelcase
  )?.long_name;

  // Get street level response
  const undisclosedAddressResponse = await axios.get(
    geoCodeURI(streetName, city),
  );
  const undisclosedAddressResult =
    undisclosedAddressResponse?.data?.results?.[0];

  // Build exact location patch object
  const exactCoordinateLocation = exactResult?.geometry?.location;
  const exactCoordinate = {
    latitude: exactCoordinateLocation?.lat,
    longitude: exactCoordinateLocation?.lng,
  };

  // Build street level patch object
  const undisclosedAddressCoordinateLocation =
    undisclosedAddressResult?.geometry?.location;
  const undisclosedAddressCoordinate = {
    name: streetName,
    latitude: undisclosedAddressCoordinateLocation?.lat,
    longitude: undisclosedAddressCoordinateLocation?.lng,
  };

  return { exactCoordinate, undisclosedAddressCoordinate, streetName };
}

type Props = {
  areas: BofHomeProfileRootAreasFragment[];
  home: BofHomeProfileHomeFragment;
  dispatch: DispatchType;
};

const LocationCard = ({ areas, home, dispatch }: Props) => {
  const L = locale.backoffice.homeLocationCard;

  const { data: assignedAgentsData } = useQuery(
    WebHomeLocationCardAssignedAgentsDocument,
    {
      variables: {
        areaId: home.areaId ?? '',
      },
      skip: !home.areaId,
    },
  );

  const exactGMapsUrl =
    home.coordinate?.latitude &&
    home.coordinate?.longitude &&
    `https://maps.google.com/?q=${home.coordinate.latitude},${home.coordinate.longitude}`;
  const undisclosedMapsUrl =
    home.undisclosedAddress.latitude &&
    home.undisclosedAddress.longitude &&
    `https://maps.google.com/?q=${home.undisclosedAddress.latitude},${home.undisclosedAddress.longitude}`;

  return (
    <Box>
      <Card>
        <CardHeader title={L.title} />
        <CardContent>
          <TextField
            label={L.streetAddress}
            value={home.address?.streetAddress}
            name="streetAddress"
            dispatch={({ value }) => {
              dispatch({
                key: 'address',
                value: {
                  ...home.address,
                  streetAddress: value,
                },
              });
            }}
          />
          <TextField
            label={L.zipCode}
            value={home.address?.zipCode}
            name="zipCode"
            dispatch={({ value }) =>
              dispatch({
                key: 'address',
                value: {
                  ...home.address,
                  zipCode: value,
                },
              })
            }
          />
          <TextField
            label={L.city}
            value={home.address?.city}
            name="city"
            dispatch={({ value }) =>
              dispatch({
                key: 'address',
                value: {
                  ...home.address,
                  city: value,
                },
              })
            }
          />
          <TextField
            label={L.streetName}
            value={home.address?.streetName}
            name="streetName"
            dispatch={({ value }) =>
              dispatch({
                key: 'address',
                value: {
                  ...home.address,
                  streetName: value,
                },
              })
            }
          />
          <Box mt={2}>
            <AreaSelect
              placeholder={L.noArea}
              label={L.area}
              value={home.areaId ?? null}
              areas={areas}
              name="areaId"
              dispatch={({ value }) =>
                dispatch({
                  key: 'areaId',
                  value,
                })
              }
            />
          </Box>
          <Box mt={2} mb={1}>
            {assignedAgentsData?.users?.map(agent => agent.name).join(', ')}
          </Box>
          <Box mt={2} mb={1}>
            <Divider />
          </Box>
          <NumberField
            label={L.latitude}
            value={home.coordinate?.latitude}
            name="latitude"
            dispatch={({ value }) =>
              dispatch({
                key: 'coordinate',
                value: value
                  ? {
                      longitude: 0,
                      ...home.coordinate,
                      latitude: value,
                    }
                  : null,
              })
            }
          />
          <NumberField
            label={L.longitude}
            value={home.coordinate?.longitude}
            name="longitude"
            dispatch={({ value }) =>
              dispatch({
                key: 'coordinate',
                value: value
                  ? {
                      latitude: 0,
                      ...home.coordinate,
                      longitude: value,
                    }
                  : null,
              })
            }
          />
          <TextField
            label={L.undisclosed_address_name}
            value={home.undisclosedAddress?.name}
            name="undisclosedAddressName"
            dispatch={({ value }) =>
              dispatch({
                key: 'undisclosedAddress',
                value: {
                  ...home.undisclosedAddress,
                  name: value,
                },
              })
            }
          />
          <NumberField
            label={L.undisclosed_address_latitude}
            value={home.undisclosedAddress?.latitude}
            name="undisclosedAddressLatitude"
            dispatch={({ value }) =>
              dispatch({
                key: 'undisclosedAddress',
                value: {
                  ...home.undisclosedAddress,
                  latitude: value,
                },
              })
            }
          />
          <NumberField
            label={L.undisclosed_address_longitude}
            value={home.undisclosedAddress?.longitude}
            name="undisclosedAddressLongitude"
            dispatch={({ value }) =>
              dispatch({
                key: 'undisclosedAddress',
                value: {
                  ...home.undisclosedAddress,
                  longitude: value,
                },
              })
            }
          />
          {exactGMapsUrl && (
            <>
              <Box mb={0.5}>
                <FormHelperText>
                  Öppna exakt adress i Google Maps
                </FormHelperText>
              </Box>
              <Box mb={0.5}>
                <Link target="_blank" href={exactGMapsUrl} underline="hover">
                  {exactGMapsUrl}
                </Link>
              </Box>
            </>
          )}
          {undisclosedMapsUrl && (
            <>
              <Box mb={0.5}>
                <FormHelperText>
                  Öppna Snart på Pre-Market™ adress i Google Maps
                </FormHelperText>
              </Box>
              <Box mb={0.5}>
                <Link
                  target="_blank"
                  href={undisclosedMapsUrl}
                  underline="hover"
                >
                  {undisclosedMapsUrl}
                </Link>
              </Box>
            </>
          )}
          <Box mt={2}>
            <Button
              variant="contained"
              color="primary"
              onClick={async () => {
                if (!home.address.city || !home.address.streetAddress) {
                  return;
                }

                const locations = await geocode(
                  home.address.streetAddress,
                  home.address.city,
                );

                dispatch({
                  key: 'coordinate',
                  value: {
                    latitude: locations.exactCoordinate.latitude,
                    longitude: locations.exactCoordinate.longitude,
                  },
                });
                dispatch({
                  key: 'undisclosedAddress',
                  value: {
                    ...home.undisclosedAddress,
                    name: home.undisclosedAddress.name
                      ? home.undisclosedAddress.name
                      : locations.streetName,
                    longitude: locations.undisclosedAddressCoordinate.longitude,
                    latitude: locations.undisclosedAddressCoordinate.latitude,
                  },
                });
                dispatch({
                  key: 'address',
                  value: {
                    ...home.address,
                    streetName: home.address.streetName
                      ? home.address.streetName
                      : locations.streetName,
                  },
                });
              }}
            >
              Slå upp
            </Button>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default LocationCard;
