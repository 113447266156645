import { useQuery } from '@apollo/client';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Theme,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { sv as locale } from '@norban/locale';
import React from 'react';

import {
  BofSalesPitchDocument,
  BofSalesPitchHomeDocument,
} from '../../../generated/backend/graphql';

type Props = {
  homeId: string;
};

const useStyles = makeStyles((theme: Theme) => ({
  tableCellLeft: {
    [theme.breakpoints.down('md')]: {
      width: '70%',
    },
    [theme.breakpoints.up('lg')]: {
      // Tight left column
      width: '1px',
      whiteSpace: 'nowrap',
    },
  },
  tableCellRight: {
    [theme.breakpoints.down('md')]: {
      width: '30%',
    },
    [theme.breakpoints.up('lg')]: {
      // Wide right column
      width: '100%',
    },
  },
}));

const SalesPitch = ({ homeId }: Props) => {
  const classes = useStyles();
  const L = locale.backoffice.salesPitch;
  const { data: dataHome, loading: loadingHome } = useQuery(
    BofSalesPitchHomeDocument,
    {
      variables: {
        homeId: homeId ?? '',
      },
      skip: !homeId,
    },
  );
  const { data: dataPitch, loading: loadingPitch } = useQuery(
    BofSalesPitchDocument,
    {
      variables: {
        homeId: homeId ?? '',
        areaId: dataHome?.home?.areaId ?? '',
        today: new Date().toISOString().split('T')[0] ?? '',
        numberOfRooms: dataHome?.home?.numberOfRooms ?? 0,
      },
      skip: !homeId || !dataHome?.home,
    },
  );

  const numberOfRooms = dataHome?.home?.numberOfRooms ?? 0;

  if (loadingHome || loadingPitch) {
    return (
      <Paper>
        <Box p={2}>{L.loading}</Box>
      </Paper>
    );
  }

  if (!homeId || !dataHome?.home || !dataPitch) {
    return (
      <Paper>
        <Box p={2}>{L.userHasNoHome}</Box>
      </Paper>
    );
  }

  function formatDate(date: string | null | undefined) {
    return date
      ? Intl.DateTimeFormat('sv-SE', {
          day: 'numeric',
          month: 'short',
          year: 'numeric',
        }).format(new Date(date))
      : '-';
  }

  const homeSize = numberOfRooms ? `${numberOfRooms}:or` : '-';

  const rows = [
    {
      type: 'header',
      label: L.interestInProperty,
      value: '',
    },
    {
      type: 'data',
      label: L.followersThisProperty,
      value: dataPitch?.followerCount,
    },
    {
      type: 'data',
      label: L.homeMessagesThisProperty,
      value: dataPitch?.homeMessageCount,
    },
    {
      type: 'header',
      label: L.interestInArea,
      value: '',
    },
    {
      type: 'data',
      label: L.followersArea,
      value: dataPitch?.areaFollowerCount,
    },
    {
      type: 'data',
      label: L.followersAreaInclAssociatedAreas,
      value: dataPitch?.areaFollowerCountInclAssociatedAreas,
    },
    {
      type: 'data',
      label: `${L.followersArea}, ${homeSize}`,
      value: dataPitch?.areaFollowerCountByNbrRooms,
    },
    {
      type: 'data',
      label: `${L.followersAreaInclAssociatedAreas}, ${homeSize}`,
      value: dataPitch?.areaFollowerCountInclAssociatedAreasByNbrRooms,
    },
    {
      type: 'data',
      label: L.followersAreaToday,
      value: dataPitch?.areaFollowerCountFromToday,
    },
    {
      type: 'data',
      label: L.homeMessagesInArea,
      value: dataPitch?.homeMessageInAreaCount,
    },
    {
      type: 'header',
      label: L.forSaleInArea,
      value: '',
    },
    { type: 'data', label: L.homesInArea, value: dataPitch?.homesInArea },
    {
      type: 'data',
      label: L.homesInAreaLastPublished,
      value: formatDate(dataPitch?.lastPublishedHomeInArea?.[0]?.created),
    },
    {
      type: 'header',
      label: L.salesHistory,
      value: '',
    },
    {
      type: 'data',
      label: L.percentageSoldPremarket,
      value: `${dataPitch?.homeStats?.percentageSoldPremarket}%`,
    },
    {
      type: 'data',
      label: L.percentageSoldOpenMarket,
      value: `${dataPitch?.homeStats?.percentageSoldOpenMarket}%`,
    },
    {
      type: 'data',
      label: L.squareMeterPricePremarket,
      value: `${dataPitch?.homeStats?.squareMeterPricePremarket} kr/m²`,
    },
    {
      type: 'data',
      label: L.squareMeterPriceOpenMarket,
      value: `${dataPitch?.homeStats?.squareMeterPriceOpenMarket} kr/m²`,
    },
    {
      type: 'data',
      label: L.homesInAreaSold,
      value: dataPitch?.soldHomesInArea,
    },
    {
      type: 'data',
      label: L.homesInAreaLastSold,
      value: formatDate(dataPitch?.lastSoldHomeInArea?.[0]?.contractDate),
    },
  ];

  return (
    <Table size="small">
      <TableBody>
        {rows.map((row, index) => {
          let backgroundColor;

          if (index % 2 !== 0 && row.type === 'data') {
            backgroundColor = '#f5f5f5';
          } else {
            backgroundColor = 'white';
          }

          return (
            <TableRow
              key={index}
              sx={{
                backgroundColor,
              }}
            >
              <TableCell
                sx={{
                  fontSize: row.type === 'data' ? 14 : 16,
                  fontWeight: row.type === 'data' ? 'normal' : 'bold',
                }}
                className={classes.tableCellLeft}
                colSpan={row.type === 'data' ? 1 : 2}
              >
                {row.label}
              </TableCell>
              {row.type === 'data' && (
                <TableCell className={classes.tableCellRight}>
                  <b>{row.value}</b>
                </TableCell>
              )}
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

export default SalesPitch;
