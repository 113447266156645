import {
  STORE_START_DATE,
  STORE_END_DATE,
  STORE_OWNERSHIP,
  STORE_INCLUDE_HANDLED,
  STORE_CAP_BY_LIMIT,
} from '../actions/chatActions';

export type ChatFilterType = {
  startDate: Date | null;
  endDate: Date | null;
  ownership: string | null;
  includeHandled: boolean;
  capByLimit: boolean;
};

const defaultState = {
  startDate: null,
  endDate: null,
  ownership: null,
  includeHandled: false,
  capByLimit: true,
};

export default (
  state = defaultState,
  action: ChatFilterType & { type: string } = { ...defaultState, type: '' },
) => {
  switch (action.type) {
    case STORE_START_DATE:
      return {
        ...state,
        startDate: action.startDate,
      };
    case STORE_END_DATE:
      return {
        ...state,
        endDate: action.endDate,
      };
    case STORE_OWNERSHIP:
      return {
        ...state,
        ownership: action.ownership,
      };
    case STORE_INCLUDE_HANDLED:
      return {
        ...state,
        includeHandled: action.includeHandled,
      };
    case STORE_CAP_BY_LIMIT:
      return {
        ...state,
        capByLimit: action.capByLimit,
      };
    default:
      return state;
  }
};
