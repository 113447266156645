import NotInterestedIcon from '@mui/icons-material/NotInterested';
import { Box, Checkbox, FormControlLabel } from '@mui/material';
import React from 'react';

type Props = {
  disabled?: boolean;
  checked: boolean;
  onCheckedChanged: (checked: boolean) => void;
  icon?: React.ReactNode;
};

export default function FilterCheckbox({
  disabled = false,
  checked,
  onCheckedChanged,
  icon = <NotInterestedIcon fontSize="small" />,
}: Props) {
  return (
    <FormControlLabel
      label={<Box display="flex">{icon}</Box>}
      disabled={disabled}
      control={
        <Checkbox
          checked={checked}
          color="primary"
          onChange={(_, checked) => onCheckedChanged(checked)}
        />
      }
    />
  );
}
