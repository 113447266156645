import { useQuery } from '@apollo/client';
import { Alert, CircularProgress, Paper } from '@mui/material';
import {
  DataGrid,
  GridColDef,
  gridClasses,
  gridDateComparator,
  gridNumberComparator,
} from '@mui/x-data-grid';
import { sv as locale } from '@norban/locale';
import React from 'react';

import {
  BofHomeGreetingsDocument,
  HomeMessageState,
} from '../../../generated/backend/graphql';

type Props = {
  homeId: string;
};

const HomeGreetings = ({ homeId }: Props) => {
  const L = locale.backoffice;

  const { data, error, loading } = useQuery(BofHomeGreetingsDocument, {
    variables: {
      filter: {
        toHomeId: homeId,
        states: [HomeMessageState.Delivered],
      },
    },
  });

  const columns: GridColDef[] = [
    {
      field: 'createdAt',
      headerName: L.homeGreetings.created,
      sortable: true,
      width: 150,
      valueGetter: ({ value }) => value,
      renderCell: ({ value }) =>
        value
          ? new Intl.DateTimeFormat('sv-SE', {
              day: 'numeric',
              month: 'short',
              hour: 'numeric',
              minute: 'numeric',
            }).format(new Date(value))
          : '',
      sortComparator: gridDateComparator,
    },
    {
      field: 'fromUserName',
      headerName: L.homeGreetings.sentBy,
      sortable: true,
      width: 200,
      valueGetter: ({ row }) => row.fromUser.user.name,
    },
    {
      field: 'fromUserId',
      headerName: 'Userid',
      sortable: true,
      width: 120,
      renderCell: ({ value }) => (
        <a
          onClick={e => e.stopPropagation()}
          href={`/users/${value}`}
          target="_blank"
          rel="noreferrer"
        >
          {value}
        </a>
      ),
      sortComparator: gridNumberComparator,
    },
    {
      field: 'message',
      flex: 1,
      maxWidth: 460,
      minWidth: 150,
      headerName: L.homeGreetings.message,
      sortable: true,
      valueGetter: ({ value }) => value,
    },
  ];

  const rows = data?.homeMessages ?? [];

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Alert severity="error">{L.errors.general}</Alert>;
  }

  return (
    <Paper>
      <DataGrid
        autoHeight
        columns={columns}
        getRowHeight={() => 'auto'}
        rows={rows}
        sx={{
          [`& .${gridClasses.cell}`]: {
            alignItems: 'flex-start',
            paddingBottom: '10px',
            paddingTop: '10px',
          },
        }}
        disableRowSelectionOnClick
      />
    </Paper>
  );
};

export default HomeGreetings;
