export const extractInitials = (fullName: string) =>
  fullName
    .split(' ')
    .map(name => name.charAt(0).toUpperCase())
    .filter(initial => /\p{Letter}/u.test(initial))
    .join('');

export function splitName(name: string) {
  const [firstName, ...rest] = name.split(' ');
  const lastName = rest.join(' ') || firstName;

  return [firstName, lastName];
}
