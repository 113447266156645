import sha256 from 'sha256';

const COLORS = {
  spm: '#E7B558', // Yellow
  pm: '#14594C', // Greenish
  bids: '#E3928F', // Pinkish
  sold: '#C48669', // Orange
  default: '#275390', // Blue
};

// Duplicated from GraphQL generated types
enum HomeState {
  Hidden = 'HIDDEN',
  None = 'NONE',
  OpenMarket = 'OPEN_MARKET',
  Premarket = 'PREMARKET',
  PremarketLight = 'PREMARKET_LIGHT',
  Sold = 'SOLD',
  Withdrawn = 'WITHDRAWN',
}

export function randomizeIndex(id: string, n: number) {
  const hash = sha256(`${id}norban`);
  const sum = hash
    .split('')
    .map(char => parseInt(char, 16))
    .reduce((acc, curr) => acc + curr);
  return sum % n;
}

export function generateDefaultImage(id: string | undefined, small: boolean) {
  return `/assets/img/defaults/${randomizeIndex(id ?? '', 10)}${
    small ? 'sm' : ''
  }.jpg`;
}

export function labelColor(state: HomeState) {
  if (state === HomeState.PremarketLight) {
    return COLORS.spm;
  }

  if (state === HomeState.Premarket) {
    return COLORS.pm;
  }

  if (state === HomeState.Sold) {
    return COLORS.sold;
  }

  return COLORS.default;
}

export type ImageCategory = 'PHOTO' | 'FLOORPLAN';
