import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {
  Box,
  Collapse,
  Divider,
  Grid,
  IconButton,
  TableCell,
  TableRow,
} from '@mui/material';
import React, { PropsWithChildren } from 'react';

import FilterRow from './FilterRow';

function FilterSection({
  title,
  expanded,
  onExpandedChanged,
  filters,
}: PropsWithChildren<{
  title: string;
  expanded: boolean;
  onExpandedChanged: (expanded: boolean) => void;
  filters: {
    label: string;
    icon: React.ReactNode;
    enabled?: boolean;
    onEnabledChanged?: (enabled: boolean) => void;
    children: React.ReactNode;
  }[];
}>) {
  const numEnabled = filters.filter(f => f.enabled ?? true).length;

  return (
    <>
      <TableRow onClick={() => onExpandedChanged(!expanded)}>
        <TableCell style={{ borderBottom: 0 }}>
          <IconButton aria-label="expand row" size="small">
            {expanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
          {`${title} (${numEnabled}/${filters.length})`}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={1}>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <Box mx={2}>
              {filters.map((filter, i) => (
                <React.Fragment key={i}>
                  {i > 0 && <Divider />}
                  <Grid container>
                    <FilterRow
                      label={filter.label}
                      icon={filter.icon}
                      checked={filter.enabled}
                      onCheckedChanged={filter.onEnabledChanged}
                    >
                      {filter.children}
                    </FilterRow>
                  </Grid>
                </React.Fragment>
              ))}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

export default FilterSection;
