import EditIcon from '@mui/icons-material/Edit';
import HomeIcon from '@mui/icons-material/Home';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import PersonIcon from '@mui/icons-material/Person';
import {
  Box,
  Checkbox,
  Chip,
  TableCell,
  TableRow,
  useMediaQuery,
} from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import { sv as locale } from '@norban/locale';
import cx from 'classnames';
import {
  format,
  isPast,
  isToday,
  isTomorrow,
  isYesterday,
  parseISO,
} from 'date-fns';
import React from 'react';

import { blue, tableRowHoverColor } from '../../../theme';
import { vitecEstateLink } from '../../../utils';

const useStyles = makeStyles(theme => ({
  chatLink: {
    textDecoration: 'none',
  },
  chip: {
    [theme.breakpoints.down('md')]: {
      fontSize: 10,
      height: 18,
    },
  },
  row: {
    cursor: 'pointer',
    textDecoration: 'none',
    '&:hover': {
      backgroundColor: tableRowHoverColor,
    },
  },
  banned: {
    backgroundColor: '#fdd',
    '&:hover': {
      backgroundColor: '#fbb',
    },
  },
  cell: {
    padding: '4px 16px',
  },
  subCategory: {
    color: '#999',
    fontSize: 12,
  },
  email: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
    wordBreak: 'break-word',
  },
  phone: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  bullet: {
    backgroundColor: blue,
    borderRadius: 5,
    display: 'inline-block',
    height: 10,
    width: 10,
  },
  suffix: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  editCell: {
    position: 'relative',
    [theme.breakpoints.up('sm')]: {
      '&:hover $editIcon': {
        display: 'flex',
      },
    },
  },
  editIcon: {
    bottom: 8,
    display: 'none',
    position: 'absolute',
    right: 8,
  },
  hiddenMobile: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  mobileRow: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
    border: 0,
    cursor: 'pointer',
    textDecoration: 'none',
    padding: '16px 16px 0',
  },
  mobileInfo: {
    backgroundColor: '#eee',
    borderRadius: 10,
    padding: 8,
  },
  mobileEmail: {
    color: '#999',
    fontSize: 12,
  },
  mobilePhone: {
    color: '#999',
    fontSize: 12,
  },
  overflowHidden: {
    overflow: 'hidden',
  },
  action: {
    display: 'flex',
  },
  actionAlert: {
    alignSelf: 'center',
    backgroundColor: '#db8053',
    borderRadius: 5,
    flexShrink: 0,
    height: 10,
    marginRight: 8,
    width: 10,
  },
  actionText: {
    color: '#999',
    fontSize: '12px',
    maxWidth: '80px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}));

const UserRow = ({
  user,
  userLabelMap,
  checked,
  onEditUserLabels,
  onToggle,
}) => {
  const L = locale.backoffice;
  const classes = useStyles();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { streetAddress, zipCode, city } =
    (user.home && user.home.address) || {};

  const vitecLink =
    user.home?.vitecEstateId && vitecEstateLink(user.home.vitecEstateId);

  const handleClick = e => {
    e.preventDefault();
    window.open(`/users/${user.id}`, '_blank', 'noreferrer');
  };

  const formatCRMTarget = date => {
    const parsedDate = parseISO(date);

    if (isTomorrow(parsedDate)) {
      return { slug: 'tomorrow', date: '' };
    }
    if (isToday(parsedDate)) {
      return { slug: 'today', date: '' };
    }
    if (isYesterday(parsedDate)) {
      return { slug: 'yesterday', date: '' };
    }
    if (isPast(parsedDate)) {
      return { slug: 'past', date: '' };
    }

    return {
      slug: 'future',
      date: format(parsedDate, 'd MMM'),
    };
  };

  return (
    <>
      {/* Mobile */}
      <TableRow>
        <TableCell
          colSpan={6}
          className={cx(classes.mobileRow, {
            [classes.banned]: user.role === 'BANNED',
          })}
          onClick={handleClick}
        >
          <div className={classes.mobileInfo}>
            {user.name} <br />
            <span className={classes.mobilePhone}>{user.phone}</span>
            <br />
            <span className={classes.mobileEmail}>{user.email}</span>
            <br />
            {streetAddress}, {city}
          </div>
        </TableCell>
      </TableRow>
      {/* Desktop/Shared */}
      <TableRow
        className={cx(classes.row, {
          [classes.banned]: user.role === 'BANNED',
        })}
        onClick={handleClick}
      >
        <TableCell
          className={classes.cell}
          onClick={e => {
            e.stopPropagation();
            onToggle(user);
          }}
        >
          <Checkbox checked={checked} />
        </TableCell>
        <TableCell className={classes.cell}>
          <Box display="flex" alignItems="center">
            <PersonIcon fontSize="small" style={{ color: '#a1a1a1' }} />
            {user.id}
          </Box>{' '}
          <Box display="flex" alignItems="center">
            <HomeIcon fontSize="small" style={{ color: '#a1a1a1' }} />
            {user.home ? user.home.id : ''}
          </Box>
        </TableCell>
        <TableCell className={[classes.hiddenMobile, classes.cell].join(' ')}>
          {user.name}
          <br />
          <span className={classes.phone}>{user.phone}</span>
          <br />
          <span className={[classes.subCategory, classes.email].join(' ')}>
            {user.email}
          </span>
        </TableCell>
        <TableCell className={[classes.hiddenMobile, classes.cell].join(' ')}>
          {streetAddress}
          <br />
          {zipCode} {city}
        </TableCell>
        <TableCell
          style={{ padding: '8px 16px' }}
          className={classes.hiddenMobile}
          onClick={evt => {
            if (vitecLink) {
              evt.preventDefault();
              evt.stopPropagation();
              window.open(vitecLink, '_blank', 'noreferrer');
              return false;
            }

            return undefined;
          }}
        >
          {vitecLink && (
            <a
              href={vitecLink}
              target="_blank"
              rel="noopener noreferrer"
              aria-label="vitec link"
            >
              <OpenInNewIcon />
            </a>
          )}
        </TableCell>
        <TableCell>{user.home?.assignedAgent?.name ?? '-'}</TableCell>
        <TableCell
          style={{ padding: '8px 16px' }}
          className={classes.editCell}
          onClick={evt => {
            if (mobile) {
              handleClick(evt);
              return;
            }
            evt.preventDefault();
            evt.stopPropagation();

            onEditUserLabels(user.id);
          }}
        >
          {user?.userLabelIds.map((id, i) => (
            <Box mb={1} key={i}>
              <Chip
                className={classes.chip}
                size="small"
                label={userLabelMap?.[id]}
              />
            </Box>
          ))}
          <EditIcon className={classes.editIcon} fontSize="small" />
        </TableCell>
        <TableCell
          style={{ padding: '8px 16px' }}
          className={classes.overflowHidden}
        >
          {user.crmActions.map((info, i) => {
            const actionInfo = formatCRMTarget(info.targetDate);

            return (
              <div key={i} className={classes.action}>
                <div
                  className={
                    ['past', 'yesterday', 'today'].includes(actionInfo.slug)
                      ? classes.actionAlert
                      : undefined
                  }
                />
                <div>
                  <Chip
                    className={classes.chip}
                    size="small"
                    label={L.crmActions[actionInfo.slug]}
                  />
                  <div className={classes.actionText}>{info.action}</div>
                </div>
              </div>
            );
          })}
        </TableCell>
        <TableCell
          style={{ padding: '8px 16px' }}
          className={classes.overflowHidden}
        >
          {user?.activityScore.toLocaleString('sv-SE', {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          })}
        </TableCell>
      </TableRow>
    </>
  );
};

export default UserRow;
