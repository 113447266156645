import { gql } from '@apollo/client';
import { Query } from '@apollo/client/react/components';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { addMonths, endOfDay, startOfDay, subMonths } from 'date-fns';
import moment from 'moment';
import React, { useMemo } from 'react';
import { DateParam, useQueryParam, withDefault } from 'use-query-params';

import QueryError from '../../../components/QueryError';
import QueryLoading from '../../../components/QueryLoading';
import { FollowerUniqueType } from '../../../generated/backend/graphql';

import LineChart from './LineChart';
import StatsDateSelector from './StatsDateSelector';

const Q_FOLLOWERS = gql`
  query BofStatsFollowers($startDate: String, $endDate: String) {
    allFollowers: followers(
      followerOptions: { filter: { createdAt: { startDate: $startDate, endDate: $endDate }, unique: ${FollowerUniqueType.None} } }
    ) {
      createdAt
    }
    uniqueFollowers: followers(
      followerOptions: { filter: { createdAt: { startDate: $startDate, endDate: $endDate }, unique: ${FollowerUniqueType.Oldest} } }
    ) {
      createdAt
    }
  }
`;

const useStyles = makeStyles({
  chart: {
    paddingTop: 24,
    '& .ct-series-a': {
      '& .ct-line': {
        stroke: '#D87762',
      },
      '& .ct-point': {
        stroke: '#D87762',
      },
    },
    '& .ct-series-b': {
      '& .ct-line': {
        stroke: '#3E83E3',
      },
      '& .ct-point': {
        stroke: '#3E83E3',
      },
    },
  },
});

const StatsFollowers = () => {
  const timeAtMount = useMemo(() => new Date(), []);
  const [startDate, setStartDate] = useQueryParam(
    'startDate',
    withDefault(DateParam, startOfDay(subMonths(timeAtMount, 3))),
  );
  const [endDate, setEndDate] = useQueryParam(
    'endDate',
    withDefault(DateParam, endOfDay(timeAtMount)),
  );

  const classes = useStyles();

  return (
    <Query
      query={Q_FOLLOWERS}
      variables={{
        startDate,
        endDate,
      }}
    >
      {({ loading, error, data }) => {
        if (loading) {
          return <QueryLoading />;
        }

        if (error) {
          return <QueryError error={error} data={data} />;
        }

        const arrangeFollowers = arr =>
          arr
            .filter(follower => {
              const date = new Date(follower.createdAt);
              return date > startDate && date < endDate;
            })
            .reverse();
        const reduceFollowers = arr =>
          arr?.reduce((acc, cur) => {
            const date = moment(new Date(cur.createdAt)).format('MMM DD');
            const count = acc[date] || 0;
            return { ...acc, [date]: count + 1 };
          }, {}) || {};
        const sum = arr => arr.reduce((acc, cur) => acc + cur, 0);

        const { allFollowers, uniqueFollowers } = data;
        const allFollowersFiltered = arrangeFollowers(allFollowers);
        const uniqueFollowersFiltered = arrangeFollowers(uniqueFollowers);
        const allPerDay = reduceFollowers(allFollowersFiltered);
        const uniquePerDay = reduceFollowers(uniqueFollowersFiltered);
        const allValues = Object.values(allPerDay);
        const uniqueValues = Object.values(uniquePerDay);

        const graphData = {
          labels: Object.keys(allPerDay),
          series: [allValues, uniqueValues],
        };

        const allMax = allValues.length > 0 ? Math.max(...allValues) : 0;
        const uniqueMax =
          uniqueValues.length > 0 ? Math.max(...uniqueValues) : 0;
        const allTotal = sum(allValues);
        const uniqueTotal = sum(uniqueValues);

        const options = {
          high: allMax,
          low: 0,
          axisX: {
            labelInterpolationFnc(value, index) {
              return index % 10 === 0 ? value : null;
            },
          },
          height: '50vh',
          width: '100%',
        };

        return (
          <div>
            <StatsDateSelector
              startDate={startDate}
              endDate={endDate}
              accumulated={false}
              onChangeCombinedDates={(start, end) => {
                if (addMonths(start, 3) < end) {
                  setStartDate(subMonths(end, 3));
                } else {
                  setStartDate(start);
                }
                setEndDate(end);
              }}
              onChangeStartDate={date => {
                const threeMonthsAhead = addMonths(date, 3);
                if (threeMonthsAhead < endDate) {
                  setEndDate(threeMonthsAhead);
                }
                setStartDate(date);
              }}
              onChangeEndDate={date => {
                const threeMonthsSince = subMonths(date, 3);
                if (threeMonthsSince > startDate) {
                  setStartDate(threeMonthsSince);
                }
                setEndDate(date);
              }}
              onChangeAccumulated={() => {}}
            />
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Totalt</TableCell>
                  <TableCell>Totalt unika</TableCell>
                  <TableCell>Max alla</TableCell>
                  <TableCell>Max unika</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>{allTotal}</TableCell>
                  <TableCell>{uniqueTotal}</TableCell>
                  <TableCell>{allMax}</TableCell>
                  <TableCell>{uniqueMax}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <LineChart
              className={classes.chart}
              data={graphData}
              options={options}
            />
          </div>
        );
      }}
    </Query>
  );
};

export default StatsFollowers;
