import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import MenuIcon from '@mui/icons-material/Menu';
import { AppBar, IconButton, Menu, MenuItem, Toolbar } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { useHistory } from 'react-router';

import { NORBAN_ENVIRONMENT } from '../config';
import useSession from '../hooks/useSession';
import { green } from '../theme';

const logo = require('../logo.svg').default;

const isProduction = NORBAN_ENVIRONMENT === 'production';

const theme = createTheme();

const useStyles = makeStyles(() => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: isProduction ? theme.palette.primary.main : green,
  },
  logoContainer: {
    marginRight: 'auto',
  },
  logo: {
    height: '26px',
    marginRight: '8px',
  },
  devText: {
    fontWeight: 'bold',
    position: 'relative',
    top: '-2px',
  },
  menuButton: {
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
}));

type Props = {
  onToggleMenu: () => void;
};

const TopNavigation = ({ onToggleMenu }: Props) => {
  const history = useHistory();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);
  const devText = isProduction ? '' : `(${NORBAN_ENVIRONMENT})`;

  const { logout, userId } = useSession();

  const handleMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    await logout();
    history.push('/');
    handleClose();
  };

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar>
        {userId && (
          <div>
            <IconButton
              color="inherit"
              aria-label="Open drawer"
              edge="start"
              onClick={onToggleMenu}
              className={classes.menuButton}
              size="large"
            >
              <MenuIcon />
            </IconButton>
          </div>
        )}
        <div className={classes.logoContainer}>
          <img src={logo} className={classes.logo} alt="logo" />
          <span className={classes.devText}>{devText}</span>
        </div>
        {userId && (
          <div>
            <IconButton
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
              size="large"
            >
              <AccountCircleIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={open}
              onClose={handleClose}
            >
              <MenuItem onClick={handleLogout}>Log out</MenuItem>
            </Menu>
          </div>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default TopNavigation;
