import { useQuery, useMutation, gql } from '@apollo/client';
import { Box, Paper, Switch, FormControlLabel, Button } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Prismjs from 'prismjs';
import React, { useEffect, useCallback, useState } from 'react';

import 'prismjs/components/prism-json';
import 'prismjs/themes/prism.css';
import QueryError from '../../components/QueryError';
import QueryLoading from '../../components/QueryLoading';

const Q_GEO_JSON = gql`
  query BofGeoJson {
    geoJson
  }
`;

const M_UPDATE_GEO_JSON = gql`
  mutation BofUpdateGeoJson(
    $geoJson: String!
    $dryRun: Boolean!
    $deleteMissing: Boolean!
  ) {
    updateGeoJson(
      geoJson: $geoJson
      dryRun: $dryRun
      deleteMissing: $deleteMissing
    )
  }
`;

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  title: {
    fontSize: 18,
    marginBottom: theme.spacing(2),
  },
  button: {
    marginTop: theme.spacing(1),
  },
}));

const triggerFileDownload = async (fileName, json) => {
  const blob = new Blob([json], { type: 'application/json' });
  const href = await URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = href;
  link.download = `${fileName}.json`;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const triggerFileUpload = (onContent, onNotSupported) => {
  if (window.File && window.FileReader && window.FileList && window.Blob) {
    const file = document.querySelector('input[type=file]').files[0];
    const reader = new FileReader();

    const jsonFile = /application\/json/;
    if (file.type.match(jsonFile)) {
      reader.onload = ({ target: { result } }) => onContent(result);
    }

    reader.readAsText(file);
  } else {
    onNotSupported();
  }
};

const AreaExportContainer = () => {
  const { loading, error, data } = useQuery(Q_GEO_JSON);
  const [updateGeoJson] = useMutation(M_UPDATE_GEO_JSON);
  const [updateReport, setUpdateReport] = useState('');
  const [dryRun, setRealRun] = useState(true);
  const [deleteMissing, setDeleteAreas] = useState(false);
  const classes = useStyles();

  const onClickExport = useCallback(
    async () =>
      triggerFileDownload('exported-areas-geojson.json', data.geoJson),
    [data],
  );

  const onChangeFile = useCallback(
    () =>
      triggerFileUpload(
        async geoJson => {
          try {
            const result = await updateGeoJson({
              variables: {
                geoJson,
                dryRun,
                deleteMissing,
              },
            });

            setUpdateReport(result.data.updateGeoJson);
          } catch (e) {
            setUpdateReport(JSON.stringify(e, null, 2));
          }
        },
        () => {
          setUpdateReport('Din webbläsare stöds tyvärr inte');
        },
      ),
    [dryRun, deleteMissing, updateGeoJson],
  );

  useEffect(() => {
    setTimeout(() => Prismjs.highlightAll(), 0);
  });

  if (loading) {
    return <QueryLoading />;
  }

  if (error) {
    return <QueryError error={error} data={data} />;
  }

  if (!data.geoJson) {
    return <div>Något gick fel...</div>;
  }

  return (
    <Box m={2}>
      <Paper className={classes.paper}>
        <div className={classes.title}>Exportera fil med nuvarande areor</div>
        <Button
          className={classes.button}
          variant="contained"
          onClick={() => onClickExport()}
        >
          Exportera
        </Button>
      </Paper>
      <Paper className={classes.paper}>
        <div className={classes.title}>
          Importera fil med uppdaterade eller nya areor
        </div>
        <FormControlLabel
          control={
            <Switch
              checked={dryRun}
              onChange={({ target: { checked } }) => setRealRun(checked)}
              name="UploadDryRun"
              color="primary"
            />
          }
          label="Ladda upp fil utan att ändra på riktigt"
        />
        <FormControlLabel
          control={
            <Switch
              checked={deleteMissing}
              onChange={({ target: { checked } }) => setDeleteAreas(checked)}
              name="UploadDeleteMissing"
              color="primary"
            />
          }
          label="Ta bort okända areor vid uppladdning"
        />
        <Button
          className={classes.button}
          variant="contained"
          component="label"
        >
          Ladda upp
          <input
            type="file"
            style={{ display: 'none' }}
            onChange={onChangeFile}
          />
        </Button>
      </Paper>
      {updateReport && (
        <Paper>
          <pre>
            <code id="json" className="language-json">
              {updateReport}
            </code>
          </pre>
        </Paper>
      )}
    </Box>
  );
};

export default AreaExportContainer;
