import { useQuery } from '@apollo/client';
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material';
import React, { useCallback, useState } from 'react';

import {
  BofAddFollowingDialogAllPublicHomesDocument,
  BofAddFollowingDialogHomeFragment,
} from '../../../generated/backend/graphql';
import useDebouncedState from '../../../hooks/useDebouncedState';

type Props = {
  open: boolean;
  onCancel: () => Promise<void>;
  onConfirm: (homeId: string) => Promise<void>;
};

const AddFollowingDialog = ({ onConfirm, onCancel, open }: Props) => {
  const [selectedHome, setSelectedHome] =
    useState<BofAddFollowingDialogHomeFragment | null>(null);

  const handleConfirm = useCallback(async () => {
    if (!selectedHome?.id) {
      return;
    }

    await onConfirm(selectedHome?.id);
  }, [onConfirm, selectedHome?.id]);

  const [searchQuery, debouncedSearchQuery, setDebouncedSearchQuery] =
    useDebouncedState('', 300);

  const { data } = useQuery(BofAddFollowingDialogAllPublicHomesDocument, {
    variables: { filter: { searchQuery: debouncedSearchQuery, limit: 30 } },
    skip: !debouncedSearchQuery,
  });

  return (
    <Dialog open={open} maxWidth="xs">
      <DialogTitle>Lägg till följning</DialogTitle>
      <DialogContent>
        <Autocomplete
          id="public-home-autocomplete"
          options={data?.homes ?? []}
          getOptionLabel={option => {
            const id = option?.id ?? '-';
            const street = option?.address.streetAddress ?? '-';
            const city = option?.address.city ?? '-';

            return `${street} ${city} [${id}]`;
          }}
          style={{ width: 300 }}
          onChange={(_, newValue) => {
            setSelectedHome(newValue);
          }}
          renderInput={params => (
            <Box mt={1}>
              <TextField
                {...params}
                label="Bostad"
                variant="outlined"
                value={searchQuery}
                onChange={evt => setDebouncedSearchQuery(evt.target.value)}
                autoFocus
              />
            </Box>
          )}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="primary">
          Cancel
        </Button>
        <Button
          disabled={!selectedHome?.id}
          onClick={handleConfirm}
          color="primary"
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddFollowingDialog;
