import { useQuery } from '@apollo/client';
import React from 'react';
import { useParams } from 'react-router';

import {
  BofChatbyIdOwnersDocument,
  ChatOwnershipType,
} from '../../../generated/backend/graphql';

import ChatTable from './ChatTable';

const ChatById = () => {
  const { id } = useParams<{ id: string }>();

  const { loading, data } = useQuery(BofChatbyIdOwnersDocument, {
    variables: {
      filter: {
        includeHandled: false,
        ownership: ChatOwnershipType.ByOwnershipId,
        ownershipId: id,
      },
      userId: id,
    },
  });

  return (
    <ChatTable
      admins={data?.user ? [data.user] : []}
      chatOwners={data?.chatOwners}
      loading={loading}
      onRowClick={() => {}}
    />
  );
};

export default ChatById;
