import { createStore } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web

import root from './reducers/root';

const persistConfig = {
  key: 'versioned',
  storage,
  blacklist: ['userSearch'],
  version: 0,
};

const persistedReducer = persistReducer(persistConfig, root);

export default () => {
  const store = createStore(persistedReducer);
  const persistor = persistStore(store);
  return { store, persistor };
};
