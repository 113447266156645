import { Card, CardContent, CardHeader } from '@mui/material';
import { sv as locale } from '@norban/locale';
import React from 'react';

import Select from '../../../../components/form/Select';
import { BofHomeProfileHomeFragment } from '../../../../generated/backend/graphql';
import { DispatchType } from '../../hooks/useHomeProfileReducer';

const L = locale.backoffice;

type Props = {
  home: BofHomeProfileHomeFragment;
  dispatch: DispatchType;
};

const SalesStatusCard = ({ home, dispatch }: Props) => {
  return (
    <Card>
      <CardHeader title={L.homeProfileSalesStatusCard.title} />
      <CardContent>
        <Select
          label={L.homeProfileSalesStatusCard.selectTitle}
          value={home.salesProcessStatus}
          name="salesProcessStatus"
          dispatch={dispatch}
          options={L.enumerations.SalesProcessStatus}
          includeEmpty={false}
          sortOptions={false}
        />
      </CardContent>
    </Card>
  );
};

export default SalesStatusCard;
