import { Grid } from '@mui/material';
import React, { useEffect } from 'react';

const DescribeYourHomeForm = props => {
  const { home, submitting, updateUser } = props;
  const { descriptiveLines } = home;

  useEffect(() => {
    if (submitting) {
      updateUser();
    }
  }, [submitting, updateUser]);

  return (
    <Grid container spacing={0}>
      {descriptiveLines.map(dl => (
        <Grid key={dl} item xs={12}>
          <div>{dl}</div>
        </Grid>
      ))}
    </Grid>
  );
};

export default DescribeYourHomeForm;
