import { useMutation, useQuery, gql } from '@apollo/client';
import { Snackbar } from '@mui/material';
import { sv as locale } from '@norban/locale';
import React, { useReducer, useState } from 'react';

import PageHeader from '../../components/PageHeader';
import SlideButton from '../../components/SlideButton';
import AreaEditCard from '../AreaView/AreaEditCard';

const Q_AREAS = gql`
  query BofArea {
    areas(rootOnly: true, filter: { includePrivate: true }) {
      id
      name
      public
      children {
        id
        name
        public
        children {
          id
          name
          public
        }
      }
    }
  }
`;

const M_NEW_AREA = gql`
  mutation BofNewArea($input: AreaInput!) {
    newArea(input: $input) {
      id
    }
  }
`;

const AreaNewView = () => {
  const L = locale.backoffice;
  const [modified, setModified] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '' });

  const [state, dispatch] = useReducer((state, action) => {
    if (action.initialState) {
      const area = action.initialState;
      return {
        name: area.name,
        public: area.public,
        parentId: area.parentId,
      };
    }

    if (action.key) {
      setModified(true);
      return {
        ...state,
        [action.key]: action.value,
        public: action.value === 'true',
      };
    }

    throw new Error(`invalid action: ${JSON.stringify(action)}`);
  }, {});

  const { data } = useQuery(Q_AREAS, {
    onCompleted: () => {
      if (!modified) {
        dispatch({
          initialState: { id: null, name: '', public: false, parentId: null },
        });
      }
    },
  });

  const [newArea] = useMutation(M_NEW_AREA, {
    onCompleted: () => setModified(false),
    onError: err => {
      console.error(err);
      setSnackbar({ open: true, message: 'Failed to update area' });
    },
  });

  const submit = evt => {
    evt.preventDefault();
    newArea({
      variables: {
        input: {
          ...state,
          polygon: state.polygon ? JSON.parse(state.polygon) : null,
        },
      },
    });
  };

  return (
    <div>
      <PageHeader title={L.areas.newArea} />
      <form onSubmit={submit}>
        <AreaEditCard
          area={state}
          areas={data?.areas}
          agents={[]}
          homes={[]}
          dispatch={dispatch}
          hasActions={false}
        />
        <SlideButton modified={modified} />
        <Snackbar
          open={snackbar.open}
          onClose={() => setSnackbar({ open: false, message: '' })}
          message={snackbar.message}
          autoHideDuration={5000}
        />
      </form>
    </div>
  );
};

export default AreaNewView;
