import { useMutation } from '@apollo/client';
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  FormControlLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/system';
import { sv as locale } from '@norban/locale';
import React, { useCallback, useMemo, useState } from 'react';

import ConfirmationDialog from '../../../components/ConfirmationDialog';
import { CONTENT_URI } from '../../../config';
import {
  BofUserUploadedImageDeleteDocument,
  OwnerUploadedImageState,
} from '../../../generated/backend/graphql';

type Props = {
  id: string;
  imageId: string;
  state: OwnerUploadedImageState;
  showAllOptions: boolean;
  reason: string;
  onChangeImageState: (
    id: string,
    state: OwnerUploadedImageState,
    reason: string,
  ) => void;
  onDeleteImage(id: string): void;
};

const colorMap: {
  [key in OwnerUploadedImageState]: 'warning' | 'success' | 'error';
} = {
  [OwnerUploadedImageState.Approved]: 'success',
  [OwnerUploadedImageState.Pending]: 'warning',
  [OwnerUploadedImageState.Rejected]: 'error',
  [OwnerUploadedImageState.Initial]: 'warning',
};

const useStyles = makeStyles((theme: Theme) => ({
  image: {
    aspectRatio: '3/2',
    backgroundColor: theme.palette.secondary.light,
    display: 'block',
    height: '100%',
    objectFit: 'contain',
    width: '100%',
  },
  checkboxContainer: {
    backgroundColor: '#fff',
    borderRadius: '3px',
  },
  hidden: {
    display: 'none',
  },
}));

const rejectionReasons = [
  'Bildkvaliteten är inte tillräcklig - vi behöver skarpa och välbelysta bilder för att visa ditt hem från sin bästa sida.',
  'Bilden innehåller personer - vänligen ladda upp bilder utan personer.',
  'Bilden kan avslöja bostadens adress - byt ut bilden eller uppgradera till Pre-Market för att visa den i din annons.',
  'Bilden är inte relevant - se till att varje bild visar bostaden som är till salu.',
  'Olämpligt innehåll - alla bilder ska vara fria från stötande eller irrelevant material och passa en professionell bostadsmarknad.',
];

const buildImageUrl = (
  id: string | undefined,
  fallback: string,
  sizeClass: string,
  fileEnding = 'jpg',
  fallbackEnding: string | undefined = undefined,
) => {
  if (!id) {
    return fallback;
  }

  const path = [CONTENT_URI, 'images', sizeClass, `${id}.${fileEnding}`]
    .filter(Boolean)
    .join('/');

  const queryParams = fallbackEnding ? `?fallback=${fallbackEnding}` : '';

  return path + queryParams;
};

export default function UserUploadedImage({
  id,
  imageId,
  showAllOptions,
  state,
  reason,
  onChangeImageState,
  onDeleteImage,
}: Props) {
  const L = locale.backoffice;
  const classes = useStyles();

  const [deleteId, setDeleteId] = useState<string | undefined>(undefined);

  const [deleteImage] = useMutation(BofUserUploadedImageDeleteDocument, {
    update: (cache, result) => {
      if (!result.data?.deleteOwnerUploadedImage) {
        return;
      }

      cache.evict({
        id: 'ROOT_QUERY',
        fieldName: 'ownerUploadedImages',
      });
      cache.gc();
    },
  });

  const handleDeleteImage = useCallback(
    async (id: string) => {
      try {
        deleteImage({ variables: { id } });
        onDeleteImage(id);
      } catch (e) {
        console.warn('FAIL');
      }
    },
    [deleteImage, onDeleteImage],
  );

  const showDeleteButton = useMemo(
    () =>
      state === OwnerUploadedImageState.Initial ||
      state === OwnerUploadedImageState.Rejected,
    [state],
  );

  return (
    <>
      <Paper>
        <Box p={2}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={10}>
              <img
                className={classes.image}
                src={buildImageUrl(imageId, '', 'w600', 'avif', 'jpg')}
                alt={`Bild ${id}`}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <Chip
                label={L.ownerUploadedImages[state as OwnerUploadedImageState]}
                color={colorMap[state]}
              />
              <RadioGroup
                aria-label="Bildens status"
                value={state}
                onChange={evt =>
                  onChangeImageState(
                    id,
                    evt.target.value as OwnerUploadedImageState,
                    reason,
                  )
                }
              >
                <FormControlLabel
                  value={OwnerUploadedImageState.Approved}
                  control={<Radio />}
                  label={L.ownerUploadedImages.APPROVED}
                />
                <FormControlLabel
                  value={OwnerUploadedImageState.Rejected}
                  control={<Radio />}
                  label={L.ownerUploadedImages.REJECTED}
                />
                <FormControlLabel
                  className={showAllOptions ? undefined : classes.hidden}
                  value={OwnerUploadedImageState.Pending}
                  control={<Radio />}
                  label={L.ownerUploadedImages.PENDING}
                />
                <FormControlLabel
                  className={showAllOptions ? undefined : classes.hidden}
                  value={OwnerUploadedImageState.Initial}
                  control={<Radio />}
                  label={L.ownerUploadedImages.INITIAL}
                />
              </RadioGroup>
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                disabled={state !== OwnerUploadedImageState.Rejected}
                freeSolo
                options={rejectionReasons}
                renderInput={params => (
                  <TextField {...params} label="Anledning till avslag" />
                )}
                value={reason}
                onChange={(_, value) =>
                  onChangeImageState(id, state, value ?? '')
                }
                onInputChange={(_, value) =>
                  onChangeImageState(id, state, value ?? '')
                }
              />
            </Grid>
            {showDeleteButton && (
              <Grid item xs={12}>
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={() => setDeleteId(id)}
                >
                  Radera
                </Button>
              </Grid>
            )}
          </Grid>
        </Box>
      </Paper>
      <ConfirmationDialog
        open={!!deleteId}
        onConfirm={() => {
          if (deleteId) {
            handleDeleteImage(id);
            setDeleteId(undefined);
          }
        }}
        onCancel={() => setDeleteId(undefined)}
        title={L.ownerUploadedImages.confirmTitle}
        content={L.ownerUploadedImages.confirmContent}
      />
    </>
  );
}
