import { useMutation, useQuery } from '@apollo/client';
import {
  Box,
  Button,
  FormControlLabel,
  Paper,
  Switch,
  TextField,
} from '@mui/material';
import { sv as locale } from '@norban/locale';
import React, { useMemo, useState } from 'react';

import PageHeader from '../../components/PageHeader';
import {
  BofScheduledJobsDocument,
  BofScheduledJobsQuery,
  BofUpdateScheduledJobDocument,
} from '../../generated/backend/graphql';
import usePopupAlert from '../../hooks/usePopupAlert';

const ScheduledEmail = () => {
  const [saving, setSaving] = useState(false);
  const [modified, setModified] = useState(false);
  const { PopupAlert, showPopupAlert } = usePopupAlert();

  const L = locale.backoffice.scheduledEmail;
  const [state, setState] = useState<BofScheduledJobsQuery['scheduledJobs']>(
    [],
  );
  const { data, loading } = useQuery(BofScheduledJobsDocument, {
    onCompleted: data => {
      if (!modified) {
        setState(data.scheduledJobs);
      }
    },
  });
  const scheduledJobs = useMemo(() => data?.scheduledJobs ?? [], [data]);

  const [updateScheduledJob] = useMutation(BofUpdateScheduledJobDocument, {
    update: (cache, result) => {
      const { data } = result ?? {};
      if (!data || !data.updateScheduledJob) {
        return;
      }
      cache.writeQuery({
        query: BofScheduledJobsDocument,
        data: {
          scheduledJobs: scheduledJobs.map(job =>
            job.name === data?.updateScheduledJob?.name
              ? data?.updateScheduledJob
              : job,
          ),
        },
      });
    },
  });

  const updateState = (name: string, enabled: boolean, cronTime: string) => {
    setModified(true);
    setState(
      scheduledJobs.map(job =>
        job.name === name ? { ...job, cronTime, enabled } : job,
      ),
    );
  };

  const isValid = (index: number) => {
    const job = state[index];
    if (!job) {
      return false;
    }
    const { cronTime } = job;
    if (!cronTime) {
      return false;
    }

    const regex = /^(\*|\d{1,2})(\s+(\*|\d{1,2})){5}$/;
    return regex.test(cronTime);
  };

  const handleSubmit = () => {
    setSaving(true);
    try {
      const promises = state.map(job => {
        return updateScheduledJob({
          variables: {
            enabled: job.enabled,
            cronTime: job.cronTime,
          },
        });
      });

      Promise.all(promises)
        .then(results => {
          const errorResult = results.find(
            result => result.errors?.[0]?.message,
          );
          const errorMessage = errorResult?.errors?.[0]?.message;
          if (errorMessage) {
            showPopupAlert(errorMessage, 'error');
          }
        })
        .catch(error => {
          showPopupAlert(error.message, 'error');
        })
        .finally(() => setSaving(false));
    } catch (error) {
      if (error instanceof Error) {
        showPopupAlert(error.message, 'error');
      }
    }
    setModified(false);
  };

  return (
    <>
      <PageHeader title={L.title} />
      <Paper style={{ overflowX: 'auto' }}>
        <Box m={1} ml={2}>
          {state.map((job, index) => (
            <Box mb={2} mt={2} display="flex" key={job.name}>
              <FormControlLabel
                key={job.name}
                control={
                  <Switch
                    checked={job.enabled}
                    disabled={loading || saving}
                    onChange={({ target: { checked } }) =>
                      updateState(job.name, checked, job.cronTime)
                    }
                    name={job.name}
                    color="primary"
                  />
                }
                label={(L.slugs as { [key: string]: string })[job.name] ?? ''}
              />
              <TextField
                variant="outlined"
                value={job.cronTime}
                error={!isValid(index)}
                onChange={ev =>
                  updateState(job.name, job.enabled, ev.target.value)
                }
              />
            </Box>
          ))}
          <Box mb={2}>
            <Button
              variant="contained"
              disabled={!modified || state.some((_, index) => !isValid(index))}
              onClick={() => handleSubmit()}
            >
              Spara
            </Button>
          </Box>
        </Box>
      </Paper>
      <PopupAlert />
    </>
  );
};

export default ScheduledEmail;
