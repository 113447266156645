import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select,
} from '@mui/material';
import { Stack } from '@mui/system';
import { sv as locale } from '@norban/locale';
import React from 'react';

import { FollowerUpdatePreference } from '../../../generated/backend/graphql';

const L = locale.backoffice.editCategoriesDialog;

type Props = {
  onClose: () => void;
  onOk: (category: FollowerUpdatePreference | null) => void;
};

const EditCategoriesDialogContent = ({ onClose, onOk }: Props) => {
  const [category, setCategory] = React.useState<
    FollowerUpdatePreference | 'none'
  >('none');

  return (
    <DialogContent>
      <DialogTitle>{L.title}</DialogTitle>
      <Stack padding={2} spacing={2}>
        <Select
          value={category}
          onChange={e =>
            setCategory(e.target.value as FollowerUpdatePreference | 'none')
          }
          fullWidth
          variant="outlined"
        >
          <MenuItem value="none">{L.none}</MenuItem>
          <MenuItem value={FollowerUpdatePreference.Contacted}>
            {locale.backoffice.followerCategory.CONTACTED}
          </MenuItem>
          <MenuItem value={FollowerUpdatePreference.DeclinesUpdates}>
            {locale.backoffice.followerCategory.DECLINES_UPDATES}
          </MenuItem>
          <MenuItem value={FollowerUpdatePreference.WantsUpdates}>
            {locale.backoffice.followerCategory.WANTS_UPDATES}
          </MenuItem>
        </Select>

        <DialogActions>
          <Button variant="outlined" onClick={onClose}>
            {L.cancel}
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => onOk(category === 'none' ? null : category)}
          >
            {L.ok}
          </Button>
        </DialogActions>
      </Stack>
    </DialogContent>
  );
};

type PropsWithOpen = Props & {
  open: boolean;
};

const EditCategoriesDialog = ({ open, onClose, onOk }: PropsWithOpen) => {
  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <EditCategoriesDialogContent onClose={onClose} onOk={onOk} />
    </Dialog>
  );
};

export default EditCategoriesDialog;
