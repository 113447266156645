/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQuery } from '@apollo/client';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import {
  BofGetAllUserLabelsDocument,
  BofGetAllUsersDocument,
  BofGetRootAreasDocument,
  HomeState,
  HomeType,
  Role,
  SortingFields,
} from '../../../generated/backend/graphql';
import useSession from '../../../hooks/useSession';
import { PAGE_COUNT } from '../config';

function useFilterSearchResult(page: number) {
  const { data: dataUserLabels } = useQuery(BofGetAllUserLabelsDocument);

  // Global store
  const {
    filterAreas,
    filterAreasEnabled,
    filterCrmActionsDateEnd,
    filterCrmActionsDateStart,
    filterCrmActionsEnabled,
    filterCrmActionsInverted,
    filterCrmAssignedUserId: filterCrmAssignedUserIdOrDefault,
    filterFollowersEnabled,
    filterFollowersEndDate,
    filterFollowersStartDate,
    filterHomeCreationDateEnd,
    filterHomeCreationDateEnabled,
    filterHomeCreationDateStart,
    filterHomeStates,
    filterHomeStatesEnabled,
    filterHomeTypes,
    filterHomeTypesEnabled,
    filterLivingAreaRange,
    filterLivingAreaRangeEnabled,
    filterMessagesEnabled,
    filterMessagesInverted,
    filterRoles,
    filterRolesEnabled,
    filterRoomsRange,
    filterRoomsRangeEnabled,
    filterUserCreatedDateEnabled,
    filterUserCreatedDateEnd,
    filterUserCreatedDateStart,
    filterUserHasHome,
    filterUserHasHomeEnabled,
    filterUserLabels,
    filterUserLabelsEnabled,
    filterUserLabelsInverted,
    orderUsersEnabled,
    hasAssignedAgentEnabled,
    hasAssignedAgentInverted,
    filterAssignedAgentId,
    filterUserHasEmailEnabled,
    filterUserHasEmail,
    includeAssociatedAreas,
  } = useSelector((state: any) => state.userFilter);

  const { userId } = useSession();
  const filterCrmAssignedUserId =
    filterCrmAssignedUserIdOrDefault === 'DEFAULT'
      ? userId?.toString()
      : filterCrmAssignedUserIdOrDefault;

  const { searchQuery } = useSelector((state: any) => state.userSearch);

  // Data fetch helpers
  const enabledRoles = useMemo(() => {
    if (!filterRolesEnabled) {
      return undefined;
    }

    return Object.values(Role).reduce<string[]>((acc, role, index) => {
      if (filterRoles[index]) {
        acc.push(role);
      }
      return acc;
    }, []);
  }, [filterRoles, filterRolesEnabled]);
  const enabledStates = useMemo(() => {
    if (!filterHomeStatesEnabled) {
      return undefined;
    }

    return Object.values(HomeState).reduce<HomeState[]>((acc, state, index) => {
      const included = filterHomeStates[index];
      if (included) {
        acc.push(state);
      }
      return acc;
    }, []);
  }, [filterHomeStates, filterHomeStatesEnabled]);

  const enabledHomeCreationDateRange = useMemo(() => {
    if (!filterHomeCreationDateEnabled) {
      return undefined;
    }

    return { from: filterHomeCreationDateStart, to: filterHomeCreationDateEnd };
  }, [
    filterHomeCreationDateEnabled,
    filterHomeCreationDateEnd,
    filterHomeCreationDateStart,
  ]);

  const enabledHomeTypes = useMemo(() => {
    if (!filterHomeTypesEnabled) {
      return undefined;
    }

    return Object.values(HomeType).reduce<HomeType[]>((acc, type, index) => {
      const included = filterHomeTypes[index];
      if (included) {
        acc.push(type);
      }
      return acc;
    }, []);
  }, [filterHomeTypes, filterHomeTypesEnabled]);

  const enabledHaveHome = useMemo(() => {
    if (!filterUserHasHomeEnabled) {
      return undefined;
    }
    return filterUserHasHome;
  }, [filterUserHasHome, filterUserHasHomeEnabled]);

  const enabledHaveEmail = useMemo(() => {
    if (!filterUserHasEmailEnabled) {
      return undefined;
    }

    return filterUserHasEmail;
  }, [filterUserHasEmail, filterUserHasEmailEnabled]);

  const enabledUserLabels = useMemo(() => {
    if (!filterUserLabelsEnabled) {
      return undefined;
    }

    return dataUserLabels?.userLabels
      ?.map((label, i) => filterUserLabels?.[i] && label.id)
      .filter(Boolean);
  }, [filterUserLabelsEnabled, dataUserLabels?.userLabels, filterUserLabels]);

  const enabledRoomsRange = useMemo(() => {
    if (!filterRoomsRangeEnabled) {
      return undefined;
    }

    return { low: filterRoomsRange[0], high: filterRoomsRange[1] };
  }, [filterRoomsRange, filterRoomsRangeEnabled]);

  const enabledLivingAreaRange = useMemo(() => {
    if (!filterLivingAreaRangeEnabled) {
      return undefined;
    }

    return {
      low: filterLivingAreaRange?.[0] ?? -12,
      high: filterLivingAreaRange?.[1] ?? 999,
    };
  }, [filterLivingAreaRange, filterLivingAreaRangeEnabled]);

  const enabledAreaIds = useMemo(() => {
    if (!filterAreasEnabled) {
      return undefined;
    }

    // Translate 'NULL' to null
    return filterAreas.map((area: any) => (area === 'NULL' ? null : area));
  }, [filterAreas, filterAreasEnabled]);

  const assignedAgentId = useMemo(() => {
    // Disable assigned agent filter if
    // - it is disabled
    // - it is inverted (i.e. we want to filter out users with assigned agent)
    if (!hasAssignedAgentEnabled || hasAssignedAgentInverted) {
      return undefined;
    }

    return filterAssignedAgentId ?? undefined;
  }, [
    hasAssignedAgentEnabled,
    hasAssignedAgentInverted,
    filterAssignedAgentId,
  ]);

  const hasAssignedAgent = useMemo(() => {
    if (!hasAssignedAgentEnabled) {
      return undefined;
    }

    return !hasAssignedAgentInverted;
  }, [hasAssignedAgentEnabled, hasAssignedAgentInverted]);

  const userFilter = {
    hasHome: enabledHaveHome,
    hasEmail: enabledHaveEmail,
    roles: enabledRoles,
    homeFilter: {
      homeStates: enabledStates,
      homeTypes: enabledHomeTypes,
      areaIds: enabledAreaIds,
      roomsRange: enabledRoomsRange,
      livingAreaRange: enabledLivingAreaRange,
      dateRange: enabledHomeCreationDateRange,
      hasAssignedAgent,
      assignedAgentId,
      includeAssociatedAreas,
    },
    userLabels: filterUserLabelsEnabled
      ? {
          inverted: filterUserLabelsInverted,
          labels: enabledUserLabels,
        }
      : undefined,
    crmActions: filterCrmActionsEnabled
      ? {
          inverted: filterCrmActionsInverted,
          start: filterCrmActionsDateStart,
          end: filterCrmActionsDateEnd,
          assignedUserId: filterCrmAssignedUserId,
        }
      : undefined,
    unreadMessages: {
      enabled: filterMessagesEnabled,
      inverted: filterMessagesInverted,
    },
    date: filterUserCreatedDateEnabled
      ? {
          startDate: filterUserCreatedDateStart,
          endDate: filterUserCreatedDateEnd,
        }
      : undefined,
    searchQuery,
    followers: filterFollowersEnabled
      ? {
          start: filterFollowersStartDate,
          end: filterFollowersEndDate,
        }
      : undefined,
  };

  const {
    data: dataUsers,
    loading: loadingUsers,
    error: errorUsers,
    refetch: refetchUsers,
  } = useQuery(BofGetAllUsersDocument, {
    variables: {
      sortingFields: orderUsersEnabled
        ? [SortingFields.ActiveScore, SortingFields.Id]
        : undefined,
      userFilter,
      last: PAGE_COUNT,
      offset: PAGE_COUNT * (page - 1),
    },
    skip: dataUserLabels?.userLabels === undefined,
  });

  // Fetch data
  const {
    data: dataRootAreas,
    loading: loadingRootAreas,
    error: errorRootAreas,
  } = useQuery(BofGetRootAreasDocument);

  return {
    dataUsers,
    loadingUsers,
    errorUsers,
    refetchUsers,
    dataRootAreas,
    loadingRootAreas,
    errorRootAreas,
    dataUserLabels,
    userFilter,
  };
}

export default useFilterSearchResult;
