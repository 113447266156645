import { useMutation, useQuery } from '@apollo/client';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  IconButton,
  TextField,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/system';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import React, { useState } from 'react';

import {
  BofCreateOfficeDocument,
  BofDeleteOfficeDocument,
  BofOfficesDocument,
} from '../../generated/backend/graphql';

type Row = {
  id: string;
  address: string;
  city: string;
};

const useStyles = makeStyles((theme: Theme) => ({
  fab: {
    position: 'fixed',
    bottom: theme.spacing(4),
    right: theme.spacing(4),
  },
}));

const Offices = () => {
  const classes = useStyles();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');

  const { data, refetch } = useQuery(BofOfficesDocument);
  const [createOffice] = useMutation(BofCreateOfficeDocument, {
    onCompleted: () => refetch(),
  });
  const [deleteOffice] = useMutation(BofDeleteOfficeDocument, {
    onCompleted: () => refetch(),
  });

  const closeDialog = () => {
    setAddress('');
    setCity('');
    setDialogOpen(false);
  };

  const rows = data?.offices ?? [];

  const columns: GridColDef<Row>[] = [
    { field: 'id', headerName: 'ID', width: 80 },
    { field: 'address', headerName: 'Address', width: 200 },
    { field: 'city', headerName: 'City', width: 150 },

    {
      field: 'delete',
      headerName: 'Delete',
      width: 100,
      renderCell: params => (
        <IconButton
          onClick={async () => {
            await deleteOffice({
              variables: {
                id: params.id.toString(),
              },
            });
          }}
        >
          <DeleteIcon />
        </IconButton>
      ),
    },
  ];

  return (
    <>
      <Dialog open={dialogOpen} onClose={closeDialog}>
        <DialogTitle>Add Office</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="address"
            label="Address"
            type="text"
            fullWidth
            value={address}
            onChange={e => setAddress(e.target.value)}
          />
          <TextField
            margin="dense"
            id="city"
            label="City"
            type="text"
            fullWidth
            value={city}
            onChange={e => setCity(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog} color="primary">
            Cancel
          </Button>
          <Button
            onClick={async () => {
              await createOffice({
                variables: {
                  address,
                  city,
                },
              });
              closeDialog();
            }}
            color="primary"
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>
      <DataGrid columns={columns} rows={rows} />
      <Fab
        color="primary"
        aria-label="add"
        className={classes.fab}
        onClick={e => {
          e.preventDefault();
          setDialogOpen(true);
        }}
      >
        <AddIcon />
      </Fab>
    </>
  );
};

export default Offices;
