import {
  Button,
  Card,
  CardContent,
  CardHeader,
  TextField,
} from '@mui/material';
import { Stack } from '@mui/system';
import { sv as locale } from '@norban/locale';
import React, { useRef } from 'react';

import { BofHomeProfileHomeFragment } from '../../../../generated/backend/graphql';
import { DispatchType } from '../../hooks/useHomeProfileReducer';

type Props = {
  home: BofHomeProfileHomeFragment;
  dispatch: DispatchType;
};

const SalesBulletsCard = ({ home, dispatch }: Props) => {
  const L = locale.backoffice.salesBullets;

  const ref = useRef<HTMLInputElement>(null);

  return (
    <Card>
      <CardHeader title={L.title} />
      <CardContent>
        <Stack spacing={2} alignItems="flex-start">
          <Button
            variant="contained"
            color="secondary"
            size="small"
            onClick={() => {
              dispatch({
                key: 'salesBullets',
                value: `${home.salesBullets}${L.bullet}`,
              });
              ref.current?.focus();
            }}
          >
            {L.addBullet}
          </Button>
          <Button
            variant="contained"
            color="secondary"
            size="small"
            onClick={() => {
              dispatch({
                key: 'salesBullets',
                value: `${home.salesBullets}${L.defaultBullet}\n`,
              });
              ref.current?.focus();
            }}
          >
            {L.addDefaultBullet}
          </Button>
          <TextField
            inputRef={ref}
            label={L.textToProspects}
            value={home.salesBullets}
            onChange={e =>
              dispatch({ key: 'salesBullets', value: e.target.value })
            }
            fullWidth
            multiline
            rows={4.5}
          />
        </Stack>
      </CardContent>
    </Card>
  );
};

export default SalesBulletsCard;
