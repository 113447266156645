import { RadioGroup, Radio, FormControlLabel } from '@mui/material';
import { sv as locale } from '@norban/locale';
import React, { useEffect } from 'react';

const BookValuationMeetingForm = ({
  home,
  readOnly,
  submitting,
  updateUser,
}) => {
  const choices = {
    SOON: locale.MH.BOOK_VALUATION_MEETING.option1,
    LATER: locale.MH.BOOK_VALUATION_MEETING.option2,
    MORE_INFO: locale.MH.BOOK_VALUATION_MEETING.option3,
  };

  useEffect(() => {
    if (submitting) {
      updateUser();
    }
  }, [submitting, updateUser]);

  return (
    <RadioGroup value={home.valuationMeeting.request}>
      {Object.keys(choices).map(key => (
        <FormControlLabel
          disabled={readOnly}
          key={key}
          value={key}
          control={<Radio />}
          label={choices[key]}
        />
      ))}
    </RadioGroup>
  );
};

export default BookValuationMeetingForm;
