import { useApolloClient, useMutation } from '@apollo/client';
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Snackbar,
  Stack,
  TextField,
} from '@mui/material';
import { sv as locale } from '@norban/locale';
import React, { useState } from 'react';

import {
  BofCreateUserDocument,
  TenureType,
} from '../../../generated/backend/graphql';

interface Props {
  asDialog: boolean;
  open: boolean;
  onClose: (userId?: string) => void;
  withHomeDefault?: boolean;
}

const CreateUserForm = ({
  asDialog,
  open,
  onClose,
  withHomeDefault = false,
}: Props) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [withHome, setWithHome] = useState(withHomeDefault);
  const [tenure, setTenure] = useState(TenureType.HousingCooperative);
  const [snackbarOpen, showSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarServerity, setSnackbarServerity] = useState<
    'success' | 'error'
  >('success');
  const L = locale.backoffice;

  const apolloClient = useApolloClient();

  const [createUser] = useMutation(BofCreateUserDocument, {
    onCompleted: async () => {
      setSnackbarServerity('success');
      setSnackbarMessage('User created');
      showSnackbar(true);

      // Reset the cache to update the user list
      await apolloClient.resetStore();
    },
    onError: err => {
      setSnackbarServerity('error');
      setSnackbarMessage(err.message);
      showSnackbar(true);
    },
  });

  const clearForm = () => {
    setName('');
    setEmail('');
    setPhone('');
    setWithHome(withHomeDefault);
    setTenure(TenureType.HousingCooperative);
  };

  const handleSubmit = async (evt: { preventDefault: () => void }) => {
    evt.preventDefault();

    const input = {
      name,
      email: email === '' ? null : email,
      personalNumber: '000000-0000',
      phone,
    };

    const homeInput = withHome
      ? {
          tenure,
        }
      : null;

    const result = await createUser({
      variables: {
        input,
        homeInput,
        options: { client: 'BOF' },
      },
    });

    onClose(result.data?.createUser?.id ?? undefined);
    clearForm();
  };

  const cancel = () => {
    clearForm();
    onClose();
  };

  const form = (
    <form onSubmit={handleSubmit}>
      <Stack spacing={2} mb={2}>
        <TextField
          autoFocus
          label={L.name}
          value={name}
          onChange={evt => setName(evt.target.value)}
          fullWidth
        />
        <TextField
          margin="dense"
          label={L.email}
          type="email"
          value={email}
          onChange={evt => setEmail(evt.target.value)}
          fullWidth
        />
        <TextField
          margin="dense"
          label={L.phone}
          type="phone"
          value={phone}
          onChange={evt => setPhone(evt.target.value)}
          fullWidth
        />
      </Stack>

      <FormControl component="fieldset">
        <RadioGroup
          aria-label="Med eller utan hem"
          name="withHome"
          value={withHome}
          onChange={evt => setWithHome(evt.target.value === 'true')}
          row
        >
          <FormControlLabel
            value
            control={<Radio color="primary" />}
            label="Med bostad"
            labelPlacement="end"
          />
          <FormControlLabel
            value={false}
            control={<Radio color="primary" />}
            label="Utan bostad"
            labelPlacement="end"
          />
        </RadioGroup>
      </FormControl>

      {withHome && (
        <FormControl component="fieldset" margin="normal">
          <RadioGroup
            aria-label="Välj bostadstyp"
            name="tenure"
            value={tenure}
            onChange={evt => setTenure(evt.target.value as TenureType)}
            row
          >
            <FormControlLabel
              value={TenureType.GenericHouse}
              control={<Radio color="primary" />}
              label={L.enumerations.Tenure[TenureType.GenericHouse]}
              labelPlacement="end"
              disabled={!withHome}
            />
            <FormControlLabel
              value={TenureType.HousingCooperative}
              control={<Radio color="primary" />}
              label={L.enumerations.Tenure[TenureType.HousingCooperative]}
              labelPlacement="end"
              disabled={!withHome}
            />
            <FormControlLabel
              value={TenureType.ShareInHousingAssociation}
              control={<Radio color="primary" />}
              label={
                L.enumerations.Tenure[TenureType.ShareInHousingAssociation]
              }
              labelPlacement="end"
              disabled={!withHome}
            />
            <FormControlLabel
              value={TenureType.Coownership}
              control={<Radio color="primary" />}
              label={L.enumerations.Tenure[TenureType.Coownership]}
              labelPlacement="end"
              disabled={!withHome}
            />
            <FormControlLabel
              value={TenureType.Condominium}
              control={<Radio color="primary" />}
              label={L.enumerations.Tenure[TenureType.Condominium]}
              labelPlacement="end"
              disabled={!withHome}
            />
            <FormControlLabel
              value={TenureType.Cottage}
              control={<Radio color="primary" />}
              label={L.enumerations.Tenure[TenureType.Cottage]}
              labelPlacement="end"
              disabled={!withHome}
            />
            <FormControlLabel
              value={TenureType.Project}
              control={<Radio color="primary" />}
              label={L.enumerations.Tenure[TenureType.Project]}
              labelPlacement="end"
              disabled={!withHome}
            />
          </RadioGroup>
        </FormControl>
      )}
    </form>
  );

  let wrappedContent = null;
  if (asDialog) {
    wrappedContent = (
      <Dialog
        open={open}
        onClose={() => onClose()}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle>{L.createNewUser.title}</DialogTitle>
        <DialogContent>{form}</DialogContent>

        <DialogActions>
          <Button onClick={cancel} color="primary">
            {L.cancel}
          </Button>

          <Button onClick={handleSubmit} color="primary">
            {L.create}
          </Button>
        </DialogActions>
      </Dialog>
    );
  } else if (open) {
    wrappedContent = (
      <>
        {form}
        <Box mt={2}>
          <Button variant="contained" onClick={handleSubmit} color="primary">
            {L.create}
          </Button>
        </Box>
      </>
    );
  }

  return (
    <>
      {wrappedContent}
      <Snackbar
        open={snackbarOpen}
        onClose={() => showSnackbar(false)}
        autoHideDuration={5000}
      >
        <Alert variant="filled" severity={snackbarServerity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default CreateUserForm;
