import LaunchIcon from '@mui/icons-material/Launch';
import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { sv as locale } from '@norban/locale';
import React from 'react';

import SpinnerRow from '../../../components/SpinnerRow';
import { ChatOwnership } from '../../../generated/backend/graphql';
import { tableRowHoverColor } from '../../../theme';

type Props = {
  admins: Array<{ id?: string | null; name?: string | null }> | undefined;
  chatOwners: Array<ChatOwnership> | undefined;
  loading: boolean;
  onRowClick: (
    userId: string | undefined | null,
    ownerId: string | undefined | null,
  ) => void;
};

const useStyles = makeStyles((theme: Theme) => ({
  row: {
    [theme.breakpoints.up('md')]: {
      '&:hover': {
        backgroundColor: tableRowHoverColor,
      },
    },
    cursor: 'pointer',
  },
  hiddenMobile: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  mobileRow: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
    border: 0,
    cursor: 'pointer',
    textDecoration: 'none',
    padding: '16px 16px 0',
  },
  mobileCell: {
    borderBottom: 0,
  },
}));

const ChatTable = ({ admins, chatOwners, loading, onRowClick }: Props) => {
  const L = locale.backoffice.chats;
  const classes = useStyles();

  return (
    <Paper>
      <Table className="Chats-table" size="small" aria-label="Chattabell">
        <TableHead>
          <TableRow>
            <TableCell>{L.user}</TableCell>
            <TableCell>homeId</TableCell>
            <TableCell className={classes.hiddenMobile}>
              {L.streetAddress}
            </TableCell>
            <TableCell className={classes.hiddenMobile}>{L.name}</TableCell>
            <TableCell>{L.link}</TableCell>
            <TableCell className={classes.hiddenMobile}>{L.owner}</TableCell>
            <TableCell>{L.handled}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {loading && (
            <TableRow>
              <TableCell colSpan={7}>
                <SpinnerRow />
              </TableCell>
            </TableRow>
          )}
          {chatOwners?.map(cs => {
            const ownerExist = !!cs.ownerId;
            const ownerName = ownerExist
              ? admins?.find(admin => admin.id === cs.ownerId)?.name
              : '';
            const owner = ownerName
              ? `${ownerName} (${cs.ownerId})`
              : cs.ownerId;
            return (
              <React.Fragment key={cs.userId}>
                {/* Mobile */}
                <TableRow
                  className={classes.mobileRow}
                  onClick={() => onRowClick(cs.userId, cs.ownerId)}
                >
                  <TableCell colSpan={4} className={classes.mobileCell}>
                    <div className="Chats-mobileContent">
                      {cs.streetAddress}
                      <br />
                      {cs.name}
                      <br />
                      {owner}
                    </div>
                  </TableCell>
                </TableRow>
                {/* Mobile & Desktop */}
                <TableRow
                  className={classes.row}
                  onClick={() => onRowClick(cs.userId, cs.ownerId)}
                >
                  <TableCell className="Chats-cell">{cs.userId}</TableCell>
                  <TableCell>{cs.homeId || '-'}</TableCell>
                  <TableCell className={classes.hiddenMobile}>
                    {cs.streetAddress}
                  </TableCell>
                  <TableCell className={classes.hiddenMobile}>
                    {cs.name}
                  </TableCell>
                  <TableCell className="Chats-cell">
                    <IconButton
                      aria-label="go to user"
                      onClick={evt => {
                        evt.preventDefault();
                        evt.stopPropagation();
                        window.open(
                          `/users/${cs.userId}/chat`,
                          '_blank',
                          'noreferrer',
                        );
                      }}
                      size="large"
                    >
                      <LaunchIcon />
                    </IconButton>
                  </TableCell>
                  <TableCell className={classes.hiddenMobile}>
                    {owner}
                  </TableCell>
                  <TableCell>{cs.handled ? '✔' : ''}</TableCell>
                </TableRow>
              </React.Fragment>
            );
          })}
        </TableBody>
      </Table>
    </Paper>
  );
};

export default ChatTable;
