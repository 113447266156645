import { useMutation, useQuery, gql } from '@apollo/client';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  IconButton,
  Paper,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { sv as locale } from '@norban/locale';
import React, { useState } from 'react';

import QueryError from '../../components/QueryError';
import QueryLoading from '../../components/QueryLoading';
import { tableRowHoverColor } from '../../theme';

const useStyles = makeStyles(theme => ({
  fab: {
    position: 'fixed',
    bottom: theme.spacing(4),
    right: theme.spacing(4),
  },
  row: {
    cursor: 'pointer',
    [theme.breakpoints.up('sm')]: {
      '&:hover': {
        backgroundColor: tableRowHoverColor,
      },
    },
  },
}));

const Q_OPEN_MARKET_REDIRECTS = gql`
  query BofOpenMarketRedirects {
    openMarketRedirects {
      id
      identifier
      url
    }
  }
`;

const M_CREATE_OPEN_MARKET_REDIRECT = gql`
  mutation BofCreateOpenMarketRedirect($redirect: OpenMarketRedirectInput!) {
    createOpenMarketRedirect(redirect: $redirect) {
      id
    }
  }
`;

const M_UPDATE_OPEN_MARKET_REDIRECT = gql`
  mutation BofUpdateOpenMarketRedirect(
    $id: ID!
    $redirect: OpenMarketRedirectInput!
  ) {
    updateOpenMarketRedirect(id: $id, redirect: $redirect) {
      id
    }
  }
`;

const M_DELETE_OPEN_MARKET_REDIRECT = gql`
  mutation BofDeleteOpenMarketRedirect($id: ID!) {
    deleteOpenMarketRedirect(id: $id) {
      id
    }
  }
`;

const OpenMarketRedirects = () => {
  const L = locale.backoffice.openMarketRedirects;
  const classes = useStyles();

  const [type, setType] = useState(null);
  const [open, setOpen] = useState(false);
  const [id, setId] = useState(null);
  const [url, setUrl] = useState('');
  const [identifier, setIdentifier] = useState('');
  const [snackbar, setSnackbar] = useState({ open: false, message: '' });

  const { data, loading, error, refetch } = useQuery(Q_OPEN_MARKET_REDIRECTS);

  const [createEntry] = useMutation(M_CREATE_OPEN_MARKET_REDIRECT, {
    onError: () => setSnackbar({ open: true, message: L.failedCreate }),
  });
  const [updateEntry] = useMutation(M_UPDATE_OPEN_MARKET_REDIRECT, {
    onError: () =>
      setSnackbar({
        open: true,
        message: L.failedUpdate,
      }),
  });
  const [deleteEntry] = useMutation(M_DELETE_OPEN_MARKET_REDIRECT, {
    onError: () =>
      setSnackbar({
        open: true,
        message: L.failedDelete,
      }),
  });

  if (loading) {
    return <QueryLoading />;
  }

  if (error) {
    return <QueryError error={error} data={data} />;
  }

  const setDialog = (isOpen, theType, theId, theIdentifier, theUrl) => {
    setOpen(isOpen);
    if (theType !== undefined) setType(theType);
    if (theId !== undefined) setId(theId);
    if (theIdentifier != null) setIdentifier(theIdentifier);
    if (theUrl != null) setUrl(theUrl);
  };

  const submit = async () => {
    if (type === 'add') {
      await createEntry({
        variables: {
          redirect: {
            identifier,
            url,
          },
        },
      });
    } else if (type === 'edit') {
      await updateEntry({
        variables: {
          id,
          redirect: {
            identifier,
            url,
          },
        },
      });
    }
    await refetch();
  };

  const deleteRow = async deleteId => {
    await deleteEntry({
      variables: {
        id: deleteId,
      },
    });
    await refetch();
  };

  const errorIdentifier = !/^OBJ31529_\d{10}$/.exec(identifier);
  const errorUrl = !/^https?:\/\//.exec(url);

  return (
    <>
      <Paper style={{ overflowX: 'auto' }}>
        <Table className="table" aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Id</TableCell>
              <TableCell>{L.identifier}</TableCell>
              <TableCell>URL</TableCell>
              <TableCell>{L.delete}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.openMarketRedirects.map(entry => (
              <TableRow
                className={classes.row}
                key={entry.id}
                onClick={() =>
                  setDialog(true, 'edit', entry.id, entry.identifier, entry.url)
                }
              >
                <TableCell>{entry.id}</TableCell>
                <TableCell>{entry.identifier}</TableCell>
                <TableCell>{entry.url}</TableCell>
                <TableCell>
                  {' '}
                  <IconButton
                    aria-label="delete"
                    onClick={evt => {
                      evt.stopPropagation();
                      deleteRow(entry.id);
                    }}
                    size="large"
                  >
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
      <Fab
        color="primary"
        aria-label="add"
        className={classes.fab}
        onClick={() => setDialog(true, 'add', null, '', '')}
      >
        <AddIcon />
      </Fab>
      <Dialog open={open} onClose={() => setDialog(false)}>
        <DialogTitle>
          {type === 'edit' ? `${L.editTitle}` : `${L.addTitle}`}
        </DialogTitle>
        <DialogContent>
          <TextField
            label={L.identifier}
            value={identifier}
            onChange={evt => setIdentifier(evt.target.value)}
            error={errorIdentifier && identifier?.length > 0}
            helperText={
              errorIdentifier && identifier?.length > 0
                ? L.incorrectIdentifier
                : null
            }
            fullWidth
          />
          <TextField
            label="URL"
            value={url}
            onChange={evt => setUrl(evt.target.value)}
            error={errorUrl && url?.length > 0}
            helperText={errorUrl && url?.length > 0 ? L.incorrectUrl : null}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialog(false)} color="primary">
            {L.cancel}
          </Button>
          <Button
            onClick={() => {
              submit();
              setDialog(false);
            }}
            color="primary"
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackbar.open}
        onClose={() => setSnackbar({ open: false, message: '' })}
        message={snackbar.message}
        autoHideDuration={5000}
      />
    </>
  );
};

export default OpenMarketRedirects;
