import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Card from '@mui/material/Card';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { sv as locale } from '@norban/locale';
import React from 'react';

import { TaskState } from '../../../../generated/backend/graphql';

import TaskStateView from './TaskStateView';

const useStyles = makeStyles({
  taskCard: {
    marginBottom: '16px',
  },

  taskExpanderContent: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    marginLeft: 8,
    marginRight: 8,
  },

  taskExpanderTitle: {
    flexGrow: 1,
  },

  taskContent: {
    display: 'flex',
    flexDirection: 'column',
    background: '#eee',
  },

  taskExpander: {
    background: '#eee',
  },

  chipLocked: {
    background: '#dfdfdf',
    border: '1px solid #dfdfdf',
    borderRadius: '8px',
  },

  chipAvailable: {
    background: '#ffffff',
    color: '#153df1',
    border: '1px solid #153df1',
    borderRadius: '8px',
  },

  chipPending: {
    background: '#d2dafb',
    color: '#153df1',
    border: '1px solid #153df1',
    borderRadius: '8px',
  },

  chipProcessed: {
    background: '#5f7df3',
    color: '#fff',
    border: '1px solid #153df1',
    borderRadius: '8px',
  },

  chipConcluded: {
    background: '#153df1',
    color: '#fff',
    border: '1px solid #153df1',
    borderRadius: '8px',
  },
});

const TaskView = props => {
  const classes = useStyles();
  const { task, submitTaskData, currentTaskState, user } = props;

  const L = locale.backoffice;
  const currentTaskStateName = L.enumerations.TaskState[currentTaskState];
  const taskLocale = L.tasks[task.type] || L.tasks.default;

  const { title } = taskLocale;
  const appTitle = locale.MH.tasks[task.type];

  let chipClass;

  switch (currentTaskState) {
    case TaskState.Locked:
      chipClass = classes.chipLocked;
      break;
    case TaskState.Available:
      chipClass = classes.chipAvailable;
      break;
    case TaskState.Pending:
      chipClass = classes.chipPending;
      break;
    case TaskState.Processed:
      chipClass = classes.chipProcessed;
      break;
    case TaskState.Concluded:
      chipClass = classes.chipConcluded;
      break;
    default:
  }

  return (
    <Card className={classes.taskCard}>
      <Accordion>
        <AccordionSummary
          className={classes.taskExpander}
          expandIcon={<ExpandMoreIcon />}
        >
          <div className={classes.taskExpanderContent}>
            <div className={classes.taskExpanderTitle}>
              <Typography variant="h6">{title || appTitle}</Typography>
              {title && title !== appTitle && (
                <Typography variant="subtitle1">
                  Task title: {appTitle}
                </Typography>
              )}
            </div>
            <Chip
              label={currentTaskStateName}
              className={chipClass}
              variant="outlined"
            />
          </div>
        </AccordionSummary>

        <AccordionDetails className={classes.taskContent}>
          {task.states.slice(0, -1).map(taskState => (
            <TaskStateView
              key={taskState}
              task={task}
              state={taskState}
              current={currentTaskState}
              submitTaskData={submitTaskData}
              user={user}
            />
          ))}
        </AccordionDetails>
      </Accordion>
    </Card>
  );
};

export default TaskView;
