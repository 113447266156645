import { useMemo, useReducer } from 'react';

import { BofHomeProfileHomeFragment } from '../../../generated/backend/graphql';

type KeyValuePair = {
  key: keyof BofHomeProfileHomeFragment;
  value: BofHomeProfileHomeFragment[keyof BofHomeProfileHomeFragment];
};

type ActionType = 'reset' | KeyValuePair;

type StateType = Partial<BofHomeProfileHomeFragment>;

const useModifiedHomeReducer = () => {
  const [modifiedHome, updateModifiedHome] = useReducer(
    (state: StateType, action: ActionType): StateType => {
      if (action === 'reset') {
        return {};
      }

      if (action.key) {
        return { ...state, [action.key]: action.value };
      }

      throw new Error('action must contain a "key" and "value" attribute');
    },
    {},
  );

  const isModified = useMemo(() => {
    return Object.keys(modifiedHome).length > 0;
  }, [modifiedHome]);

  return { modifiedHome, updateModifiedHome, isModified };
};

export type DispatchType = ReturnType<
  typeof useModifiedHomeReducer
>['updateModifiedHome'];

export default useModifiedHomeReducer;
