import { ApolloError, useMutation, useQuery } from '@apollo/client';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Switch,
  TextField,
  useMediaQuery,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/system';
import {
  DataGrid,
  GridColDef,
  GridSortModel,
  gridClasses,
} from '@mui/x-data-grid';
import { sv as locale } from '@norban/locale';
import React, { useCallback, useMemo, useState } from 'react';

import {
  BofFollowerDialogAddLogEntryDocument,
  BofFollowerDialogLogsDocument,
  BofFollowerDialogUpdateFollowerDocument,
  BofHomeFollowersDocument,
  BofHomeFollowersFollowerFragment,
  EventType,
  FollowerUpdatePreference,
} from '../../../generated/backend/graphql';
import usePopupAlert from '../../../hooks/usePopupAlert';
import useSession from '../../../hooks/useSession';

type ContentProps = {
  follower: BofHomeFollowersFollowerFragment;
  onClose: () => void;
};

const L = locale.backoffice;

const useStyles = makeStyles(() => ({
  copyButton: {
    marginLeft: '5px',
  },
}));

const FollowerDialogContent = ({
  follower: originalFollower,
  onClose,
}: ContentProps) => {
  const { PopupAlert, showPopupAlert } = usePopupAlert();
  const classes = useStyles();
  const [newLogMessage, setNewLogMessage] = useState<string>();
  const [showAllLogEntries, setShowAllLogEntries] = useState(false);
  const [modifiedFollower, setModifiedFollower] = useState<
    Partial<BofHomeFollowersFollowerFragment>
  >({});
  const [sortModel, setSortModel] = useState<GridSortModel>([
    { field: 'timestamp', sort: 'desc' },
  ]);
  const { userId: originatorId } = useSession();

  const { data: logsData } = useQuery(BofFollowerDialogLogsDocument, {
    variables: {
      filter: {
        userId: originalFollower.userId,
        relatesToHomeId: showAllLogEntries
          ? undefined
          : originalFollower.homeId,
      },
    },
  });

  const follower = useMemo(
    () => ({
      ...originalFollower,
      ...modifiedFollower,
    }),
    [modifiedFollower, originalFollower],
  );

  const [updateFollower, { loading: updateLoading }] = useMutation(
    BofFollowerDialogUpdateFollowerDocument,
    {
      refetchQueries: [BofHomeFollowersDocument],
    },
  );

  const [addLogEntry, { loading: addLogLoading }] = useMutation(
    BofFollowerDialogAddLogEntryDocument,
    {
      update: cache => {
        cache.evict({
          id: 'ROOT_QUERY',
          fieldName: 'logEntries',
        });
        cache.gc();
      },
    },
  );

  const logColumns: GridColDef[] = [
    {
      field: 'timestamp',
      headerName: L.date,
      width: 80,
      valueFormatter: ({ value }) =>
        new Intl.DateTimeFormat('sv-SE', {
          month: 'short',
          day: 'numeric',
        }).format(value),
      valueGetter: ({ value }) => new Date(value),
    },
    {
      field: 'streetAddress',
      headerName: L.streetAddress,
      width: 140,
      renderCell: ({ row }) => {
        // Fallback to row.home for backwards compatibility
        const home = row.relatesToHome ?? row.home ?? undefined;

        return home ? (
          <a href={`/homes/${home.id}`}>{home.address.streetAddress}</a>
        ) : (
          '-'
        );
      },
    },
    {
      field: 'message',
      flex: 1,
      headerName: L.followerDialog.event,
      minWidth: 200,
    },
    {
      field: 'originator',
      headerName: 'Originator',
      width: 180,
      valueGetter: ({ row }) => (row.originator ? row.originator.name : ''),
    },
  ];

  const handleChange = useCallback(
    (e: SelectChangeEvent<FollowerUpdatePreference | null>) => {
      setModifiedFollower({
        ...modifiedFollower,
        updatePreference:
          e.target.value === ''
            ? null
            : (e.target.value as FollowerUpdatePreference),
      });
    },
    [modifiedFollower],
  );

  const handleSave = useCallback(async () => {
    try {
      await updateFollower({
        variables: {
          id: follower?.id ?? '',
          input: {
            isViewingParticipant:
              modifiedFollower.isViewingParticipant ?? undefined,
            updatePreference:
              modifiedFollower.updatePreference !== undefined
                ? modifiedFollower.updatePreference
                : undefined,
          },
        },
      });
      showPopupAlert(L.followerDialog.followerUpdated, 'success');
      onClose();
    } catch (error) {
      const ae = error as ApolloError;
      showPopupAlert(ae.message, 'error');
    }
  }, [
    follower?.id,
    modifiedFollower.isViewingParticipant,
    modifiedFollower.updatePreference,
    onClose,
    showPopupAlert,
    updateFollower,
  ]);

  const handleAddLog = useCallback(async () => {
    try {
      await addLogEntry({
        variables: {
          input: {
            userId: follower?.userId,
            relatesToHomeId: follower?.homeId,
            message: newLogMessage ?? '',
            timestamp: new Date().toISOString(),
            type: EventType.CrmCustomEntry,
            originatorId: `${originatorId}`,
          },
        },
      });
      setNewLogMessage(undefined);
    } catch (error) {
      const ae = error as ApolloError;
      showPopupAlert(ae.message, 'error');
    }
  }, [
    addLogEntry,
    follower?.homeId,
    follower?.userId,
    newLogMessage,
    originatorId,
    showPopupAlert,
  ]);

  if (!follower) {
    return null;
  }

  return (
    <>
      <PopupAlert />

      <DialogTitle variant="subtitle1">
        <a
          href={`/users/${follower.user?.id}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {`${follower.user?.name}, ${follower.user?.email}`}
        </a>
        <IconButton
          aria-label="copy"
          className={classes.copyButton}
          onClick={() => {
            navigator.clipboard.writeText(follower.user?.email ?? '');
          }}
        >
          <ContentCopyIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Grid container gap={2} alignItems="center" mt={1}>
          <Grid item xs={12} md>
            <FormControl fullWidth>
              <InputLabel id="update-preference-select-label">
                {L.followerDialog.category}
              </InputLabel>
              <Select
                labelId="update-preference-select-label"
                value={follower.updatePreference ?? ''}
                label={L.followerDialog.category}
                onChange={handleChange}
              >
                <MenuItem value="">-</MenuItem>
                <MenuItem value={FollowerUpdatePreference.WantsUpdates}>
                  {L.followerCategory[FollowerUpdatePreference.WantsUpdates]}
                </MenuItem>
                <MenuItem value={FollowerUpdatePreference.DeclinesUpdates}>
                  {L.followerCategory[FollowerUpdatePreference.DeclinesUpdates]}
                </MenuItem>
                <MenuItem value={FollowerUpdatePreference.Contacted}>
                  {L.followerCategory[FollowerUpdatePreference.Contacted]}
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md="auto">
            <FormControlLabel
              control={
                <Checkbox
                  checked={follower.isViewingParticipant ?? false}
                  onChange={(_e, value) =>
                    setModifiedFollower({
                      ...modifiedFollower,
                      isViewingParticipant: value,
                    })
                  }
                />
              }
              label="Visningsdeltagare"
            />
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label={L.followerDialog.event}
              value={newLogMessage ?? ''}
              onChange={e => setNewLogMessage(e.target.value)}
            />
          </Grid>
          <Grid item>
            <Button
              disabled={!newLogMessage || addLogLoading}
              sx={{ flexShrink: 0 }}
              variant="contained"
              onClick={handleAddLog}
            >
              {L.followerDialog.addLog}
            </Button>
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Switch
                  value={showAllLogEntries}
                  onChange={() => setShowAllLogEntries(!showAllLogEntries)}
                />
              }
              label={L.followerDialog.showAllLogs}
            />
          </Grid>
          <Grid item xs={12}>
            <DataGrid
              autoHeight
              columns={logColumns}
              disableRowSelectionOnClick
              getRowHeight={() => 'auto'}
              initialState={{
                pagination: { paginationModel: { pageSize: 3 } },
              }}
              pageSizeOptions={[3, 10, 25]}
              rows={logsData?.logEntries ?? []}
              sx={{
                [`& .${gridClasses.cell}`]: {
                  alignItems: 'flex-start',
                  paddingBottom: '10px',
                  paddingTop: '10px',
                },
              }}
              sortModel={sortModel}
              onSortModelChange={setSortModel}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{L.close}</Button>
        <Button
          onClick={handleSave}
          variant="contained"
          disabled={Object.keys(modifiedFollower).length === 0 || updateLoading}
        >
          {L.save}
        </Button>
      </DialogActions>
    </>
  );
};

type Props = {
  open: boolean;
  follower?: BofHomeFollowersFollowerFragment;
  onClose: () => void;
};

const FollowerDialog = ({ open, onClose, follower = undefined }: Props) => {
  const isMobile = useMediaQuery<Theme>(theme => theme.breakpoints.down('sm'));

  if (!follower) {
    return null;
  }

  return (
    <Dialog
      fullScreen={isMobile}
      fullWidth
      maxWidth="md"
      open={open}
      onClose={onClose}
    >
      <FollowerDialogContent follower={follower} onClose={onClose} />
    </Dialog>
  );
};

export default FollowerDialog;
