import cx from 'classnames';
import React, { useMemo } from 'react';
import { useDropzone } from 'react-dropzone';
import './DropZone.css';

type Props = {
  onFileSelected: (file: File) => void;
  loading: boolean;
};

export function DropZone({ onFileSelected, loading }: Props) {
  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
    useDropzone({
      onDropAccepted: files =>
        files[0] ? onFileSelected(files[0]) : undefined,
      multiple: false,
      disabled: loading,
    });

  const className = useMemo(
    () =>
      cx('DropZone-container', {
        focus: isFocused,
        accept: isDragAccept,
        reject: isDragReject,
      }),
    [isDragAccept, isDragReject, isFocused],
  );

  return (
    <div {...getRootProps({})} className={className}>
      <input {...getInputProps()} />
      {loading ? (
        <p>Laddar upp...</p>
      ) : (
        <p>Släpp filer här för att ladda upp</p>
      )}
    </div>
  );
}
