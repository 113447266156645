import { Button, CircularProgress } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  progress: {
    marginLeft: '12px',
  },
}));

type Props = {
  loading: boolean;
  onClick: () => void;
};

const SubmitButton = ({ loading, onClick }: Props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Button
        disabled={loading}
        variant="contained"
        color="primary"
        type="submit"
        onClick={onClick}
      >
        Save
      </Button>
      {loading && <CircularProgress size={24} className={classes.progress} />}
    </div>
  );
};

export default SubmitButton;
