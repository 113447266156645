import { useQuery } from '@apollo/client';
import { Box, Paper, TextField } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { BofAreaListAreasDocument } from '../../generated/backend/graphql';
import {
  AreaListType,
  STORE_FILTER_MODEL,
  STORE_SORT_MODEL,
} from '../../reducers/areaList';

const columns: GridColDef[] = [
  {
    field: 'id',
    headerName: 'Id',
    type: 'string',
  },
  {
    field: 'name',
    headerName: 'Namn',
    type: 'string',
    width: 200,
  },
  {
    field: 'parent',
    headerName: 'Stad',
    type: 'string',
  },
  {
    field: 'homeCount',
    headerName: 'Bostäder',
    type: 'number',
  },
  {
    field: 'agentCount',
    headerName: 'Mäklare',
    type: 'number',
  },
  {
    field: 'associatedAreaCount',
    headerName: 'Syskonområden',
    type: 'number',
    width: 160,
  },
  {
    field: 'public',
    headerName: 'Publik',
    type: 'boolean',
  },
];

const AreaList = () => {
  const [filter, setFilter] = useState('');
  const { data } = useQuery(BofAreaListAreasDocument);
  const dispatch = useDispatch();

  const filterModel = useSelector(
    (state: { areaList: AreaListType }) =>
      state.areaList.filterModel ?? undefined,
  );

  const sortModel = useSelector(
    (state: { areaList: AreaListType }) =>
      state.areaList.sortModel ?? undefined,
  );

  const rows = useMemo(() => {
    if (!data?.areas) {
      return [];
    }

    return data.areas
      .filter(area =>
        filter ? area.name.toLowerCase().includes(filter.toLowerCase()) : area,
      )
      .map(area => ({
        id: area.id,
        name: area.name,
        homeCount: area.homeCount,
        agentCount: area.assignedAdminCount,
        public: area.public,
        parent: area?.parent?.name ?? area.name,
        associatedAreaCount: area.associatedAreas.length,
      }));
  }, [data?.areas, filter]);

  return (
    <Paper>
      <Box p={2}>
        <TextField
          label="Filtrera"
          variant="outlined"
          value={filter}
          onChange={e => setFilter(e.target.value)}
        />
      </Box>
      <DataGrid
        autoHeight
        columns={columns}
        filterModel={filterModel}
        rows={rows}
        sortModel={sortModel}
        onFilterModelChange={filterModel =>
          dispatch({ type: STORE_FILTER_MODEL, value: filterModel })
        }
        onRowClick={row =>
          window.open(`/area/${row.id}`, '_blank', 'noreferrer')
        }
        onSortModelChange={sortModel =>
          dispatch({ type: STORE_SORT_MODEL, value: sortModel })
        }
      />
    </Paper>
  );
};

export default AreaList;
