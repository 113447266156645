import NotificationsIcon from '@mui/icons-material/Notifications';
import {
  Card,
  CardContent,
  CardActions,
  Switch,
  Typography,
  Button,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { sv as locale } from '@norban/locale';
import cx from 'classnames';
import React, { useState } from 'react';

import { TaskType, TaskState } from '../../../../generated/backend/graphql';

import AccessMeetingForm from './forms/AccessMeetingForm';
import AccountCreationForm from './forms/AccountCreationForm';
import BiddingForm from './forms/BiddingForm';
import BookPhotoShootForm from './forms/BookPhotoShootForm';
import BookPhotoShootPendingForm from './forms/BookPhotoShootPendingForm';
import BookValuationMeetingForm from './forms/BookValuationMeetingForm';
import ContractMeetingForm from './forms/ContractMeetingForm';
import DepositForm from './forms/DepositForm';
import DescribeYourHomeForm from './forms/DescribeYourHomeForm';
import GoOpenMarketForm from './forms/GoOpenMarketForm';
import InformationForValuationMeetingForm from './forms/InformationForValuationMeetingForm';
import PhotoShootForm from './forms/PhotoShootForm';
import PublishingDateForm from './forms/PublishingDateForm';
import ReviewPremarketAdForm from './forms/ReviewPremarketAdForm';
import ShowYourHomeForm from './forms/ShowYourHomeForm';
import StagingForm from './forms/StagingForm';
import TagYourHomeForm from './forms/TagYourHomeForm';
import ValuationMeetingForm from './forms/ValuationMeetingForm';
import ValuationMeetingSummaryForm from './forms/ValuationMeetingSummaryForm';
import ViewingsForm from './forms/ViewingsForm';

const taskStateForms = {
  [TaskType.PutHomeOnMap]: {
    [TaskState.Available]: AccountCreationForm,
  },
  [TaskType.TagYourHome]: {
    [TaskState.Available]: TagYourHomeForm,
  },
  [TaskType.DescribeYourHome]: {
    [TaskState.Available]: DescribeYourHomeForm,
  },
  [TaskType.ShowYourHome]: {
    [TaskState.Available]: ShowYourHomeForm,
  },
  [TaskType.BookValuationMeeting]: {
    [TaskState.Available]: BookValuationMeetingForm,
  },
  [TaskType.InformationForValuationMeeting]: {
    [TaskState.Available]: InformationForValuationMeetingForm,
  },
  [TaskType.ValuationMeeting]: {
    [TaskState.Pending]: ValuationMeetingForm,
  },
  [TaskType.ValuationMeetingSummary]: {
    [TaskState.Pending]: ValuationMeetingSummaryForm,
  },
  [TaskType.BookPhotoShoot]: {
    [TaskState.Available]: BookPhotoShootForm,
    [TaskState.Pending]: BookPhotoShootPendingForm,
  },
  [TaskType.Staging]: {
    [TaskState.Pending]: StagingForm,
  },
  [TaskType.PhotoShoot]: {
    [TaskState.Pending]: PhotoShootForm,
  },
  [TaskType.ReviewPremarketAd]: {
    [TaskState.Pending]: ReviewPremarketAdForm,
  },
  [TaskType.GoOpenMarket]: {
    [TaskState.Available]: GoOpenMarketForm,
    [TaskState.Pending]: PublishingDateForm,
  },
  [TaskType.Viewings]: {
    [TaskState.Pending]: ViewingsForm,
  },
  [TaskType.Biddings]: {
    [TaskState.Pending]: BiddingForm,
  },
  [TaskType.ContractMeeting]: {
    [TaskState.Pending]: ContractMeetingForm,
  },
  [TaskType.DepositAndBuyerApproval]: {
    [TaskState.Available]: DepositForm,
  },
  [TaskType.AccessDay]: {
    [TaskState.Pending]: AccessMeetingForm,
  },
};

// Returns object: { title: "...", subtitle: "..." }
const getTaskStateSwitchTitle = (taskType, taskState) => {
  let title;

  try {
    title = locale.backoffice.tasks[taskType].states[taskState].switch;
  } catch (e) {
    // No-op
  }

  return (
    title || locale.backoffice.tasks.default.states[taskState].switch || {}
  );
};

const isSellerTaskState = (type, state) => {
  if (state === TaskState.Available) {
    return true;
  }

  if (type === TaskType.ValuationMeetingSummary) {
    if (state === TaskState.Processed) {
      return true;
    }
  }

  return false;
};

const taskSendsNotification = {
  [TaskType.SignContract]: [TaskState.Pending],
  [TaskType.ReviewPhotos]: [TaskState.Pending],
  [TaskType.ReviewPremarketAd]: [TaskState.Pending],
  [TaskType.GoOpenMarket]: [TaskState.Processed],
  [TaskType.Viewings]: [TaskState.Pending],
  [TaskType.Biddings]: [TaskState.Pending, TaskState.Processed],
  [TaskType.ReadContract]: [TaskState.Pending],
  [TaskType.ContractMeeting]: [TaskState.Pending, TaskState.Processed],
  [TaskType.DepositAndBuyerApproval]: [TaskState.Pending],
  [TaskType.ReviewSettlement]: [TaskState.Pending],
  [TaskType.AccessDay]: [TaskState.Pending, TaskState.Processed],
};

const useStyles = makeStyles({
  taskStateCard: {
    marginBottom: '16px',
  },

  sellerTaskStateCard: {
    background: '#e8e8e8',
  },

  taskStateContent: {
    borderBottom: '1px solid #ddd',
  },

  taskStateActions: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },

  taskStateSwitchTitle: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },

  taskStateContentHeader: {
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 1,
  },
});

const TaskStateFormContainer = props => {
  const classes = useStyles;
  const {
    Component,
    user,
    isActiveState,
    state,
    task,
    submitUser,
    submitting,
    onEditSubmit,
  } = props;

  let title;
  const L = locale.backoffice;

  const { home } = user;

  try {
    title = L.tasks[task.type].states[state].content.title;
  } catch (e) {
    // No-op
  }

  const [editMode, setEditMode] = useState(false);

  const bySeller = isSellerTaskState(task, state);

  return (
    <div>
      <div className={classes.taskStateContentHeader}>
        <Typography variant="h6" gutterBottom style={{ flexGrow: 1 }}>
          {title}
        </Typography>

        {!editMode && !bySeller && !isActiveState && (
          <Button onClick={() => setEditMode(true)}>{L.generic.edit}</Button>
        )}

        {editMode && !bySeller && !isActiveState && (
          <Button
            onClick={evt => {
              evt.preventDefault();
              onEditSubmit();
              setEditMode(false);
            }}
          >
            {L.generic.save}
          </Button>
        )}
      </div>

      <Component
        home={home}
        user={user}
        updateUser={data => submitUser(data)}
        readOnly={!isActiveState && !editMode}
        submitting={submitting}
      />
    </div>
  );
};

const renderTaskStateContent = ({
  task,
  state,
  user,
  isActiveState,
  submitting,
  submitUser,
  onEditSubmit,
}) => {
  const taskStateForm = taskStateForms[task.type]?.[state];

  if (taskStateForm) {
    return (
      <TaskStateFormContainer
        Component={taskStateForm}
        task={task}
        state={state}
        submitting={submitting}
        user={user}
        submitUser={submitUser}
        isActiveState={isActiveState}
        onEditSubmit={onEditSubmit}
      />
    );
  }

  return null;
};

const TaskStateView = props => {
  const classes = useStyles();
  const { task, state, current, submitTaskData, user } = props;

  const [submitting, setSubmitting] = useState(false);

  const bySeller = isSellerTaskState(task.type, state);
  const currentNum = task.states.indexOf(current);
  const taskStateNum = task.states.indexOf(state);
  const nextState = task.states[taskStateNum + 1];

  const checked = currentNum > taskStateNum;
  const disabled = currentNum < taskStateNum || currentNum > taskStateNum + 1;

  const switchTitle = getTaskStateSwitchTitle(task.type, state);
  const switchTitleView = (
    <Typography variant="subtitle2">{switchTitle.title}</Typography>
  );
  const switchSubtitleView = switchTitle.subtitle && (
    <Typography variant="caption" gutterBottom>
      {switchTitle.subtitle}
    </Typography>
  );

  let nextTaskView;

  if (nextState === TaskState.Concluded && task.next) {
    const nextTaskTitles = Object.keys(task.next)
      .filter(t => task.next[t] === TaskState.Available)
      .map(t => locale.MH.tasks[t] || t);

    if (nextTaskTitles.length > 0) {
      nextTaskView = (
        <div>
          <Typography variant="body2" gutterBottom>
            När denna uppgiften är klar kommer nästa steg öppnas automatiskt:
          </Typography>
          <ul>
            {nextTaskTitles.map(title => (
              <li key={title}>
                <Typography variant="body2">{title}</Typography>
              </li>
            ))}
          </ul>
        </div>
      );
    }
  }

  const isActiveState = currentNum === taskStateNum;

  const submitUser = data => {
    submitTaskData(nextState, data);
    setSubmitting(false);
  };

  const onEditSubmit = () => setSubmitting(true);

  const content = renderTaskStateContent({
    task,
    state,
    user,
    isActiveState,
    submitUser,
    submitting,
    onEditSubmit,
  });

  const rootClassName = cx(classes.taskStateCard, {
    [classes.sellerTaskStateCard]: bySeller,
  });

  return (
    <Card className={rootClassName}>
      {!bySeller && content && (
        <CardContent className={classes.taskStateContent}>
          {content}
        </CardContent>
      )}

      <CardActions className={classes.taskStateActions}>
        <div className={classes.taskStateSwitchTitle}>
          {switchTitleView}
          {switchSubtitleView}
          {!bySeller && nextTaskView}
        </div>

        {taskSendsNotification[task.type] &&
          taskSendsNotification[task.type].indexOf(state) >= 0 && (
            <NotificationsIcon />
          )}

        <Switch
          className={classes.bar}
          checked={checked}
          disabled={disabled}
          onChange={(evt, on) => {
            evt.preventDefault();
            if (on) {
              if (content) {
                setSubmitting(true);
              } else {
                submitTaskData(nextState, null);
              }
            } else {
              submitTaskData(state, null);
            }
          }}
        />
      </CardActions>

      {bySeller && content && (
        <CardContent className={classes.taskStateContent}>
          {content}
        </CardContent>
      )}
    </Card>
  );
};

export default TaskStateView;
