import { sv as locale } from '@norban/locale';

import { BofAgreementContractContractFragment } from '../generated/backend/graphql';

const L = locale.backoffice;

export const contractStateCopy = (
  contract: BofAgreementContractContractFragment,
) => {
  if (contract.state === 'CANCELLED') {
    return L.agreementContract.CANCELLED;
  }

  if (contract.signState === 'SIGNED') {
    return L.agreementContract.SIGNED;
  }

  return L.agreementContract[
    contract.state as 'DRAFT' | 'ACTIVATED' | 'FINALIZED' | 'CANCELLED'
  ];
};
