import { Stack, Tabs, Tab } from '@mui/material';
import React from 'react';
import { useHistory } from 'react-router';
import {
  Link,
  Redirect,
  Route,
  Switch,
  useLocation,
  useRouteMatch,
} from 'react-router-dom';

import NewConstructionSettings from '../NewConstruction';

import HomeFacts from './HomeFacts';
import HomeNewConstruction from './HomeNewConstruction';
import HomeSettings from './HomeSettings';
import OwnerUploadedImages from './OwnerUploadedImages';

type Props = {
  homeId: string;
};

export default function HomeListing({ homeId }: Props) {
  const { path, url } = useRouteMatch();
  const { pathname } = useLocation();
  const history = useHistory();

  return (
    <Stack spacing={2}>
      <Tabs value={pathname.split('/')[4]}>
        <Tab component={Link} label="Fakta" to={`${url}/facts`} value="facts" />
        <Tab
          component={Link}
          label="Inställningar"
          to={`${url}/settings`}
          value="settings"
        />
        <Tab
          component={Link}
          label="Bilder"
          to={`${url}/images`}
          value="images"
        />
        <Tab
          component={Link}
          label="Nyproduktion"
          to={`${url}/new-construction`}
          value="new-construction"
        />
      </Tabs>
      <Switch>
        <Route path={`${path}/settings`} exact>
          <HomeSettings homeId={homeId} />
        </Route>
        <Route path={`${path}/facts`} exact>
          <HomeFacts homeId={homeId} />
        </Route>
        <Route path={`${path}/images`} exact>
          <OwnerUploadedImages homeId={homeId} />
        </Route>
        <Route path={`${path}/new-construction`} exact>
          <HomeNewConstruction basePath={url} homeId={homeId} />
        </Route>
        <Route path={`${path}/newConstruction/`}>
          <NewConstructionSettings
            homeId={homeId}
            onClose={() => {
              history.replace(url);
            }}
          />
        </Route>
        <Route>
          <Redirect to={`${url}/facts`} />
        </Route>
      </Switch>
    </Stack>
  );
}
