import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Link,
} from '@mui/material';
import { sv as locale } from '@norban/locale';
import React from 'react';

import BumpHomeButton from '../../../../components/BumpHomeButton';
import { BofHomeProfileHomeFragment } from '../../../../generated/backend/graphql';
import { DispatchType } from '../../hooks/useHomeProfileReducer';

type Props = {
  home: BofHomeProfileHomeFragment;
  dispatch: DispatchType;
  homeId: string;
  adPreviewUrl: string;
};

const ListingCard = ({ home, homeId, adPreviewUrl, dispatch }: Props) => {
  const L = locale.backoffice.homeProfileListingCard;
  const { photosApproved, adApproved } = home;

  return (
    <Box>
      <Card>
        <CardHeader title={L.title} />
        <CardContent>
          <Box mb={0.5}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={!!photosApproved}
                  value={!!photosApproved}
                  onChange={evt =>
                    dispatch({
                      key: 'photosApproved',
                      value: evt.target.checked,
                    })
                  }
                  color="primary"
                />
              }
              label={L.photosApproved}
            />
          </Box>

          <Box mb={0.5}>
            <FormControlLabel
              control={
                <Checkbox
                  value={!!adApproved}
                  checked={!!adApproved}
                  onChange={evt =>
                    dispatch({ key: 'adApproved', value: evt.target.checked })
                  }
                  color="primary"
                />
              }
              label={L.adApproved}
            />
          </Box>

          <Box mb={0.5}>
            <FormHelperText>Ad preview URL</FormHelperText>
          </Box>
          <Box mb={0.5}>
            <Link target="_blank" href={adPreviewUrl} underline="hover">
              {adPreviewUrl}
            </Link>
          </Box>
        </CardContent>
        <CardActions>
          <BumpHomeButton homeId={homeId} />
        </CardActions>
      </Card>
    </Box>
  );
};

export default ListingCard;
