import { Box, Button, Menu, MenuItem } from '@mui/material';
import Switch from '@mui/material/Switch';
import { makeStyles } from '@mui/styles';
import {
  startOfMonth,
  addMonths,
  format,
  subMonths,
  subWeeks,
  addWeeks,
  startOfWeek,
} from 'date-fns';
import React, { useRef, useState } from 'react';

import DateSelector from '../../../components/DateSelector';

const START_OF_NORBAN = new Date('2019-07-01');

const StatsDateSelector = ({
  startDate,
  endDate,
  accumulated,
  onChangeCombinedDates,
  onChangeStartDate,
  onChangeEndDate,
  onChangeAccumulated,
}) => {
  const [showMonthMenu, setShowMonthMenu] = useState(false);
  const [showWeekMenu, setShowWeekMenu] = useState(false);
  const setMonth = diff => {
    const month = addMonths(new Date(), diff);
    onChangeCombinedDates(
      startOfMonth(month),
      startOfMonth(addMonths(month, 1)),
    );
  };
  const setWeek = diff => {
    const week = addWeeks(new Date(), diff);
    onChangeCombinedDates(
      startOfWeek(week, { weekStartsOn: 1 }),
      startOfWeek(addWeeks(week, 1), { weekStartsOn: 1 }),
    );
  };
  const dateAnchorMonthRef = useRef(null);
  const dateAnchorWeekRef = useRef(null);

  const useStyles = makeStyles(theme => ({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      margin: '8px',
    },
    item: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
  }));
  const classes = useStyles();
  return (
    <>
      <div className={classes.root}>
        <DateSelector
          startDate={startDate}
          endDate={endDate}
          startLabel="Start date"
          endLabel="End date"
          onChangeStartDate={onChangeStartDate}
          onChangeEndDate={onChangeEndDate}
        />
      </div>
      <Box ml={1}>
        <Button
          className={classes.item}
          variant="contained"
          color="primary"
          ref={dateAnchorMonthRef}
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={() => setShowMonthMenu(true)}
        >
          Månad
        </Button>
        <Menu
          className={classes.item}
          anchorEl={dateAnchorMonthRef.current}
          keepMounted
          open={showMonthMenu}
          onClose={() => setShowMonthMenu(false)}
        >
          {Array(12)
            .fill()
            .map((_, month) => (
              <MenuItem
                key={month}
                onClick={() => setMonth(-month) && setShowMonthMenu(false)}
              >
                {format(subMonths(new Date(), month), 'MMM')}
              </MenuItem>
            ))}
        </Menu>
        <Button
          className={classes.item}
          variant="contained"
          color="primary"
          ref={dateAnchorWeekRef}
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={() => setShowWeekMenu(true)}
        >
          Vecka
        </Button>
        <Menu
          className={classes.item}
          anchorEl={dateAnchorWeekRef.current}
          keepMounted
          open={showWeekMenu}
          onClose={() => setShowWeekMenu(false)}
        >
          {Array(12)
            .fill()
            .map((_, week) => (
              <MenuItem
                key={week}
                onClick={() => setWeek(-week) && setShowWeekMenu(false)}
              >
                {format(subWeeks(new Date(), week), 'w')}
              </MenuItem>
            ))}
        </Menu>
        <span className={classes.item}>
          Akumulerat{' '}
          <Switch
            inputProps={{ 'aria-label': 'primary checkbox' }}
            onChange={() => onChangeAccumulated(!accumulated)}
          />
        </span>
        <Button
          className={classes.item}
          variant="contained"
          color="primary"
          onClick={() => {
            onChangeStartDate(START_OF_NORBAN);
            onChangeEndDate(new Date());
          }}
        >
          Återställ
        </Button>
      </Box>
    </>
  );
};

export default StatsDateSelector;
