import { Alert, AlertColor, Snackbar } from '@mui/material';
import React, { useCallback, useState } from 'react';

type PopupAlert = {
  open: boolean;
  message: string;
  severity: AlertColor;
};

const usePopupAlert = () => {
  const [popup, setPopup] = useState<PopupAlert>({
    open: false,
    message: '',
    severity: 'error',
  });

  const PopupAlert = useCallback(
    () => (
      <Snackbar
        open={popup.open}
        onClose={() => setPopup({ ...popup, open: false })}
        autoHideDuration={5000}
      >
        <Alert variant="filled" severity={popup.severity}>
          {popup.message}
        </Alert>
      </Snackbar>
    ),
    [popup],
  );

  const showPopup = (message: string, severity: 'error' | 'success') => {
    setPopup({
      open: true,
      message,
      severity,
    });
  };

  return { PopupAlert, showPopupAlert: showPopup };
};

export default usePopupAlert;
