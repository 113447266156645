import { useMutation, useQuery } from '@apollo/client';
import AddIcon from '@mui/icons-material/Add';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import DeleteIcon from '@mui/icons-material/Delete';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  FormControlLabel,
  IconButton,
  Paper,
  Stack,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { sv as locale } from '@norban/locale';
import { format, parseISO } from 'date-fns';
import React, { useReducer, useState } from 'react';

import CrmActionDialog from '../../../../components/CrmActionDialog';
import QueryError from '../../../../components/QueryError';
import QueryLoading from '../../../../components/QueryLoading';
import {
  BofAddUserCrmActionDocument,
  BofDeleteUserCrmActionDocument,
  BofUpdateUserCrmActionDocument,
  BofUserCrmActionFragment,
  BofUserCrmActionsDocument,
  CrmActionField,
  SortDirection,
} from '../../../../generated/backend/graphql';
import usePopupAlert from '../../../../hooks/usePopupAlert';
import { tableRowHoverColor } from '../../../../theme';

const L = locale.backoffice;

const mapCRMType = {
  MANUAL: <HowToRegIcon />,
  EVENT: <AutoAwesomeIcon />,
};

const useStyles = makeStyles((theme: Theme) => ({
  row: {
    cursor: 'pointer',
    textDecoration: 'none',
    [theme.breakpoints.up('sm')]: {
      '&:hover': {
        backgroundColor: tableRowHoverColor,
      },
    },
  },
  cell: {
    padding: '4px 16px',
  },
  button: {
    whiteSpace: 'nowrap',
  },
  horizontalScrollPaper: {
    overflowX: 'scroll',
  },
}));

type Props = {
  userId: string;
  homeId?: string;
};

const UserCrmActionView = ({ userId, homeId = undefined }: Props) => {
  const classes = useStyles();

  const { showPopupAlert, PopupAlert } = usePopupAlert();

  const [{ open: editDialogOpen, entry: editDialogEntry }, setDialogEntry] =
    useReducer(
      (
        oldState: { open: boolean; entry?: BofUserCrmActionFragment },
        selectedEntry: BofUserCrmActionFragment | undefined,
      ) =>
        selectedEntry === undefined
          ? { open: false, entry: oldState.entry }
          : { open: true, entry: selectedEntry },
      { open: false, entry: undefined },
    );

  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [includeCompleted, setIncludeCompleted] = useState(false);

  const { loading, error, data, refetch } = useQuery(
    BofUserCrmActionsDocument,
    {
      variables: {
        filter: {
          homeId,
          userId,
          completed: includeCompleted ? undefined : false,
          orderBy: {
            field: CrmActionField.TargetDate,
            direction: SortDirection.Asc,
          },
        },
      },
    },
  );

  const [addCrmAction] = useMutation(BofAddUserCrmActionDocument, {
    onCompleted: () => refetch(),
  });
  const [deleteCrmAction] = useMutation(BofDeleteUserCrmActionDocument, {
    onCompleted: () => refetch(),
  });
  const [updateCrmAction] = useMutation(BofUpdateUserCrmActionDocument, {
    onCompleted: () => refetch(),
  });

  if (loading) {
    return <QueryLoading />;
  }

  if (error) {
    return <QueryError error={error} data={data} />;
  }

  return (
    <>
      <PopupAlert />
      <CrmActionDialog
        open={editDialogOpen}
        entry={editDialogEntry}
        onSubmit={async updatedEntry => {
          if (!editDialogEntry) {
            return;
          }

          updateCrmAction({
            variables: {
              id: editDialogEntry.id,
              input: {
                action: updatedEntry.action,
                targetDate: updatedEntry.targetDate,
                assignedUserId:
                  updatedEntry.assignedUser === null
                    ? null
                    : updatedEntry.assignedUser?.id,
                completed: updatedEntry.completed,
              },
            },
          });
        }}
        onClose={() => {
          setDialogEntry(undefined);
        }}
      />
      <CrmActionDialog
        open={createDialogOpen}
        onSubmit={async newEntry => {
          await addCrmAction({
            variables: {
              input: {
                userId,
                action: newEntry.action ?? '',
                targetDate: newEntry.targetDate ?? new Date().toISOString(),
                assignedUserId: newEntry.assignedUser?.id ?? null,
                completed: newEntry.completed,
              },
            },
          });
        }}
        onClose={() => {
          setCreateDialogOpen(false);
        }}
      />
      <Card>
        <CardHeader title={L.crmActionView.crms} />
        <CardContent>
          <Stack alignItems="center" direction="row" spacing={2}>
            <Button
              color="secondary"
              size="small"
              startIcon={<AddIcon />}
              variant="contained"
              onClick={() => {
                setCreateDialogOpen(true);
              }}
            >
              {L.add}
            </Button>
            <FormControlLabel
              control={
                <Switch
                  checked={includeCompleted}
                  onChange={(_evt, checked) => setIncludeCompleted(checked)}
                />
              }
              label={L.crmActionView.showHandled}
            />
          </Stack>
        </CardContent>
        <Paper className={classes.horizontalScrollPaper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{L.crmActionView.tableHeaders.id}</TableCell>
                <TableCell>{L.crmActionView.tableHeaders.type}</TableCell>
                <TableCell>{L.crmActionView.tableHeaders.action}</TableCell>
                <TableCell>{L.crmActionView.tableHeaders.target}</TableCell>
                <TableCell>{L.crmActionView.tableHeaders.assignedTo}</TableCell>
                <TableCell>{L.crmActionView.tableHeaders.handled}</TableCell>
                <TableCell>{L.crmActionView.tableHeaders.delete}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.crmActions.map(entry => (
                <TableRow
                  className={classes.row}
                  key={entry.id}
                  onClick={() => setDialogEntry(entry)}
                >
                  <TableCell className={classes.cell}>{entry.id}</TableCell>
                  <TableCell className={classes.cell}>
                    {mapCRMType[entry.type] ?? ''}
                  </TableCell>
                  <TableCell className={classes.cell}>{entry.action}</TableCell>
                  <TableCell className={classes.cell}>
                    {format(parseISO(entry.targetDate), 'd MMM, yyyy')}
                  </TableCell>
                  <TableCell className={classes.cell}>
                    {entry.assignedUser?.name ?? '-'}
                  </TableCell>
                  <TableCell className={classes.cell}>
                    <Checkbox
                      checked={entry.completed}
                      onClick={e => e.stopPropagation()}
                      onChange={(_e, checked) => {
                        try {
                          updateCrmAction({
                            variables: {
                              id: entry.id,
                              input: {
                                completed: checked,
                              },
                            },
                          });
                        } catch {
                          showPopupAlert(L.errors.general, 'error');
                        }
                      }}
                    />
                  </TableCell>
                  <TableCell className={classes.cell}>
                    <IconButton
                      aria-label="delete"
                      onClick={async evt => {
                        evt.stopPropagation();
                        try {
                          await deleteCrmAction({
                            variables: { id: entry.id },
                          });
                        } catch {
                          showPopupAlert(L.errors.general, 'error');
                        }
                      }}
                      size="large"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
      </Card>
    </>
  );
};

export default UserCrmActionView;
