import { useMutation } from '@apollo/client';
import { Button, Card, CardContent, CardHeader } from '@mui/material';
import { sv as locale } from '@norban/locale';
import React, { useCallback, useMemo, useState } from 'react';

import { BofSyncImagesDocument } from '../../../../generated/backend/graphql';

type Props = {
  homeId: string;
  onError: () => void;
};

const SyncImagesCard = ({ homeId, onError }: Props) => {
  const L = locale.backoffice.syncVitecImages;

  const [syncVitecImages] = useMutation(BofSyncImagesDocument, {
    onError,
    variables: {
      homeId,
    },
  });

  const [state, setState] = useState<'init' | 'syncing' | 'done' | 'error'>(
    'init',
  );

  const handleSyncImages = useCallback(async () => {
    setState('syncing');

    try {
      await syncVitecImages();
    } catch (e) {
      setState('error');
      return;
    }

    setState('done');
  }, [syncVitecImages]);

  const content = useMemo(() => {
    switch (state) {
      case 'init':
        return 'Tryck på knappen nedan för att synkronisera bilder';
      case 'syncing':
        return `Synkroniserar bilder`;
      case 'done':
        return 'Synkronisering klar';
      case 'error':
        return 'Något gick fel';
      default:
        return '';
    }
  }, [state]);

  return (
    <Card>
      <CardHeader title={L.title} />
      <CardContent>{content}</CardContent>
      <CardContent>
        <Button
          variant="contained"
          color="primary"
          type="button"
          onClick={() => handleSyncImages()}
        >
          {L.startSync}
        </Button>
      </CardContent>
    </Card>
  );
};

export default SyncImagesCard;
