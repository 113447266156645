import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import HomeIcon from '@mui/icons-material/Home';
import LabelIcon from '@mui/icons-material/Label';
import LinkOutlinedIcon from '@mui/icons-material/LinkOutlined';
import LocalActivityOutlinedIcon from '@mui/icons-material/LocalActivityOutlined';
import PersonIcon from '@mui/icons-material/Person';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import {
  Checkbox,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { sv as locale } from '@norban/locale';
import React from 'react';

import { appBarHeight } from '../../../theme';

const L = locale.backoffice.userList;

const useStyles = makeStyles(theme => ({
  header: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  'header-text': {
    marginLeft: 8,
    [theme.breakpoints.down('md')]: {
      marginLeft: 0,
      fontSize: 10,
    },
  },
  hiddenMobile: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  th: {
    background: 'white',
    position: 'sticky',
    top: appBarHeight,
    zIndex: 1,
    [theme.breakpoints.down('sm')]: {
      top: 56,
    },
  },
  thHighlight: {
    backgroundColor: '#eee',
    textWeight: '900',
    textDecoration: 'underline',
  },
}));

const UserTableHead = ({
  onClick,
  order,
  orderBy,
  checkbox: { toggleAll, clearAll, isAllChecked },
}) => {
  const classes = useStyles();
  const categories = [
    {
      key: 'id',
      content: (
        <div className={classes.header}>
          <FingerprintIcon />
          <span className={classes['header-text']}>{L.id}</span>
        </div>
      ),
    },
    {
      key: 'user',
      className: classes.hiddenMobile,
      content: (
        <div className={classes.header}>
          <PersonIcon />
          <span className={classes['header-text']}>{L.user}</span>
        </div>
      ),
    },
    {
      key: 'address',
      className: classes.hiddenMobile,
      content: (
        <div className={classes.header}>
          <HomeIcon />
          <span className={classes['header-text']}>{L.object}</span>
        </div>
      ),
    },
    {
      key: 'link',
      className: classes.hiddenMobile,
      content: (
        <div className={classes.header}>
          <LinkOutlinedIcon />
          <span className={classes['header-text']}>{L.link}</span>
        </div>
      ),
    },
    {
      key: 'agent',
      content: (
        <div className={classes.header}>
          <SupportAgentIcon />
          <span className={classes['header-text']}>{L.agent}</span>
        </div>
      ),
    },
    {
      key: 'label',
      content: (
        <div className={classes.header}>
          <LabelIcon />
          <span className={classes['header-text']}>{L.label}</span>
        </div>
      ),
    },
    {
      key: 'action',
      content: (
        <div className={classes.header}>
          <AssignmentTurnedInIcon />
          <span className={classes['header-text']}>{L.crm}</span>
        </div>
      ),
    },
    {
      key: 'activityScore',
      content: (
        <div className={classes.header}>
          <LocalActivityOutlinedIcon />
          <span className={classes['header-text']}>{L.ap}</span>
        </div>
      ),
    },
  ];

  return (
    <TableHead>
      <TableRow>
        <TableCell className={classes.th}>
          <Checkbox
            checked={isAllChecked()}
            onChange={e => {
              if (e.target.checked) {
                toggleAll();
              } else {
                clearAll();
              }
            }}
          />
        </TableCell>
        {categories.map(category => (
          <TableCell
            key={category.key}
            className={`${category.className} ${classes.th} ${
              orderBy === category.key ? classes.thHighlight : ''
            }`}
          >
            <TableSortLabel
              onClick={() => onClick(category.key)}
              active={orderBy === category.key}
              direction={order}
            >
              {category.content}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default UserTableHead;
