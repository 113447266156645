import React from 'react';

import Field, { PassthroughProps } from './Field';

const DateField = <N extends string>(props: PassthroughProps<N, string>) => (
  <Field
    {...props}
    type="date"
    onEncode={value => {
      try {
        return new Date(value ?? '').toISOString().slice(0, 10);
      } catch (e) {
        return '';
      }
    }}
    onDecode={value => {
      try {
        return new Date(value).toISOString();
      } catch (e) {
        return null;
      }
    }}
  />
);

export default DateField;
