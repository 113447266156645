import { Stack } from '@mui/material';
import React from 'react';

import HomeActivityLogCard from './HomeActivityLogCard';
import HomeCrmActionCard from './HomeCrmActionCard';

type Props = {
  homeId: string;
  userId?: string;
};

export default function HomeCrmAndLog({ homeId, userId = undefined }: Props) {
  return (
    <Stack spacing={3}>
      <HomeCrmActionCard homeId={homeId} userId={userId} />
      <HomeActivityLogCard homeId={homeId} />
    </Stack>
  );
}
