import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { sv as locale } from '@norban/locale';
import React, { useEffect, useState } from 'react';

import AreaSelect from '../../../../../components/form/AreaSelect';

const NewConstructionSettingsEditDialog = ({
  open,
  rootAreas,
  entry,
  onClose,
  onSubmit,
}) => {
  const L = locale.backoffice;

  const [name, setName] = useState('');
  const [state, setState] = useState('');
  const [tenure, setTenure] = useState('HOUSING_COOPERATIVE');
  const [type, setType] = useState('APARTMENT');
  const [homeState, setHomeState] = useState('PREMARKET');
  const [streetAddress, setStreetAddress] = useState('');
  const [city, setCity] = useState('');
  const [areaId, setAreaId] = useState(null);
  const [askingPrice, setAskingPrice] = useState(null);
  const [livingArea, setLivingArea] = useState(null);
  const [numberOfRooms, setNumberOfRooms] = useState(null);
  const [floor, setFloor] = useState(null);
  const [monthlyFee, setMonthlyFee] = useState(null);
  const [plotArea, setPlotArea] = useState(null);
  const [
    newConstructionEstCompletionDate,
    setNewConstructionEstCompletionDate,
  ] = useState(null);
  const [vitecEstateId, setVitecEstateId] = useState('');
  const [adHideArea, setAdHideArea] = useState(false);

  useEffect(() => {
    [
      { name: 'name', dispatch: setName },
      { name: 'state', dispatch: setState },
    ].forEach(state => {
      if (entry?.[state.name]) {
        state.dispatch(entry?.[state.name]);
      }
    });
    [
      { name: 'tenure', dispatch: setTenure },
      { name: 'type', dispatch: setType },
      { name: 'areaId', dispatch: setAreaId },
      { name: 'askingPrice', dispatch: setAskingPrice },
      { name: 'livingArea', dispatch: setLivingArea },
      { name: 'numberOfRooms', dispatch: setNumberOfRooms },
      { name: 'floor', dispatch: setFloor },
      { name: 'monthlyFee', dispatch: setMonthlyFee },
      {
        name: 'newConstructionEstCompletionDate',
        dispatch: setNewConstructionEstCompletionDate,
      },
      { name: 'vitecEstateId', dispatch: setVitecEstateId },
      { name: 'adHideArea', dispatch: setAdHideArea },
    ].forEach(state => {
      if (entry?.home?.[state.name]) {
        state.dispatch(entry?.home?.[state.name]);
      }
    });
    [
      { name: 'streetAddress', dispatch: setStreetAddress },
      { name: 'city', dispatch: setCity },
    ].forEach(state => {
      if (entry?.home?.address?.[state.name]) {
        state.dispatch(entry.home.address[state.name]);
      }
    });
    if (entry?.home?.state) {
      setHomeState(entry.home.state);
    }
    if (entry?.home?.plot?.area) {
      setPlotArea(entry.home.plot.area);
    }
  }, [entry]);

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">{L.editTitle}</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label={L.newConstructionSettings.name}
          fullWidth
          value={name}
          onChange={evt => setName(evt.target.value)}
        />
        <TextField
          margin="dense"
          label={L.newConstructionSettings.state}
          fullWidth
          value={state}
          onChange={evt => setState(evt.target.value)}
        />
        <Box mb={2}>
          <Select
            value={tenure || 'default'}
            onChange={evt => setTenure(evt.target.value)}
          >
            {Object.keys(L.enumerations.Tenure).map(tenure => (
              <MenuItem key={tenure} value={tenure}>
                {L.enumerations.Tenure[tenure]}
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Box mb={2}>
          <Select
            value={type || 'default'}
            onChange={evt => setType(evt.target.value)}
          >
            {Object.keys(L.enumerations.HomeType).map(type => (
              <MenuItem key={type} value={type}>
                {L.enumerations.HomeType[type]}
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Box>
          <Select
            value={homeState || 'default'}
            onChange={evt => setHomeState(evt.target.value)}
          >
            {Object.keys(L.enumerations.HomeState).map(type => (
              <MenuItem key={type} value={type}>
                {L.enumerations.HomeState[type]}
              </MenuItem>
            ))}
          </Select>
        </Box>
        <TextField
          margin="dense"
          label={L.newConstructionSettings.streetAddress}
          fullWidth
          value={streetAddress}
          onChange={evt => setStreetAddress(evt.target.value)}
        />
        <TextField
          margin="dense"
          label={L.newConstructionSettings.city}
          fullWidth
          value={city}
          onChange={evt => setCity(evt.target.value)}
        />
        <AreaSelect
          label={L.newConstructionSettings.area}
          value={areaId}
          areas={rootAreas}
          name="areaId"
          dispatch={({ value }) => setAreaId(value)}
          variant="standard"
        />
        <TextField
          margin="dense"
          label={L.newConstructionSettings.askingPrice}
          fullWidth
          type="number"
          value={askingPrice != null ? askingPrice : ''}
          onChange={evt =>
            setAskingPrice(parseInt(evt.target.value, 10) || null)
          }
        />
        <TextField
          margin="dense"
          label={L.newConstructionSettings.livingArea}
          fullWidth
          type="number"
          value={livingArea != null ? livingArea : ''}
          onChange={evt => {
            setLivingArea(parseFloat(evt.target.value) || null);
          }}
        />
        <TextField
          margin="dense"
          label={L.newConstructionSettings.numberOfRooms}
          fullWidth
          type="number"
          value={numberOfRooms != null ? numberOfRooms : ''}
          onChange={evt => {
            const nor = parseFloat(evt.target.value);
            const truncNor = nor != null && nor < 1 ? 1 : nor;
            setNumberOfRooms(truncNor != null ? truncNor : null);
          }}
        />
        <TextField
          margin="dense"
          label={L.newConstructionSettings.floor}
          fullWidth
          type="number"
          value={floor != null ? floor : ''}
          onChange={evt => {
            const floor = parseFloat(evt.target.value);
            const truncFloor = floor != null && floor < 0 ? 0 : floor;
            setFloor(truncFloor != null ? truncFloor : null);
          }}
        />
        <TextField
          margin="dense"
          label={L.newConstructionSettings.monthlyFee}
          fullWidth
          type="number"
          value={monthlyFee != null ? monthlyFee : ''}
          onChange={evt => setMonthlyFee(parseFloat(evt.target.value) || null)}
        />
        <TextField
          margin="dense"
          label={L.newConstructionSettings.plotArea}
          fullWidth
          type="number"
          value={plotArea != null ? plotArea : ''}
          onChange={evt => setPlotArea(parseFloat(evt.target.value) || null)}
        />
        <DateTimePicker
          clearable="true"
          ampm={false}
          format="yyyy-MM-dd HH:mm"
          margin="normal"
          label={L.estCompletionDate}
          value={newConstructionEstCompletionDate}
          onChange={value => setNewConstructionEstCompletionDate(value)}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
          renderInput={props => <TextField {...props} />}
        />
        <TextField
          margin="dense"
          label={L.newConstructionSettings.vitecEstateId}
          fullWidth
          value={vitecEstateId || ''}
          onChange={evt => setVitecEstateId(evt.target.value)}
        />
        <FormControlLabel
          control={
            <Checkbox
              value={adHideArea}
              checked={adHideArea}
              onChange={evt => setAdHideArea(evt.target.checked)}
            />
          }
          label={L.newConstructionSettings.adHideArea}
        />
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} color="primary">
          {L.newConstructionSettings.cancel}
        </Button>
        <Button
          onClick={async () => {
            onSubmit({
              name,
              state,
              tenure,
              type,
              homeState,
              streetAddress,
              city,
              areaId,
              askingPrice,
              livingArea,
              numberOfRooms,
              floor,
              monthlyFee,
              plotArea,
              newConstructionEstCompletionDate,
              vitecEstateId,
              adHideArea,
            });
            onClose();
          }}
          color="primary"
        >
          {L.newConstructionSettings.change}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default NewConstructionSettingsEditDialog;
