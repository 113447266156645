import { Button, Stack } from '@mui/material';
import React from 'react';

const UserListHead = ({ buttons }) => {
  const buttonGroup = buttons?.map(btn => (
    <Button
      variant="contained"
      color="secondary"
      key={btn.text}
      size="small"
      startIcon={btn.icon}
      onClick={btn.onClick}
    >
      {btn.text}
    </Button>
  ));

  return (
    <Stack direction="row" flexWrap="wrap" spacing={2} useFlexGap>
      {buttonGroup}
    </Stack>
  );
};

export default UserListHead;
