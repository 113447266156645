import { RadioGroup, Radio, FormControlLabel } from '@mui/material';
import { sv as locale } from '@norban/locale';
import React, { useEffect } from 'react';

const GoOpenMarketForm = ({ home, readOnly, updateUser, submitting }) => {
  const choices = {
    ASAP: locale.MH.GO_OPEN_MARKET.option1,
    MORE_INFO: locale.MH.GO_OPEN_MARKET.option2,
  };

  useEffect(() => {
    if (submitting) {
      updateUser();
    }
  }, [submitting, updateUser]);

  return (
    <RadioGroup value={home.openMarket.request}>
      {Object.keys(choices).map(key => (
        <FormControlLabel
          disabled={readOnly}
          key={key}
          value={key}
          control={<Radio />}
          label={choices[key]}
        />
      ))}
    </RadioGroup>
  );
};

export default GoOpenMarketForm;
