import { ApolloProvider } from '@apollo/client';
import { StyledEngineProvider, ThemeProvider } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
// import svLocale from 'date-fns/locale/sv';
import { sv as svLocale } from 'date-fns/locale/sv';
import React, { useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter5Adapter } from 'use-query-params/adapters/react-router-5';

import './App.css';
import BaseLayout from './components/BaseLayout';
import { useBackendClient } from './hooks/useBackendClient';
import { theme } from './theme';

const App = () => {
  const client = useBackendClient();

  useEffect(() => {
    // Prevent scroll wheel from interacting with number inputs
    const wheelEventListener = () => {
      if (
        document.activeElement &&
        'type' in document.activeElement &&
        'blur' in document.activeElement &&
        typeof document.activeElement.blur === 'function' &&
        document.activeElement?.type === 'number'
      ) {
        document.activeElement.blur();
      }
    };

    // Stop key up/down from interacting with number inputs
    const keydownEventListener = (event: {
      preventDefault: () => void;
      key: unknown;
    }) => {
      if (
        document.activeElement &&
        'type' in document.activeElement &&
        document.activeElement?.type === 'number'
      ) {
        const { key } = event;
        switch (key) {
          case 'ArrowUp':
          case 'ArrowDown':
            event.preventDefault();
            break;
          default:
        }
      }
    };

    document.addEventListener('wheel', wheelEventListener);
    document.addEventListener('keydown', keydownEventListener);
    return () => {
      document.removeEventListener('wheel', wheelEventListener);
      document.removeEventListener('keydown', keydownEventListener);
    };
  }, []);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <LocalizationProvider
          dateAdapter={AdapterDateFns}
          adapterLocale={svLocale}
        >
          <ApolloProvider client={client}>
            <Router>
              <QueryParamProvider
                adapter={ReactRouter5Adapter}
                options={{
                  removeDefaultsFromUrl: true,
                }}
              >
                <BaseLayout />
              </QueryParamProvider>
            </Router>
          </ApolloProvider>
        </LocalizationProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
