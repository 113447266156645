import { useCallback } from 'react';
import { Dispatch } from 'redux';

import useStateWithDelayedCallback from './useStateWithDelayedCallback';

export const useGlobalStateWithDelayedCallback = <T>(
  initialValue: T | undefined,
  globalDispatch: Dispatch,
  globalAction: (dispatch: Dispatch, value: T | undefined) => void,
  delay: number,
): [T | undefined, (arg: T) => void] => {
  return useStateWithDelayedCallback(
    initialValue,
    useCallback(
      value => globalAction(globalDispatch, value),
      [globalAction, globalDispatch],
    ),
    delay,
  );
};
