enum TaskType {
  AboutPremarket = 'ABOUT_PREMARKET',
  AboutSharingProfile = 'ABOUT_SHARING_PROFILE',
  AccessDay = 'ACCESS_DAY',
  AdIsOut = 'AD_IS_OUT',
  Biddings = 'BIDDINGS',
  BookPhotoShoot = 'BOOK_PHOTO_SHOOT',
  BookValuationMeeting = 'BOOK_VALUATION_MEETING',
  ContractMeeting = 'CONTRACT_MEETING',
  DepositAndBuyerApproval = 'DEPOSIT_AND_BUYER_APPROVAL',
  DescribeYourHome = 'DESCRIBE_YOUR_HOME',
  GoOpenMarket = 'GO_OPEN_MARKET',
  InformationForValuationMeeting = 'INFORMATION_FOR_VALUATION_MEETING',
  ItIsSold = 'IT_IS_SOLD',
  PhotoShoot = 'PHOTO_SHOOT',
  PutHomeOnMap = 'PUT_HOME_ON_MAP',
  ReadContract = 'READ_CONTRACT',
  ReviewPhotos = 'REVIEW_PHOTOS',
  ReviewPremarketAd = 'REVIEW_PREMARKET_AD',
  ReviewSettlement = 'REVIEW_SETTLEMENT',
  ShowYourHome = 'SHOW_YOUR_HOME',
  SignContract = 'SIGN_CONTRACT',
  Staging = 'STAGING',
  StartPreparing = 'START_PREPARING',
  TagYourHome = 'TAG_YOUR_HOME',
  ThinkingOfSelling = 'THINKING_OF_SELLING',
  ValuationMeeting = 'VALUATION_MEETING',
  ValuationMeetingSummary = 'VALUATION_MEETING_SUMMARY',
  Viewings = 'VIEWINGS',
}

enum PhaseType {
  Access = 'ACCESS',
  Contract = 'CONTRACT',
  GetStarted = 'GET_STARTED',
  OnYourWay = 'ON_YOUR_WAY',
  OpenMarket = 'OPEN_MARKET',
  Premarket = 'PREMARKET',
}

export type TaskSpec = {
  type: TaskType;
  phase: PhaseType;
  state: TaskState;
  states: TaskState[];
  isReadingTask?: boolean;
  next?: { [key in TaskType]?: TaskState };
};

enum TaskState {
  Available = 'AVAILABLE',
  Concluded = 'CONCLUDED',
  Locked = 'LOCKED',
  Pending = 'PENDING',
  Processed = 'PROCESSED',
}

const LOCKED = TaskState.Locked;
const AVAILABLE = TaskState.Available;
const PENDING = TaskState.Pending;
const PROCESSED = TaskState.Processed;
const CONCLUDED = TaskState.Concluded;

export const taskStateOrder: TaskState[] = [
  LOCKED,
  AVAILABLE,
  PENDING,
  PROCESSED,
  CONCLUDED,
];

// The key "state" below is the timeline's states' default state
const timelineSpec: TaskSpec[] = [
  {
    // 1.1
    type: TaskType.ThinkingOfSelling,
    phase: PhaseType.GetStarted,
    state: AVAILABLE,
    isReadingTask: true,
    states: [LOCKED, AVAILABLE, CONCLUDED],
  },
  {
    // 1.2
    type: TaskType.PutHomeOnMap,
    phase: PhaseType.GetStarted,
    state: AVAILABLE,
    states: [LOCKED, AVAILABLE, CONCLUDED],
    next: {
      [TaskType.StartPreparing]: AVAILABLE,
      [TaskType.TagYourHome]: AVAILABLE,
      [TaskType.DescribeYourHome]: AVAILABLE,
      [TaskType.ShowYourHome]: AVAILABLE,
      [TaskType.BookValuationMeeting]: AVAILABLE,
    },
  },
  {
    // 2.1
    type: TaskType.StartPreparing,
    phase: PhaseType.OnYourWay,
    state: LOCKED,
    isReadingTask: true,
    states: [LOCKED, AVAILABLE, CONCLUDED],
  },
  {
    // 2.2
    type: TaskType.TagYourHome,
    phase: PhaseType.OnYourWay,
    state: LOCKED,
    states: [LOCKED, AVAILABLE, CONCLUDED],
  },
  {
    // 2.3
    type: TaskType.DescribeYourHome,
    phase: PhaseType.OnYourWay,
    state: LOCKED,
    states: [LOCKED, AVAILABLE, CONCLUDED],
  },
  {
    // 2.4
    type: TaskType.ShowYourHome,
    phase: PhaseType.OnYourWay,
    state: LOCKED,
    states: [LOCKED, AVAILABLE, PENDING, CONCLUDED],
  },
  {
    // 2.5
    // Renamed Valuate Your Home
    type: TaskType.BookValuationMeeting,
    phase: PhaseType.OnYourWay,
    state: LOCKED,
    states: [LOCKED, AVAILABLE, PENDING, CONCLUDED],
    next: {
      [TaskType.InformationForValuationMeeting]: AVAILABLE,
      [TaskType.ValuationMeeting]: PENDING,
    },
  },
  {
    // 2.6
    type: TaskType.InformationForValuationMeeting,
    phase: PhaseType.OnYourWay,
    state: LOCKED,
    states: [LOCKED, AVAILABLE, CONCLUDED],
  },
  {
    // 2.7
    type: TaskType.ValuationMeeting,
    phase: PhaseType.OnYourWay,
    state: LOCKED,
    states: [LOCKED, PENDING, PROCESSED, CONCLUDED],
    next: {
      [TaskType.ValuationMeetingSummary]: PENDING,
      [TaskType.SignContract]: AVAILABLE,
    },
  },
  {
    // 2.8
    type: TaskType.ValuationMeetingSummary,
    phase: PhaseType.OnYourWay,
    state: LOCKED,
    states: [LOCKED, PENDING, PROCESSED, CONCLUDED],
  },
  {
    // 2.9
    type: TaskType.SignContract,
    phase: PhaseType.OnYourWay,
    state: LOCKED,
    states: [LOCKED, AVAILABLE, PENDING, CONCLUDED],
    next: {
      [TaskType.AboutPremarket]: AVAILABLE,
      [TaskType.BookPhotoShoot]: AVAILABLE,
      [TaskType.AboutSharingProfile]: AVAILABLE,
    },
  },
  {
    // 3.1
    type: TaskType.AboutPremarket,
    phase: PhaseType.Premarket,
    isReadingTask: true,
    state: LOCKED,
    states: [LOCKED, AVAILABLE, CONCLUDED],
  },
  {
    // 3.2
    type: TaskType.BookPhotoShoot,
    phase: PhaseType.Premarket,
    state: LOCKED,
    states: [LOCKED, AVAILABLE, PENDING, CONCLUDED],
    // Special case! No 'next' field as the next task depends
    // on if the seller has requested staging or not.
  },
  {
    // 3.3
    type: TaskType.AboutSharingProfile,
    phase: PhaseType.Premarket,
    isReadingTask: true,
    state: LOCKED,
    states: [LOCKED, AVAILABLE, CONCLUDED],
  },
  {
    // 3.4
    type: TaskType.Staging,
    phase: PhaseType.Premarket,
    state: LOCKED,
    states: [LOCKED, PENDING, PROCESSED, CONCLUDED],
    next: {
      [TaskType.PhotoShoot]: PENDING,
    },
  },
  {
    // 3.5
    type: TaskType.PhotoShoot,
    phase: PhaseType.Premarket,
    state: LOCKED,
    states: [LOCKED, PENDING, PROCESSED, CONCLUDED],
    next: {
      [TaskType.ReviewPhotos]: PENDING,
    },
  },
  {
    // 3.6
    type: TaskType.ReviewPhotos,
    phase: PhaseType.Premarket,
    state: LOCKED,
    states: [LOCKED, PENDING, PROCESSED, CONCLUDED],
    next: {
      [TaskType.ReviewPremarketAd]: PENDING,
    },
  },
  {
    // 3.7
    type: TaskType.ReviewPremarketAd,
    phase: PhaseType.Premarket,
    state: LOCKED,
    states: [LOCKED, PENDING, PROCESSED, CONCLUDED],
    next: {
      [TaskType.GoOpenMarket]: AVAILABLE,
    },
  },
  {
    // 3.8
    type: TaskType.GoOpenMarket,
    phase: PhaseType.Premarket,
    state: LOCKED,
    states: [LOCKED, AVAILABLE, PENDING, PROCESSED, CONCLUDED],
    next: {
      [TaskType.AdIsOut]: AVAILABLE,
      [TaskType.Viewings]: PENDING,
    },
  },
  {
    // 4.1
    type: TaskType.AdIsOut,
    phase: PhaseType.OpenMarket,
    isReadingTask: true,
    state: LOCKED,
    states: [LOCKED, AVAILABLE, CONCLUDED],
  },
  {
    // 4.2
    type: TaskType.Viewings,
    phase: PhaseType.OpenMarket,
    state: LOCKED,
    states: [LOCKED, PENDING, PROCESSED, CONCLUDED],
    next: {
      [TaskType.Biddings]: PENDING,
    },
  },
  {
    // 4.3
    type: TaskType.Biddings,
    phase: PhaseType.OpenMarket,
    state: LOCKED,
    states: [LOCKED, PENDING, PROCESSED, CONCLUDED],
    next: {
      [TaskType.ReadContract]: PENDING,
      [TaskType.ContractMeeting]: PENDING,
    },
  },
  {
    // 5.1
    type: TaskType.ReadContract,
    phase: PhaseType.Contract,
    state: LOCKED,
    states: [LOCKED, PENDING, PROCESSED, CONCLUDED],
  },
  {
    // 5.2
    type: TaskType.ContractMeeting,
    phase: PhaseType.Contract,
    state: LOCKED,
    states: [LOCKED, PENDING, PROCESSED, CONCLUDED],
    next: {
      [TaskType.ItIsSold]: AVAILABLE,
      [TaskType.DepositAndBuyerApproval]: AVAILABLE,
    },
  },
  {
    // 6.1
    type: TaskType.ItIsSold,
    phase: PhaseType.Access,
    isReadingTask: true,
    state: LOCKED,
    states: [LOCKED, AVAILABLE, CONCLUDED],
  },
  {
    // 6.2
    type: TaskType.DepositAndBuyerApproval,
    phase: PhaseType.Access,
    state: LOCKED,
    states: [LOCKED, AVAILABLE, PENDING, CONCLUDED],
    next: {
      [TaskType.ReviewSettlement]: PENDING,
      [TaskType.AccessDay]: PENDING,
    },
  },
  {
    // 6.3
    type: TaskType.ReviewSettlement,
    phase: PhaseType.Access,
    state: LOCKED,
    states: [LOCKED, PENDING, PROCESSED, CONCLUDED],
  },
  {
    // 6.4
    type: TaskType.AccessDay,
    phase: PhaseType.Access,
    state: LOCKED,
    states: [LOCKED, PENDING, PROCESSED, CONCLUDED],
  },
];

export default timelineSpec;
