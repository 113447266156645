import {
  Card,
  CardContent,
  CardHeader,
  FormControlLabel,
  Switch,
} from '@mui/material';
import { sv as locale } from '@norban/locale';
import React from 'react';

import NumberField from '../../../../components/form/NumberField';
import { BofHomeProfileHomeFragment } from '../../../../generated/backend/graphql';
import { DispatchType } from '../../hooks/useHomeProfileReducer';

type Props = {
  home: BofHomeProfileHomeFragment;
  dispatch: DispatchType;
};

const PriceCard = ({ home, dispatch }: Props) => {
  const L = locale.backoffice.homePriceCard;
  const { askingPrice, askingPriceMax, closingPrice, showPrice } = home || {};

  return (
    <Card>
      <CardHeader title={L.title} />
      <CardContent>
        <FormControlLabel
          control={
            <Switch
              checked={showPrice ?? undefined}
              name="showPrice"
              onChange={e =>
                dispatch({
                  key: 'showPrice',
                  value: e.target.checked,
                })
              }
            />
          }
          label={L.showPrice}
        />
        <NumberField
          label={L.askingPrice}
          value={askingPrice}
          name="askingPrice"
          dispatch={dispatch}
          type="number"
        />
        <NumberField
          label={L.askingPriceMax}
          value={askingPriceMax}
          name="askingPriceMax"
          dispatch={dispatch}
          type="number"
        />
        <NumberField
          label={L.closingPrice}
          value={closingPrice}
          name="closingPrice"
          dispatch={dispatch}
          type="number"
        />
      </CardContent>
    </Card>
  );
};

export default PriceCard;
