import { Grid } from '@mui/material';
import { sv as locale } from '@norban/locale';
import React, { useEffect } from 'react';

const ShowYourHomeForm = props => {
  const { home, submitting, updateUser } = props;
  const { showYourHomePreference } = home;

  const choices = {
    ALL: locale.MH.SHOW_YOUR_HOME.option1,
    EXTERIOR: locale.MH.SHOW_YOUR_HOME.option2,
    FLOORPLAN: locale.MH.SHOW_YOUR_HOME.option3,
  };

  useEffect(() => {
    if (submitting) {
      updateUser();
    }
  }, [submitting, updateUser]);

  return (
    <Grid container spacing={0}>
      <div>{choices[showYourHomePreference]}</div>
    </Grid>
  );
};

export default ShowYourHomeForm;
