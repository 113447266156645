import { useQuery } from '@apollo/client';
import HomeIcon from '@mui/icons-material/Home';
import PersonIcon from '@mui/icons-material/Person';
import {
  DataGrid,
  GridColDef,
  gridDateComparator,
  gridNumberComparator,
  GridSortItem,
} from '@mui/x-data-grid';
import { sv as locale } from '@norban/locale';
import React, { useState } from 'react';

import PageHeader from '../../components/PageHeader';
import QueryError from '../../components/QueryError';
import RootCard from '../../components/RootCard';
import {
  BofAllHomesDocument,
  BofAllHomesCountDocument,
  OrderDirectionInput,
} from '../../generated/backend/graphql';
import {
  translateState,
  translateTenure,
  translateType,
} from '../../utils/translateEnum';

const PAGE_SIZE = 100;

const mapSorting = (field: string): string => {
  const sortMap: { [key: string]: string } = {
    area: 'areaId',
    address: 'streetAddress',
  };

  if (!sortMap[field]) {
    return field;
  }

  return sortMap[field];
};

const Homes = () => {
  const L = locale.backoffice.allHomes;

  const [page, setPage] = useState(0);
  const [sorting, setSorting] = useState<GridSortItem>();

  const {
    loading: loadingCount,
    data: dataCount,
    error: errorCount,
  } = useQuery(BofAllHomesCountDocument, {
    variables: {
      filter: undefined,
    },
  });
  const count = dataCount?.homesCount ?? 0;
  const { loading, data, error } = useQuery(BofAllHomesDocument, {
    variables: {
      filter: {
        limit: PAGE_SIZE,
        offset: page * PAGE_SIZE,
        order: sorting
          ? {
              field: mapSorting(sorting.field),
              direction:
                sorting.sort === 'asc'
                  ? OrderDirectionInput.Asc
                  : OrderDirectionInput.Desc,
            }
          : undefined,
      },
    },
    skip: loadingCount || !!errorCount || !count,
  });

  const rows = data?.homes ?? [];

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: L.homeId,
      sortable: true,
      renderHeader: () => <HomeIcon />,
      renderCell: ({ value }) => (
        <a href={`/homes/${value}`} target="_blank" rel="noreferrer">
          {value}
        </a>
      ),
      sortComparator: gridNumberComparator,
    },
    {
      field: 'userId',
      headerName: L.userId,
      sortable: true,
      renderHeader: () => <PersonIcon />,
      renderCell: ({ value }) => (
        <a href={`/users/${value}`} target="_blank" rel="noreferrer">
          {value}
        </a>
      ),
      sortComparator: gridNumberComparator,
    },
    {
      field: 'state',
      headerName: L.state,
      sortable: true,
      width: 200,
      valueGetter: ({ value }) => translateState(value),
    },
    {
      field: 'address',
      headerName: L.address,
      sortable: true,
      width: 200,
      valueGetter: ({ value: { city, streetAddress, zipCode } }) =>
        `${streetAddress ?? '-'}\n${zipCode ?? '-'} ${city ?? '-'}`,
      renderCell: ({ value }) => (
        <span style={{ whiteSpace: 'pre-wrap' }}>{value}</span>
      ),
    },
    {
      field: 'area',
      headerName: L.area,
      sortable: true,
      width: 200,
      valueGetter: ({ value }) =>
        value?.name
          ? `${value.name}${value?.public ? '' : ' - stängt område'}`
          : '-',
    },
    {
      field: 'type',
      headerName: L.type,
      sortable: true,
      width: 200,
      valueGetter: ({ value }) => translateType(value),
    },
    {
      field: 'tenure',
      headerName: L.tenure,
      sortable: true,
      width: 200,
      valueGetter: ({ value }) => translateTenure(value),
    },
    {
      field: 'created',
      headerName: L.created,
      sortable: true,
      width: 200,
      valueGetter: ({ value }) => value,
      renderCell: ({ value }) =>
        value
          ? new Intl.DateTimeFormat('sv-SE', {
              year: 'numeric',
              day: 'numeric',
              month: 'short',
            }).format(new Date(value))
          : '-',
      sortComparator: gridDateComparator,
    },
    {
      field: 'agreementDate',
      headerName: L.dateOfAgreement,
      sortable: true,
      width: 200,
      valueGetter: ({ value }) => value,
      renderCell: ({ value }) =>
        value
          ? new Intl.DateTimeFormat('sv-SE', {
              year: 'numeric',
              day: 'numeric',
              month: 'short',
            }).format(new Date(value))
          : '-',
      sortComparator: gridDateComparator,
    },
  ];

  if (error) {
    return <QueryError error={error} data={data} />;
  }
  return (
    <>
      <PageHeader title={L.title} />
      <RootCard>
        <DataGrid
          autoHeight
          columns={columns}
          initialState={{ sorting: { sortModel: sorting ? [sorting] : [] } }}
          loading={loadingCount || loading}
          paginationMode="server"
          paginationModel={{
            pageSize: PAGE_SIZE,
            page,
          }}
          rows={rows}
          rowCount={count}
          sortingMode="server"
          onSortModelChange={model => {
            setSorting(model[0]);
          }}
          onPaginationModelChange={params => {
            setPage(params.page);
          }}
        />
      </RootCard>
    </>
  );
};

export default Homes;
