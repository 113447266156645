import {
  Box,
  Card,
  CardContent,
  CardHeader,
  MenuItem,
  Select,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { sv as locale } from '@norban/locale';
import React from 'react';
import { Link } from 'react-router-dom';

import NumberField from '../../../../components/form/NumberField';
import TextField from '../../../../components/form/TextField';
import { BofHomeProfileHomeFragment } from '../../../../generated/backend/graphql';
import { DispatchType } from '../../hooks/useHomeProfileReducer';

const useStyles = makeStyles(() => ({
  root: {
    paddingLeft: 0,
  },
}));

type Props = {
  home: BofHomeProfileHomeFragment;
  dispatch: DispatchType;
  newConstructionLink: string;
};

const NewConstructionCard = ({
  home,
  newConstructionLink,
  dispatch,
}: Props) => {
  const L = locale.backoffice;
  const classes = useStyles();

  const {
    newConstructionProjectName,
    newConstructionCount,
    newConstructionAvailable,
    newConstructionSubTenure,
    newConstructionHouseView,
  } = home;

  return (
    <Card>
      <CardHeader title={L.newConstruction.title} />
      <CardContent>
        <CardHeader
          classes={{ root: classes.root }}
          title="Projektet"
          titleTypographyProps={{ variant: 'h6' }}
        />
        <Link to={newConstructionLink}>Lista över hem</Link>
        <TextField
          label={L.newConstruction.projectName}
          value={newConstructionProjectName}
          name="newConstructionProjectName"
          dispatch={dispatch}
        />
        <NumberField
          label={L.newConstruction.count}
          value={newConstructionCount}
          name="newConstructionCount"
          dispatch={dispatch}
        />
        <NumberField
          label={L.newConstruction.available}
          value={newConstructionAvailable}
          name="newConstructionAvailable"
          dispatch={dispatch}
        />
        <Box mt={1} mb={1}>
          <Select
            value={newConstructionSubTenure || ''}
            onChange={evt =>
              dispatch({
                key: 'newConstructionSubTenure',
                value: evt.target.value,
              })
            }
          >
            {Object.keys(L.enumerations.Tenure).map(tenure => (
              <MenuItem key={tenure} value={tenure}>
                {
                  L.enumerations.Tenure[
                    tenure as keyof typeof L.enumerations.Tenure
                  ]
                }
              </MenuItem>
            ))}
          </Select>
        </Box>
        <TextField
          label={L.newConstruction.houseView}
          value={newConstructionHouseView}
          name="newConstructionHouseView"
          dispatch={dispatch}
        />
      </CardContent>
    </Card>
  );
};

export default NewConstructionCard;
