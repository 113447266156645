import { Stack, Tab, Tabs } from '@mui/material';
import { sv as locale } from '@norban/locale';
import React from 'react';
import {
  Link,
  Redirect,
  Route,
  Switch,
  useLocation,
  useRouteMatch,
} from 'react-router-dom';

import UserFollowings from '../../UserFollowings';

import UserGreetings from './UserGreetings';
import UserMonitors from './UserMonitors';

type Props = {
  userId: string;
};

const L = locale.backoffice.userPage;

export default function UserInterests({ userId }: Props) {
  const { path, url } = useRouteMatch();
  const { pathname } = useLocation();

  return (
    <Stack spacing={2}>
      <Tabs value={pathname.split('/')[4]}>
        <Tab
          component={Link}
          label={L.tabs.homes}
          to={`${url}/followings`}
          value="followings"
        />
        <Tab
          component={Link}
          label={L.tabs.greetings}
          to={`${url}/greetings`}
          value="greetings"
        />
        <Tab
          component={Link}
          label={L.tabs.monitors}
          to={`${url}/monitors`}
          value="monitors"
        />
      </Tabs>
      <Switch>
        <Route path={`${path}/followings`} exact>
          <UserFollowings userId={userId} />
        </Route>
        <Route path={`${path}/greetings`} exact>
          <UserGreetings userId={userId} />
        </Route>
        <Route path={`${path}/monitors`} exact>
          <UserMonitors id={userId} />
        </Route>
        <Route>
          <Redirect to={`${url}/followings`} />
        </Route>
      </Switch>
    </Stack>
  );
}
