import { Autocomplete, Box, FormControl, TextField } from '@mui/material';
import React, { useMemo } from 'react';

type RecursiveArea = {
  id: string;
  name: string;
  children?: RecursiveArea[];
  public: boolean;
};

function createOptions(
  area: RecursiveArea,
  prevValue = '',
): {
  key: string;
  value: string;
}[] {
  const key = area.id;
  const value = !prevValue ? area.name : `${prevValue} / ${area.name}`;
  const closedStatus = area.public ? '' : ' - stängt område';

  return [
    { key, value: `${value} [${area.id}]${closedStatus}` },
    ...(area.children?.flatMap(childArea => createOptions(childArea, value)) ??
      []),
  ];
}

type Props = {
  areas: RecursiveArea[];
  className?: string;
  label: string;
  placeholder?: string;
  value: string | null;
  name: string;
  dispatch: (action: { key: string; value: string | null }) => void;
  margin?: 'none' | 'dense' | 'normal';
  variant?: 'standard' | 'outlined' | 'filled';
};

const AreaSelect = ({
  areas,
  className = undefined,
  label,
  value,
  name,
  dispatch,
  placeholder = undefined,
  margin = undefined,
  variant = undefined,
}: Props) => {
  const options = useMemo(
    () =>
      areas
        .flatMap(area => createOptions(area))
        .sort((a, b) => a.value.localeCompare(b.value)),
    [areas],
  );

  const selectedOption = useMemo(
    () => options.find(opt => opt.key === value),
    [options, value],
  );

  return (
    <FormControl margin={margin} fullWidth variant={variant || 'outlined'}>
      <Autocomplete
        id="combo-box-demo"
        fullWidth
        options={options}
        value={selectedOption || null}
        getOptionLabel={option => option.value}
        onChange={(_, option) => {
          dispatch({ key: name, value: option?.key ?? null });
        }}
        renderInput={params => (
          <Box>
            <TextField
              {...params}
              className={className}
              fullWidth
              label={label}
              placeholder={placeholder}
              InputLabelProps={{ shrink: placeholder ? true : undefined }}
              variant="outlined"
            />
          </Box>
        )}
      />
    </FormControl>
  );
};

export default AreaSelect;
