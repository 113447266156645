import { Button } from '@mui/material';
import { sv as locale } from '@norban/locale';
import React from 'react';

type Props = {
  disabled?: boolean;
  handled: boolean;
  onClick: () => void;
};

const HandledButton = ({ disabled = false, handled, onClick }: Props) => {
  const L = locale.backoffice.handledButton;

  return (
    <Button
      color={handled ? 'inherit' : 'secondary'}
      disabled={disabled}
      size="small"
      variant="contained"
      onClick={() => onClick()}
    >
      {handled ? L.handled : L.notHandled}
    </Button>
  );
};

export default HandledButton;
