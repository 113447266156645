import { ApolloError, useMutation, useQuery } from '@apollo/client';
import DeleteIcon from '@mui/icons-material/Delete';
import { Button, IconButton, Stack, Typography } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import React, { useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import AreaSelect from '../../components/form/AreaSelect';
import {
  BofAgentUserAndAreasDocument,
  BofAgentUpdateUserDocument,
} from '../../generated/backend/graphql';
import usePopupAlert from '../../hooks/usePopupAlert';

type Row = {
  id: string;
  name: string;
};

const Agent = () => {
  const { id } = useParams<{ id: string }>();
  const [selectedAreaId, setSelectedAreaId] = useState<string>();
  const { PopupAlert, showPopupAlert } = usePopupAlert();

  const { data } = useQuery(BofAgentUserAndAreasDocument, {
    variables: { id },
  });

  const [updateUser] = useMutation(BofAgentUpdateUserDocument);

  const adminAssignedAreas = useMemo(
    () => data?.user.adminAssignedAreas ?? [],
    [data?.user.adminAssignedAreas],
  );

  const handleAddArea = useCallback(async () => {
    try {
      if (!selectedAreaId) {
        throw new Error('No selected area');
      }

      await updateUser({
        variables: {
          input: {
            adminAssignedAreaIds: [
              ...adminAssignedAreas.map(area => area.id),
              selectedAreaId,
            ],
          },
          userId: id,
        },
      });
    } catch (error) {
      const ae = error as ApolloError;
      showPopupAlert(ae.message, 'error');
    } finally {
      setSelectedAreaId(undefined);
    }
  }, [id, selectedAreaId, showPopupAlert, updateUser, adminAssignedAreas]);

  const handleDeleteArea = useCallback(
    async (areaId: string) => {
      try {
        if (!areaId) {
          throw new Error('No area to delete');
        }

        await updateUser({
          variables: {
            input: {
              adminAssignedAreaIds: [
                ...adminAssignedAreas
                  .filter(area => area.id !== areaId)
                  .map(area => area.id),
              ],
            },
            userId: id,
          },
        });
      } catch (error) {
        const ae = error as ApolloError;
        showPopupAlert(ae.message, 'error');
      }
    },
    [id, showPopupAlert, updateUser, adminAssignedAreas],
  );

  const columns: GridColDef<Row>[] = [
    {
      field: 'id',
      headerName: 'Id',
      type: 'string',
      width: 80,
    },
    {
      field: 'name',
      headerName: 'Namn',
      type: 'string',
      width: 250,
    },
    {
      field: 'delete',
      headerName: 'Ta bort',
      type: 'string',
      sortable: false,
      filterable: false,
      width: 80,
      renderCell: ({ row }) => (
        <IconButton
          aria-label="Ta bort"
          onClick={e => {
            e.stopPropagation();
            handleDeleteArea(row.id);
          }}
        >
          <DeleteIcon />
        </IconButton>
      ),
    },
  ];

  const rows: Row[] = adminAssignedAreas.map(({ id, name, parent }) => ({
    id,
    name: `${name}${parent?.name ? `, ${parent?.name}` : ''}`,
  }));

  return (
    <>
      <Typography mb={5} variant="h5">
        {data?.user.name}
      </Typography>
      <Typography mb={1} variant="h6">
        Tilldelade områden
      </Typography>
      <Stack alignItems="center" direction="row" mb={2} spacing={2}>
        <AreaSelect
          areas={data?.rootAreas ?? []}
          dispatch={action => setSelectedAreaId(action.value ?? undefined)}
          label="Område"
          name="areaId"
          value={selectedAreaId ?? ''}
        />
        <Button
          disabled={!selectedAreaId}
          sx={{ flexShrink: 0 }}
          onClick={handleAddArea}
        >
          Lägg till
        </Button>
      </Stack>
      <DataGrid
        autoHeight
        columns={columns}
        rows={rows}
        getRowHeight={() => 'auto'}
      />
      <PopupAlert />
    </>
  );
};

export default Agent;
