import { useMutation, useQuery, gql } from '@apollo/client';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Snackbar,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { sv as locale } from '@norban/locale';
import React, { useEffect, useState } from 'react';

import QueryError from '../../../components/QueryError';
import QueryLoading from '../../../components/QueryLoading';
import SlideButton from '../../../components/SlideButton';

const Q_USER_AND_USER_LABELS = gql`
  query BofGetUserAndUserLabels($id: ID) {
    userLabels {
      id
      label
    }
    user(id: $id) {
      id
      userLabelIds
    }
  }
`;

const M_UPDATE_USER = gql`
  mutation BofUpdateUser($userId: ID!, $input: UpdatedUserInput!) {
    updateUser(userId: $userId, input: $input) {
      userLabelIds
    }
  }
`;

const useStyles = makeStyles(() => ({
  chip: {
    marginRight: 8,
  },
}));
const EditLabels = ({ id }) => {
  const L = locale.backoffice.userViewEditLabels;
  const classes = useStyles();
  const [editedLabels, setEditedLabels] = useState(null);
  const [modified, setModified] = useState();
  const [snackbar, setSnackbar] = useState({ open: false, message: '' });

  const { loading, error, data, refetch } = useQuery(Q_USER_AND_USER_LABELS, {
    variables: { id },
  });
  const [updateUser] = useMutation(M_UPDATE_USER, {
    onCompleted: () => refetch(),
    onError: () => setSnackbar({ open: true, message: L.failed }),
  });

  useEffect(() => {
    setEditedLabels(data?.user?.userLabelIds);
  }, [data]);

  if (loading) {
    return <QueryLoading />;
  }

  if (error) {
    return <QueryError error={error} data={data} />;
  }

  const { userLabels } = data || {};

  return (
    <>
      <Card>
        <CardHeader title={L.title} />
        <CardContent>
          {userLabels
            ?.slice()
            ?.sort((a, b) => {
              return a.label.localeCompare(b.label);
            })
            ?.map(l => (
              <Box key={l.label} mb={1} display="inline-flex">
                <Chip
                  className={classes.chip}
                  color={editedLabels?.includes(l.id) ? 'primary' : 'default'}
                  label={l.label}
                  clickable
                  onClick={() => {
                    setModified(true);
                    if (editedLabels?.includes(l.id)) {
                      setEditedLabels(
                        editedLabels.filter(aLabel => aLabel !== l.id),
                      );
                    } else if (editedLabels?.includes(l.id) === false) {
                      setEditedLabels([...editedLabels, l.id]);
                    }
                  }}
                />
              </Box>
            ))}
        </CardContent>
      </Card>
      <SlideButton
        modified={modified}
        // eslint-disable-next-line no-console
        onClick={() => {
          if (editedLabels) {
            setModified(false);
            updateUser({
              variables: {
                userId: id,
                input: { userLabelIds: editedLabels },
              },
            });
          }
        }}
      />
      <Snackbar
        open={snackbar.open}
        onClose={() => setSnackbar({ open: false, message: '' })}
        message={snackbar.message}
        autoHideDuration={5000}
      />
    </>
  );
};

export default EditLabels;
