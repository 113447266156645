import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { sv as locale } from '@norban/locale';
import React, { useEffect, useState } from 'react';

import InfoBar from '../InfoBar';

const ValuationMeetingSummaryForm = props => {
  const { home, updateUser, submitting, readOnly } = props;
  const vm = home.valuationMeeting;
  const [participants, setParticipants] = useState(vm.participants);
  const [result, setResult] = useState(vm.result);
  const [notes, setNotes] = useState(vm.notes);

  useEffect(() => {
    if (submitting) {
      const data = {
        valuationMeeting: {
          participants,
          result,
          notes,
        },
      };

      updateUser({ home: data });
    }
  }, [notes, participants, result, submitting, updateUser]);

  return (
    <form>
      <Grid container spacing={4} style={{ maxWidth: 520 }}>
        <Grid item xs={12}>
          <TextField
            disabled={readOnly}
            fullWidth
            label="Ägare"
            margin="normal"
            onChange={evt => setParticipants(evt.target.value)}
            value={participants}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            disabled={readOnly}
            fullWidth
            label="The valuation of the home"
            margin="normal"
            onChange={evt => setResult(evt.target.value)}
            value={result}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            disabled={readOnly}
            fullWidth
            label="Extra notes"
            margin="normal"
            onChange={evt => setNotes(evt.target.value)}
            value={notes}
          />
        </Grid>
      </Grid>

      <InfoBar
        content={
          locale.backoffice.tasks.VALUATION_MEETING_SUMMARY.opportunitiesInfo
        }
      />
    </form>
  );
};

export default ValuationMeetingSummaryForm;
