import { gql, useMutation } from '@apollo/client';
import DeleteIcon from '@mui/icons-material/Delete';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import {
  Card,
  CardContent,
  Box,
  Switch,
  FormControlLabel,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField as MuiTextField,
  Stack,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { sv as locale } from '@norban/locale';
import React, { useCallback, useMemo, useState } from 'react';
import { useHistory } from 'react-router';

import AreaSelect from '../../components/form/AreaSelect';
import TextField from '../../components/form/TextField';
import {
  BofAreaViewUpdateAreaAndHomesDocument,
  Home,
} from '../../generated/backend/graphql';

const M_DELETE_AREA = gql`
  mutation BofDeleteArea($id: ID!) {
    deleteArea(id: $id)
  }
`;

type Area = {
  id: string;
  name: string;
  public: boolean;
  children?: {
    id: string;
    name: string;
    public: boolean;
  }[];
};

const useStyles = makeStyles(() => ({
  input: {
    maxWidth: 400,
  },
  inputShort: {
    maxWidth: 120,
  },
}));

type Props = {
  area: {
    id: string;
    parentId: string;
    name: string;
    public: boolean;
    polygon: string;
  };
  areas: Area[] | undefined;
  agents: { id: string; name?: string | null }[];
  homes: Home[];
  hasActions: boolean;
  dispatch: (action: { key: string; value: string | null }) => void;
};

const AreaEditCard = ({
  area,
  areas,
  agents,
  homes,
  hasActions,
  dispatch,
}: Props) => {
  const classes = useStyles();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [confirmId, setConfirmId] = useState('');
  const [migrateDialogOpen, setMigrateDialogOpen] = useState(false);
  const [migrateId, setMigrateId] = useState<string | null>(null);
  const [fromId, setfromId] = useState('');
  const [toId, setToId] = useState('');
  const L = locale.backoffice.areaView;
  const { id, parentId, name, public: isPublic, polygon } = area || {};
  const history = useHistory();
  const [deleteArea] = useMutation(M_DELETE_AREA);
  const [updateAreaAndHomes] = useMutation(
    BofAreaViewUpdateAreaAndHomesDocument,
  );

  const migrationAreas = useMemo(() => {
    if (!areas) {
      return [];
    }

    // Deep copy areas
    const copy = JSON.parse(JSON.stringify(areas));

    // Recursively check if the current area or any of its children
    // are the same as the area to be deleted. If so remove it.
    const removeArea = (areas: Area[], id: string) => {
      const index = areas.findIndex(a => a.id === id);
      if (index !== -1) {
        areas.splice(index, 1);
      } else {
        areas.forEach(a => a.children && removeArea(a.children, id));
      }

      return areas;
    };

    return removeArea(copy, id);
  }, [areas, id]);

  const handleDelete = useCallback(async () => {
    if (confirmId === area?.id) {
      const success = await deleteArea({ variables: { id } });
      if (success) {
        history.push('/areas/list');
      }
    }
    setDeleteDialogOpen(false);
  }, [area?.id, confirmId, deleteArea, history, id]);

  const handleMigrate = async () => {
    if (!migrateId) {
      setMigrateDialogOpen(false);
      return;
    }

    // eslint-disable-next-line no-console
    console.log(homes);
    // eslint-disable-next-line no-console
    console.log(migrateId);

    try {
      await updateAreaAndHomes({
        variables: {
          homeIds: homes.map(home => home.id),
          inputs: homes.map(() => ({ areaId: migrateId })),
          fromAreaId: id,
          toAreaId: migrateId,
          assignedAgentIds: agents.map(agent => agent.id),
        },
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }

    setMigrateId(null);
    setMigrateDialogOpen(false);
  };

  return (
    <>
      <Dialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
      >
        <DialogTitle>Bekräfta borttagning genom att skriva in id</DialogTitle>
        <DialogContent>
          <Box mb={1}>
            <form noValidate>
              <MuiTextField
                type="number"
                label={L.confirmId}
                value={confirmId}
                name="confirmId"
                onChange={evt => setConfirmId(evt.target.value)}
              />
            </form>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={migrateDialogOpen}
        onClose={() => setMigrateDialogOpen(false)}
      >
        <DialogTitle>Välj område att flytta innehållet till</DialogTitle>
        <DialogContent>
          <Stack gap={1}>
            <AreaSelect
              areas={migrationAreas || []}
              label="Att flytta till"
              className={classes.input}
              value={migrateId}
              name="parentId"
              margin="normal"
              dispatch={({ value }) => setMigrateId(value)}
            />
            <Typography variant="body2">
              Bekräfta med att skriva in idn
            </Typography>
            <Stack direction="row" gap={1} mt={1}>
              <MuiTextField
                label="Från-id"
                className={classes.inputShort}
                value={fromId}
                onChange={evt => setfromId(evt.target.value)}
                name="fromId"
              />
              <MuiTextField
                label="Till-id"
                className={classes.inputShort}
                value={toId}
                onChange={evt => setToId(evt.target.value)}
                name="toId"
              />
            </Stack>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setMigrateDialogOpen(false)} color="primary">
            Cancel
          </Button>
          <Button
            onClick={handleMigrate}
            color="primary"
            disabled={!migrateId || fromId !== id || toId !== migrateId}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
      <Card>
        <CardContent>
          {hasActions && (
            <Stack direction="row" mb={1} gap={1}>
              <Button
                onClick={() => setDeleteDialogOpen(true)}
                color="secondary"
                size="small"
                startIcon={<DeleteIcon />}
                variant="contained"
              >
                Ta bort område
              </Button>
              <Button
                onClick={() => setMigrateDialogOpen(true)}
                color="secondary"
                size="small"
                startIcon={<SyncAltIcon />}
                variant="contained"
              >
                Migrera område
              </Button>
            </Stack>
          )}
          <Stack>
            {area.id && (
              <TextField
                label="id"
                className={classes.input}
                value={area.id}
                name="id"
                dispatch={dispatch}
                disabled
              />
            )}
            <TextField
              label={L.name}
              className={classes.input}
              value={name}
              name="name"
              dispatch={dispatch}
            />
            <AreaSelect
              areas={areas || []}
              label={L.parent}
              className={classes.input}
              value={parentId}
              name="parentId"
              margin="normal"
              dispatch={dispatch}
            />
            <TextField
              label={L.polygon}
              value={polygon}
              name="polygon"
              dispatch={dispatch}
              minRows={4}
              maxRows={10}
              multiline
            />
          </Stack>
          <Box display="flex" justifyContent="space-between">
            <FormControlLabel
              control={
                <Switch
                  checked={!!isPublic}
                  onChange={({ target: { checked } }) =>
                    dispatch({ key: 'public', value: `${checked}` })
                  }
                />
              }
              label={L.public}
            />
          </Box>
        </CardContent>
      </Card>
    </>
  );
};

export default AreaEditCard;
