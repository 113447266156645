import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from '@mui/material';
import { Stack } from '@mui/system';
import { sv as locale } from '@norban/locale';
import React, { useState } from 'react';

type Props = {
  message: string;
  phone: string;
  open: boolean;
  onClose: () => void;
  onSend: (message: string) => void;
};

const SMSDialog = ({
  message: origMessage,
  phone,
  open,
  onClose,
  onSend,
}: Props) => {
  const L = locale.backoffice;
  const [message, setMessage] = useState<string | undefined>(undefined);
  const currentMessage = message ?? origMessage;

  const handleClose = () => {
    setMessage(undefined);
    onClose();
  };

  return (
    <Dialog open={open} fullWidth maxWidth="sm">
      <DialogTitle>{L.SMSDialog.title}</DialogTitle>
      <DialogContent>
        <Stack spacing={2} mt={1}>
          <Typography fontWeight="bold">
            {L.SMSDialog.recipient}: {phone}
          </Typography>
          <TextField
            label={L.SMSDialog.message}
            variant="outlined"
            fullWidth
            required
            multiline
            value={currentMessage}
            onChange={e => setMessage(e.target.value)}
            rows={6}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={handleClose}>
          {L.close}
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={() => {
            onSend(currentMessage);
            handleClose();
          }}
          disabled={!currentMessage || currentMessage.length === 0}
        >
          {L.send}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SMSDialog;
