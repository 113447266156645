import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import { sv as locale } from '@norban/locale';
import React, { useEffect, useState } from 'react';

import { TenureType } from '../../../../../generated/backend/graphql';

const useStyles = makeStyles({
  ownershipDivider: {
    marginBottom: 16,
    marginTop: 8,
  },
});

const InformationForValuationMeetingForm = props => {
  const classes = useStyles();
  const { home, user, updateUser, submitting, readOnly } = props;
  const { tenure } = home;
  const vm = home.valuationMeeting;
  const [fee, setFee] = useState(vm.fee || '');
  const [updatedTenure, setUpdatedTenure] = useState(vm.tenure || '');
  const [buildingYear, setBuildingYear] = useState(vm.buildingYear || '');
  const [renovations, setRenovations] = useState(vm.renovations || '');
  const [entryCode, setEntryCode] = useState(vm.entryCode || '');
  const [phone, setPhone] = useState(user.phone || '');
  const { ownership } = vm;

  useEffect(() => {
    if (submitting) {
      const data = {
        valuationMeeting: {
          fee: Number(fee),
          renovations,
          entryCode,
        },
      };
      updateUser({ home: data });
    }
  }, [entryCode, fee, renovations, submitting, updateUser]);

  const labels = locale.MH.INFORMATION_FOR_VALUATION_MEETING;

  return (
    <form>
      <Grid container spacing={4} style={{ maxWidth: 520 }}>
        {(tenure === TenureType.HousingCooperative ||
          tenure === TenureType.ShareInHousingAssociation ||
          tenure === TenureType.Coownership ||
          tenure === TenureType.Condominium) && (
          <Grid item xs={12}>
            <TextField
              disabled={readOnly}
              inputProps={{ readOnly: true }}
              label={labels.monthlyFee}
              value={fee}
              margin="normal"
              variant="outlined"
              onChange={evt => setFee(evt.target.value)}
              fullWidth
            />
          </Grid>
        )}

        {(tenure === TenureType.GenericHouse ||
          tenure === TenureType.Freehold ||
          tenure === TenureType.Leasehold) && (
          <>
            <Grid item xs={12}>
              <TextField
                disabled={readOnly}
                inputProps={{ readOnly: true }}
                label={labels.tenure}
                value={updatedTenure}
                margin="normal"
                variant="outlined"
                onChange={evt => setUpdatedTenure(evt.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                disabled={readOnly}
                inputProps={{ readOnly: true }}
                label={labels.buildingYear}
                value={buildingYear}
                margin="normal"
                variant="outlined"
                onChange={evt => setBuildingYear(evt.target.value)}
                fullWidth
              />
            </Grid>
          </>
        )}

        <Grid item xs={12}>
          <TextField
            disabled={readOnly}
            inputProps={{ readOnly: true }}
            label={labels.renovations}
            value={renovations}
            margin="normal"
            variant="outlined"
            onChange={evt => setRenovations(evt.target.value)}
            fullWidth
            maxRows={6}
            multiline
          />
        </Grid>

        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Typography variant="caption">{labels.ownership}</Typography>
              <Divider className={classes.ownershipDivider} />
              <Typography variant="caption">{labels.seller}</Typography>
              <Typography gutterBottom>
                {ownership && ownership.sellerIsOwner
                  ? labels.owner
                  : labels.notOwner}
              </Typography>
              <Typography gutterBottom>
                {ownership && ownership.hasMoreThanOneOwner
                  ? labels.moreOwnersExist
                  : labels.noMoreOwnersExist}
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        {(tenure === TenureType.HousingCooperative ||
          tenure === TenureType.ShareInHousingAssociation ||
          tenure === TenureType.Condominium) && (
          <Grid item xs={12}>
            <TextField
              disabled={readOnly}
              inputProps={{ readOnly: true }}
              label={labels.code}
              value={entryCode}
              margin="normal"
              variant="outlined"
              onChange={evt => setEntryCode(evt.target.value)}
              fullWidth
            />
          </Grid>
        )}

        <Grid item xs={12}>
          <TextField
            disabled={readOnly}
            inputProps={{ readOnly: true }}
            label={labels.phone}
            value={phone}
            margin="normal"
            variant="outlined"
            onChange={evt => setPhone(evt.target.value)}
            fullWidth
          />
        </Grid>
      </Grid>
    </form>
  );
};

export default InformationForValuationMeetingForm;
