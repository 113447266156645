import { STORE_FILTER_SEARCH_QUERY } from '../actions/userActions';

export default (
  state = {
    searchQuery: '',
  },
  action = {},
) => {
  const { type, value } = action;
  switch (type) {
    case STORE_FILTER_SEARCH_QUERY:
      return {
        ...state,
        searchQuery: value,
      };
    default:
      return state;
  }
};
