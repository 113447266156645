import { useMutation, useQuery } from '@apollo/client';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Stack,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { sv as locale } from '@norban/locale';
import { addDays } from 'date-fns';
import React, { useMemo } from 'react';

import {
  DeleteHomeMonitorCandidateDocument,
  HomeMonitorCandidatesDocument,
  SetHomeAsMonitorCandidateDocument,
} from '../../../../generated/backend/graphql';

type Props = {
  homeId: string;
  onError: () => void;
};

const MonitorCandidateCard = ({ homeId, onError }: Props) => {
  const L = locale.backoffice.monitorCandidate;

  const [setHomeAsMonitoringCandidate] = useMutation(
    SetHomeAsMonitorCandidateDocument,
    {
      onError,
      variables: {
        homeId,
      },
      refetchQueries: [HomeMonitorCandidatesDocument],
    },
  );

  const [deleteMonitoringCandidate] = useMutation(
    DeleteHomeMonitorCandidateDocument,
    {
      onError,
      refetchQueries: [HomeMonitorCandidatesDocument],
    },
  );

  const { data } = useQuery(HomeMonitorCandidatesDocument, {
    variables: {
      homeId,
    },
  });

  const anySameDate = useMemo(
    () =>
      data?.homeMonitorCandidates.some(
        candidate =>
          new Date(candidate.createdAt).toLocaleDateString() ===
          new Date().toLocaleDateString(),
      ),
    [data?.homeMonitorCandidates],
  );

  return (
    <Card>
      <CardHeader title={L.title} />
      <CardContent>
        <Stack spacing={2} alignItems="flex-start">
          <Box>{L.content}</Box>
          <Button
            variant="contained"
            color="primary"
            type="button"
            onClick={() => setHomeAsMonitoringCandidate()}
            disabled={anySameDate}
          >
            {L.setAsCandidate}
          </Button>
          {data?.homeMonitorCandidates &&
            data?.homeMonitorCandidates?.length > 0 && <Box>{L.match}</Box>}
          {data?.homeMonitorCandidates.map(candidate => {
            const sameDate =
              new Date(candidate.createdAt).toLocaleDateString() ===
              new Date().toLocaleDateString();

            return (
              <Box
                key={candidate.id}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                {sameDate && (
                  <CheckCircleIcon
                    style={{
                      color: 'green',
                      marginRight: '8px',
                    }}
                  />
                )}
                {new Intl.DateTimeFormat('sv-SE', {
                  year: 'numeric',
                  month: 'short',
                  day: 'numeric',
                }).format(addDays(new Date(candidate.createdAt), 1))}
                <Box ml={1}>
                  <IconButton
                    edge="end"
                    color="inherit"
                    onClick={() =>
                      deleteMonitoringCandidate({
                        variables: { id: candidate.id },
                      })
                    }
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
              </Box>
            );
          })}
        </Stack>
      </CardContent>
    </Card>
  );
};

export default MonitorCandidateCard;
