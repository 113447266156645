// This component is used to handle loading state in a generic way:
//
//  <Query ...>
//    {(loading, error, data) => {
//      if (loading) {
//        return <QueryLoading/>;
//      }
//    }
//  </Query>

import { Box } from '@mui/material';
import React, { useState } from 'react';

import { useInterval } from '../utils';

const QueryLoading = () => {
  const [count, setCount] = useState(0);
  useInterval(() => setCount(count + 1), 320);

  if (count < 4) {
    return <div />;
  }

  return (
    <Box ml={2} display="flex">
      <h3>Loading{'.'.repeat(count % 4)}</h3>
    </Box>
  );
};

export default QueryLoading;
