import { ApolloError, useMutation, useQuery } from '@apollo/client';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import {
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  IconButton,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  DataGrid,
  GridColDef,
  GridSortModel,
  gridNumberComparator,
} from '@mui/x-data-grid';
import { sv as locale } from '@norban/locale';
import { buildAgreementContractUrl } from '@norban/utils/dist';
import React, { useEffect, useMemo, useState } from 'react';
import { Prompt } from 'react-router';

import ConfirmationDialog from '../../../../components/ConfirmationDialog';
import EmailDialog from '../../../../components/EmailDialog';
import SlideButton from '../../../../components/SlideButton';
import { WEB_URI } from '../../../../config';
import {
  AgreementContractCrmState,
  AgreementContractKyc,
  BofActivateAgreementContractDocument,
  BofAgreementContractAndSigneesContractFragment,
  BofAgreementContractAndSigneesDocument,
  BofAgreementContractAndSigneesSigneeFragment,
  BofCreateAgreementContractSigneeDocument,
  BofDeleteAgreementContractSigneeDocument,
  BofUpdateAgreementContractDocument,
  BofUpdateAgreementContractSigneesDocument,
  BulkEmailType,
  BulkEmailUnsubscribeType,
} from '../../../../generated/backend/graphql';
import usePopupAlert from '../../../../hooks/usePopupAlert';
import { contractStateCopy } from '../../../../utils/agreementContract';
import AgreementContractUserPicker from '../../../User/components/AgreementContractUserPicker';

const L = locale.backoffice;

type Props = {
  agreementContractId: string;
  homeId: string;
  onClose: () => void;
};

const AgreementContract = ({ agreementContractId, homeId, onClose }: Props) => {
  const { PopupAlert, showPopupAlert } = usePopupAlert();

  const [dialogOpen, setDialogOpen] = useState(false);

  const [showUnsavedCloseDialog, setShowUnsavedCloseDialog] = useState(false);

  const { loading, data, error } = useQuery(
    BofAgreementContractAndSigneesDocument,
    {
      variables: { id: agreementContractId },
    },
  );

  const [updateContract] = useMutation(BofUpdateAgreementContractDocument);

  const [createContractSignee, { loading: creatingSignee }] = useMutation(
    BofCreateAgreementContractSigneeDocument,
    {
      refetchQueries: [BofAgreementContractAndSigneesDocument],
    },
  );

  const [updateContractSignees] = useMutation(
    BofUpdateAgreementContractSigneesDocument,
  );

  const [deleteContractSignee] = useMutation(
    BofDeleteAgreementContractSigneeDocument,
    { refetchQueries: [BofAgreementContractAndSigneesDocument] },
  );

  const [activateContract] = useMutation(BofActivateAgreementContractDocument);

  const [modifiedContract, setModifiedContract] = useState<
    Partial<BofAgreementContractAndSigneesContractFragment>
  >({});
  const contract = useMemo(
    () =>
      data?.agreementContract && {
        ...data?.agreementContract,
        ...modifiedContract,
      },
    [data?.agreementContract, modifiedContract],
  );
  const isContractModified = useMemo(
    () => Object.keys(modifiedContract).length > 0,
    [modifiedContract],
  );

  type PartialSigneeWithId = Pick<
    BofAgreementContractAndSigneesSigneeFragment,
    'id'
  > &
    Partial<BofAgreementContractAndSigneesSigneeFragment>;
  const [modifiedSignees, setModifiedSignees] = useState(
    new Map<string, PartialSigneeWithId>(),
  );
  const signees = useMemo(
    () =>
      data?.agreementContractSignees?.map(signee => {
        return {
          ...signee,
          ...modifiedSignees.get(signee.id),
        };
      }),
    [data?.agreementContractSignees, modifiedSignees],
  );
  const initiator = signees?.find(signee => signee.role === 'INITIATOR');
  const intitatorLink = initiator
    ? buildAgreementContractUrl(
        WEB_URI,
        initiator.role,
        agreementContractId,
        contract?.limitedScopeToken ?? '',
        initiator.id,
      )
    : undefined;
  const initiatorLinkContent = initiator
    ? intitatorLink?.split('?')[0]
    : undefined;

  const updateSignee = (signee: PartialSigneeWithId) => {
    const updatedSignees = new Map(modifiedSignees);
    updatedSignees.set(signee.id, {
      ...(modifiedSignees.get(signee.id) ?? {}),
      ...signee,
    });

    setModifiedSignees(updatedSignees);
  };

  const isSigneesModified = useMemo(
    () => modifiedSignees.size > 0,
    [modifiedSignees],
  );

  const state = useMemo(() => {
    if (!contract) {
      return '-';
    }

    const state = contractStateCopy(contract);

    if (contract.signState === 'SIGNED') {
      const signDate = signees?.reduce<Date | undefined>((acc, cur) => {
        if (!cur.signedAt) {
          return acc;
        }

        const signedAt = new Date(cur.signedAt);

        if (!acc) {
          return signedAt;
        }
        return signedAt > acc ? signedAt : acc;
      }, undefined);

      return `${state}, ${signDate?.toLocaleDateString()}`;
    }

    return state;
  }, [contract, signees]);

  const [dialogPickUserSignee, setDialogPickUserSignee] =
    useState<BofAgreementContractAndSigneesSigneeFragment>();
  const [dialogDeleteSigneeId, setDialogDeleteSigneeId] = useState<string>();

  const [sortModel, setSortModel] = useState<GridSortModel>([
    { field: 'id', sort: 'asc' },
  ]);

  const isDraftOrActivated =
    contract?.state === 'DRAFT' || contract?.state === 'ACTIVATED';

  const isDraft = contract?.state === 'DRAFT';

  const handleCreateSignee = async () => {
    if (creatingSignee || !signees) {
      return;
    }

    const role = ['AGENT', 'INITIATOR'][signees.length] ?? 'CO_SIGNEE';

    try {
      await createContractSignee({
        variables: {
          input: {
            agreementContractId,
            role,
          },
        },
      });
    } catch (error) {
      const ae = error as ApolloError;
      showPopupAlert(ae.message, 'error');
    }
  };

  const handleSubmit = async () => {
    try {
      if (isSigneesModified) {
        await updateContractSignees({
          variables: {
            agreementContractId,
            input: Array.from(modifiedSignees.values()).map(signee => ({
              id: signee?.id,
              email: signee?.email,
              name: signee?.name,
              role: signee?.role,
              userId: signee?.userId,
            })),
          },
        });

        setModifiedSignees(new Map());
      }

      if (isContractModified) {
        await updateContract({
          variables: {
            id: agreementContractId,
            input: modifiedContract,
          },
        });

        setModifiedContract({});
      }
    } catch (error) {
      const ae = error as ApolloError;
      showPopupAlert(ae.message, 'error');
    }
  };

  const handleActivateContract = async (body: string, subject: string) => {
    try {
      await activateContract({
        variables: {
          id: agreementContractId,
          body,
          subject,
        },
      });
    } catch (error) {
      const ae = error as ApolloError;
      showPopupAlert(ae.message, 'error');
    }
  };

  const handleDeleteSignee = async (id: string) => {
    try {
      await deleteContractSignee({
        variables: {
          id,
        },
      });
    } catch (error) {
      const ae = error as ApolloError;
      showPopupAlert(ae.message, 'error');
    }
  };

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'Id',
      sortable: true,
      width: 80,
      valueGetter: ({ value }) => value,
      sortComparator: gridNumberComparator,
    },
    {
      field: 'role',
      headerName: L.agreementContractSignee.role,
      sortable: true,
      type: 'singleSelect',
      valueOptions: ['AGENT', 'INITIATOR', 'CO_SIGNEE'],
      width: 120,
      valueGetter: ({ value }) =>
        L.agreementContractSignee[value as 'AGENT' | 'INITIATOR' | 'CO_SIGNEE'],
    },
    {
      field: 'import',
      headerName: L.agreementContractSignee.import,
      width: 80,
      renderCell: ({ row }) => (
        <Tooltip title={L.agreementContractSignee.selectUser}>
          <span>
            <IconButton
              aria-label="import"
              onClick={evt => {
                evt.stopPropagation();
                setDialogPickUserSignee(row);
              }}
              disabled={loading || !isDraftOrActivated || row.role === 'AGENT'}
            >
              <PersonSearchIcon />
            </IconButton>
          </span>
        </Tooltip>
      ),
    },
    {
      // Note: always editable as it is not part of the contract
      editable: true,
      field: 'email',
      headerName: L.email,
      sortable: true,
      width: 300,
      valueGetter: ({ value }) => value ?? undefined,
      valueSetter: ({ row, value }) => {
        const prevValue = modifiedSignees.get(row.id)?.email;
        if (value !== prevValue) {
          updateSignee({ id: row.id, email: value });
        }

        return { ...row, email: value };
      },
    },
    {
      editable: isDraftOrActivated,
      field: 'name',
      headerName: L.name,
      sortable: true,
      width: 300,
      valueGetter: ({ value }) => value ?? undefined,
      valueSetter: ({ row, value }) => {
        const prevValue = modifiedSignees.get(row.id)?.name;
        if (value !== prevValue) {
          updateSignee({ id: row.id, name: value });
        }

        return { ...row, name: value };
      },
    },
    {
      field: 'copy',
      headerName: L.agreementContractSignee.link,
      width: 80,
      renderCell: ({ row }) => (
        <Tooltip title={L.agreementContractSignee.copyLink}>
          <span>
            <IconButton
              aria-label="copy"
              onClick={evt => {
                evt.stopPropagation();
                const { role, id: signeeId } = row;
                const linkUrl = buildAgreementContractUrl(
                  WEB_URI,
                  role,
                  agreementContractId,
                  contract?.limitedScopeToken ?? '',
                  signeeId,
                );
                navigator.clipboard.writeText(linkUrl.toString());
              }}
              disabled={loading}
            >
              <ContentCopyIcon />
            </IconButton>
          </span>
        </Tooltip>
      ),
    },
    {
      field: 'signature',
      headerName: L.agreementContractSignee.signature,
      sortable: true,
      renderCell: ({ value }) => {
        return value ? L.yes : L.no;
      },
    },
    {
      field: 'personalNumber',
      headerName: L.agreementContractSignee.personalNumber,
      sortable: true,
      width: 150,
    },
    {
      field: 'legalName',
      headerName: L.agreementContractSignee.legalName,
      sortable: true,
      width: 400,
    },
    {
      field: 'delete',
      headerName: L.delete,
      width: 80,
      renderCell: ({ row }) => {
        const roleDisabled = row.role !== 'CO_SIGNEE';
        return (
          <Tooltip title={L.agreementContractSignee.removeSignee}>
            <span>
              <IconButton
                aria-label="delete"
                onClick={evt => {
                  evt.stopPropagation();
                  setDialogDeleteSigneeId(row.id);
                }}
                disabled={roleDisabled || loading || !isDraftOrActivated}
              >
                <DeleteIcon />
              </IconButton>
            </span>
          </Tooltip>
        );
      },
    },
  ];

  const unsavedChangesMessage =
    'Du har osparade ändringar. Är du säker på att du vill lämna sidan?';

  useEffect(() => {
    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      if (!isSigneesModified && !isContractModified) {
        return undefined;
      }

      // Note that most modern browsers ignore the return value of beforeunload and show a standard message for security reasons.
      (e || window.event).returnValue = unsavedChangesMessage;
      return unsavedChangesMessage;
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
  }, [isContractModified, isSigneesModified]);

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if (loading || !contract) {
    return <div>{L.loading}</div>;
  }

  return (
    <>
      <form
        onSubmit={e => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <Prompt
          when={isContractModified || isSigneesModified}
          message={unsavedChangesMessage}
        />

        <Stack direction="row" mb={2} spacing={2}>
          <Button
            color="primary"
            onClick={() => {
              if (isContractModified || isSigneesModified) {
                setShowUnsavedCloseDialog(true);
                return;
              }
              onClose();
            }}
          >
            <ArrowBackIcon />
            {L.back}
          </Button>
          <Typography
            variant="h6"
            component="h1"
            style={{ display: 'inline-block' }}
          >
            Kontrakt {agreementContractId}
          </Typography>
        </Stack>
        <Card>
          <CardContent>
            <Grid container spacing={2} mb={4} columnSpacing={8}>
              <Grid item xs={6} container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h6">
                    {L.agreementContract.crmState}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Select
                    value={contract.crmState}
                    onChange={evt => {
                      const newCrmState = evt.target
                        .value as AgreementContractCrmState;
                      setModifiedContract({
                        ...modifiedContract,
                        crmState: newCrmState,
                      });
                    }}
                  >
                    {Object.entries(AgreementContractCrmState).map(
                      ([key, value]) => (
                        <MenuItem key={key} value={value}>
                          {L.agreementContract.crmStates[value]}
                        </MenuItem>
                      ),
                    )}
                  </Select>
                </Grid>
              </Grid>
              <Grid item container xs={6} spacing={2}>
                <Grid item xs={12}>
                  <Button
                    color="secondary"
                    disabled={
                      loading ||
                      !isDraft ||
                      isContractModified ||
                      isSigneesModified
                    }
                    variant="contained"
                    onClick={() => setDialogOpen(true)}
                  >
                    {L.agreementContract.send}
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h6">{L.state}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body1">{state}</Typography>
                </Grid>
              </Grid>
              <Grid item xs={12} md={6} container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h6">
                    {L.agreementContractSignee.contractData}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label={L.agreementContractSignee.homeIdentifier}
                    value={contract?.streetNumber ?? undefined}
                    disabled={!isDraftOrActivated}
                    onChange={evt =>
                      setModifiedContract({
                        ...modifiedContract,
                        streetNumber: evt.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label={L.agreementContractSignee.address}
                    value={contract.streetAddress ?? undefined}
                    disabled={!isDraftOrActivated}
                    onChange={evt => {
                      setModifiedContract({
                        ...modifiedContract,
                        streetAddress: evt.target.value,
                      });
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label={L.agreementContractSignee.municipality}
                    value={contract.municipality ?? undefined}
                    disabled={!isDraftOrActivated}
                    onChange={evt => {
                      setModifiedContract({
                        ...modifiedContract,
                        municipality: evt.target.value,
                      });
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label={
                      L.agreementContractSignee.housingCooperativeAssociation
                    }
                    value={contract.housingCooperativeName ?? undefined}
                    disabled={!isDraftOrActivated}
                    onChange={evt => {
                      setModifiedContract({
                        ...modifiedContract,
                        housingCooperativeName: evt.target.value,
                      });
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label={L.agreementContractSignee.organizationNumber}
                    value={contract.housingCooperativeIdentifier ?? undefined}
                    disabled={!isDraftOrActivated}
                    onChange={evt =>
                      setModifiedContract({
                        ...modifiedContract,
                        housingCooperativeIdentifier: evt.target.value,
                      })
                    }
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} md={6} container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h6">
                    {L.agreementContractSignee.kycData}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">
                      {L.agreementContractSignee.pep}
                    </FormLabel>
                    <RadioGroup
                      value={
                        contract.pep !== undefined
                          ? `${contract.pep}`
                          : undefined
                      }
                      onChange={evt => {
                        setModifiedContract({
                          ...modifiedContract,
                          pep: evt.target.value === 'true',
                        });
                      }}
                      row
                    >
                      <FormControlLabel
                        value="true"
                        control={<Radio disabled={!isDraftOrActivated} />}
                        label="Ja"
                      />
                      <FormControlLabel
                        value="false"
                        control={<Radio disabled={!isDraftOrActivated} />}
                        label="Nej"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">
                      {L.agreementContractSignee.consent}
                    </FormLabel>
                    <RadioGroup
                      value={
                        contract.pep !== undefined
                          ? `${contract.consent}`
                          : undefined
                      }
                      onChange={evt => {
                        setModifiedContract({
                          ...modifiedContract,
                          consent: evt.target.value === 'true',
                        });
                      }}
                      row
                    >
                      <FormControlLabel
                        value="true"
                        control={<Radio disabled={!isDraftOrActivated} />}
                        label="Ja"
                      />
                      <FormControlLabel
                        value="false"
                        control={<Radio disabled={!isDraftOrActivated} />}
                        label="Nej"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">
                      {L.agreementContractSignee.ownersHome}
                    </FormLabel>
                    <RadioGroup
                      value={
                        contract.ownersHome !== undefined
                          ? `${contract.ownersHome}`
                          : undefined
                      }
                      onChange={evt => {
                        const newOwnersHome = evt.target.value === 'true';
                        setModifiedContract({
                          ...modifiedContract,
                          ownersHome: newOwnersHome,
                        });
                      }}
                      row
                    >
                      <FormControlLabel
                        value="true"
                        control={<Radio disabled={!isDraftOrActivated} />}
                        label="Ja"
                      />
                      <FormControlLabel
                        value="false"
                        control={<Radio disabled={!isDraftOrActivated} />}
                        label="Nej"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormLabel component="legend">
                    {L.agreementContractSignee.kyc}
                  </FormLabel>
                  <FormGroup row>
                    {[
                      {
                        label: L.agreementContractSignee.kycLivingArea,
                        value: AgreementContractKyc.LivingArea,
                      },
                      {
                        label: L.agreementContractSignee.kycNotUsed,
                        value: AgreementContractKyc.NotUsed,
                      },
                      {
                        label: L.agreementContractSignee.kycOtherTown,
                        value: AgreementContractKyc.OtherTown,
                      },
                      {
                        label: L.agreementContractSignee.kycOtherCountry,
                        value: AgreementContractKyc.OtherCountry,
                      },
                      {
                        label: L.agreementContractSignee.kycOther,
                        value: AgreementContractKyc.OtherReason,
                      },
                    ].map(({ label, value }) => {
                      return (
                        <FormControlLabel
                          disabled={!isDraftOrActivated}
                          control={
                            <Checkbox
                              checked={contract.kyc?.includes(value) ?? false}
                              value={value}
                              onChange={(_1, checked) => {
                                const kycWithoutValue =
                                  contract.kyc?.filter(kyc => kyc !== value) ??
                                  [];

                                setModifiedContract({
                                  ...modifiedContract,
                                  kyc: checked
                                    ? [...kycWithoutValue, value]
                                    : kycWithoutValue,
                                });
                              }}
                            />
                          }
                          key={value}
                          label={label}
                        />
                      );
                    })}
                  </FormGroup>
                </Grid>
              </Grid>
              <Grid item xs={12} container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h6">
                    {L.agreementContractSignee.signee}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    color="secondary"
                    disabled={loading || !isDraftOrActivated}
                    variant="contained"
                    onClick={() => {
                      handleCreateSignee();
                    }}
                  >
                    {L.agreementContractSignee.addSignee}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
          <DataGrid
            autoHeight
            columns={columns}
            rows={signees ?? []}
            sortModel={sortModel}
            onSortModelChange={setSortModel}
            disableRowSelectionOnClick
            isCellEditable={params =>
              params.row.role !== 'AGENT' &&
              (params.field === 'email' || params.field === 'name')
            }
          />
        </Card>

        <ConfirmationDialog
          open={!!dialogDeleteSigneeId}
          onConfirm={() => {
            if (dialogDeleteSigneeId) {
              handleDeleteSignee(dialogDeleteSigneeId);
              setDialogDeleteSigneeId(undefined);
            }
          }}
          onCancel={() => setDialogDeleteSigneeId(undefined)}
          title={L.agreementContractSignee.confirmTitle}
          content={L.agreementContractSignee.confirmContent}
        />
        <AgreementContractUserPicker
          open={!!dialogPickUserSignee}
          limitToUserRoles={
            dialogPickUserSignee?.role === 'AGENT' ? ['admin'] : undefined
          }
          onCancel={() => setDialogPickUserSignee(undefined)}
          onConfirm={user => {
            if (!dialogPickUserSignee) {
              return;
            }

            updateSignee({
              id: dialogPickUserSignee.id,
              email: user.email,
              name: user.name,
              userId: user.id,
            });
            setDialogPickUserSignee(undefined);
          }}
        />
        <ConfirmationDialog
          open={showUnsavedCloseDialog}
          onConfirm={() => {
            onClose();
          }}
          onCancel={() => setShowUnsavedCloseDialog(false)}
          title={L.agreementContractSignee.confirmTitle}
          content={L.agreementContractSignee.unsavedCloseContent}
        />
        <PopupAlert />

        <SlideButton modified={isSigneesModified || isContractModified} />
      </form>
      <EmailDialog
        defaultType={BulkEmailType.AgreementContractActivation}
        recipients={[
          { id: initiator?.userId ?? '', email: initiator?.email ?? '' },
        ]}
        userFilter={{}}
        userFilterCount={-1}
        open={dialogOpen}
        onSent={async () => {
          // FIXME: DO something
        }}
        onClose={() => setDialogOpen(false)}
        onOverrideSend={async ({
          body,
          subject,
        }: {
          body: string;
          subject: string;
        }) => {
          setDialogOpen(false);
          await handleActivateContract(body, subject);
        }}
        typeDatas={[
          {
            type: BulkEmailType.AgreementContractActivation,
            unsubscribeType: BulkEmailUnsubscribeType.None,
            description: 'Aktivera avtalet',
            message: locale.backoffice.emailDialog.agreementContractActivation,
            signatureType: 'withLogo',
          },
        ]}
        bodySubstitutions={[
          {
            regex: /{contractLink}/g,
            value:
              `<a href="${intitatorLink}">${initiatorLinkContent}</a>` ?? '',
          },
        ]}
        skipFirstTwoSteps
        homeId={Number(homeId)}
      />
    </>
  );
};

export default AgreementContract;
