import { useMutation, useQuery } from '@apollo/client';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import {
  CardContent,
  Checkbox,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
} from '@mui/material';
import { DataGrid, GridColDef, GridSortModel } from '@mui/x-data-grid';
import { sv as locale } from '@norban/locale';
import React, { useCallback, useMemo, useReducer, useState } from 'react';
import { useLocation } from 'react-router';

import CrmActionDialog from '../../components/CrmActionDialog';
import PageHeader from '../../components/PageHeader';
import RootCard from '../../components/RootCard';
import {
  BofEventsCrmActionUpdateDocument,
  BofEventsCrmActionsDocument,
  BofEventsCrmActionsFragment,
  CrmActionType,
} from '../../generated/backend/graphql';

const L = locale.backoffice.events;

type FilterType =
  | 'showUnhandledUnassigned'
  | 'showUnandledAndAssigned'
  | 'showHandled';

const Events = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const filterMode = queryParams.get('filterMode');

  const [filter, setFilter] = useState<FilterType>(
    (filterMode as FilterType) ?? 'showUnhandledUnassigned',
  );
  const assignedUserId = queryParams.get('assignedUserId');

  const [handleUpdateCrmAction] = useMutation(
    BofEventsCrmActionUpdateDocument,
    {
      update: cache => {
        cache.evict({ fieldName: 'crmActions' });
        cache.gc();
      },
    },
  );

  const getUser = (row: BofEventsCrmActionsFragment) => {
    return row.user ?? row.home?.user;
  };

  const getHome = (row: BofEventsCrmActionsFragment) => {
    return row.home ?? row.user?.home;
  };

  const columns: GridColDef<BofEventsCrmActionsFragment>[] = useMemo(
    () => [
      {
        field: 'event',
        headerName: L.event,
        width: 250,
        valueGetter: ({ row }) => row.action,
      },
      {
        field: 'link',
        headerName: L.link,
        sortable: false,
        width: 80,
        valueGetter: ({ row }) => {
          if (row.home?.id) {
            return `/homes/${row.home.id}/crm`;
          }

          if (row.user?.id) {
            return `/users/${row.user.id}/crm`;
          }

          return undefined;
        },
        renderCell: ({ value }) =>
          value ? (
            <IconButton
              aria-label="vitec link"
              href={value}
              target="_blank"
              rel="noopener noreferrer"
              onClick={e => e.stopPropagation()}
            >
              <OpenInNewIcon />
            </IconButton>
          ) : null,
      },
      {
        field: 'userName',
        headerName: L.userName,
        width: 150,
        valueGetter: ({ row }) => getUser(row)?.name,
        renderCell: ({ value }) => value ?? '-',
      },
      {
        field: 'homeAddress',
        headerName: L.homeAddress,
        width: 150,
        valueGetter: ({ row }) => getHome(row)?.address?.streetAddress,
        renderCell: ({ value }) => value ?? '-',
      },
      {
        field: 'assignedAgent',
        headerName: L.assignedAgent,
        width: 150,
        valueGetter: ({ row }) => getHome(row)?.assignedAgent?.name,
      },
      {
        field: 'date',
        headerName: L.date,
        type: 'date',
        width: 150,
        valueGetter: ({ row }) => new Date(row.targetDate),
        renderCell: ({ value }) =>
          new Intl.DateTimeFormat('sv-SE', {
            day: 'numeric',
            month: 'short',
            year: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
          }).format(value),
      },
      {
        field: 'assignedTo',
        headerName: L.assignedUser,
        valueGetter: ({ row }) =>
          row.assignedUser
            ? `${row.assignedUser.name} ${row.assignedUser?.id}`
            : '-',
        width: 200,
      },
      {
        field: 'handled',
        headerName: L.handled,
        sortable: false,
        width: 80,
        valueGetter: ({ row }) => row.completed,
        renderCell: ({ value, row }) => (
          <Checkbox
            checked={value}
            onClick={e => e.stopPropagation()}
            onChange={() => {
              handleUpdateCrmAction({
                variables: {
                  id: row.id,
                  input: {
                    completed: !value,
                  },
                },
              });
            }}
          />
        ),
      },
    ],
    [handleUpdateCrmAction],
  );

  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: 'createdAt',
      sort: 'desc',
    },
  ]);

  const assignedFilter = useMemo(() => {
    if (filter === 'showUnhandledUnassigned') {
      return false;
    }

    if (filter === 'showUnandledAndAssigned') {
      return true;
    }

    return undefined;
  }, [filter]);

  const { data } = useQuery(BofEventsCrmActionsDocument, {
    variables: {
      filter: {
        completed: filter === 'showHandled',
        assigned: assignedFilter,
        types: [CrmActionType.Event],
      },
    },
  });

  const rows = useMemo<BofEventsCrmActionsFragment[]>(() => {
    const actions = data?.crmActions ?? [];
    return assignedUserId
      ? actions.filter(item => item.assignedUser?.id === assignedUserId)
      : actions;
  }, [assignedUserId, data?.crmActions]);

  const [{ open: editDialogOpen, entry: editDialogEntry }, setDialogEntry] =
    useReducer(
      (
        oldState: { open: boolean; entry?: BofEventsCrmActionsFragment },
        selectedEntry: BofEventsCrmActionsFragment | undefined,
      ) =>
        selectedEntry === undefined
          ? { open: false, entry: oldState.entry }
          : { open: true, entry: selectedEntry },
      { open: false, entry: undefined },
    );
  const handleRowClick = useCallback(
    (id: string) => {
      const row = rows.find(r => r.id === id);
      if (!row) {
        return;
      }

      setDialogEntry(row);
    },
    [rows],
  );

  return (
    <>
      <CrmActionDialog
        open={editDialogOpen}
        entry={editDialogEntry}
        onClose={() => {
          setDialogEntry(undefined);
        }}
        onSubmit={async updates => {
          if (!editDialogEntry) {
            return;
          }

          await handleUpdateCrmAction({
            variables: {
              id: editDialogEntry?.id,
              input: {
                action: updates.action,
                targetDate: updates.targetDate,
                assignedUserId:
                  updates.assignedUser === null
                    ? null
                    : updates.assignedUser?.id,
                completed: updates.completed,
              },
            },
          });
        }}
      />
      <PageHeader title={L.title} />
      <RootCard>
        <CardContent>
          <RadioGroup
            aria-label="Filterinställning"
            value={filter}
            onChange={(_event, value) => {
              setFilter(value as typeof filter);
            }}
            row
          >
            <FormControlLabel
              value="showUnhandledUnassigned"
              control={<Radio />}
              label={L.showUnhandledUnassigned}
            />
            <FormControlLabel
              value="showUnandledAndAssigned"
              control={<Radio />}
              label={L.showUnandledAndAssigned}
            />
            <FormControlLabel
              value="showHandled"
              control={<Radio />}
              label={L.showHandled}
            />
          </RadioGroup>
        </CardContent>
        <DataGrid
          autoHeight
          columns={columns}
          disableRowSelectionOnClick
          rows={rows}
          onRowClick={({ row }) => handleRowClick(row.id)}
          sortModel={sortModel}
          onSortModelChange={model => setSortModel(model)}
        />
      </RootCard>
    </>
  );
};

export default Events;
