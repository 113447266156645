import { ApiPayloadZod } from '@norban/utils/dist/runtimeTypes/jwtPayloads';
import { jwtDecode } from 'jwt-decode';
import React, {
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { WEB_URI } from '../config';
import {
  InputSessionLoginEmail,
  InputSessionMutation,
  SessionState,
  SessionValue,
} from '../generated/backend/graphql';

const anonymousSessionValue: SessionValue = {
  id: '',
  state: SessionState.Anonymous,
  jwt: null,
};

// Infer user id from jwt and add it to the session value (for convenience)
type SessionValueWithInferredData = SessionValue & {
  userId?: number;
  role?: string;
};

const SessionContext = React.createContext<{
  initialLoadDone: boolean;
  value?: SessionValue;
  mutate: (
    mutation: InputSessionMutation,
  ) => Promise<SessionValueWithInferredData>;
}>({
  initialLoadDone: false,
  ...anonymousSessionValue,
  mutate: async () => anonymousSessionValue,
});

function jwtToUserData(token?: string | null) {
  if (!token) {
    return {};
  }

  try {
    const decodedToken = jwtDecode(token);
    const validatedPayload = ApiPayloadZod.parse(decodedToken);
    return {
      userId: validatedPayload.user,
      role: validatedPayload.role,
    };
  } catch (e) {
    return {};
  }
}

// Utility functions to modify or refresh the session
type Mutators = {
  initialLoadDone: boolean;
  refreshJwt: () => Promise<string | undefined>;
  loginEmail: (
    input: InputSessionLoginEmail,
  ) => Promise<SessionValueWithInferredData>;
  logout: () => Promise<SessionValueWithInferredData>;
};

const useSession = (): SessionValueWithInferredData & Mutators => {
  const sessionContext = useContext(SessionContext);
  const mutate = useMemo(() => sessionContext.mutate, [sessionContext.mutate]);

  const { userId, role } = useMemo(
    () => jwtToUserData(sessionContext.value?.jwt),
    [sessionContext.value?.jwt],
  );

  const refreshJwt = useCallback(
    async () => (await mutate({})).jwt ?? undefined,
    [mutate],
  );

  const loginEmail = useCallback(
    (input: InputSessionLoginEmail) => mutate({ loginEmail: input }),
    [mutate],
  );

  const sessionValue = useMemo(() => {
    if (!sessionContext.value) {
      return anonymousSessionValue;
    }

    return sessionContext.value;
  }, [sessionContext.value]);

  const logout = useCallback(() => mutate({ logout: true }), [mutate]);

  return {
    initialLoadDone: sessionContext.initialLoadDone,
    // Add session
    ...sessionValue,
    // Add inferred data
    userId,
    role,
    // Add mutators
    refreshJwt,
    loginEmail,
    logout,
  };
};
export default useSession;

const mutateSession = async (mutation: InputSessionMutation) => {
  const response = await fetch(`${WEB_URI}/session`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(mutation),
    credentials: 'include',
  });

  // If empty response, return anonymous session
  if (response.status === 204) {
    return anonymousSessionValue;
  }

  // Handle 5XX errors
  if (response.status >= 500) {
    throw new Error(`Server error, status code: ${response.status}`);
  }

  return (await response.json()) as SessionValue;
};

export function SessionProvider({
  children = null,
}: PropsWithChildren<unknown>) {
  const [initialLoadDone, setInitialLoadDone] = useState(false);
  const [value, setValue] = useState<SessionValue | undefined>(undefined);

  const mutate = useCallback(async (mutation: InputSessionMutation) => {
    const newValue = await mutateSession(mutation);
    setValue(newValue);

    const { userId, role } = jwtToUserData(newValue.jwt);
    return { ...newValue, userId, role };
  }, []);

  const context = useMemo(
    () => ({ initialLoadDone, value, mutate }),
    [initialLoadDone, mutate, value],
  );

  useEffect(() => {
    (async () => {
      await mutate({});
      setInitialLoadDone(true);
    })();
  }, [mutate, setInitialLoadDone]);

  return (
    <SessionContext.Provider value={context}>
      {children}
    </SessionContext.Provider>
  );
}
