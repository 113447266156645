import { Grid, Switch, Tooltip } from '@mui/material';
import React, { PropsWithChildren, useCallback } from 'react';

function FilterRow({
  icon,
  label,
  checked = undefined,
  onCheckedChanged = undefined,
  children = null,
}: PropsWithChildren<{
  icon: React.ReactNode;
  label: string;
  checked?: boolean;
  onCheckedChanged?: (checked: boolean) => void;
}>) {
  const EnableCheckbox = useCallback(() => {
    if (onCheckedChanged === undefined) {
      return null;
    }

    return (
      <Switch
        size="medium"
        checked={checked}
        color="primary"
        onChange={(_, checked) => onCheckedChanged(checked)}
      />
    );
  }, [checked, onCheckedChanged]);

  return (
    <>
      <Tooltip title={label} placement="left">
        <Grid item xs={12} md={2} xl={1} display="flex" alignItems="center">
          {icon}
          <EnableCheckbox />
        </Grid>
      </Tooltip>
      <Grid item xs={12} md={10} xl={11} display="flex" flexWrap="wrap">
        <Grid container>{children}</Grid>
      </Grid>
    </>
  );
}

export default FilterRow;
