// Read only by design.

import { Box, Grid, Typography, TextField } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { sv as locale } from '@norban/locale';
import cx from 'classnames';
import React, { useEffect } from 'react';

const strings =
  locale.backoffice.tasks.PUT_HOME_ON_MAP.states.AVAILABLE.content;

const useStyles = makeStyles({
  words: {
    display: 'flex',
    margin: '6px 0',
    flexWrap: 'wrap',
  },

  word: {
    color: '#888',
    marginRight: '40px',
  },

  selected: {
    color: '#000',
    fontWeight: 'bold',
  },

  textField: {
    marginRight: '8px',
  },
});

const WordSelection = ({ words, selected }) => {
  const classes = useStyles();
  const selectedArray = Array.isArray(selected) ? selected : [selected];

  return (
    <div className={classes.words}>
      {words.map(word => (
        <div
          key={word}
          className={cx({
            [classes.word]: true,
            [classes.selected]: selectedArray.includes(word),
          })}
        >
          {word}
        </div>
      ))}
    </div>
  );
};

const AccountCreationForm = props => {
  const classes = useStyles();
  const { home, submitting, updateUser } = props;
  const homeTypes = locale.backoffice.enumerations.HomeType;
  const estimateTypes = locale.backoffice.enumerations.EstimateType;
  const { type: homeType } = home;
  const additionalSpaceTypes = locale.backoffice.enumerations.AdditionalSpace;

  useEffect(() => {
    if (submitting) {
      updateUser();
    }
  }, [submitting, updateUser]);

  const additionalSpace = [];
  if (home.balcony) {
    additionalSpace.push(additionalSpaceTypes.BALCONY);
  }
  if (home.patio) {
    additionalSpace.push(additionalSpaceTypes.PATIO);
  }
  if (additionalSpace.length === 0) {
    additionalSpace.push('None');
  }

  const caption = text => (
    <Typography component="div" variant="button">
      {text}
    </Typography>
  );

  return (
    <Grid container spacing={4} style={{ maxWidth: 520 }}>
      <Grid item xs={12}>
        {caption(strings.location)}
        <WordSelection
          words={['Helsingborg', 'Stockholm', 'Malmö', 'Göteborg']}
          selected="Helsingborg"
        />
      </Grid>

      <Grid item xs={12}>
        {caption(strings.typeOfHome)}
        <WordSelection
          words={Object.values(homeTypes)}
          selected={homeTypes[homeType]}
        />
      </Grid>

      <Grid item xs={12}>
        {caption(strings.roomCount)}
        <TextField
          inputProps={{ readOnly: true }}
          value={home.numberOfRooms}
          fullWidth
        />
      </Grid>

      <Grid item xs={12}>
        {caption(strings.livingArea)}
        <TextField
          inputProps={{ readOnly: true }}
          value={home.livingArea}
          fullWidth
        />
      </Grid>

      <Grid item xs={12}>
        {caption(strings.exteriorSpace)}
        <WordSelection
          words={[...Object.values(additionalSpaceTypes), 'None']}
          selected={additionalSpace}
        />
      </Grid>

      <Grid item xs={12}>
        {caption(strings.floor)}
        <TextField
          inputProps={{ readOnly: true }}
          value={home.floor}
          fullWidth
        />
      </Grid>

      <Grid item xs={12}>
        {caption(strings.elevator)}
        <WordSelection
          words={['Yes', 'No']}
          selected={home.elevator ? 'Yes' : 'No'}
        />
      </Grid>

      <Grid item xs={12}>
        {caption(strings.sellingEstimate)}
        <WordSelection
          words={Object.values(estimateTypes)}
          selected={estimateTypes[home.sellingEstimate]}
        />
      </Grid>

      <Grid item xs={12}>
        {caption(strings.address)}
        <Box mb={1}>
          <TextField
            className={classes.textField}
            inputProps={{ readOnly: true }}
            value={home.address.streetAddress}
            fullWidth
          />
        </Box>
        <Box mb={1}>
          <TextField
            className={classes.textField}
            inputProps={{ readOnly: true }}
            value={home.address.zipCode}
            fullWidth
          />
        </Box>
        <Box mb={1}>
          <TextField
            className={classes.textField}
            inputProps={{ readOnly: true }}
            value={home.address.city}
            fullWidth
          />
        </Box>
        <TextField
          inputProps={{ readOnly: true }}
          value={home.area && home.area.name}
          fullWidth
        />
      </Grid>
    </Grid>
  );
};

export default AccountCreationForm;
