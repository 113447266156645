import type { GridFilterModel, GridSortModel } from '@mui/x-data-grid';

export const STORE_SORT_MODEL = 'STORE_SORT_MODEL';
export const STORE_FILTER_MODEL = 'STORE_FILTER_MODEL';

export type AreaListType = {
  sortModel?: GridSortModel;
  filterModel?: GridFilterModel;
};

export default (
  state = {},
  action: { type?: string; value?: unknown } = {},
) => {
  const { type, value } = action;

  switch (type) {
    case STORE_SORT_MODEL:
      return {
        ...state,
        sortModel: value,
      };
    case STORE_FILTER_MODEL:
      return {
        ...state,
        filterModel: value,
      };
    default:
      return state;
  }
};
