import { makeStyles } from '@mui/styles';
import classnames from 'classnames';
import moment from 'moment';
import React from 'react';

const dynamicStyles = {
  title: incoming => ({
    display: 'flex',
    fontSize: 12,
    justifyContent: incoming ? 'flex-start' : 'flex-end',
    marginLeft: incoming ? 6 : 0,
    marginRight: incoming ? 0 : 6,
  }),
};

const useStyles = makeStyles({
  bubble: {
    backgroundColor: '#2196f3',
    borderRadius: '16px',
    color: '#fff',
    display: 'flex',
    flexShrink: 0,
    fontSize: '0.8125rem',
    padding: '4px 12px',
    marginBottom: '8px',
    whiteSpace: 'pre-wrap',
  },

  incomingBubble: {
    backgroundColor: '#e0e0e0',
    color: '#000',
  },

  bubbleContainer: {
    display: 'flex',
    margin: '2px',
  },

  message: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '75%',
  },
  date: {
    color: '#888',
  },
  initials: {
    marginRight: 4,
  },

  incoming: {
    justifyContent: 'flex-start',
  },

  outgoing: {
    justifyContent: 'flex-end',
  },
});

const ChatBubble = ({
  sender,
  message,
  incoming,
  timestamp,
  lastRead,
  displayNames,
}) => {
  const classes = useStyles();

  const containerClass = classnames({
    [classes.bubbleContainer]: true,
    [classes.incoming]: incoming,
    [classes.outgoing]: !incoming,
  });

  const bubbleClass = classnames({
    [classes.bubble]: true,
    [classes.incomingBubble]: incoming,
  });

  const findDisplayName = () => {
    const dn = displayNames?.find(dn => dn.id === sender.id);

    return dn?.displayName;
  };

  const displayName = findDisplayName();

  const initials =
    sender.name
      ?.split(' ')
      .map(n => n[0])
      .join('') ?? '';

  const name = displayName || initials;

  return (
    <div className={containerClass}>
      <div className={classes.message}>
        <span style={dynamicStyles.title(incoming)}>
          <b className={classes.initials}>{name}</b>
          <span className={classes.date}>
            {moment(timestamp).format('HH:mm')}
          </span>
          {lastRead && incoming && lastRead > timestamp && <span>✓</span>}
        </span>
        <div className={bubbleClass}>{message}</div>
      </div>
    </div>
  );
};

export default ChatBubble;
