import { Paper, Tab, Tabs } from '@mui/material';
import { sv as locale } from '@norban/locale';
import React, { useState } from 'react';
import { Route, Switch, useLocation, useRouteMatch } from 'react-router';
import { Link } from 'react-router-dom';

import StatsFollowers from './components/StatsFollowers';
import StatsFollowerVsHome from './components/StatsFollowerVsHome';
import StatsHomes from './components/StatsHomes';
import StatsMonitors from './components/StatsMonitors';

const L = locale.backoffice.stats;

const Stats = () => {
  const { pathname } = useLocation();

  const [selectedTab, setSelectedTab] = useState(pathname || '/stats/homes');

  const { path, url } = useRouteMatch();

  return (
    <Paper>
      <Tabs
        variant="fullWidth"
        value={selectedTab}
        onChange={(_, tab) => setSelectedTab(tab)}
      >
        <Tab
          value={`${url}/homes`}
          label={L.homes}
          component={Link}
          to={`${url}/homes`}
        />
        <Tab
          value={`${url}/followers`}
          label={L.followers}
          component={Link}
          to={`${url}/followers`}
        />
        <Tab
          value={`${url}/followerVsHome`}
          label={L.followerVsHome}
          component={Link}
          to={`${url}/followerVsHome`}
        />
        <Tab
          value={`${url}/monitors`}
          label={L.monitors}
          component={Link}
          to={`${url}/monitors`}
        />
      </Tabs>

      <Switch>
        <Route path={`${path}/homes`}>
          <StatsHomes />
        </Route>

        <Route path={`${path}/followers`}>
          <StatsFollowers />
        </Route>

        <Route path={`${path}/followerVsHome`}>
          <StatsFollowerVsHome />
        </Route>

        <Route path={`${path}/monitors`}>
          <StatsMonitors />
        </Route>
      </Switch>
    </Paper>
  );
};

export default Stats;
