import AddHomeIcon from '@mui/icons-material/AddHome';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import HomeIcon from '@mui/icons-material/Home';
import LinkIcon from '@mui/icons-material/Link';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import ListAltIcon from '@mui/icons-material/ListAlt';
import NotificationAddOutlinedIcon from '@mui/icons-material/NotificationAddOutlined';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import PhotoOutlinedIcon from '@mui/icons-material/PhotoOutlined';
import SmsOutlinedIcon from '@mui/icons-material/SmsOutlined';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import VisibilityIcon from '@mui/icons-material/Visibility';
import WavingHandOutlinedIcon from '@mui/icons-material/WavingHandOutlined';
import React from 'react';

import { EventType } from '../generated/backend/graphql';

export const activityLogMap: Record<string, JSX.Element> = {
  [EventType.ChatReminder]: <NotificationAddOutlinedIcon />,
  [EventType.CrmCustomEntry]: <AssignmentTurnedInIcon />,
  [EventType.CurrentTaskTransition]: <ListAltIcon />,
  [EventType.EmailRecipient]: <EmailOutlinedIcon />,
  [EventType.EmailFeedback]: <EmailOutlinedIcon />,
  [EventType.HomeAgentUpdated]: <SupportAgentIcon />,
  [EventType.HomeBulkMail]: <EmailOutlinedIcon />,
  [EventType.HomeChangeState]: <HomeIcon />,
  [EventType.HomeMessage]: <WavingHandOutlinedIcon />,
  [EventType.HomeMonitorCandidateCreated]: <AddHomeIcon />,
  [EventType.HomeOwnerConnected]: <LinkIcon />,
  [EventType.HomeOwnerDisconnected]: <LinkOffIcon />,
  [EventType.ReviewOfUploadedImages]: <PhotoOutlinedIcon />,
  [EventType.ViewingParticipantCreated]: <VisibilityIcon />,
  [EventType.UserCreated]: <PersonAddIcon />,
  [EventType.SmsSent]: <SmsOutlinedIcon />,
};
