import { Card, CardContent, CardHeader } from '@mui/material';
import { sv as locale } from '@norban/locale';
import React from 'react';

import TextField from '../../../../components/form/TextField';
import { BofHomeProfileHomeFragment } from '../../../../generated/backend/graphql';
import { DispatchType } from '../../hooks/useHomeProfileReducer';

type Props = {
  home: BofHomeProfileHomeFragment;
  dispatch: DispatchType;
};

const NotesCard = ({ home, dispatch }: Props) => {
  const L = locale.backoffice.homeNotesCard;

  return (
    <Card>
      <CardHeader title={L.title} />
      <CardContent>
        <TextField
          label={L.title}
          value={home.notes}
          name="other"
          multiline
          rows="8"
          dispatch={({ value }) => dispatch({ key: 'notes', value })}
        />
      </CardContent>
    </Card>
  );
};

export default NotesCard;
