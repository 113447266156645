export function buildAgreementContractUrl(
  base: string,
  role: string,
  contractId: string,
  limitedScopeToken: string,
  signeeId?: string,
) {
  const linkUrl = new URL(
    `${base}/${
      role === 'INITIATOR' ? 'uppgradering' : 'uppgradering-medagare'
    }/${contractId}`,
  );
  if (signeeId) {
    linkUrl.searchParams.set('signee', `${signeeId}`);
  }
  linkUrl.searchParams.set('lt', limitedScopeToken);

  return linkUrl.toString();
}
