import { useQuery } from '@apollo/client';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import {
  Box,
  Button,
  CardContent,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Snackbar,
} from '@mui/material';
import { sv as locale } from '@norban/locale';
import {
  addDays,
  differenceInDays,
  endOfDay,
  startOfDay,
  subDays,
} from 'date-fns';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  storeCapByLimit,
  storeEndDate,
  storeIncludeHandled,
  storeOwnership,
  storeStartDate,
} from '../../actions/chatActions';
import DateSelector from '../../components/DateSelector';
import PageHeader from '../../components/PageHeader';
import RootCard from '../../components/RootCard';
import {
  BofChatAdminsDocument,
  BofChatOwnersDocument,
  ChatOwnershipType,
} from '../../generated/backend/graphql';
import { ChatFilterType } from '../../reducers/chatFilter';
import { insertIf } from '../../utils';

import './Chats.css';
import ChatAdministerDialog from './components/ChatAdministerDialog';
import ChatTable from './components/ChatTable';

const Chats = () => {
  const L = locale.backoffice;

  const now = new Date();

  // Global store
  const dispatch = useDispatch();
  const startDate = useSelector(
    (state: { chatFilter: ChatFilterType }) =>
      state.chatFilter.startDate ?? now,
  );
  const endDate = useSelector(
    (state: { chatFilter: ChatFilterType }) => state.chatFilter.endDate ?? now,
  );
  const ownership = useSelector(
    (state: { chatFilter: ChatFilterType }) =>
      (state.chatFilter.ownership as ChatOwnershipType) ??
      ChatOwnershipType.Unassigned,
  );
  const includeHandled = useSelector(
    (state: { chatFilter: ChatFilterType }) =>
      state.chatFilter.includeHandled || false,
  );
  const capByLimit = useSelector((state: { chatFilter: ChatFilterType }) =>
    state.chatFilter.capByLimit !== undefined
      ? state.chatFilter.capByLimit
      : true,
  );

  const [snackbar, setSnackbar] = useState({ open: false, message: '' });
  const [administer, setAdminister] = useState({
    open: false,
    user: '',
    owner: '',
    type: 'take',
  });

  const { loading, data, refetch } = useQuery(BofChatOwnersDocument, {
    variables: {
      filter: {
        includeHandled,
        ownership,
        ...insertIf(!capByLimit, {
          date: {
            startDate: startDate && startOfDay(new Date(startDate)),
            endDate: endDate && endOfDay(new Date(endDate)),
          },
        }),
        ...insertIf(capByLimit, {
          limit: 100,
        }),
      },
    },
  });
  const { data: dataAdmins } = useQuery(BofChatAdminsDocument, {
    skip: !data?.chatOwners,
  });

  const onRowClick = (
    userId: string | null | undefined,
    ownerId: string | null | undefined,
  ) => {
    setAdminister({
      open: true,
      user: `${userId}`,
      owner: ownerId ? `${ownerId}` : '',
      type: ownerId ? 'release' : 'take',
    });
  };

  // key handler
  const ctrlPressed = useRef<boolean | null>(null);

  useEffect(() => {
    const handleDown = (e: KeyboardEvent) => {
      if (e.key === 'Control') {
        ctrlPressed.current = true;
      }
      if (e.key === 'd' && ctrlPressed.current) {
        storeCapByLimit(dispatch, !capByLimit);
      }
    };
    const handleUp = (e: KeyboardEvent) => {
      if (e.key === 'Control') {
        ctrlPressed.current = false;
      }
    };

    window.addEventListener('keydown', handleDown);
    window.addEventListener('keyup', handleUp);

    return () => {
      window.removeEventListener('keydown', handleDown);
      window.removeEventListener('keyup', handleUp);
    };
  }, [capByLimit, dispatch]);

  return (
    <>
      <PageHeader
        title={L.chats.title}
        buttons={[
          {
            text: L.chats.administer,
            icon: <SupervisorAccountIcon />,
            onClick: () =>
              setAdminister({
                open: true,
                user: '',
                owner: '',
                type: 'take',
              }),
          },
        ]}
      />
      <RootCard>
        <CardContent>
          <RadioGroup
            aria-label="Tilldelad"
            value={ownership}
            onChange={async evt => {
              storeOwnership(dispatch, evt.target.value);
            }}
            row
          >
            <FormControlLabel
              value={ChatOwnershipType.Unassigned}
              control={<Radio />}
              label={L.chats.unassigned}
            />
            <FormControlLabel
              value={ChatOwnershipType.ByOwnershipId}
              control={<Radio />}
              label={L.chats.me}
            />
            <FormControlLabel
              value={ChatOwnershipType.Others}
              control={<Radio />}
              label={L.chats.others}
            />
            <FormControlLabel
              value={ChatOwnershipType.All}
              control={<Radio />}
              label={L.chats.all}
            />
          </RadioGroup>
          <Box mb={1}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={includeHandled}
                  value={includeHandled}
                  onChange={evt =>
                    storeIncludeHandled(dispatch, evt.target.checked)
                  }
                />
              }
              label={L.chats.includeHandled}
            />
          </Box>
        </CardContent>
        <Grid container>
          {!capByLimit && (
            <Grid item xs={12} sm={7} md={8}>
              <Box mt={1}>
                <Box mr={1} display="inline">
                  <Button
                    variant="outlined"
                    onClick={() => {
                      storeStartDate(dispatch, now);
                      storeEndDate(dispatch, now);
                    }}
                    disabled={capByLimit}
                  >
                    {L.chats.today}
                  </Button>
                </Box>
                <Box mr={1} display="inline">
                  <Button
                    variant="outlined"
                    onClick={() => {
                      const startDate = subDays(now, 7);
                      storeEndDate(dispatch, now);
                      storeStartDate(dispatch, startDate);
                    }}
                    disabled={capByLimit}
                  >
                    {L.chats.minus7days}
                  </Button>
                </Box>
                <Button
                  variant="outlined"
                  onClick={() => {
                    const startDate = subDays(now, 30);
                    storeStartDate(dispatch, startDate);
                    storeEndDate(dispatch, now);
                  }}
                  disabled={capByLimit}
                >
                  {L.chats.minus30days}
                </Button>
              </Box>
              <DateSelector
                startDate={startDate}
                startLabel={L.from}
                endDate={endDate}
                endLabel={L.to}
                onChangeStartDate={(date: Date | null) => {
                  // Is the date valid?
                  if (date && !isNaN(date.getTime())) {
                    storeStartDate(dispatch, date);
                    const diff = differenceInDays(
                      new Date(date),
                      new Date(endDate),
                    );
                    if (Math.abs(diff) > 30) {
                      storeEndDate(dispatch, addDays(new Date(date), 30));
                    }
                  }
                }}
                onChangeEndDate={(date: Date | null) => {
                  // Is the date valid?
                  if (date && !isNaN(date.getTime())) {
                    storeEndDate(dispatch, date);
                    const diff = differenceInDays(
                      new Date(date),
                      new Date(startDate),
                    );
                    if (Math.abs(diff) > 30) {
                      storeStartDate(dispatch, subDays(new Date(date), 30));
                    }
                  }
                }}
                disabled={capByLimit}
              />
            </Grid>
          )}
        </Grid>

        <div className="Chats-divider">
          <Divider />
        </div>

        <ChatTable
          admins={dataAdmins?.users}
          chatOwners={data?.chatOwners}
          loading={loading}
          onRowClick={onRowClick}
        />
      </RootCard>
      <Snackbar
        open={snackbar.open}
        onClose={() => setSnackbar({ open: false, message: '' })}
        message={snackbar.message}
        autoHideDuration={5000}
      />
      <ChatAdministerDialog
        open={administer.open}
        user={administer.user}
        owner={administer.owner}
        type={administer.type}
        onAdminister={async () => {
          await refetch();
        }}
        onError={(message: string) => setSnackbar({ open: true, message })}
        onClose={() => setAdminister({ ...administer, open: false })}
      />
    </>
  );
};

export default Chats;
