import { useQuery, gql } from '@apollo/client';
import { Mutation } from '@apollo/client/react/components';
import React, { useEffect } from 'react';

import QueryError from '../../../../components/QueryError';
import QueryLoading from '../../../../components/QueryLoading';

import TaskView from './TaskView';

const Q_USER = gql`
  query BofUserInTimeline($id: ID) {
    user(id: $id) {
      id
      phone
      bankInformation {
        name
        clearingNumber
        accountNumber
      }
      home {
        id
        tenure
        type
        address {
          streetAddress
          zipCode
          city
        }
        valuationMeeting {
          tenure
          request
          fee
          buildingYear
          renovations
          ownership {
            sellerIsOwner
            hasMoreThanOneOwner
          }
          date
          location
          entryCode
          participants
          result
          notes
        }
        photoShoot {
          request
          includeStaging
          stagingDate
          stylist
          photoShootDate
          photographer
        }
        openMarket {
          request
        }
        contractMeeting {
          date
          location
        }
        accessMeeting {
          date
          location
        }
        viewings {
          startsAt
          endsAt
        }
        area {
          name
        }
        floor
        sellingEstimate
        livingArea
        numberOfRooms
        elevator
        terrace
        askingPrice
        monthlyFee
        buildingYear
        vitecEstateId
        tenure
        adPreviewUrl
        publishingDate
        biddingUrl
        viewingsSummary
        bankAccountNumber
        tags
        descriptiveLines
        showYourHomePreference
      }
    }
  }
`;

const M_UPDATE_USER = gql`
  mutation BofUpdateUser($id: ID!, $input: UpdatedUserInput!) {
    updateUser(userId: $id, input: $input) {
      home {
        id
        timeline {
          tasks {
            type
            state
          }
        }
      }
    }
  }
`;

const PhaseView = props => {
  const { taskStates, updateUser, phase, user } = props;

  return (
    <div>
      {phase.map(task => (
        <TaskView
          key={task.type}
          user={user}
          task={task}
          currentTaskState={taskStates[task.type]}
          title={task.type}
          submitTaskData={(state, userData) => {
            updateUser({
              ...userData,
              home: {
                timeline: state
                  ? {
                      tasks: [
                        {
                          state,
                          type: task.type,
                        },
                      ],
                    }
                  : undefined,
                ...(userData && userData.home),
              },
            });
          }}
        />
      ))}
    </div>
  );
};

const PhaseViewQueryWrapper = ({
  userId,
  phase,
  subscription,
  onSubscriptionHandled,
  timeline,
  refetchTimeline,
}) => {
  const { loading, error, data } = useQuery(Q_USER, {
    variables: { id: userId },
  });

  const { user } = data || {};
  const { home } = user || {};

  useEffect(() => {
    const handleUpdate = async () => {
      await refetchTimeline();
      onSubscriptionHandled();
    };

    if (
      home?.id &&
      subscription?.homeUpdated.id &&
      subscription?.homeUpdated.id === home?.id
    ) {
      handleUpdate();
    }
  }, [
    home?.id,
    onSubscriptionHandled,
    refetchTimeline,
    subscription?.homeUpdated,
  ]);

  if (loading) {
    return <QueryLoading />;
  }

  if (error) {
    return <QueryError error={error} data={data} />;
  }

  if (!timeline) {
    return null;
  }

  return (
    <Mutation mutation={M_UPDATE_USER}>
      {updateUser => {
        const updateUserWrapper = userInput => {
          updateUser({
            variables: {
              id: userId,
              input: userInput,
            },
          });
        };

        const states = timeline.tasks.reduce((tasks, task) => {
          // eslint-disable-next-line no-param-reassign
          tasks[task.type] = task.state;
          return tasks;
        }, {});

        return (
          <PhaseView
            phase={phase}
            userId={userId}
            taskStates={states}
            updateUser={updateUserWrapper}
            user={user}
          />
        );
      }}
    </Mutation>
  );
};

export default PhaseViewQueryWrapper;
