import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material';
import { sv as locale } from '@norban/locale';
import React, { useEffect, useState } from 'react';

const UserLabelDialog = ({ entry, open, onClose, onSubmit }) => {
  const L = locale.backoffice.editUserLabels;

  const [label, setLabel] = useState(entry?.label);
  const [teamsHook, setTeamsHook] = useState(entry?.teamsHook);

  useEffect(() => {
    if (entry) {
      setLabel(entry.label);
      setTeamsHook(entry.teamsHook);
    }
  }, [entry]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{L.title}</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="Label"
          value={label}
          onChange={evt => {
            setLabel(evt.target.value);
          }}
          fullWidth
        />
        <TextField
          margin="dense"
          label="Teams"
          value={teamsHook}
          onChange={evt => {
            setTeamsHook(evt.target.value);
          }}
          fullWidth
        />
      </DialogContent>

      <DialogActions>
        <Button
          onClick={() => {
            onClose();
          }}
          color="primary"
        >
          {L.cancel}
        </Button>
        <Button
          onClick={() => {
            onSubmit({ label, teamsHook, id: entry.id });
            onClose();
          }}
          color="primary"
        >
          {L.submit}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UserLabelDialog;
