import {
  BofAreasAreaInfoFragment,
  BofAreasQuery,
} from '../generated/backend/graphql';

type BofAreasAreaInfoFragmentWithChildren = BofAreasAreaInfoFragment & {
  children?: BofAreasAreaInfoFragmentWithChildren[];
};

export function flattenAreas(
  area: BofAreasAreaInfoFragmentWithChildren,
): BofAreasAreaInfoFragment[] {
  if (!area.children) {
    return [area];
  }

  return [area, ...area.children.flatMap(child => flattenAreas(child))];
}

export const findAreaByIds = (
  ids: string[],
  rootAreas: BofAreasQuery['rootAreas'],
) => {
  if (ids.length === 0) {
    return [];
  }

  const findRecursive = (
    area: BofAreasAreaInfoFragmentWithChildren,
    searchIds: string[],
  ): BofAreasAreaInfoFragment[] => {
    const results = searchIds.includes(area.id) ? [area] : [];

    // Recursively search children, return first where find(child, searchId) !== undefined
    if (!area.children) {
      return results;
    }

    return [
      ...results,
      ...area.children.flatMap(child => findRecursive(child, searchIds)),
    ];
  };

  return rootAreas.flatMap(rootArea => findRecursive(rootArea, ids));
};
