import { Alert, Box, Typography } from '@mui/material';
import { sv as locale } from '@norban/locale';
import React from 'react';

import { SyncFollowerError } from '../generated/backend/graphql';

type Props = {
  errors: SyncFollowerError[];
};

const L = locale.backoffice.userFollowings;

const FollowerSyncErrors = ({ errors }: Props) => (
  <>
    {errors.map(error => (
      <Box key={error.id} mb={2} mt={2}>
        <Alert severity="warning">
          <Typography variant="body1">{`${L.failedSyncFollowing}: ${error.id}`}</Typography>
          {(error.message || error.type) && (
            <Box mt={1}>
              {error.message && (
                <Typography mt={1} variant="body2">
                  {`${L.errorMessage}: ${error.message}`}
                </Typography>
              )}
              {error.type && (
                <Typography mt={1} variant="body2">
                  {`${L.errorType}: ${error.type}`}
                </Typography>
              )}
              {error.validationErrors && (
                <>
                  <Typography mt={1} variant="body2">
                    {L.validationErrors}
                  </Typography>
                  {error.validationErrors.map((validationError, index) => (
                    <Typography variant="body2" key={index}>
                      {validationError}
                    </Typography>
                  ))}
                </>
              )}
            </Box>
          )}
        </Alert>
      </Box>
    ))}
  </>
);

export default FollowerSyncErrors;
