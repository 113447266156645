import { useQuery } from '@apollo/client';
import {
  Alert,
  Card,
  CardContent,
  CircularProgress,
  FormControlLabel,
  Switch,
} from '@mui/material';
import {
  DataGrid,
  GridColDef,
  gridClasses,
  gridDateComparator,
  gridNumberComparator,
} from '@mui/x-data-grid';
import { sv as locale } from '@norban/locale';
import React, { useState } from 'react';

import {
  BofUserGreetingsDocument,
  HomeMessageState,
} from '../../../generated/backend/graphql';

type Props = {
  userId: string;
};

const UserGreetings = ({ userId }: Props) => {
  const L = locale.backoffice;

  const [showOnlyDelivered, setShowOnlyDelivered] = useState(true);

  const { data, error, loading } = useQuery(BofUserGreetingsDocument, {
    variables: {
      filter: {
        fromUserId: userId,
        states: showOnlyDelivered ? [HomeMessageState.Delivered] : undefined,
      },
    },
  });

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'Id',
      sortable: true,
      width: 100,
      valueGetter: ({ value }) => value,
      sortComparator: gridNumberComparator,
    },
    {
      field: 'createdAt',
      headerName: L.userGreetings.created,
      sortable: true,
      width: 150,
      valueGetter: ({ value }) => value,
      renderCell: ({ value }) =>
        value
          ? new Intl.DateTimeFormat('sv-SE', {
              day: 'numeric',
              month: 'short',
              hour: 'numeric',
              minute: 'numeric',
            }).format(new Date(value))
          : '',
      sortComparator: gridDateComparator,
    },
    {
      field: 'toHomeAddress',
      headerName: L.userGreetings.sentTo,
      sortable: true,
      width: 200,
      valueGetter: ({ row }) => row.toHome.address.streetAddress,
    },
    {
      field: 'toHomeId',
      headerName: 'Homeid',
      sortable: true,
      width: 120,
      renderCell: ({ value }) => (
        <a onClick={e => e.stopPropagation()} href={`/homes/${value}`}>
          {value}
        </a>
      ),
      sortComparator: gridNumberComparator,
    },
    {
      field: 'state',
      headerName: L.userGreetings.state,
      sortable: true,
      width: 120,
      valueGetter: ({ value }) => L.homeMessages[value as HomeMessageState],
    },
    {
      field: 'message',
      flex: 1,
      headerName: L.userGreetings.message,
      sortable: true,
      valueGetter: ({ value }) => value,
    },
  ];

  const rows = data?.homeMessages ?? [];

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Alert severity="error">{L.errors.general}</Alert>;
  }

  return (
    <Card>
      <CardContent>
        <FormControlLabel
          control={
            <Switch
              checked={showOnlyDelivered}
              onChange={() => setShowOnlyDelivered(!showOnlyDelivered)}
            />
          }
          label={L.userGreetings.showOnlyDelivered}
        />
      </CardContent>
      <DataGrid
        autoHeight
        columns={columns}
        getRowHeight={() => 'auto'}
        rows={rows}
        sx={{
          [`& .${gridClasses.cell}`]: {
            alignItems: 'flex-start',
            paddingBottom: '10px',
            paddingTop: '10px',
          },
        }}
        disableRowSelectionOnClick
      />
    </Card>
  );
};

export default UserGreetings;
