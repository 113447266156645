import { TextField, FormControlLabel, Box, Switch } from '@mui/material';
import React from 'react';

const LayerPopup = ({
  editMode,
  onEditModeChanged,
  name,
  onNameChanged,
  isPublic,
  onIsPublicChanged,
}) => {
  return (
    <Box mt={4} mb={2} ml={1} mr={1} width={250}>
      <Box mb={2}>
        <TextField
          fullWidth
          value={name}
          onChange={e => onNameChanged(e.target.value)}
          label="Namn"
          variant="outlined"
        />
      </Box>
      <FormControlLabel
        control={
          <Switch
            checked={isPublic}
            onChange={({ target: { checked } }) => onIsPublicChanged(checked)}
            name="Public"
            color="primary"
          />
        }
        label="Publik"
      />
      <FormControlLabel
        control={
          <Switch
            checked={editMode}
            onChange={({ target: { checked } }) => onEditModeChanged(checked)}
            name="Edit mode"
            color="primary"
          />
        }
        label="Editera"
      />
    </Box>
  );
};

export default LayerPopup;
