export const UPDATE_FILTER = 'UPDATE_FILTER';

const actionUpdateFilter = filter => ({
  type: UPDATE_FILTER,
  filter,
});

export const updateFilter = (dispatch, filter) => {
  dispatch(actionUpdateFilter(filter));
};
