import AddIcon from '@mui/icons-material/Add';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import ListAltIcon from '@mui/icons-material/ListAlt';
import MapIcon from '@mui/icons-material/Map';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import { Box, Fab, Tab, Tabs, useMediaQuery, useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/system';
import React from 'react';
import {
  Link,
  Route,
  Switch,
  useLocation,
  useRouteMatch,
} from 'react-router-dom';

import PageHeader from '../../components/PageHeader';
import AreaAgent from '../Agent';
import AreaAgents from '../AreaAgents';
import AreaExport from '../AreaExport';
import AreaList from '../AreaList';
import AreaMap from '../AreaMap';

const useStyles = makeStyles((theme: Theme) => ({
  tabs: {
    background: 'red',
    [theme.breakpoints.down('md')]: {
      background: 'green',
    },
  },
  tab: {
    [theme.breakpoints.down('sm')]: {
      minWidth: '54px',
    },
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(4),
    right: theme.spacing(4),
  },
}));

const Areas = () => {
  const theme = useTheme();
  const smallDisplay = useMediaQuery(theme.breakpoints.down('sm'));

  const classes = useStyles();

  const { path, url } = useRouteMatch();

  const tabs = [
    { icon: <ListAltIcon />, title: 'Alla områden', path: `${url}/list` },
    { icon: <MapIcon />, title: 'Karta', path: `${url}/map` },
    {
      icon: <ImportExportIcon />,
      title: 'Importera / Exportera',
      path: `${url}/export`,
    },
    {
      icon: <SupportAgentIcon />,
      title: 'Mäklare',
      path: `${url}/agents`,
    },
  ];

  const { pathname } = useLocation();

  return (
    <>
      <PageHeader title="Områden" />

      <Box mb={12}>
        <Tabs
          variant={smallDisplay ? 'fullWidth' : 'standard'}
          value={pathname.split('/')[2]}
        >
          {tabs.map(tab => (
            <Tab
              icon={tab.icon}
              key={tab.path}
              className={classes.tab}
              value={tab.path.split('/')[2]}
              label={smallDisplay ? undefined : tab.title}
              component={Link}
              to={tab.path}
            />
          ))}
        </Tabs>

        <Switch>
          <Route path={`${path}/list`}>
            <AreaList />
          </Route>

          <Route path={`${path}/map`}>
            <AreaMap />
          </Route>

          <Route path={`${path}/export`}>
            <AreaExport />
          </Route>

          <Route exact path={`${path}/agents`}>
            <AreaAgents />
          </Route>

          <Route path={`${path}/agents/:id`}>
            <AreaAgent />
          </Route>
        </Switch>
      </Box>
      <Fab
        color="primary"
        aria-label="add"
        className={classes.fab}
        onClick={e => {
          e.preventDefault();
          window.open('/area-new', '_blank', 'noreferrer');
        }}
      >
        <AddIcon />
      </Fab>
    </>
  );
};

export default Areas;
