import { Box, Card, CardContent, TextField } from '@mui/material';
import { grey } from '@mui/material/colors';
import { sv as locale } from '@norban/locale';
import React from 'react';

import useStateWithDelayedCallback from '../hooks/useStateWithDelayedCallback';

type Props = {
  homeId: number | undefined;
  label?: string;
  loading: boolean;
  streetAddress: string | undefined;
  onChangeHomeId: (homeId: number | undefined) => void;
};

const HomeLookup = ({
  homeId,
  label = undefined,
  loading,
  streetAddress,
  onChangeHomeId,
}: Props) => {
  const L = locale.backoffice;

  const [lookupHomeId, setLookupHomeId] = useStateWithDelayedCallback<
    number | undefined
  >(homeId, value => onChangeHomeId(value), 300);

  return (
    <>
      <Box mt={4} mb={2}>
        <TextField
          fullWidth
          label={label ?? L.homeId}
          type="number"
          value={lookupHomeId ?? ''}
          onChange={ev =>
            setLookupHomeId(
              ev.target.value ? parseInt(ev.target.value, 10) : undefined,
            )
          }
        />
      </Box>
      <Box mb={2}>
        <Card
          sx={{
            bgcolor: grey.A200,
          }}
        >
          <CardContent>
            {loading && '...'}
            {!loading && (streetAddress ?? '-')}
          </CardContent>
        </Card>
      </Box>
    </>
  );
};

export default HomeLookup;
