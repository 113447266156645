import { useMutation } from '@apollo/client';
import React, { useCallback } from 'react';

import HandledButton from '../../../components/HandledButton';
import {
  BofMarkChatAsNotHandledDocument,
  BofReleaseAndMarkChatAsHandledDocument,
  ChatChangeOwnership,
} from '../../../generated/backend/graphql';

const ChatHandledButton = ({
  disabled,
  handled,
  ownerId,
  userId,
  onToggle,
}: {
  disabled: boolean;
  handled: boolean;
  ownerId: string | undefined;
  userId: string;
  onToggle: (handled: boolean) => void;
}) => {
  const [releaseAndMarkChatAsHandled] = useMutation(
    BofReleaseAndMarkChatAsHandledDocument,
  );
  const [markChatAsNotHandled] = useMutation(BofMarkChatAsNotHandledDocument);

  const handleClick = useCallback(async () => {
    if (!handled) {
      await releaseAndMarkChatAsHandled({
        variables: {
          userId,
          ownerId,
          type: ChatChangeOwnership.Release,
        },
      });
    } else {
      await markChatAsNotHandled({
        variables: {
          userId,
        },
      });
    }
    onToggle(handled);
  }, [
    handled,
    markChatAsNotHandled,
    onToggle,
    ownerId,
    releaseAndMarkChatAsHandled,
    userId,
  ]);

  return (
    <HandledButton
      disabled={disabled}
      handled={!!handled}
      onClick={handleClick}
    />
  );
};

export default ChatHandledButton;
