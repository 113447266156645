import { ApolloError, useMutation, useQuery } from '@apollo/client';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  IconButton,
  Paper,
  TextField,
  Theme,
  Tooltip,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import React, { useMemo, useState } from 'react';

import AreaSelect from '../../components/form/AreaSelect';
import {
  Area,
  BofAreaAssociatedAreasDocument,
  BofCreateAssociatedAreaDocument,
  BofDeleteAssociatedAreaDocument,
} from '../../generated/backend/graphql';
import usePopupAlert from '../../hooks/usePopupAlert';

const useStyles = makeStyles((theme: Theme) => ({
  fab: {
    position: 'fixed',
    bottom: theme.spacing(4),
    right: theme.spacing(4),
  },
}));

type Props = {
  areaId: string;
  areas: Area[];
};

const AssociatedAreasCard = ({ areaId, areas }: Props) => {
  const classes = useStyles();
  const [addDialogOpen, setAddDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [addArea, setAddArea] = useState<string | null>(null);
  const [deleteId, setDeleteId] = useState<string | undefined>();
  const [confirmId, setConfirmId] = useState<string | undefined>();

  const { data, refetch } = useQuery(BofAreaAssociatedAreasDocument, {
    variables: {
      id: areaId,
    },
  });

  const [createAssociatedArea] = useMutation(BofCreateAssociatedAreaDocument);
  const [deleteAssociatedArea] = useMutation(BofDeleteAssociatedAreaDocument);

  const { PopupAlert, showPopupAlert } = usePopupAlert();

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'Id',
      type: 'string',
      width: 90,
    },
    {
      // associatedArea as a combination of associatedAreaId and associatedAreaName
      field: 'associatedArea',
      headerName: 'Syskonområde',
      type: 'string',
      width: 200,
      valueGetter: ({ row }) => row,
      renderCell: ({ row }) => {
        const { associatedAreaId } = row;
        const { associatedAreaName } = row;
        return `${associatedAreaName} [${associatedAreaId}]`;
      },
    },
    {
      field: 'delete',
      headerName: 'Ta bort',
      valueGetter: ({ row }) => row,
      renderCell: ({ row }) => {
        const { id } = row;

        return (
          <Tooltip title="Ta bort syskonområde">
            <IconButton
              aria-label="delete"
              onClick={evt => {
                evt.stopPropagation();
                setDeleteId(id);
                setDeleteDialogOpen(true);
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        );
      },
    },
  ];

  const associatedAreas = useMemo(() => data?.associatedAreas ?? [], [data]);

  const eigibleAreas = useMemo(() => {
    if (!areas) {
      return [];
    }

    // Deep copy areas
    const copy = JSON.parse(JSON.stringify(areas));

    const excludeAreas = [
      areaId,
      ...associatedAreas.map(a => a.associatedAreaId),
    ];

    // Recursively check if the current area or any of its children
    // includes any of the excludeAreas. If so remove it.
    const removeAreas = (areas: Area[], excludeAreas: string[]): Area[] => {
      const filtered = areas.filter(area => !excludeAreas.includes(area.id));
      return filtered.map(area => ({
        ...area,
        children: removeAreas(area.children ?? [], excludeAreas),
      }));
    };

    return removeAreas(copy, excludeAreas);
  }, [areaId, areas, associatedAreas]);

  return (
    <Paper>
      <Dialog
        open={addDialogOpen}
        onClose={() => setAddDialogOpen(false)}
        fullWidth
      >
        <DialogTitle>Lägg till</DialogTitle>
        <DialogContent>
          <AreaSelect
            areas={eigibleAreas}
            label="Välj område"
            value={addArea}
            name="NAME"
            margin="normal"
            dispatch={({ value }) => setAddArea(value ?? null)}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={async () => {
              try {
                await createAssociatedArea({
                  variables: {
                    areaId,
                    associatedAreaId: addArea ?? '',
                  },
                });
                await refetch();
              } catch (error) {
                const ae = error as ApolloError;
                showPopupAlert(
                  `Error creating associated area: ${ae.message}`,
                  'error',
                );
                console.error(error);
              }
              setAddArea(null);
              setAddDialogOpen(false);
            }}
            disabled={addArea === null}
          >
            OK
          </Button>
          <Button onClick={() => setAddDialogOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
      >
        <DialogTitle>Ta bort</DialogTitle>
        <DialogContent>
          <TextField
            label="Bekräfta id"
            margin="normal"
            value={confirmId}
            onChange={evt => setConfirmId(evt.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={async () => {
              try {
                await deleteAssociatedArea({
                  variables: {
                    id: deleteId ?? '',
                  },
                });
                await refetch();
              } catch (error) {
                const ae = error as ApolloError;
                showPopupAlert(
                  `Error deleting associated area: ${ae.message}`,
                  'error',
                );
                console.error(error);
              }
              setDeleteId(undefined);
              setConfirmId(undefined);
              setDeleteDialogOpen(false);
            }}
            disabled={deleteId !== confirmId}
          >
            Ok
          </Button>
          <Button onClick={() => setDeleteDialogOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>
      <Box p={2}>
        <Typography variant="body1">
          Nedan områden kommer inkluderas i matchningar mot följare.
        </Typography>
      </Box>
      <DataGrid rows={associatedAreas} columns={columns} />
      <Fab
        color="primary"
        aria-label="add"
        className={classes.fab}
        onClick={e => {
          e.preventDefault();
          setAddDialogOpen(true);
        }}
      >
        <AddIcon />
      </Fab>
      <PopupAlert />
    </Paper>
  );
};

export default AssociatedAreasCard;
